import React from 'react'
import Translation from '../../global/Translation'
import Results from './Results'

import { Hits, InitialLoader, RefinementListFilter, SearchBox } from 'searchkit'
import { injectIntl } from 'react-intl'
import Spinner from '../../global/Spinner'
import Select from 'react-select'
import StockResultContainer from './StockResultContainer'
import Button from '../../global/Button'
import TableListResult from "./TableListResult";
import {typeMap} from "../../global/TypeMaps";


class MaterialsList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showHelp: true,
            showOutletModal: this.props.outlet === 'outlet' && this.props.isAdmin
        }
    }

    finishOptions = props => {
        return (
            <Select
                className="border-bottom border-secondary"
                options={props.items.map(item => ({
                    label: this.props.intl.formatMessage({ id: item.key }),
                    value: item.key
                }))}
                value={props.selectedItems[0]}
                onChange={selectedOption => {
                    let selected = selectedOption ? [selectedOption.value] : []
                    props.setItems(selected)
                }}
                placeholder={this.props.intl.formatMessage({id: 'finish'})}
            />
        )
    }

    thicknessOptions = props => {
        return (
            <Select
                className="border-bottom border-secondary"
                options={props.items
                    .sort((a, b) => parseFloat(a.key) - parseFloat(b.key))
                    .map(item => ({
                        label: item.key.replace('.', ',') + ' cm',
                        value: item.key
                    }))}
                value={props.selectedItems[0]}
                onChange={selectedOption => {
                    let selected = selectedOption ? [selectedOption.value] : []
                    props.setItems(selected)
                }}
                placeholder={this.props.intl.formatMessage({id: 'thickness'})}
            />
        )
    }

    rotationOptions = (props) => {
        const getKeyTranslation = key => {
            switch(key) {
                case 'D': return 'rotation_discontinued_material'
                case 'C': return 'rotation_low_rotation'
                case 'R': return 'rotation_discontinued_format'
                case 'X': return 'rotation_material_no_portfolio'
                case 'N': return 'rotation_new_creation'
                case 'F': return 'rotation_discontinued_finish'
                case 'A': return 'rotation_high_rotation'
                case 'B': return 'rotation_medium_rotation'
                case 'S': return 'rotation_slow_moving'
                case 'K': return 'rotation_purchase_sale'
                case '': return 'not_applicable'
                default: return key
            }
        }
        
        return (
            <Select
                className="border-bottom border-secondary"
                options={props.items.map(item => ({
                    label: this.props.intl.formatMessage({ id: getKeyTranslation(item.key) }),
                    value: item.key
                }))}
                value={props.selectedItems[0]}
                onChange={selectedOption => {
                    let selected = selectedOption ? [selectedOption.value] : []
                    props.setItems(selected)
                }}
                placeholder={this.props.intl.formatMessage({id: 'rotation'})}
            />
        )
    }

    outletOptions = props => {
        return (
            <Select
                className="border-bottom border-secondary"
                options={[
                    {
                        label: this.props.intl.formatMessage({ id: 'only_outlet', defaultMessage: 'Only Outlet' }),
                        value: true
                    },
                    {
                        label: this.props.intl.formatMessage({ id: 'without_outlet', defaultMessage: 'Without Outlet' }),
                        value: false
                    }
                ]}
                value={props.selectedItems[0]}
                onChange={selectedOption => {
                    let selected = selectedOption ? [selectedOption.value] : []
                    props.setItems(selected)
                }}
                placeholder={this.props.intl.formatMessage({id: 'outlet'})}
            />
        )
    }

    brandOptions = props => {
        const typeMap = {
            SERVICES: <Translation id="operaciones" defaultMessage="Operaciones" />,
            DEKTON: this.props.selectedCenter === 7130 ? 'DKTN' : 'Dekton'
        }
        return (
            <Select
                className="border-bottom border-secondary"
                options={props.items.map(item => ({
                    label: typeMap[item.key] || item.key,
                    value: item.key
                }))}
                value={props.selectedItems[0]}
                onChange={selectedOption => {
                    let selected = selectedOption ? [selectedOption.value] : []
                    props.setItems(selected)
                }}
                placeholder={this.props.intl.formatMessage({ id: 'brands' })}
            />
        )
    }

    typeOptions = props => {
        const sorting = {
            '01': { val: 0 },
            '08': { val: 1 },
            '07': { val: 2 },
            '09': { val: 3 },
            '12': { val: 4 },
            '04': { val: 5 },
            '03': { val: 6 },
            '05': { val: 7 },
            '02': { val: 9999 }
        }
        props.items.sort((a, b) => {
            if (b.key === '02') return -1
            else if (sorting[b.key] && sorting[a.key]) return sorting[a.key].val - sorting[b.key].val
            else return 1
        })
        return (
            <Select
                className="border-bottom border-secondary"
                options={props.items
                    .filter(bucket => typeMap[bucket.key])
                    .map(item => ({
                        label: typeMap[item.key],
                        value: item.key
                    }))}
                value={props.selectedItems[0]}
                onChange={selectedOption => {
                    let selected = selectedOption ? [selectedOption.value] : []
                    props.setItems(selected)
                }}
                placeholder={this.props.intl.formatMessage({id: 'type'})}
            />
        )
    }

    colorOptions = props => {
        return (
            <Select
                className="border-bottom border-secondary"
                options={props.items.map(item => ({ label: item.key, value: item.key }))}
                value={props.selectedItems[0]}
                onChange={selectedOption => {
                    let selected = selectedOption ? [selectedOption.value] : []
                    props.setItems(selected)
                }}
                placeholder={this.props.intl.formatMessage({id: 'color'})}
            />
        )
    }

    render() {
        let LANG_TO_INDEX = {
            da: 'EN',
            de: 'DE',
            'en-US': 'Z2',
            en: 'EN',
            es: 'ES',
            fi: 'EN',
            'fr-CA': 'Z2',
            'en-CA': 'Z2',
            fr: 'FR',
            it: 'IT',
            nl: 'NL',
            no: 'EN',
            pt: 'PT',
            pl: 'EN',
            sv: 'SV',
            tr: 'TR'
        }

        let hits
        if (this.props.productId) {
            hits = <Hits hitsPerPage={8} itemComponent={<StockResultContainer {...this.props}/>} key={this.props.selectedMaterials} />
        } else {
            const listComponent = <TableListResult {...this.props}/>
            hits = <Hits hitsPerPage={8} listComponent={listComponent}/>
        }

        if (!this.props.sk) {
            return null
        }

        return (
            <section id="section-catalog" className={this.props.outlet ? 'outlet col' : 'col'}>
                {!this.props.productId && (
                    <div className="row pb-3">
                        <div className="col-12 col-lg-6">
                            <div className="promotion__material-list">
                                <SearchBox
                                    searchThrottleTime={2000}
                                    searchOnChange={true}
                                    queryFields={[
                                        'colorId',
                                        'colorName',
                                        'description',
                                        'brand',
                                        'thickness',
                                        'finish',
                                        'finishFamily',
                                        'color',
                                        'type',
                                        'productId',
                                        'productName',
                                        'subtypeName',
                                        'format',
                                        'bigAccountsErpCode',
                                        `translations.${LANG_TO_INDEX[this.props.intl.locale]}.displayName`,
                                        `translations.${LANG_TO_INDEX[this.props.intl.locale]}.colorName`,
                                        `translations.${LANG_TO_INDEX[this.props.intl.locale]}.productName`,
                                        `translations.${LANG_TO_INDEX[this.props.intl.locale]}.indRotation`,
                                        `colorFormulations.colorName`
                                    ]}
                                    queryOptions={{ default_operator: 'AND' }}
                                    placeholder={this.props.intl.formatMessage({ id: 'search' })}
                                />
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <Button onClick={this.props.selectAllMaterials}>
                                <Translation id="select_all" defaultMessage="Select all" />
                            </Button>
                        </div>
                        <div className="col-6 col-lg-3">
                            <Button onClick={this.props.removeAllMaterials}>
                                <Translation id="remove_all" defaultMessage="Remove all" />
                            </Button>
                        </div>
                    </div>
                )}
                <div className={this.props.productId ? "" : "filter-and-results-container"}>
                    <div className="container p-0">
                        {!this.props.productId && (
                            <div className="row">
                                <div className="col-12 col-lg mb-1">
                                    <RefinementListFilter
                                        id="type"
                                        field={`${this.props.mktools ? 'typeId.raw' : 'type.raw'}`}
                                        operator="OR"
                                        listComponent={this.typeOptions}
                                        size={9999}
                                        orderKey="_term"
                                    />
                                </div>
                                <div className="col-6 col-lg mb-1">
                                    <RefinementListFilter
                                        id="brands"
                                        field="brand.raw"
                                        operator="OR"
                                        listComponent={this.brandOptions}
                                        size={9999}
                                    />
                                </div>
                                <div className="col-6 col-lg mb-1">
                                    <RefinementListFilter
                                        id="color"
                                        field="colorName.raw"
                                        operator="OR"
                                        listComponent={this.colorOptions}
                                        size={9999}
                                        orderKey="_term"
                                    />
                                </div>
                                <div className="col-6 col-lg mb-1">
                                    <RefinementListFilter
                                        id="finish"
                                        field="finish.raw"
                                        operator="OR"
                                        listComponent={this.finishOptions}
                                        size={9999}
                                    />
                                </div>

                                <div className="col-6 col-lg mb-1">
                                    <RefinementListFilter
                                        id="thickness"
                                        field="thickness.raw"
                                        operator="OR"
                                        listComponent={this.thicknessOptions}
                                        size={9999}
                                        // bucketsTransform={this.thicknessTransform}
                                    />
                                </div>
                                <div className="col-6 col-lg mb-1">
                                    <RefinementListFilter
                                        id="rotation"
                                        field="indRotation.raw"
                                        operator="OR"
                                        // itemComponent={RefOptionsComponent}
                                        listComponent={this.rotationOptions}
                                        size={9999}
                                        // bucketsTransform={this.thicknessTransform}
                                    />
                                </div>
                                <div className="col-6 col-lg mb-1">
                                    <RefinementListFilter
                                        id="outlet"
                                        field="outlet.raw"
                                        operator="OR"
                                        listComponent={this.outletOptions}
                                        size={9999}
                                        // bucketsTransform={this.thicknessTransform}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-12">
                                <InitialLoader component={() => <Spinner isLoading={this.props.sk} relative />} />

                                <Results hits={hits} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default injectIntl(MaterialsList)
