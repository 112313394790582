import { connect } from 'react-redux'
import * as ui from '../../store/ui/actions'
import * as segmentation from '../../store/segmentation/actions'

import * as seg from '../../store/segmentation/reducers'
import { injectIntl } from 'react-intl'
import { getSalesOrg, getSelectedCenter } from '../../store/centers/reducers'
import { showingSegmentationModal, getUICallback, getMode, getLocalizedUrl } from '../../store/ui/reducers'
import { getImpersonatedBy } from '../../store/login/reducers'
import SegmentationSelector from './SegmentationSelector'
import { byProductId, isExclusiveHomeDepot } from '../../store/products/reducers'
import {getChannelOptionsV3, getSelectedProjectId, getSelectedSalesForceName} from "../../store/segmentation/reducers";
import { getProductById } from "../../store/products/actions";
import {isOptionalBuilderProjectCenter} from "../../store/centers/reducers";

const mapStateToProps = (state, props) => {
    const channelForV3 = seg.getSelectedChannel(state)
    const product = byProductId(state, seg.getSegmentatingProductId(state))
    const productColorId = product && product.colorId
    return {
        segmentationV3: seg.getHasSegmentationV3(state),
        key: getSelectedCenter(state),
        mode: getMode(state),
        isSaving: seg.getIsSaving(state),
        isLoading: seg.getIsLoading(state),
        show: showingSegmentationModal(state),
        currentSegmentation: seg.getCurrentSegmentation(state),
        callback: getUICallback(state),
        segmentatingProductId: seg.getSegmentatingProductId(state),
        segmentatingProductIsOutlet: seg.getSegmentatingProductIsOutlet(state),
        validHomeDepotSegmentation: seg.validHomeDepotSegmentation(state),
        businessSegment: seg.getSelectedBusinesSegment(state),
        currentFavourite: seg.getCurrentFavourite(state),
        channel: seg.getSelectedChannel(state),
        subchannel: seg.getSelectedSubchannel(state),
        salesforceId: seg.getSelectedSalesForceId(state),
        projectId: seg.getSelectedProjectId(state),
        projectOptions: seg.getProjectIdV3(state, seg.getSelectedChannel(state), seg.getSelectedSalesForceId(state)),
        typology: seg.getSelectedTypology(state),
        kb: seg.getSelectedKB(state),
        builder: seg.getSelectedBuilder(state),
        use: seg.getSelectedUse(state),
        useOptions: seg.getUseOptions(state),
        buildersOptions: seg.getBuildersOptions(state),
        preferredSegmentation: seg.getPreferredSegmentation(state),
        sfOptions: seg.getHasSegmentationV3(state) ? seg.getSalesforceRelationsV3(state, channelForV3) : [],
        supplierCenterSalesOrg: getSalesOrg(state),
        isExclusiveHomeDepot: isExclusiveHomeDepot(state, productColorId),
        viewFullSegmentation: getSalesOrg(state) === '2011' || getSalesOrg(state) === '2032' || getSalesOrg(state) === '2037' || getImpersonatedBy(state) ? true : false,
        viewFullSegmentationV3: getImpersonatedBy(state) ? true : false,
        localizedUrl: (routeId) => getLocalizedUrl(state, props.intl, routeId),
        isOptionalBuilderProjectCenter: isOptionalBuilderProjectCenter(state),
        salesForceName: getSelectedSalesForceName(state),
        channelOptionsV3: getChannelOptionsV3(state),
        savedSegmentations: state.segmentation.preferredSegmentation
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clearSegmentation: () => {
            dispatch(segmentation.clearSegmentation())
        },
        onClose: () => {
            dispatch(ui.closeSegmentationModal())
        },
        setPO: value => {
            dispatch(segmentation.setPO(value))
        },
        onSubmitSegmentation: callback => {
            dispatch(segmentation.submittSegmentationModal(callback))
        },
        fetchSegmentation: () => {
            dispatch(segmentation.fetchSegmentation())
        },
        loadPreferredSegmentation: currentFavourite => {
            dispatch(segmentation.loadPreferredSegmentation(currentFavourite))
        },
        saveSegmentationPreferred: currentFavourite => {
            dispatch(segmentation.saveSegmentationPreferred(currentFavourite))
        },
        deletePreferredSegmentation: currentFavourite => {
            dispatch(segmentation.deletePreferredSegmentation(currentFavourite))
        },
        getProductById: (productId) => dispatch(getProductById(productId))
    }
}
const SegmentationContainer = injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SegmentationSelector)
)

export default SegmentationContainer
