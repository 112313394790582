import { connect } from 'react-redux'
import { showMenuSelector, getTranslateMode } from '../../store/ui/reducers'
import { fetchCenterPreferences } from '../../store/centers/actions'
import { canViewCatalog, getSelectedCenter, getIsLowesCenter, getLowesCenterId } from '../../store/centers/reducers'
import { getUserType, getIsLowesAdmin } from '../../store/login/reducers'
import { fetchInfoEnv } from '../../store/infoEnv/actions'
import { getInfoEnv } from '../../store/infoEnv/reducers'
import { getMaintenanceMode } from '../../store/maintenance-mode/reducers'
import { toggleTranslateMode } from '../../store/ui/actions'
import { fetchUserLandings } from '../../store/landing/actions'
import HeaderV2 from "./HeaderV2";

const mapStateToProps = state => {
    return {
        showMenu: showMenuSelector(state),
        canViewCatalog: canViewCatalog(state),
        center: getSelectedCenter(state),
        userType: getUserType(state),
        infoDev: getInfoEnv(state),
        maintenanceMode: getMaintenanceMode(state),
        isLowesCenter: getIsLowesCenter(state),
        isLowesAdmin: getIsLowesAdmin(state),
        translateMode: getTranslateMode(state),
        LowesCenterId: getLowesCenterId()
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCenterPreferences: () => {
            dispatch(fetchCenterPreferences())
        },
        fetchUserLandings: () => {
            dispatch(fetchUserLandings())
        },
        fetchInfoEnv: () => dispatch(fetchInfoEnv()),
        toggleTranslateMode: () => dispatch(toggleTranslateMode())
    }
}

const HeaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderV2)

export default HeaderContainer
