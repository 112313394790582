import SilestoneImg from '../../assets/img/silestone.svg';
import SilestoneImgWhite from '../../assets/img/silestone-white.svg';
import DektonImg from '../../assets/img/dekton.svg';
import DektonImgWhite from '../../assets/img/dekton-white.svg';
import SensaImg from '../../assets/img/sensa.svg';
import SensaImgWhite from '../../assets/img/sensa-white.svg';
import NaturalStoneImg from '../../assets/img/natural-stone-black.svg';
import NaturalStoneImgWhite from '../../assets/img/natural-stone.svg';
import {clone} from "lodash";

export const LANDING_TEMPLATES_IDS = {
    TEMPLATE_1: '1633bb39-e784-4bd6-9973-61cd53a27fb6',
    TEMPLATE_2: '0ed8493b-65d2-4944-96f6-c9b01e0a9e11',
}

export const TYPE_FILTERS = {
    BRAND: 'brand',
    THICKNESS: 'thickness',
    FORMAT: 'format',
};

export const SORT_OPTIONS = [
    { text: 'Stock ascendiente', id: 'STOCK_ASC' },
    { text: 'Stock descendiente', id: 'STOCK_DESC' },
];

export const SORT_CLEAR_OPTION = { text: 'Limpiar', id: '' };


// const BRAND_WITH_IMAGES = ['silestone', 'dekton'];

const BRAND_IMAGES = {
    'silestone': SilestoneImg,
    'silestone-white': SilestoneImgWhite,
    'dekton': DektonImg,
    'dekton-white': DektonImgWhite,
    'sensa': SensaImg,
    'sensa-white': SensaImgWhite,
    'natural-stone': NaturalStoneImg,
    'natural-stone-white': NaturalStoneImgWhite,
};

export const removeNullString = (value) => value && value !== 'null' ? value : '';

export const getFiltersByKey = (products = [], key = '') => products
    .reduce((filters, product) => {
        const value = removeNullString(product[key])
        if (!value || filters.indexOf(value) > -1) {
            return filters;
        }
        return [...filters, value]
    }, []);

export const isProductSoldOut = product => {
    const stock = getProductStock(product);
    return !(!!stock) || stock === 0
    // return product && product.productLanding && product.data && ((product.data.outlet && ((!product.data.isStockOutlet && product.productLanding.stock == 0) || (product.data.isStockOutlet && product.productLanding.stock_outlet == 0))) || (!product.data.outlet && product.productLanding.stock == 0));
}

export const isProductOutlet = product => product && product.data && product.data.outlet && product.data.isStockOutlet;

export const getProductStock = product => {

    if (product && product.productLanding && product.data) {
        if (product.data.mall === 'X') {
            if (product.productId == "248071") console.log('STOCK MALL')
            if (product.data.lots) {
                if (product.productId == "248071") console.log('STOCK MALL CON LOTS', product)
                return (product.productsLots && product.productsLots[product.id]) ? product.productsLots[product.id].stock : 0
            }
            if (product.productId == "248071") console.log('STOCK MALL SIN LOTS')
            else return product.productLanding.stock_mall
        }
        return !!isProductOutlet(product) ? product.productLanding.stock_outlet : product.productLanding.stock
    }

    if (product && product.productId == "248071") console.log('NO STOCK')


    return ''
    // product && product.productLanding && product.data ? (product.data.outlet ? (product.data.isStockOutlet ? product.productLanding.stock_outlet : product.productLanding.stock) : product.productLanding.stock) : '';

}

export const getProductQtyInCart = (cart, product) => {
    let qty = 0;

    const matches = cart.filter(l => l.productId === product.productId || l.productId === product.product_id)

    if (matches.length > 0) {
        matches.forEach(l => {
            qty += l.qty;
        })
    }

    return qty;
}

export const getMaxQtyLeftProduct = (cart, product) => {
    const maxQty = product.realStock;
    let totalQty = 0;
    let maxAvailable = 0;

    const matches = cart.filter(
        l =>
            (l.productId === product.productId || l.productId === product.product_id) &&
            l.extra &&
            l.extra.outlet === (product.outlet ? product.outletQuality : null)
    )

    matches.forEach(l => {
        totalQty += l.qty;
    })

    maxAvailable = maxQty - totalQty;

    return maxAvailable;
}

export const canAddCartLine = (cart, product, qty = 0) => getMaxQtyLeftProduct(cart, product) >= qty;

export const getFilters = (products = []) => ({
    ...Object.keys(TYPE_FILTERS).
        reduce((filters, filterKey) => ({
            ...filters,
            [TYPE_FILTERS[filterKey]]: getFiltersByKey(products || [], TYPE_FILTERS[filterKey]),
        }), {}),
});

export const generateFiltersState = () => ({
    ...Object.keys(TYPE_FILTERS).
        reduce((filters, filterKey) => ({
            ...filters,
            [TYPE_FILTERS[filterKey]]: [],
        }), {}),
})

export const isFilterSelected = (filterGroup = [], filterValue) => filterGroup.indexOf(filterValue) > -1;

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const hasImage = (brand) => BRAND_IMAGES[brand]

export const getBrandNameWithoutSpaces = (brand) => brand.toLowerCase().split(' ').join('-');


export function getMallProduct (productFromMall = {}, productsFromCatalog = [], productsLots = {}) {

    console.log('catalogProduct_' , productFromMall)
    const data = productFromMall.data ? (typeof productFromMall.data === 'string' ? JSON.parse(productFromMall.data) : productFromMall.data) : productFromMall;
    const catalogProduct = clone(productsFromCatalog.find(prod => prod.productId === productFromMall.product_id || prod.productId === productFromMall.productId));

    console.log('catalogProduct_' + data.displayName)
    // console.log('catalogProduct_' + data.displayName, data)

    if (!catalogProduct) return null

    const minimumOrder = productFromMall.minimumOrder ? productFromMall.minimumOrder : parseInt(productFromMall.minimum_order)

    catalogProduct.langingProductId = productFromMall.landing_common_id ? productFromMall.id : productFromMall.langingProductId
    catalogProduct.landingId = productFromMall.landing_common_id ? productFromMall.landing_common_id : data.landingId
    catalogProduct.minimumOrder = isNaN(minimumOrder) ? 0 : minimumOrder
    catalogProduct.detailImage = productFromMall.detail_image_uri || productFromMall.catalog_image
    catalogProduct.backgroundImage = productFromMall.background_image_uri || productFromMall.catalog_image
    catalogProduct.thumbnailImage = productFromMall.thumbnail_image_uri || productFromMall.catalog_image
    catalogProduct.imgLarge = (data && data.imgLarge) || productFromMall.background_image_uri || productFromMall.catalog_image
    catalogProduct.name = capitalize((productFromMall.name || '').toLowerCase())
    catalogProduct.brandWithoutSpaces = getBrandNameWithoutSpaces(productFromMall.brand)
    catalogProduct.hasImage = hasImage(getBrandNameWithoutSpaces(productFromMall.brand))
    catalogProduct.hasImageWhite = hasImage(`${getBrandNameWithoutSpaces(productFromMall.brand)}-white`)
    catalogProduct.promotion = removeNullString(productFromMall.promotion)
    catalogProduct.format = removeNullString(productFromMall.format)
    catalogProduct.thickness = removeNullString(productFromMall.thickness)
    catalogProduct.finish = removeNullString(productFromMall.finish)
    catalogProduct.centerId = data ? data.centerId : ''
    catalogProduct.outlet = data ? (data.isStockOutlet ? true : false) : catalogProduct.outlet
    catalogProduct.outletQuality = data ? (catalogProduct.outlet && data.outletQuality) : catalogProduct.outletQuality
    catalogProduct.productLots = productsLots[catalogProduct.langingProductId]
    catalogProduct.mallProductId = productFromMall.id
    catalogProduct.realStock = data.lots ? (catalogProduct.productLots ? catalogProduct.productLots.stock : 0) : (catalogProduct.productLots ? catalogProduct.productLots.stock : catalogProduct.stock_mall)
    catalogProduct.additionalInfo = data.additionalInfo
    catalogProduct.fromMall = true

    // console.log('catalogProduct_' + catalogProduct.name, data, catalogProduct)

    return catalogProduct
}

export const getFilteredProductsByType = (products = [], type, filterByType = []) =>
    filterByType && filterByType.length ?
        products.filter(product => filterByType.indexOf(product[type]) > - 1) :
        products
    ;

const sortByStockAsc = (products = []) => (prod1, prod2) => prod1.realStock - prod2.realStock
const sortByStockDesc = (products = []) => (prod1, prod2) => prod2.realStock - prod1.realStock;

const getSortFunction = (sortSelected, products = []) => {
    switch (sortSelected) {
        case 'STOCK_ASC':
            return sortByStockAsc(products)
        case 'STOCK_DESC':
            return sortByStockDesc(products)
    }
    return () => { }
}

export const sortDataBy = (products, sortSelected) => {
    if (!sortSelected) {
        return products;
    }
    return products.sort(getSortFunction(sortSelected, products));
}

export const getFilteredProducts = ({
    filters = {},
    products = [],
    sortSelected = ''
}) => {
    return sortDataBy(
        Object.keys(filters)
            .reduce((filteredProducts, type) => getFilteredProductsByType(
                filteredProducts, type, filters[type]
            ), [...products]),
        sortSelected
    );
}

export const generateUnitsData = (from = 1, to) => [...Array(to).keys()].slice(from);

