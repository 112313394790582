import React from 'react'

import { injectIntl } from 'react-intl'
import { showBannerOldVersion } from '../../store/ui/reducers'
import { connect } from 'react-redux'
import ProfileContainer from '../profile/ProfileContainer'

const mapStateToProps = state => {
    return {
        showBanner: showBannerOldVersion(state)
    }
}

class UserProfileModal extends React.Component {

    render(){
        return (
            <div>
                <div className="modal-overlay" style={{
                    display: this.props.show ? 'block' : 'none',
                }} onClick={this.props.onClose} />
                <div id="user-profile-modal-container" className="user-profile user-profile-modal-container-v2" style={{ display: this.props.show ? 'block' : 'none' }}>
                    <ProfileContainer />
                </div>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(UserProfileModal)
)