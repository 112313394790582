import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import ProfileContainer from '../profile/ProfileContainer'
import RectangleResultContainer from '../catalog/rectangleResult/RectangleResultContainer'
import LocalizedLink from '../global/LocalizedLink'
import Spinner from '../global/Spinner'
import favoritos from '../../assets/img/favoritos.png'

class ProductsFavourite extends React.Component {
    componentDidMount() {
        this.props.fetchProductsFavourite()
    }

    render() {
        const { products } = this.props

        return (
            <div className="box-profile row">
                <div className="box-profile-menu col-12 col-md-4 col-lg-3">
                    <ProfileContainer />
                </div>
                <div className="box-profile-form col-12 col-xl-9">
                    <div className="profile-form" style={{backgroundColor: 'white'}}>
                        {this.props.isLoading && <Spinner isLoading={this.props.isLoading} />}

                        {products && products.length > 0 ? (
                            <div className="row last-colors">
                                <div className="col-12">
                                    <div className="container result_products_favourite">
                                        {products.map(product => {
                                            return <RectangleResultContainer product={product} key={product.productId}/>
                                        })}
                                        <div className='box-favourite-add'>
                                            <span className='mb-5 box-favourite-add-text'><Translation
                                                id="add_more_favorite_products_here_to_find_them_more_quickly"
                                                defaultMessage="Add more favorite products here to find them more quickly"></Translation></span>
                                            <LocalizedLink routeId="ROUTE_CATALOG" params={{outlet: ''}}>
                                                <button className='btn-primary-m'>
                                                    <Translation id="keep_buying" defaultMessage="Keep Buying"/>
                                                </button>
                                            </LocalizedLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='box-favourite'>
                                <div className='box-image-favourite'>
                                    <img width={'225px'} src={favoritos} alt=''/>
                                </div>
                                <div className='box-text-favourite'>
                                    <h1 className='title-favourite'><Translation id="your_favourite_list_is_empty"
                                                                                 defaultMessage="Your favourite list is empty"/>
                                    </h1>
                                    <p className='text-favourite'><Translation id="add_favorite_products_here_find_them"
                                                                               defaultMessage="Add your favorite products here to find them faster"/>
                                    </p>
                                    <p className='text-2-favourite'><Translation
                                        id="click_the_heart_icon_each_item_will_be_automatically_added"
                                        defaultMessage="Just click on the heart icon in the upper right corner of each item and it will be automatically added to this section"/>
                                    </p>
                                    <LocalizedLink routeId="ROUTE_CATALOG" params={{outlet: ''}}>
                                        <button className='btn-primary-m'>
                                            <Translation id="keep_buying" defaultMessage="Keep Buying"/>
                                        </button>
                                    </LocalizedLink>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(ProductsFavourite)
