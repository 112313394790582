import React from 'react'

import { NoHits } from 'searchkit'
import { injectIntl, defineMessages } from 'react-intl'

defineMessages({
    displayName: {
        id: 'displayName',
        description: 'displayName',
        defaultMessage: 'Product Name'
    }
})

class Results extends React.Component {
    render() {

        return (
            <div className="results" style={{marginTop: '16px'}}>
                <div className="results-list text-center text-sm-left">
                    {this.props.hits}
                    <NoHits />
                </div>
            </div>
        )
    }
}

export default injectIntl(Results)