import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { addHistoryAction } from '../global/history'
import { fetchCutToSizeMainParams } from '../../store/cut-to-size/actions'
import {getCutToSizeMaterials} from '../../store/cut-to-size/reducers'
import ElaborationTypeSelector from './ElaborationTypeSelector'
import CutToSizeFormBudget from './CutToSizeFormBudget'
import CutToSizeFormFurniture from "./CutToSizeFormFurniture";
import CutToSizeFormDefault from "./CutToSizeFormDefault";
import CutToSizeFormPreOrder from "./CutToSizeFormPreOrder";
import { dispatchPushURL } from '../../store/ui/actions'
import {canViewPreorder} from "../../store/login/reducers";

const mapStateToProps = (state, { match }) => {
    return {
        materials: getCutToSizeMaterials(state),
        hasPreOrder: canViewPreorder(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchMainParams: () => {
            return dispatch(fetchCutToSizeMainParams())
        },
        returnURL: (url) => {
            return dispatch(dispatchPushURL(url, 1))
        }
    }
}

class CutToSize extends Component {
    state = {
        selectedElaboration: null,
        showForm: false
    }

    goToSection = () => {
        if (window.location.search) {
            const type = this.getElaborationTypeFromUrlParams( window.location.search)
            if (type) this.handleSelectType(type)
        }
    }

    getElaborationTypeFromUrlParams = (params) => {
        const regex = /[?]type=([^&]*)/
        const type = regex.exec(params)
        return type && type[1].toLocaleUpperCase()
    }

    componentDidMount() {
        if (!this.props.materials) this.props.fetchMainParams().then(this.goToSection)
        else this.goToSection()

        const handleNavigation = location => {
            if (this.props.materials) {
                const type = this.getElaborationTypeFromUrlParams(location.search)
                if (type) this.handleSelectType(type)
                else this.setState({ selectedElaboration: null, showForm: false })
            }
        }

        addHistoryAction('CUT_TO_SIZE', handleNavigation)
    }
    componentDidUpdate( prevProps, prevState, snapshot ) {       
        
        if (this.props.materials) {
            const type = this.getElaborationTypeFromUrlParams(window.location.search)
            if (this.state.selectedElaboration !== type) {
                if (type) this.handleSelectType(type)
                else this.setState({ selectedElaboration: null, showForm: false })
            }          
        }       
       
    }

    handleSelectType = elaborationType => {
        this.setState({ selectedElaboration: elaborationType, showForm: true })
    }

    render() {
        const { selectedElaboration, showForm } = this.state
        const { materials, hasPreOrder } = this.props
        let component = <ElaborationTypeSelector materials={materials} hasPreOrder={hasPreOrder}/>
        if (showForm) {
            if (selectedElaboration === 'BUDGET') {
                component = <CutToSizeFormBudget selectedElaboration={selectedElaboration}/>
            } else if (selectedElaboration === 'PREORDER') {
                component = <CutToSizeFormPreOrder selectedElaboration={selectedElaboration}/>
            } else if (selectedElaboration === 'MOBILIARIO' || selectedElaboration === 'CUSTOM_MOBILIARIO') {
                component = <CutToSizeFormFurniture selectedElaboration={selectedElaboration}/>
            } else {
                component = <CutToSizeFormDefault selectedElaboration={selectedElaboration}/>
            }
        }
        return (
            <section className="cut_to_size">
                <div className="mt-4 container">{component}</div>
            </section>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CutToSize)
)
