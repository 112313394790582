import React from 'react'
import Translation from '../../global/Translation'
import { injectIntl } from 'react-intl'

import 'react-table/react-table.css'
import Select from 'react-select'
import { impersonate } from '../../../store/login/actions'
import {  fetchUsersToImpersonateByCenter } from '../../../store/impersonate/actions'
import { connect } from 'react-redux'
import GoBack from '../../global/GoBack'
import { canImpersonate, centerUsers } from '../../../store/impersonate/reducers'
import { getImpersonatedBy, getUserName } from '../../../store/login/reducers'
import Button from '../../global/Button'
import { fetchRefreshUserPriceList } from '../../../store/products/actions'
import { getSelectedCenter } from '../../../store/centers/reducers'
import { getLandingBySlug } from '../../../store/landing/reducers'

const mapStateToProps = state => {
    return {
        users: centerUsers(state),
        canImpersonate: canImpersonate(state),
        userName: getUserName(state),
        impersonatedBy: getImpersonatedBy(state),        
        center: getSelectedCenter(state),
        landing: getLandingBySlug(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        impersonate: (userId, stay) => {
            dispatch(impersonate(userId, true))
        },        
        fetchUsersToImpersonateByCenter: (center) => {
            dispatch(fetchUsersToImpersonateByCenter(center))
        },
        fetchRefreshPricesList: () => {
            dispatch(fetchRefreshUserPriceList())
        }
    }
}
class ImpersonateBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = { userSelected: null, centerSelected: null }
    }
    componentDidMount() {
        if (this.canShow()) this.props.fetchUsersToImpersonateByCenter(this.props.center)
        this.setState({centerSelected : this.props.center})
    }
    componentWillUnmount() {
        if (this.props.isAdmin) {
            // this.props.removePriceList() impersonate impersonatedby
        }
    }

    canShow = () => this.props.canImpersonate && (this.props.impersonatedBy == 'OUTLET' || this.props.userName == 'OUTLET')
    render() {
        if (!this.canShow()) return null
        return (
            <div className='container impersonate-bar-v2'>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mall-campaigns_preview">
                            <Translation id="campaigns_preview" defaultMessage="Campaigns Preview" />
                        </h2>
                    </div>
                    <div className="col-12 mb-2">
                        <GoBack></GoBack>
                    </div>
                    <div className="col-12 col-md-4 pt-1">
                        <Button
                            disabled={this.props.userName == 'OUTLET'}
                            onClick={this.props.fetchRefreshPricesList}>
                            <Translation id="Reset" defaultMessage="Reset" />
                        </Button>
                    </div>

                    {
                        this.props.landing && (
                            <div className="col-12 col-md-4 pt-1 select-center">
                                <Select
                                    clearable = {false}
                                    placeholder={this.props.intl.formatMessage({ id: 'promotion_select_center' })}
                                    options={
                                        this.props.landing.landing && this.props.landing.landing.centerNames &&
                                        Object.entries(this.props.landing.landing.centerNames).map(([k,v]) => {
                                            return { label: v, value: k }
                                        })
                                    }
                                    value={this.state.centerSelected}
                                    onChange={e => {
                                        if (e) {
                                            this.setState({ centerSelected: e.value }, () => {
                                                this.props.fetchUsersToImpersonateByCenter(e.value)
                                            })
                                        }
                                    }}
                                />
                            </div>
                        )
                    }
                    
                    <div className="col-12 col-md-4 pt-1 select-user">
                        <Select
                            placeholder={this.props.intl.formatMessage({ id: 'promotion_select_user' })}
                            options={
                                this.props.users &&
                                this.props.users.map(item => {
                                    return { label: item.name + ' (' + item.login + '-' + item.center + ')', value: item.login }
                                })
                            }
                            value={this.state.userSelected}
                            onChange={e => {
                                if (e) {
                                    this.setState({ userSelected: e.value }, () => {
                                        this.props.impersonate(this.state.userSelected)
                                    })
                                }
                            }}
                        />
                    </div>                  
                </div>
                <br />
            </div>
        )
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ImpersonateBar)
)
