import React from 'react'
import Translation from '../../global/Translation'

import Button from '../../global/Button'
import ReactTable from 'react-table'
import { injectIntl } from 'react-intl'

import 'react-table/react-table.css'
import Spinner from '../../global/Spinner'
import confirm from '../../global/confirm'
import FormatCSVModal from '../../global/FormatCSVModal'
import axios from 'axios'
import { showBannerOldVersion } from '../../../store/ui/reducers'
import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        showBanner: showBannerOldVersion(state)
    }
}

class ImpersonateModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = { search: '' }
    }
    componentDidMount() {
        this.props.fetchUsersToImpersonate()
    }
    confirmImpersonate(original, onImpersonate, intl) {
        confirm(intl.formatMessage({ id: 'IMPERSONATE_CONFIRM' }), {intl: intl}).then(
            confirm => {
                onImpersonate(original.login)
            },
            cancel => {}
        )
    }
    
    closeFormatCSVModal(format) {
        this.setState({ showCSVFormat: false })

        const FileDownload = require('js-file-download');
        axios.get(process.env.REACT_APP_API_HOST + `/customers/csv?format=${format}`,
            {headers: 
                {Authorization: `Bearer ${this.props.token}`},
                'Content-Type': 'text/plain',
                responseType: 'blob'
            })
        .then((response) => {
                FileDownload(response.data, `customers.${format}`);
        });
    }

    render() {
        const { show, onClose, onImpersonate, impersonatedBy, isLogging, isLoading, languageDir } = this.props

        if (this.props.users === null) {
            return null
        }

        let usersFiltered = null

        if (this.state.search) {
            usersFiltered = this.props.users
            usersFiltered = usersFiltered.filter(row => {
                return row.login.toLowerCase().includes(this.state.search.toLowerCase()) || row.name.toLowerCase().includes(this.state.search.toLowerCase())
            })
        }
        const users = usersFiltered !== null ? usersFiltered : this.props.users

        const deimpersonate = impersonatedBy ? (
            <Button onClick={() => onImpersonate(impersonatedBy)}>
                <Translation id="back_to" defaultMessage="Back to" />
                &nbsp;{impersonatedBy}
            </Button>
        ) : null
        const spinner = isLogging ? <Spinner isLoading={isLogging} /> : null
        const columns = [
                
            { Header: <Translation id="customer_id" defaultMessage="Customer id" />, accessor: 'login', minWidth: 80},
            { Header: <Translation id="customer_name" defaultMessage="Customer name" />, accessor: 'name' },  
            {
                Header: '', className: "impersonate-btn-table",
                Cell: ({ original }) => {
                    return (
                        <button gentle size="small" className='impersonate-btn-v2' onClick={() => this.confirmImpersonate(original, onImpersonate, this.props.intl)}>
                            <b> {window.innerWidth < 580 ? <i className="fal fa-check" /> : <Translation id="impersonate" defaultMessage="Impersonate" />}</b>
                        </button>
                    )
                }
            },             
        ]

        return (
            <div className='container-impersonate'>
                {spinner}

                <div
                    className="modal-overlay"
                    style={{
                        display: show ? 'block' : 'none'
                    }}
                    onClick={onClose}
                />
                <div id="modal-impersonate-container" className='modal-impersonate v2' style={{ display: show ? 'block' : 'none' }}>
                    <div id="mini-cart">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <header className="container-fluid">
                                        <div className="row">
                                            <div className="col-9 my-auto">
                                                <span>
                                                    <Translation id="choose_customer" defaultMessage="Choose customer" />
                                                </span>
                                            </div>
                                            <div className={`col-3 my-auto text-right`}>
                                                <div className="bt-close" onClick={onClose}>
                                                    <i className="fal fa-times" />
                                                </div>
                                            </div>
                                        </div>
                                    </header>
                                </div>
                            </div>
                            {this.props.userName != 'DBU' && this.props.impersonatedBy != 'DBU' && (
                                <div className='search-bar-download'>
                                    <div>
                                        <div className='search-bar-v2'>
                                            <input
                                                type="text"
                                                data-qa="query"
                                                className='search-box-v2 heigth35'
                                                placeholder={this.props.intl.formatMessage({ id: 'search' })}
                                                data-cy="Impersonate_modal__search_box"
                                                value={this.state.search}
                                                onChange={e =>
                                                    this.setState({
                                                        search: e.target.value
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='download'>
                                        {/* <a href={} data-cy="impersonate__link_download_users_list"> */}
                                        <Button
                                            icon="download"
                                            customClass="only-icon heigth35"
                                            datacy="impersonate__link_download_users_list"
                                            onClick={() => this.setState({ showCSVFormat: true })}
                                        />
                                        {/* </a> */}
                                    </div>
                                </div>
                            )}
                            {this.state.showCSVFormat && <FormatCSVModal onClose={format => this.closeFormatCSVModal(format)} token={() => this.props.token}></FormatCSVModal>}
                            {this.props.userName == 'DBU' || this.props.impersonatedBy == 'DBU' ? (
                                <div className="row">
                                    <div className="col-8">
                                        <input
                                            type="text"
                                            id="inputLogin"
                                            className="form-control"
                                            placeholder={this.props.intl.formatMessage({ id: 'identifier' })}
                                            autoFocus
                                            ref={node => {
                                                this.usernameInput = node
                                            }}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <Button gentle onClick={() => this.confirmImpersonate({ login: this.usernameInput.value }, onImpersonate, this.props.intl)}>
                                            <Translation id="impersonate" defaultMessage="Impersonate" />
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div
                                        className="col-12"
                                        style={window.innerWidth < 580 ? { marginRight: '0px', marginLeft: '0px', paddingRight: '8px', paddingLeft: '8px' } : {}}
                                    >
                                        <ReactTable
                                            data={users}
                                            loading={isLoading}
                                            loadingText={<Spinner isLoading={isLoading} />}
                                            columns={columns}
                                            defaultPageSize={4}
                                            pageSizeOptions={[4, 8]}
                                            previousText={<i className="fal fa-chevron-left"></i>}
                                            nextText={<i className="fal fa-chevron-right"></i>}
                                            noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                                            pageText={<Translation id="page" defaultMessage="Page" />}
                                            ofText={<Translation id="of" defaultMessage="Of" />}
                                            rowsText={`elementos por página`}
                                        />
                                    </div>
                                </div>
                            )}

                            <footer>
                                <div className="row">
                                    <div className="col-12 col-md-6 my-auto d-none d-md-inline">
                                        <div className="go-back" onClick={onClose}>
                                            <i className={`fal fa-chevron-${languageDir === 'rtl' ? 'right' : 'left'}`} />
                                            <span className="label">
                                                <Translation id="keep_my_user" defaultMessage="Keep my user" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className={`col-12 col-md-6 my-auto text-right`}>{deimpersonate}</div>
                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(ImpersonateModal)
)