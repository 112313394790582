import React from 'react'

import debounce from 'lodash/debounce'

const initState = { value: 1, displayValue: 1 }
class InputNumberViewV2 extends React.Component {
    _isMounted = false
    constructor(props) {
        super(props)
        this.state = initState
    }
    componentDidMount() {
        this._isMounted = true
        this.btnMinus.addEventListener('click', this.debounceMinus)
        this.btnPlus.addEventListener('click', this.debouncePlus)
        this.input.addEventListener('blur', this.inputTypeWithouthDebounce)
        this.input.addEventListener('keypress', this.debounceInput)

        let value = this.props.allowNull ? this.props.value || '' : this.props.value || 1
        let displayValue = this.props.allowNull ? this.props.value || '' : this.props.value || 1
        this.setState({ value: value, displayValue: displayValue })
    }
    componentWillUnmount() {
        this._isMounted = false
    }
    componentDidUpdate(prevProps) {
        let value = this.props.allowNull ? this.props.value || '' : this.props.value || 1
        let displayValue = this.props.allowNull ? this.props.value || '' : this.props.value || 1
        if (this.props.value != prevProps.value) this.setState({ value: value, displayValue: displayValue })
    }

    debounceMinus = debounce(() => {
        if (this._isMounted) {
            let val = this.props.onChange(this.state.value, 'minus')
            this.setState({ value: val, displayValue: val })
        }
    }, 250)
    debouncePlus = debounce(() => {
        if (this._isMounted) {
            let val = this.props.onChange(this.state.value, 'plus')
            this.setState({ value: val, displayValue: val })
        }
    }, 250)
    inputTypeWithouthDebounce = e => {
        if (this._isMounted) {
            let val = this.props.onChange(this.state.value)
            this.setState({ value: val, displayValue: e.key === ',' || e.key === '.' || e.target.value === '0' ? this.state.displayValue : val })
        }
    }
    debounceInput = debounce(e => {
        if (this._isMounted) {
            let val = this.props.onChange(this.state.value)
            this.setState({ value: val, displayValue: e.key === ',' || e.key === '.' || e.target.value === '0' ? this.state.displayValue : val })
        }
    }, 2000)
    render() {
        let { size = '', step = 1, decimals = 0 } = this.props
        const cssClass = 'input-number noselect ' + (this.props.oldStyle ? '' : 'v2 ') + size

        return (
            <div className={cssClass}>
                <div
                    className="spin minus"
                    ref={node => {
                        this.btnMinus = node
                    }}
                    onClick={() => {
                        this.setState({
                            value: parseFloat((+this.state.value - step).toFixed(decimals), 10),
                            displayValue: parseFloat((+this.state.value - step).toFixed(decimals), 10)
                        })
                    }}
                >
                    <i className="far fa-minus" />
                </div>
                <div className="input-container">
                    <input
                        ref={node => {
                            this.input = node
                        }}
                        className="inner-input-number"
                        data-cy="global__input_number"
                        data-qas={this.props.dataQas}
                        value={this.state.displayValue}
                        type="number"
                        onChange={e => {
                            this.setState({ displayValue: e.target.value })
                            if (this.props.allowNull) {
                                if (/[0-9]+([.|,][0-9]+)?/.test(e.target.value))
                                    this.setState({ value: parseFloat(e.target.value, 10) }, () => (this.input.value = this.state.value))
                                if (e.target.value == null || e.target.value == '') {
                                    this.setState({ value: null }, () => {
                                        this.props.onChange(this.state.value)
                                        this.input.value = this.state.value
                                    })
                                }
                            } else {
                                if (/[0-9]+([.|,][0-9]+)?/.test(e.target.value))
                                    this.setState({ value: parseFloat(e.target.value, 10) }, () => (this.input.value = this.state.value))
                            }
                        }}
                    />
                </div>
                <div
                    className="spin plus"
                    ref={node => {
                        this.btnPlus = node
                    }}
                    onClick={() => {
                        this.setState({
                            value: parseFloat((+this.state.value + step).toFixed(decimals), 10),
                            displayValue: parseFloat((+this.state.value + step).toFixed(decimals), 10)
                        })
                    }}
                >
                    <i className="far fa-plus" />
                </div>
            </div>
        )
    }
}

export default InputNumberViewV2
