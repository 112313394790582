import React from 'react'
import { injectIntl } from 'react-intl'
import { CUT_TO_SIZE_MATERIALS, capitalizeFirstLetter, addForwardSlash } from '../Utils'
import Translation from '../../../global/Translation'
import ProductImage from '../../../catalog/ProductImage'
import ProductDisplayName from '../../../global/ProductDisplayName'
import DisplayOutletIndicator from '../../../catalog/rectangleResult/displays/DisplayOutletIndicator'
import mallLarge from '../../../../assets/img/logos/mall-large.jpg'
import * as CutToSizeMaps from '../../../cut-to-size/CutToSizeMaps'
import DisplayThickness from '../../../catalog/rectangleResult/displays/DisplayThickness'
import moment from 'moment'
import InputNumber from '../../../global/InputNumber'
import { ModifiedLineIndicator } from './NewOrderDetails'
import LineSegmentation from "../../../cart-line/LineSegmentation";

const NewOrderDetailsEditMode = props => {
    const { orderLines, orderToEdit, intl, isEmserUser, adaptedFormat, getMeasure, canEditPosition, onLineChangeValue, locale, replaceDotWithComma } = props

    if (!orderToEdit) return null

    const getOrderToEditLine = orderLine => {
        return orderToEdit.find(line => line.position === orderLine.Posnr)
    }

    const thicknessFormatIntl = orderLine => {
        if (!orderLine.product.thickness) return null
        return replaceDotWithComma(orderLine.product.thickness, locale) + ' cm'
    } 

    const finishFormatIntl = (orderLine, intl) => {
        if (!orderLine.product.finish) return null
        return intl.formatMessage({ id: orderLine.product.finish })
    }

    const productFormatIntl = orderLine => {
        if (!orderLine.product.format) return null
        return adaptedFormat(orderLine.product.format, { upper: true, showUnits: true })
    }

    const showPrice = () => !isEmserUser && !CUT_TO_SIZE_MATERIALS.includes(String(orderLines[0].material_id))

    const displayPrice = orderLine => {
        if (showPrice()) {
            return (
                parseInt(orderLine.import) === 0 ? <Translation id="not_available" defaultMessage="Not available" /> : orderLine.import + ' ' +  orderLine.coin
            )
        }
        return <Translation id="not_available" defaultMessage="Not available" />
    }

    const descriptionBlockException = orderLine => {
        return (
            <React.Fragment>
                <div className="order__shipping__details__table--description">
                    <div className="product-img">
                        <ProductImage product={null} />
                    </div>
                    <div className="product-description-table">
                        {orderLine.short_text && (
                            <div>
                                <span className="product">
                                    <span>{capitalizeFirstLetter(orderLine.short_text)}</span>
                                </span>
                            </div>
                        )}
                        {orderLine.material_id && (
                            <div>
                                <span className="technical">{orderLine.material_id}</span>
                            </div>
                        )}
                        {orderLine.lot_id && (
                            <div className="lot-id">
                                <span>
                                    <Translation id="lot_id" defaultMessage="Lot ID" />
                                    :&nbsp;
                                </span>
                                <span>{orderLine.lot_id}</span>
                            </div>
                        )}
                        <div>
                            <span className="qty">
                                <Translation id="quantity" defaultMessage="Quantity" />
                                :&nbsp;
                                {orderLine.reservation_quantity ? (
                                    parseInt(orderLine.reservation_quantity) === 0 ? (
                                        <Translation id="product_added_by_center" defaultMessage="Product added by center" />
                                    ) : (
                                        <React.Fragment>{parseInt(orderLine.reservation_quantity)}</React.Fragment>
                                    )
                                ) : (
                                    <Translation id="not_available" defaultMessage="Not available" />
                                )}
                            </span>
                        </div>
                        <div className="price">
                            <span>
                                <Translation id="price" defaultMessage="Price" />
                            </span>
                            :&nbsp;
                            <span>
                                {displayPrice(orderLine)}
                            </span>
                        </div>
                        {/* For tablet screen */}
                        <div className="d-block-only-tablet">{additionalInfoBlock(orderLine)}</div>
                    </div>
                </div>
                {/* For mobile screen */}
                <div className="d-block-only-mobile">
                    <div className="order-divider-x"></div>
                    {additionalInfoBlock(orderLine)}
                </div>
            </React.Fragment>
        )
    }

    const descriptionBlock = orderLine => {
        return (
            <React.Fragment>
                {orderLine.product ? (
                    <React.Fragment>
                        <div className="order__shipping__details__table--description">
                            <div className="product-img">
                                <ProductImage product={orderLine.product} />
                            </div>
                            <div className="product-description-table">
                                {orderLine.outlet_quality && <DisplayOutletIndicator outletQuality={orderLine.outlet_quality} />}
                                {orderLine.mall_promotion_id && (
                                    <div className="mall-logo">
                                        <img src={mallLarge} alt="logo mall" />
                                    </div>
                                )}
                                <div>
                                    <span className="brand">{capitalizeFirstLetter(orderLine.product.brand)} &reg;</span>
                                </div>
                                <div>
                                    <span className="product">
                                        <ProductDisplayName product={orderLine.product} />
                                    </span>
                                </div>
                                <div>
                                    <span className="technical">
                                        {addForwardSlash(orderLine.product.productId, productFormatIntl(orderLine), finishFormatIntl(orderLine, intl), thicknessFormatIntl(orderLine))}
                                    </span>
                                </div>
                                {orderLine.lot_id && (
                                    <div className="lot-id">
                                        <span>
                                            <Translation id="lot_id" defaultMessage="Lot ID" />
                                            :&nbsp;
                                        </span>
                                        <span>{orderLine.lot_id}</span>
                                    </div>
                                )}
                                <div className="qty">
                                    <span>
                                        <Translation id="quantity" defaultMessage="Quantity" />
                                        :&nbsp;
                                    </span>
                                    <span>
                                        {orderLine.reservation_quantity ? (
                                            parseInt(orderLine.reservation_quantity) === 0 ? (
                                                <Translation id="product_added_by_center" defaultMessage="Product added by center" />
                                            ) : (
                                                <React.Fragment>{parseInt(orderLine.reservation_quantity)}</React.Fragment>
                                            )
                                        ) : (
                                            <Translation id="not_available" defaultMessage="Not available" />
                                        )}
                                    </span>
                                </div>
                                <div className="price">
                                    <span>
                                        <Translation id="price" defaultMessage="Price" />
                                    </span>
                                    :&nbsp;
                                    <span>
                                        {displayPrice(orderLine)}
                                    </span>
                                </div>

                                {/* For tablet screen */}
                                <div className="d-block-only-tablet">{additionalInfoBlock(orderLine)}</div>
                            </div>
                        </div>
                        {/* For mobile screen */}
                        <div className="d-block-only-mobile">
                            <div className="order-divider-x"></div>
                            {additionalInfoBlock(orderLine)}
                            <div className="order-divider-x"></div>
                            {editModeQuantityPosition(orderLine)}
                            {editModeRejectPosition(orderLine)}
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>{descriptionBlockException(orderLine)}</React.Fragment>
                )}
            </React.Fragment>
        )
    }

    const cutToSizeDescriptionBlock = orderLine => {
        const cts = orderLine.cut_to_size
        const measures = getMeasure(cts)
        return (
            <React.Fragment>
                <div className="order__shipping__details__table--description">
                    <div className="product-img">
                        <img src={cts.thumb} alt="Cut to size" />
                    </div>

                    <div className="product-description-table">
                        <div>
                            <span className="brand">{capitalizeFirstLetter(cts.brand)} &reg;</span>
                        </div>

                        <div className="product">
                            <span>{capitalizeFirstLetter(cts.colorName)}</span>
                        </div>

                        <div>
                            <span className="technical">{orderLine.material_id}</span>
                        </div>

                        {cts.elaboration === 'CUSTOM_MOBILIARIO' && (
                            <div className="cts-custom-mobiliario">
                                <span>{intl.formatMessage(CutToSizeMaps.Messages[cts.elaboration])}</span>&nbsp; (
                                <span>
                                    {!!cts.samePattern ? <Translation id="extension" defaultMessage="Extension" /> : <Translation id="main_piece" defaultMessage="Main piece" />}
                                </span>
                                )
                            </div>
                        )}

                        {measures.format && (
                            <div className="cts-format">
                                <span>
                                    <Translation id="format" defaultMessage="Format" />
                                </span>
                                :&nbsp;
                                <span>{measures.format}</span>
                            </div>
                        )}

                        <div className="cts-finish">
                            <span>
                                <Translation id="finish" defaultMessage="Finish" />
                            </span>
                            :&nbsp;
                            <span>{intl.formatMessage({ id: cts.finish })}</span>
                        </div>

                        <div className="cts-thickness">
                            <span>
                                <Translation id="thickness" defaultMessage="Thickness" />
                            </span>
                            :&nbsp;
                            <span>
                                <DisplayThickness thickness={cts.thickness} string />
                            </span>
                        </div>

                        {measures.radius && (
                            <div className="cts-radius">
                                <span>
                                    <Translation id="radius" defaultMessage="Radius" />
                                </span>
                                :&nbsp;
                                <span>{measures.radius}</span>
                            </div>
                        )}

                        {cts.edge && (
                            <div className="cts-edge">
                                <span>
                                    <Translation id="edge" defaultMessage="Edge" />
                                </span>
                                :&nbsp;
                                <span>{intl.formatMessage(CutToSizeMaps.Messages[cts.edge])}</span>
                            </div>
                        )}

                        {cts.bevel && (
                            <div className="cts-bevel">
                                <span>
                                    <Translation id="bevel" defaultMessage="Bevel" />
                                </span>
                                :&nbsp;
                                <span>{intl.formatMessage(CutToSizeMaps.Messages[cts.bevel])}</span>
                            </div>
                        )}

                        {cts.packaging && (
                            <div className="cts-packaging">
                                <span>
                                    <Translation id="packaging" defaultMessage="Packaging" />
                                </span>
                                :&nbsp;
                                <span>{intl.formatMessage(CutToSizeMaps.Messages[cts.packaging])}</span>
                            </div>
                        )}

                        {cts.samePattern === 'YES' && (
                            <div className="cts-pattern">
                                <span>
                                    <Translation id="same_pattern" />
                                </span>
                            </div>
                        )}

                        {cts.shape && (
                            <div className="cts-shape">
                                <span>
                                    <Translation id="shape" defaultMessage="Shape" />
                                </span>
                                :&nbsp;
                                <span>{intl.formatMessage(CutToSizeMaps.Messages[cts.shape])}</span>
                            </div>
                        )}

                        {measures.diameter && (
                            <div className="cts-diameter">
                                <span>
                                    <Translation id="diameter" defaultMessage="Diameter" />
                                </span>
                                :&nbsp;
                                <span>{measures.diameter}</span>
                            </div>
                        )}

                        <div>
                            <span className="qty">
                                <Translation id="quantity" defaultMessage="Quantity" />
                                :&nbsp;
                                {orderLine.reservation_quantity ? (
                                    parseInt(orderLine.reservation_quantity) === 0 ? (
                                        <Translation id="product_added_by_center" defaultMessage="Product added by center" />
                                    ) : (
                                        <React.Fragment>{parseInt(orderLine.reservation_quantity)}</React.Fragment>
                                    )
                                ) : (
                                    <Translation id="not_available" defaultMessage="Not available" />
                                )}
                            </span>
                        </div>
                        <div className="price">
                            <span>
                                <Translation id="price" defaultMessage="Price" />
                            </span>
                            :&nbsp;
                            <span>
                                {displayPrice(orderLine)}
                            </span>
                        </div>
                        {/* For tablet screen */}
                        <div className="d-block-only-tablet">{additionalInfoBlock(orderLine)}</div>
                    </div>
                </div>
                {/* For mobile screen */}
                <div className="d-block-only-mobile">
                    <div className="order-divider-x"></div>
                    {additionalInfoBlock(orderLine)}
                </div>
            </React.Fragment>
        )
    }

    const additionalInfoBlock = orderLine => {
        return (
            <div className="order__shipping__details__table--additional">
                <div>
                    {
                        orderLine.segmentation && <LineSegmentation line={orderLine} showAsList />
                    }
                </div>
                <div className="comments">
                    <span>
                        <Translation id="comments" defaultMessage="Comments" />
                        :&nbsp;
                    </span>
                    <span>{orderLine.comments ? orderLine.comments : '--'}</span>
                </div>
                <div>
                    <span>
                        <Translation id="requested_shipping_date" defaultMessage="Requested shipping date" />
                        :&nbsp;
                    </span>
                    <span>
                        {orderLine.preferredDeliveryDate ? (
                            moment(orderLine.preferredDeliveryDate).isSame(moment(), 'day') ? (
                                <Translation id="today" defaultMessage="Today" />
                            ) : (
                                moment(orderLine.preferredDeliveryDate).format('L')
                            )
                        ) : (
                            '--'
                        )}
                    </span>
                </div>
            </div>
        )
    }

    const editModeQuantityPosition = orderLine => {
        const editLine = getOrderToEditLine(orderLine)

        if (orderLine.product) {
            if (orderLine.product.lotId && orderLine.product.lotId !== '') {
                return (
                    <div className="lotid__editmode">
                        <span>
                            <Translation id="lot_id" defaultMessage="Lot ID" />
                            :&nbsp;
                        </span>
                        <span>{orderLine.product.lotId}</span>
                    </div>
                )
            } else if (orderLine.product.bundleId && orderLine.product.bundleId !== '') {
                return (
                    <div className="bundleid__editmode">
                        <span>
                            <Translation id="bundle_id" defaultMessage="bundle ID" />
                            :&nbsp;
                        </span>
                        <span>{orderLine.product.bundleId}</span>
                    </div>
                )
            } else if (canEditPosition(orderLine.Posnr)) {
                return (
                    <div className="edit-mode-position-number">
                        <InputNumber key={orderLine.Posnr + '' + editLine.quantity} value={editLine.quantity} change={qty => onLineChangeValue(orderLine.Posnr, 'qty', qty)} />
                    </div>
                )
            }
        }

        return editLine ? <ModifiedLineIndicator orderLine={orderLine} /> : null
    }

    const editModeRejectPosition = orderLine => {
        const editLine = getOrderToEditLine(orderLine)

        if (canEditPosition(orderLine.Posnr)) {
            if (!editLine.rejected) {
                return (
                    <div className="edit-mode-reject-pos">
                        <button onClick={() => onLineChangeValue(orderLine.Posnr, 'rejected', editLine.rejected)} gtm-label="ec_historial_pedidos_eliminar_producto">
                            <i className="fal fa-trash-alt" />
                            <span>
                                <Translation id="delete" defaultMessage="Remove" />
                            </span>
                        </button>
                    </div>
                )
            } else {
                return (
                    <div className="edit-mode-undo-pos">
                        <button onClick={() => onLineChangeValue(orderLine.Posnr, 'rejected', editLine.rejected)}>
                            <i className="fal fa-undo" />
                            <span>
                                <Translation id="restore" defaultMessage="Restore" />
                            </span>
                        </button>
                    </div>
                )
            }
        } else return null
    }

    return (
        <React.Fragment>
            {orderLines && (
                <section className="order__shipping__details__table edit-mode-table">
                    <table className="order__shipping__details__table--table">
                        <thead>
                            <tr className="order__shipping__details__table--heading">
                                <th className="th-description">
                                    <Translation id="description" defaultMessage="Description" />
                                </th>
                                <th className="th-addional-info">
                                    <Translation id="additional_information" defaultMessage="Additional Information" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderLines.length > 0 &&
                                orderLines.map((orderLine, idx) => {
                                    return (
                                        <React.Fragment>
                                            <tr key={idx} className={`${getOrderToEditLine(orderLine) && getOrderToEditLine(orderLine).rejected ? 'position-rejected' : ''}`}>
                                                <div className="order-details-divider-x"></div>
                                                <td className="td-description">{orderLine.cut_to_size ? cutToSizeDescriptionBlock(orderLine) : descriptionBlock(orderLine)}</td>
                                                <td className="td-additional-info">{additionalInfoBlock(orderLine)}</td>
                                                <td className="td-position-actions">
                                                    {orderLine.product && (
                                                        <React.Fragment>
                                                            {editModeQuantityPosition(orderLine)}
                                                            {editModeRejectPosition(orderLine)}
                                                        </React.Fragment>
                                                    )}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })}
                        </tbody>
                    </table>
                </section>
            )}
        </React.Fragment>
    )
}

export default injectIntl(NewOrderDetailsEditMode)
