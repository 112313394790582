import { getSalesOrg } from '../../../store/login/reducers';
import React from "react"
import { injectIntl } from 'react-intl'
import Translation from "../../global/Translation"
import copyToClipboardIcon from '../../../assets/img/components/copy-to-clipboard.svg'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
    return {
        salesOrg: getSalesOrg(state)
    }
}

class SmallBannerRocket extends React.Component {
    constructor(props) {
        super(props)

        const copyToClipboardMsg = (
            this.props.intl.formatMessage({
                id: 'copy_to_clipboard',
                defaultMessage: 'Copy to clipboard'
            })
        )

        this.state = {
            copyToClipboardMsg: copyToClipboardMsg,
        }

        this.bannerDetails = {
            //DEFAULT
            subtitle: this.props.intl.formatMessage({ id: 'rocket_main_title_small_banner', defaultMessage: 'Save 15% with the Rock[et] Promotion' }),
            description: this.props.intl.formatMessage({
                id: 'rocket_promotion_description_long',
                defaultMessage: "Don't forget to enter your promo code to take advantage of this <b>exclusive promotion for a limited time only!</b> Additional 15% savings on all Silestone and Dekton slabs"
            }),
            couponCode: '100000331'
        }

        this.bannerDetailsColor = {
            //DEFAULT FOR COLOR
            description: this.props.intl.formatMessage({
                id: 'rocket_description_big_banner_long_on_selected_colors',
                defaultMessage: "You've been chosen for an exclusive promotion offering a <b>15%</b> off on selected colors of <b>Silestone</b> and <b>Dekton</b> slabs!"
            })
        }

        //DEFAULT
        if (props.market == 'AT') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '10%')
            this.bannerDetails.description = this.bannerDetails.description.replace('15%', '10%')
        }

        if (props.market == 'CH') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '10%')
            this.bannerDetails.description = this.bannerDetails.description.replace('15%', '10%')
        }

        if (props.market == 'DE') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '10%')
            this.bannerDetails.description = this.bannerDetails.description.replace('15%', '10%')
        }

        if (props.market == 'BE') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '10%')
            this.bannerDetails.description = this.bannerDetails.description.replace('15%', '10%')
        }

        if (props.market == 'NLD') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '10%')
            this.bannerDetails.description = this.bannerDetails.description.replace('15%', '10%')
        }

        if (props.market == 'IT') {
            this.bannerDetails.subtitle = this.props.intl.formatMessage({
                id: 'rocket_promo_description_just_for_italy_small_banner_subtitle',
                defaultMessage: "Save up to 55% with the Rock[et] promotion"
            })

            this.bannerDetails.description = this.props.intl.formatMessage({
                id: 'rocket_promo_description_just_for_italy_small_banner_description',
                defaultMessage: "Don't forget to enter your promo code to take advantage of this <b>exclusive promotion for a limited time only!</b> Additional 55% savings on all Silestone and 50% off Dekton slabs"
            })
        }

        // DEFAULT WITH COLORS

        if (props.market == 'Iberia') {
            this.bannerDetails.subtitle = this.props.intl.formatMessage({
                id: 'rocket_promo_subtitle_iberia',
                defaultMessage: "Save with the Rock[et] promotion"
            })

            this.bannerDetails.description = this.props.intl.formatMessage({
                id: 'rocket_promo_second_description_iberia',
                defaultMessage: "Don't forget to enter your promotional code to benefit from this <b>exclusive promotion, for a limited time only!</b> Savings on all Silestone and Dekton slabs"
            })
        }

        if (props.market == 'IRLSCOT') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '20%')
            this.bannerDetails.description = this.bannerDetailsColor.description.replace('15%', '20%')
        }
       
        if (props.market == 'PL') {
            this.bannerDetails.description = this.bannerDetailsColor.description
        }

        // Caso base 15%. No afecta el replace.
        if (props.market == 'SE') {
            this.bannerDetails.description = this.bannerDetailsColor.description
        }

        if (props.market == 'UK') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '20%')
            this.bannerDetails.description = this.bannerDetailsColor.description.replace('15%', '20%')
        }

        // Caso base 15%. No afecta el replace.
        if (props.market == 'NO') {
            this.bannerDetails.description = this.bannerDetailsColor.description
        }

        if (props.market == 'DK') {
            this.bannerDetails.description = this.bannerDetailsColor.description
        }

        if (props.market == 'FI') {
            this.bannerDetails.description = this.bannerDetailsColor.description
        }

        if (props.market == 'Baltic') {
            this.bannerDetails.description = this.bannerDetailsColor.description
        }

        if (props.salesOrg == '1037') this.bannerDetails.couponCode = '100000378'
        if (props.salesOrg == '1100' || props.salesOrg == '1000') this.bannerDetails.couponCode = '100000378'
        if (props.salesOrg == '2005') this.bannerDetails.couponCode = '100000378'
        if (props.salesOrg == '2000') this.bannerDetails.couponCode = '100000370'
        if (props.salesOrg == '2001') this.bannerDetails.couponCode = '100000371'
        if (props.salesOrg == '2002') this.bannerDetails.couponCode = '100000390'
        if (props.salesOrg == '2004') this.bannerDetails.couponCode = '100000372'
        if (props.salesOrg == '2007') this.bannerDetails.couponCode = '100000383'
        if (props.salesOrg == '2008') this.bannerDetails.couponCode = '100000373'
        if (props.salesOrg == '2009') this.bannerDetails.couponCode = '100000380'
        if (props.salesOrg == '2010') this.bannerDetails.couponCode = '100000374'
        if (props.salesOrg == '2014') this.bannerDetails.couponCode = '100000375'
        if (props.salesOrg == '2015') this.bannerDetails.couponCode = '100000379'
        if (props.salesOrg == '2016') this.bannerDetails.couponCode = '100000384'
        if (props.salesOrg == '2038') this.bannerDetails.couponCode = '100000382'
        if (props.salesOrg == '2039') this.bannerDetails.couponCode = '100000385'
        if (props.salesOrg == '2042') this.bannerDetails.couponCode = '100000381'
        if (props.salesOrg == '2047') this.bannerDetails.couponCode = '100000386'
        if (props.salesOrg == '2049') this.bannerDetails.couponCode = '100000376'
        if (props.salesOrg == '2050') this.bannerDetails.couponCode = '100000377'

        // Special vouchers and discounts percent for USA/CA customers promotion list
        if (props.market === 'USACA5') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '5%')
            this.bannerDetails.description = this.bannerDetails.description.replace('15%', '5%')
            this.bannerDetails.couponCode =  '100000431'
        }

        if (props.market === 'USACA10') {
            this.bannerDetails.subtitle = this.bannerDetails.subtitle.replace('15%', '10%')
            this.bannerDetails.description = this.bannerDetails.description.replace('15%', '10%')
            this.bannerDetails.couponCode =  '100000446'
        }
    }
    
    copyOnClick(e) {
        e.preventDefault()
        navigator.clipboard.writeText(this.bannerDetails.couponCode).then(() => {
            this.setState({ copied: true }, () =>
                setTimeout(() => {
                    this.setState({ copied: false })
                }, 2000)
            )
        })
    }

    render() {
        return (
            <div class="small-banner-rocket">
                <div className="small-banner-rocket__container">
                    <div className="small-banner-rocket__flex">
                        <div className="small-banner-rocket__description">
                            <h2>{this.bannerDetails.subtitle}</h2>
                        </div>
                        <div className="small-banner-rocket__code--section">
                            <div>
                                <span>
                                    <Translation id="code" defaultMessage="Code" />
                                </span>
                                :&nbsp;
                                <span className="code__section--code">{this.bannerDetails.couponCode}</span>
                            </div>
                            <div>
                                <button
                                    onClick={e => {
                                        this.copyOnClick(e)
                                    }}
                                    data-tip={this.state.copyToClipboardMsg}
                                    className="copy-to-clipboard__btn"
                                >
                                    <img src={copyToClipboardIcon} alt="copy to clipboard" />
                                    <ReactTooltip
                                        bg-coloreffect="solid"
                                        place="top"
                                        getContent={() =>
                                            this.state.copied ? this.props.intl.formatMessage({ id: 'copied', defaultMessage: 'Copied' }) : this.state.copyToClipboardMsg
                                        }
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="rocket_promotion__description--long">
                        <span dangerouslySetInnerHTML={{ __html: this.bannerDetails.description }}></span>
                    </div>
                </div>
            </div>
        )
    }
}
export default injectIntl(connect(mapStateToProps, null)(SmallBannerRocket))