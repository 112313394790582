import { connect } from 'react-redux'
import {
    getShippingMethod,
    getHasMinWeight,
    getSelectedCenter,
    getMktoolsShippingCenter,
    getMktoolsBudget,
    getIsMktoolsUsaCenter,
    getShowroomCenterId
} from '../../../store/centers/reducers'
import { checkout } from '../../../store/cart/actions'
import {
    getAlert,
    isSending,
    weightSelector,
    getGoalMin,
    getGoalMax,
    getKgPoundRatio,
    getReservationCode,
    qtySelector,
    getMonthItems,
    getBudgetPlusTotal,
    getWaitTillDate,
    getStatus,
    getIsCartCutToSize
} from '../../../store/cart/reducers'
import { getIsAdmin, getUserType } from '../../../store/login/reducers'
import { getLanguage } from '../../../store/ui/reducers'
import { getProfileConfiguration } from '../../../store/profile-configuration/reducers'
import { getShippingAddresses, getShippingAddressesCenters, isLoadingShippingAddress, getCostCenters } from '../../../store/shipping-addresses/reducers'
import { fetchShippingAddresses } from '../../../store/shipping-addresses/actions'
import { setMkToolsShippingCenter } from '../../../store/centers/actions'
import {
    resetCheckout,
    setDeliveryDate,
    setPo,
    setComments,
    setCountryOfSend,
    setSelectedShippingAddress,
    setProfileConfigurationEmail,
    setHasSelectedCenter,
    setDestination,
    setSelectedShippingCenterId,
    setSelectedShippingAddressId,
    setSelectedCostCenter
} from '../../../store/checkout/actions'
import {
    getHasSelectedCenter,
    getSelectedShippingCenterId,
    getSelectedShippingAddress,
    getSelectedCostCenter,
    getDestination,
    canSendCheckoutSaleConditions
} from '../../../store/checkout/reducers'
import { dispatchPushURL } from '../../../store/ui/actions'
import React from 'react'
import CheckoutFormMktoolsV2 from './CheckoutFormMktoolsV2'

const mapStateToProps = state => {
    let language = getLanguage(state)
    let defaultUnit = language === 'en' || language === 'en-US' || language === 'fr-CA' ? 'LB' : 'KG'
    let unit = getProfileConfiguration(state).weightUnit ? getProfileConfiguration(state).weightUnit : defaultUnit

    return {
        current: unit === 'KG' ? weightSelector(state) : Math.floor(weightSelector(state) * getKgPoundRatio()),
        goalMin: getGoalMin(state, unit),
        goalMax: getGoalMax(state, unit),
        shippingAddresses: getShippingAddresses(state),
        shippingAddressesCenters: getShippingAddressesCenters(state),
        hasDestination: getShippingMethod(state) === 'CONTAINER',
        costCenters: getCostCenters(state),
        shippingMethod: getShippingMethod(state),
        isAdmin: getIsAdmin(state),
        alert: getAlert(state),
        status: getStatus(state),
        monthItems: getMonthItems(state),
        waitTillDate: getWaitTillDate(state),
        userType: getUserType(state),
        isSending: isSending(state),
        reservationCode: getReservationCode(state),
        totalQty: qtySelector(state),
        hasMinWeight: getHasMinWeight(state),
        centerSelected: getSelectedCenter(state),
        haveCutToSize: getIsCartCutToSize(state),
        isMkToolsUsaCenter: getIsMktoolsUsaCenter(state),
        isLoadingShippingAddress: isLoadingShippingAddress(state),
        mktoolsShippingCenter: getMktoolsShippingCenter(state),
        budgetPlusTotal: getBudgetPlusTotal(state),
        mktoolsBudget: getMktoolsBudget(state),
        destination: getDestination(state),
        profileConfiguration: getProfileConfiguration(state),
        hasSelectedCenter: getHasSelectedCenter(state),
        selectedShippingCenterId: getSelectedShippingCenterId(state),
        selectedShippingAddress: getSelectedShippingAddress(state),
        selectedCostCenter: getSelectedCostCenter(state),
        canSendCheckoutSaleConditions: canSendCheckoutSaleConditions(state),
        showroomCenterId: getShowroomCenterId(),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchShippingAddresses: () => dispatch(fetchShippingAddresses()),
        submitCheckout: () => dispatch(checkout()),
        onLoadCheckout: () => dispatch({ type: 'CHECKOUT_VISIT' }),
        setMkToolsShippingCenter: mktoolsShippingCenter => dispatch(setMkToolsShippingCenter(mktoolsShippingCenter)),
        goCatalog: intl => {
            dispatch(dispatchPushURL(intl.formatMessage({ id: 'ROUTE_CATALOG' }).replace(':outlet?', '')))
            dispatch({ type: 'REMEMBER_CENTER' })
        },
        resetCheckout: () => dispatch(resetCheckout()),
        setDeliveryDate: value => dispatch(setDeliveryDate(value)),
        setCountryOfSend: value => dispatch(setCountryOfSend(value)),
        setSelectedShippingAddress: value => dispatch(setSelectedShippingAddress(value)),
        setProfileConfigurationEmail: value => dispatch(setProfileConfigurationEmail(value)),
        setHasSelectedCenter: value => dispatch(setHasSelectedCenter(value)),
        setDestination: value => dispatch(setDestination(value)),
        setSelectedShippingCenterId: value => dispatch(setSelectedShippingCenterId(value)),
        setPo: value => dispatch(setPo(value)),
        setComments: value => dispatch(setComments(value)),
        setSelectedShippingAddressId: value => dispatch(setSelectedShippingAddressId(value)),
        setSelectedCostCenter: value => dispatch(setSelectedCostCenter(value))
    }
}

const CheckoutFormMktoolsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(React.forwardRef((props, ref) => <CheckoutFormMktoolsV2 ref={ref} {...props} />))

export default CheckoutFormMktoolsContainer
