import React from "react";
import ListResultContainer from "./ListResultContainer";
import Translation from "../../global/Translation";

class TableListResult extends React.Component {

    render(){
        const { hits } = this.props
        return (
            <div style={{width: '100%', boxSizing: 'border-box', padding: 8, overflowX: 'scroll'}}>
                <table style={{width: '100%'}}>
                    <thead>
                    <tr>
                        <th>
                            <Translation id="image" defaultMessage="Image"/>
                        </th>
                        <th>
                            <Translation id="product" defaultMessage="Product"/>
                        </th>
                        <th style={{textAlign:'end'}}>
                            <Translation id="thickness" defaultMessage="Thickness"/>
                        </th>
                        <th>
                            <Translation id="type" defaultMessage="Type"/>
                        </th>
                        <th>
                            <Translation id="finish" defaultMessage="Finish"/>
                        </th>
                        <th>
                            <Translation id="subtype" defaultMessage="Subtype"/>
                        </th>
                        <th>
                            <Translation id="rotation" defaultMessage="Rotation"/>
                        </th>

                        <th>
                            <Translation id="outlet" defaultMessage="Outlet"/>
                        </th>

                        <th style={{textAlign:'center'}}>
                            <Translation id="select" defaultMessage="Select"/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {hits && hits.map(hit => (
                            <ListResultContainer {...this.props} result={hit}/>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default TableListResult