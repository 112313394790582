import { connect } from 'react-redux'
import Incentives from './Incentives'
import { weightSelector, getKgPoundRatio, getGoalMin, getGoalMax, getBudgetPlusTotal, isMallCartOnly } from '../../store/cart/reducers'
import {
    getShippingMethod,
    hasIncentives,
    getIsLowesCenter,
    getIsMkToolsCenter,
    getMktoolsBudget,
    getMktoolsBudgetWasted,
    getCoin,
    getIsIkeaCenter,
    getSelectedCenter,
    getSalesOrg,
} from '../../store/centers/reducers'
import { getLanguage } from '../../store/ui/reducers'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'

const mapStateToProps = state => {
    let language = getLanguage(state)
    let defaultUnit = language === 'en' || language === 'en-US' || language === 'fr-CA' ? 'LB' : 'KG'
    let unit = getProfileConfiguration(state).weightUnit ? getProfileConfiguration(state).weightUnit : defaultUnit

    return {
        unit,
        shippingMethod: getShippingMethod(state),
        current: unit === 'KG' ? weightSelector(state) : Math.floor(weightSelector(state) * getKgPoundRatio()),
        goalMin: getGoalMin(state, unit),
        goalMax: getGoalMax(state, unit),
        hasIncentives: hasIncentives(state),
        isLowesCenter: getIsLowesCenter(state),
        isMktoolCenter: getIsMkToolsCenter(state),
        mktoolsBudgetWasted: getMktoolsBudgetWasted(state),
        total: getBudgetPlusTotal(state),
        maxBudget: getMktoolsBudget(state),
        coin: getCoin(state),
        isIkeaCenter: getIsIkeaCenter(state),
        center: getSelectedCenter(state),
        isCartMallOnly: isMallCartOnly(state),
        salesOrg: getSalesOrg(state),
    }
}

const IncentivesContainer = connect(
    mapStateToProps,
    null
)(Incentives)

export default IncentivesContainer
