import { getUserName } from '../../../store/login/reducers';
import { connect } from 'react-redux'
import ImpersonateModal from './ImpersonateModal'
import { showModalSelector, usersSelector, getFetchingUsers } from '../../../store/impersonate/reducers'
import * as ui from '../../../store/ui/actions'
import { fetchUsersToImpersonate } from '../../../store/impersonate/actions'
import { getIsLogging, getImpersonatedBy, getToken } from '../../../store/login/reducers'
import { impersonate } from '../../../store/login/actions'
import { getLanguageDir } from '../../../store/ui/reducers'

const mapStateToProps = state => {
    return {
        show: showModalSelector(state),
        users: usersSelector(state),
        impersonatedBy: getImpersonatedBy(state),
        userName:getUserName(state),
        isLogging: window.location.href.indexOf('landing-templates') == -1 && getIsLogging(state),
        isLoading: getFetchingUsers(state),
        token: getToken(state),
        languageDir: getLanguageDir(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closeImpersonateModal())
        },
        onImpersonate: (userId) => {
            dispatch(impersonate(userId))
        },
        fetchUsersToImpersonate: () => {
            dispatch(fetchUsersToImpersonate())
        }
    }
}

const ImpersonateContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ImpersonateModal)

export default ImpersonateContainer
