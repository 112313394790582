import React from 'react'
import Translation from '../global/Translation'

class ProfileConfigurationPromotionEmails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            highlightCosentinoNotAllowed: false
        }
    }

    validateAndTransformEmail(email) {
        let transformedEmail = email.toLowerCase()
        if (transformedEmail.includes('@cosentino')) {
            this.setState({ highlightCosentinoNotAllowed: true })
            return null
        } else {
            this.setState({ highlightCosentinoNotAllowed: false })
            return transformedEmail
        }
    }

    addEmail = input => {
        const email = this.validateAndTransformEmail(input.value)
        if (input.validity.valid && input.value !== '' && email) {
            this.props.change(this.props.promotionEmails.concat(email).filter((value, index, self) => self.indexOf(value) === index))
            input.value = ''
        }
    }
    removeEmail = idRemove => {
        if (this.props.promotionEmails.length > 0) {
            this.props.change(this.props.promotionEmails.filter((email, index) => index !== idRemove))
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            {this.state.highlightCosentinoNotAllowed && (
                                <span className={`d-inline-block`}>
                                    <Translation id="cosentino_emails_are_not_allowed" defaultMessage="Cosentino emails are not allowed" /> ...@cosentino.com
                                </span>
                            )}
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="input-group input-group-sm">
                                <input
                                    id={this.props.idForInput ? `input-${this.props.idForInput}` : null}
                                    type="email"
                                    ref={node => {
                                        this.inputEmail = node
                                    }}
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault()
                                            this.addEmail(this.inputEmail)
                                        }
                                    }}
                                    className='form-control customDropdown'
                                    placeholder="Email"
                                    style={{ height: '35px' }}
                                />
                                <span className="input-group-btn input-group-append">
                                    <button
                                        className="btn btn-outline-secondary btn-add"
                                        type="button"
                                        onClick={() => {
                                            this.addEmail(this.inputEmail)
                                        }}
                                    >
                                        <i className="fa fa-plus" />
                                        <span style={{ marginLeft: '6px' }}><Translation id="add" defaultMessage="Add" /></span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 my-3">
                            {this.props.promotionEmails &&
                                this.props.promotionEmails.map((email, i) => {
                                    return (
                                        <span className="badge badge-secondary badge-emails" key={email}>
                                            {email}{' '}
                                            {this.props.promotionEmails.length > 0 ? (
                                                <i
                                                    className='fa fa-times-circle'
                                                    onClick={() => {
                                                        this.removeEmail(i)
                                                    }}
                                                />
                                            ) : null}
                                        </span>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileConfigurationPromotionEmails
