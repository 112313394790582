import { connect } from 'react-redux'
import React from 'react'
import Translation from '../../global/Translation'
import { defineMessages, injectIntl } from 'react-intl'
import { canViewSilestonePromotion } from '../../../store/login/reducers'
import { isUsaOrCanada } from '../../../store/centers/reducers'

defineMessages({
    eu_ib_silestone_flash_promo_description_1: {
        id: 'eu_ib_silestone_flash_promo_description_1',
        description: 'eu_ib_silestone_flash_promo_description_1',
        defaultMessage: '10% discount in addition to your conditions for full truck.'
    },
    eu_ib_silestone_flash_promo_description_2: {
        id: 'eu_ib_silestone_flash_promo_description_2',
        description: 'eu_ib_silestone_flash_promo_description_2',
        defaultMessage: '5% discount in addition to your terms for half truck.'
    },
    usa_ca_silestone_flash_promo_description_1: {
        id: 'usa_ca_silestone_flash_promo_description_1',
        description: 'usa_ca_silestone_flash_promo_description_1',
        defaultMessage: '10% additional discount to your current conditions for full container.'
    }
})

const mapStateToProps = state => {
    return {
        canViewSilestonePromotion: canViewSilestonePromotion(state),
        isUsaOrCanadaSalesOrg: isUsaOrCanada(state),
    }
}

class BannerSilestone extends React.Component {
    constructor(props) {
        super(props)

        // Define base message. Default UE+IBERIA
        this.bannerCustomContent = {
            description_1: this.props.intl.formatMessage({
                id: 'eu_ib_silestone_flash_promo_description_1',
                defaultMessage: '10% discount in addition to your conditions for full truck.'
            }),
            description_2: this.props.intl.formatMessage({
                id: 'eu_ib_silestone_flash_promo_description_2',
                defaultMessage: '5% discount in addition to your terms for half truck.'
            })
        }

        // Show different message depending on condition

        // Norte América (USA Y CA):
        if (this.props.isUsaOrCanadaSalesOrg) {
            this.bannerCustomContent.description_1 = this.props.intl.formatMessage({
                id: 'usa_ca_silestone_flash_promo_description_1',
                defaultMessage: '10% additional discount to your current conditions for full container.'
            })
        }
    }

    render() {
        return (
            <React.Fragment>
            {!this.props.isCheckoutView ? (
                <div className="big__banner__silestone">
                    <div className="big__banner__silestone--header">
                        <div className="left-side">
                            <div>
                                <h2>
                                    <Translation id="a_silestone_flash_promo" defaultMessage="A Silestone Flash Promo!" />
                                </h2>
                            </div>
                            <div>
                                <h3>
                                    <Translation id="a_silestone_flash_promo_date" defaultMessage="Monday, March 4 to Friday, March 8" />
                                </h3>
                            </div>
                        </div>

                        <div className="right-side">
                            <div>
                                <span>{this.bannerCustomContent.description_1}</span>
                            </div>
                            {!this.props.isUsaOrCanadaSalesOrg && (
                                <div>
                                    <span>{this.bannerCustomContent.description_2}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="big__banner__silestone--footer">
                        <span><Translation id="silestone_flash_promo_info_discount" defaultMessage="Please note that discount will not be visible in your shopping cart, but will be reflected in your invoice." /></span>
                    </div>
                </div>
            ) : (
                <div className="small__banner__silestone">
                    <div className="small__banner__silestone--title">
                        <h2>
                            <Translation id="a_silestone_flash_promo" defaultMessage="A Silestone Flash Promo!" />
                        </h2>
                    </div>
                    <div className="small__banner__silestone--subtitle">
                        <div>
                            <span>{this.bannerCustomContent.description_1}</span>
                        </div>
                        {!this.props.isUsaOrCanadaSalesOrg && (
                            <div>
                                <span>{this.bannerCustomContent.description_2}</span>
                            </div>
                        )}
                    </div>
                    <div className="small__banner__silestone--date">
                        <span>
                            <Translation id="a_silestone_flash_promo_date" defaultMessage="Monday, March 4 to Friday, March 8" />
                        </span>
                    </div>
                    <div className="small__banner__silestone--discount">
                        <span><Translation id="silestone_flash_promo_info_discount" defaultMessage="Please note that discount will not be visible in your shopping cart, but will be reflected in your invoice." /></span>
                    </div>
                </div>
            )}
            </React.Fragment>
        )
    }
}

export default injectIntl(connect(mapStateToProps, null)(BannerSilestone))
