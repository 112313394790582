import React from 'react'

const ProgressBar = ({ config, value, showLabels = false, coin, difCurrent, showWeigthLabel, showMktoolsLabels }) => {
    const max = parseFloat(config[config.length - 1])
    const labels = config.filter((val, i, array) => {
        // Only even elements
        return i % 2 === 0
    })
    const percentages = labels.map((val, i, array) => {
        return ((100 * parseFloat(val)) / max).toFixed(2)
    })
    const segmentColors = config.filter((val, i, array) => {
        // Only odd elements
        return i % 2 !== 0
    })
    if (value > max) value = max
    const progress = ((100 * parseFloat(value)) / max).toFixed(2)

    return (
        <div className="progress-bar-container small" >
            <div className="bar">
                <div className="segments">
                    {(() => {
                        let result = []
                        for (let i = 0; i < percentages.length - 1; i++) {
                            result.push(
                                <div
                                    className="segment"
                                    key={percentages[i]}
                                    style={{ maxWidth: "100%", left: `${percentages[i]}%`, width: `${percentages[i + 1] - percentages[i]}%`, background: segmentColors[i] }}
                                />
                            )
                        }
                        return result
                    })()}
                </div>
                <div className="progress-fill" style={{ left: `${progress}%` }} >
                    <div className='custom-progress-circle' style={{backgroundColor: difCurrent !== 0 ? '#FF5F6D' : '#4AA297'}}></div>
                </div>
                <div className="progress-fill-child">
                    <div className="progress-fill-child-container">
                        <span style={{left: `${progress}%`}} className="progress-fill-indicator">
                        {
                            showWeigthLabel &&
                            <span style={{ position: difCurrent !== 0 ? "absolute" : "", right: difCurrent !== 0 ? "-40px" : "", top: '14px' }}>{showWeigthLabel}</span>
                        }
                        {
                            showMktoolsLabels &&
                            <span style={{ position: showMktoolsLabels[1] < showMktoolsLabels[2] ? "absolute" : "", right: showMktoolsLabels[1] < showMktoolsLabels[2] ? "-40px" : "", top: '30px' }}>{(value + showMktoolsLabels[2]).toFixed(2) + " " +coin}</span>
                        }
                        </span>
                    </div>
                </div>
            </div>
            {showLabels && (
                <div className="labels">
                    {labels.map((label, i) => {
                        let left, right
                        if (coin !== null && i === 1) left = '15%'
                        else if (coin !== null && i === 2) right = '0%'
                        else left = i === labels.length - 1 ? '' : `${percentages[i]}%`

                        if (i === 0 || labels.length - 1 === i) return <div className="label" style={{ left }} />
                        return (
                            <div className="label" style={{ left, right }}>
                                {label} {coin !== null ? ' ' + coin : ''}
                            </div>
                        )
                    })}
                </div>
            )}
            {
                showMktoolsLabels &&
                <div className="labels">
                    {showMktoolsLabels.map((label, i) => {
                        let left, right
                        if(i==0) {
                            left="0%"
                        }
                        if(i==1){
                            right="0%"
                        }
                        if(i==2) return
                        return(
                            <div className="label" style={{ left, right }}>
                                {label} {coin !== null ? ' ' + coin : ' '} {i == 1 ? "max." : ""}
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}

export default ProgressBar
