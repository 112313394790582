import React from 'react'

import Select from 'react-select'
import iconLocation from '../../assets/img/icons/location.svg'
import { injectIntl } from 'react-intl'
import confirmIkeaCenter from '../global/confirmIkeaCenter'

const confirmChangeCenter = (store, onChangeIkeaShippingAddress, employeeId, setEmployeeId, intl) => {
    confirmIkeaCenter(intl.formatMessage({ id: 'CHANGE_STORE_CONFIRM' }), intl.formatMessage({ id: 'employee_id' }), { employeeId, setEmployeeId }).then(
        confirm => {
            onChangeIkeaShippingAddress(store, intl)
        },
        cancel => {}
    )
}

const IkeaShippingAddress = ({ shippingAddress, intl, onChangeIkeaShippingAddress, selectedShippingAddress, keepHere, employeeId, setEmployeeId }) => {
    if (!shippingAddress || shippingAddress.length === 0) return null

    let options = shippingAddress
        ? shippingAddress.map(shippingAddress => {
              return { value: shippingAddress.shippingAddressId, label: shippingAddress.customerName }
          })
        : null

    let input = (
        <React.Fragment>
        <Select
            onChange={selectOption => {
                if (selectOption !== null) confirmChangeCenter(selectOption.value, onChangeIkeaShippingAddress, employeeId, setEmployeeId, intl)
            }}
            value={selectedShippingAddress}
            options={options}
            clearable={false}
            className={'Select_center center' + selectedShippingAddress}
        />       
        </React.Fragment>
    )

    return (
        <div className="select-center">
            {input}
        </div>
    )
}

export default injectIntl(IkeaShippingAddress)
