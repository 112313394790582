import { connect } from 'react-redux'
import ProfileMenu from './ProfileMenu'
import { getUserName, getName, getUserImage, getUserType, getEmail } from '../../store/login/reducers'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'
import {
    canOffer,
    getCenters,
    getIsIkeaCenter,
    getIsLowesCenter,
    getIsMkToolsCenter
} from '../../store/centers/reducers'
import { getEmployeeId } from '../../store/big-account/reducers'
import { logout } from '../../store/login/actions'

const mapStateToProps = state => {
    return {
        username: getUserName(state),
        name: getName(state),
        email: getEmail(state),
        userimage: getUserImage(state),
        userType: getUserType(state),
        profileConfiguration: getProfileConfiguration(state),
        centers: getCenters(state),
        isIkeaCenter: getIsIkeaCenter(state),
        isLowesCenter: getIsLowesCenter(state),
        employeeId: getEmployeeId(state),
        isMkToolsCenter: getIsMkToolsCenter(state),
        canOffer: canOffer(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => {
            dispatch(logout())
        }
    }
}

const ProfileContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileMenu)

export default ProfileContainer
