import { formatValue } from './../global/CalculateMeasure';
import React, { Component } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import GoBack from '../global/GoBack'
import InputNumber from '../global/InputNumber'
import Button from '../global/Button'
import { addCartLineCutToSize } from '../../store/cart/actions'
import Translation from '../global/Translation'
import {
    getCutToSizeSecondaryParams,
    getCutToSizeMaterials,
    getCutToSizeCatalog,
    getCutToSizeCustomerPricingGroup,
    getCutToSizeSoleriaColors
} from '../../store/cut-to-size/reducers'
import {askForBudget, downloadPackagingTypesPdf} from '../../store/cut-to-size/actions'
import { colorOption, colorValue } from './ColorOptions'
import { modelOption, modelValue } from './ModelOptions'
import { fetchCutToSizeSecondaryParams } from '../../store/cut-to-size/actions'
import ElaborationDetails from './ElaborationDetails'
import { Messages, BrandOptionsMap } from './CutToSizeMaps'
import FloatingAlert from '../alerts/FloatingAlert'
import { showToastNotification } from '../../store/toast-notifications/actions'
import Modal from '../global/Modal'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import {
    getAdaptedFormat,
    getCmOrInches,
    getMeasureFromMetric,
    getMetricMeasureFromInput
} from "../../helpers";
import {getDefaultFurniturePackaging, setDefaultFurniturePackaging} from "../../store/profile-configuration/reducers";
import { dispatchPushURL } from '../../store/ui/actions'
import history from "../global/history";
import {canViewPreorder} from "../../store/login/reducers";
import LocalizedLink from "../global/LocalizedLink";
import {getAddonOptions, getFinishOptions, getThicknessOptions} from "./CalculateFromOptions";

const FILE_LIMIT = 4 //MB
const BYTES_IN_MB = 1024 * 1024
const BLUEPRINT_FILE_TYPE = 'BX01' //plano
const PACKAGING_LABELS_FILE_TYPE = 'BX10' //etiquetas
const PACKAGING_DOCUMENT_FILE_TYPE = 'BX03' //documento dentro del paquete

const ACCEPTED_EXT = {
    BX01:[
        'image/svg+xml',
        'image/jpeg',
        'image/png',
        'application/dxf',
        'application/pdf'
    ],
    BX10:[
        'image/jpeg',
        'image/png',
        'application/dxf',
        'application/pdf'
    ],
    BX03:[
        'application/msword',
        'application/pdf',
        'image/jpeg',
        'image/png',
    ]
}


const mapStateToProps = state => {
    return {
        secondaryParams: getCutToSizeSecondaryParams(state),
        materials: getMaterials(state),
        catalog: getCutToSizeCatalog(state),
        soleriaColors: getCutToSizeSoleriaColors(state),
        pricingGroup: getCutToSizeCustomerPricingGroup(state),
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state),
        units: getCmOrInches(state),
        measureFromMetric: (measure, decimals) => getMeasureFromMetric(measure, decimals, state),
        metricMeasureFromInput: (measure, decimals) => getMetricMeasureFromInput(measure, decimals, state),
        defaultFurniturePackaging: getDefaultFurniturePackaging(state),
        setDefaultFurniturePackaging: (defaultPackaging) => setDefaultFurniturePackaging(state, defaultPackaging),
        hasPreOrder: canViewPreorder(state)
    }
}

//Tratar genéricos como solo corte
const getMaterials = (state) => {
    const materials = getCutToSizeMaterials(state)
    return _.cloneDeep(materials)
}

const mapDispatchToProps = dispatch => {
    return {
        fetchSecondaryParams: (elaboration, brand, color, pricingGroup, hasSoleriaDekton, callback) => {
            dispatch(fetchCutToSizeSecondaryParams(elaboration, brand, color, pricingGroup, hasSoleriaDekton, callback))
        },
        addCartLineCutToSize: (qty, cutToSize) => {
            dispatch(addCartLineCutToSize(qty, cutToSize))
        },
        showToastNotification: (text = '', type = 'none', duration = 10000) => dispatch(showToastNotification(text, type, duration)),
        downloadPackagingTypesPdf: () => dispatch(downloadPackagingTypesPdf()),
        goToURL: (url) => {
            return dispatch(dispatchPushURL(url, 1))
        }
    }
}

const CM_DECIMALS = 2
const IN_DECIMALS = 0
const MIN_WIDTH = 30
const MIN_HEIGHT = 30
const MAX_WIDTH = 270
const MAX_HEIGHT = 170
const MIN_RADIUS = 0.2
const MAX_RADIUS = 30
const SECURITY_MARGIN = 5
const MAX_INSERTS = 100



class CutToSizeFurniture extends Component {

    _minWidth = this.props.measureFromMetric(MIN_WIDTH, IN_DECIMALS)
    _minHeight = this.props.measureFromMetric(MIN_HEIGHT, IN_DECIMALS)
    _securityMargin = this.props.measureFromMetric(SECURITY_MARGIN)
    _minRadius = this.props.measureFromMetric(MIN_RADIUS, IN_DECIMALS)
    _maxRadius = this.props.measureFromMetric(MAX_RADIUS, IN_DECIMALS)

    _models = null
    _modelsOptions = null
    _modelsInfo = null
    qtyToAdd = 1;


    state = {
        activeForm:'MAIN_PIECE',
        selectedModel: null,
        selectedPackaging: this.props.defaultFurniturePackaging,
        setPackingAsDefault: false,
        selectedBrand: null,
        selectedColor: null,
        selectedEdge: null,
        selectedBevel: null,
        selectedThickness: null,
        selectedFinished: null,
        selectedAddon: null,
        selectedQuality: null,
        selectedFiles: {},
        width: null,
        height: null,
        radius: null,
        insertsQuantity: null,
        bevel: null,
        currentColorOptions: [],
        currentBrandOptions: [],
        showDetail: false,
        showExceedFileSizeAlert: false,
        comments: null,
        currentModelImageOption: 0,
        extensions: {},
        // selectedModelOption: null,
        // selectedExtensions: [],
        // currentMeasures: new Set(),
        // modelsOptions: {},
        extWidth: null,
        extHeight: null,
    }

    constructor(props) {
        super(props)
        this.widthInput = React.createRef()
        this.heightInput = React.createRef()
        this.radiusInput = React.createRef()
        this.insertsInput = React.createRef()
    }

    componentDidMount() {

        Object.keys(this.props.materials['SOLO_CORTE']).forEach(brand => {
            const allowedBrands = this.props.materials[this.props.selectedElaboration].brands

            if (allowedBrands.includes(brand)) {
                this.state.currentBrandOptions.push(
                    {
                        label: BrandOptionsMap[brand].name,
                        value: brand
                    }
                )
            } else {
                delete this.props.materials['SOLO_CORTE'][brand]
            }
        })

        this.fillColors()

        this._models = this.props.materials[this.props.selectedElaboration].models
        this._modelsOptions = this.props.materials[this.props.selectedElaboration].options
        this._modelsInfo = this.props.materials[this.props.selectedElaboration].info

        this.updateOptions()
        // this.forceUpdate()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // esto solo para marcar un radio button por defecto!!!
        if (prevState.activeForm !== this.state.activeForm) {
            const key = this.state.activeForm.replace('EXTENSION_', '')
            const extension = this.state.extensions[key]
            if (extension) {
                if (extension.refs.samePatternYes.current) extension.refs.samePatternYes.current.checked = extension.samePattern
                if (extension.refs.samePatternNo.current) extension.refs.samePatternNo.current.checked = !extension.samePattern
            }
        }
    }

    fillColors = () => {
        Object.entries(this.props.materials['SOLO_CORTE']).forEach(([brand,brandColors]) => {
            brandColors.forEach(colorKey => {
                const catalogColor = this.props.catalog[colorKey]
                if (catalogColor) {
                    this.state.currentColorOptions.push({
                        label: catalogColor.name + ' (' + brand + ')',
                        value: colorKey,
                        thumb: catalogColor.thumb,
                        brand: brand
                    })
                } 
                
                this.state.currentColorOptions.sort((a, b) => a.label.localeCompare(b.label))
            })
        }) 
    }

    // getModelOutlineImages(modelName) {
    //      const model = modelName && ModelsOutlines && ModelsOutlines.filter(m => (Object.keys(m)[0].indexOf(modelName.toLowerCase()) !== -1))
    //      return model && model.map(m => Object.values(m)[0])
    // }

    // getModelDetailImages(modelName) {
    //     const model = modelName && ModelsDetails && ModelsDetails.filter(m => (Object.keys(m)[0].indexOf(modelName.toLowerCase()) !== -1))
    //     return model && model.map(m => Object.values(m)[0])
    // }

    resetProducts = () => {
        this.qtyToAdd = 1
        this.setState({
            selectedBrand: null,
            selectedColor: null,
            selectedThickness: null,
            selectedFinished: null
        })

        // for (let m in this.state.selectedFiles) delete this.state.selectedFiles[m]
        // for (let o in this.state.selectedModelOption) delete this.state.selectedModelOption[o]
        // const fileInputs = window.top.document.querySelectorAll('.attach-file')
        // if (fileInputs) fileInputs.forEach(i => i.value = '')
        // const radio = window.top.document.querySelectorAll('input[type="radio"]')
        // if (radio) radio.forEach(r => r.checked = false)
        // const text = window.top.document.querySelectorAll('input[type="text"]')
        // if (text) text.forEach(r => r.value = null)

    }

    updateOptions = () => {
        if (this.state.currentBrandOptions.length === 1 && this.state.currentBrandOptions[0].value != this.state.selectedBrand) this.handleSelectBrand(this.state.currentBrandOptions[0])
        if (this.state.currentColorOptions.length === 1 && this.state.currentColorOptions[0].value != this.state.selectedColor) this.handleSelectColor(this.state.currentColorOptions[0])
        // const thicknessOptions = this.getThicknessOptions()
        // if (thicknessOptions && thicknessOptions.length === 1) this.setState({selectedThickness : thicknessOptions[0].value})
        // const finishOptions = this.getFinishOptions()
        // if (finishOptions && finishOptions.length === 1) this.setState({selectedFinished : finishOptions[0].value})

        // if (this.state.selectedModel) {
        //     let width = parseFloat(this.getModelWidth())
        //     let height = parseFloat(this.getModelHeight())

        //     width = isNaN(width) ? null : width
        //     height = isNaN(height) ? null : height

        //     this.setState({ width, height })
        // }

        // this.forceUpdate()
    }

    goToPreOrder = () => {
        let url = this.props.goToURL(this.props.intl.formatMessage({ id: 'ROUTE_CUT_TO_SIZE' }))
        history.push({ pathname: url, search: 'type=budget'})
    }

    // resetSelectedExtensions = () => {
    //     const exts = this.getModelExtension()
    //     const extSize = exts ? exts.length : 0
    //     this.state.selectedExtensions.length = extSize
    //     this.state.selectedExtensions.fill(false)
    // }
    //
    // resetSelectedOptions = () => {
    //     for (let o in this.state.modelsOptions) delete this.state.modelsOptions[o]
    // }
    //
    // resetCurrentMeasures = () => {
    //     this.state.currentMeasures.clear()
    //     const width = this.getModelWidth()
    //     const height = this.getModelHeight()
    //     const radius = this.getModelHasRadius() ? 'R' : null
    //
    //     if (ALLOWED_MEASURES.includes(width)) this.state.currentMeasures.add(width)
    //     if (ALLOWED_MEASURES.includes(height)) this.state.currentMeasures.add(height)
    //     if (ALLOWED_MEASURES.includes(radius)) this.state.currentMeasures.add(radius)
    // }
    //
    // updateExtendableMeasures = () => {
    //     this.resetCurrentMeasures()
    //     const extensions = this.getModelExtension()
    //     if (extensions) {
    //         this.state.selectedExtensions.forEach((isChecked, index) => {
    //             if (isChecked) {
    //                 const ext = extensions[index]
    //                 if (ALLOWED_MEASURES.includes(ext.width)) this.state.currentMeasures.add(ext.width)
    //                 if (ALLOWED_MEASURES.includes(ext.height)) this.state.currentMeasures.add(ext.height)
    //             }
    //         })
    //     }
    //     this.forceUpdate()
    // }

    handleSelectModel = (modelOption, extKey) => {
        const model = modelOption ? modelOption.value : null

        if (extKey) {
            this.state.extensions[extKey].shape = model
            this.state.extensions[extKey].width = null
            this.state.extensions[extKey].height = null
            this.state.extensions[extKey].radius = null

            if (this.state.extensions[extKey].refs.widthInput && this.state.extensions[extKey].refs.widthInput.current) this.state.extensions[extKey].refs.widthInput.current.value = null
            if (this.state.extensions[extKey].refs.heightInput && this.state.extensions[extKey].refs.heightInput.current) this.state.extensions[extKey].refs.heightInput.current.value = null
            if (this.state.extensions[extKey].refs.radiusInput && this.state.extensions[extKey].refs.radiusInput.current) this.state.extensions[extKey].refs.radiusInput.current.value = null
            this.forceUpdate()
        } else {
            // this.resetProducts()
            this.setState({
                selectedModel: model,
                currentModelImageOption: 0,
                // selectedModelOption: null,
                width: null,
                height: null,
                radius: null,
                extWidth: null,
                extHeight: null,
                selectedEdge: null,
                selectedBevel: null,
                extensions: {}
            }, () => {
                // this.resetSelectedExtensions()
                // this.resetSelectedOptions()
                // this.resetCurrentMeasures()
                // this.forceUpdate()
            })
            if (this.widthInput && this.widthInput.current) this.widthInput.current.value = null
            if (this.heightInput && this.heightInput.current) this.heightInput.current.value = null
            if (this.radiusInput && this.radiusInput.current) this.radiusInput.current.value = null
            if (this.insertsInput && this.insertsInput.current) this.insertsInput.current.value = null
        }
    }    

    handleSelectBrand = brandOption => {
        const brand = brandOption ? brandOption.value : null
        const { materials, catalog } = this.props
        this.resetProducts()
        this.state.currentColorOptions.length = 0

        if (brand) {
            const materialColors = materials['SOLO_CORTE'][brand]

            materialColors.forEach(color => {
                if (catalog[color]) {
                    this.state.currentColorOptions.push({
                        label: catalog[color].name,
                        value: color,
                        thumb: catalog[color].thumb,
                        brand: brand
                    })
                }
            })  
            this.state.currentColorOptions.sort((a, b) => a.label.localeCompare(b.label))          
        } else {
            this.fillColors()
        }

        this.setState({ selectedBrand: brand }, this.updateOptions)        
    }

    handleSelectColor = colorOption => {
        if (colorOption) {
            if (this.props.secondaryParams) this.props.secondaryParams.length = 0
            this.props.fetchSecondaryParams(
                'SOLO_CORTE',
                colorOption.brand,
                colorOption.value,
                this.props.pricingGroup,
                false,
                () => {
                    this.setState({
                        selectedThickness: null,
                        selectedFinished: null,
                        selectedAddon: null,
                        width: null,
                        height: null,
                        selectedColor: colorOption && colorOption.value,
                        selectedBrand: colorOption.brand
                    })
                }
            )
        } else {
            this.setState({
                selectedThickness: null,
                selectedFinished: null,
                selectedAddon: null,
                width: null,
                height: null,
                selectedColor: null
            })
        }
    }

    handleSelectFinish = finishOptions => { 
        const finish = finishOptions && finishOptions.value
        if (finish === this.state.selectedFinished) return
        this.setState({ selectedFinished: finish })
        if (!finish) {
            this.setState({ selectedThickness: null, selectedAddon: null })
        }
    }

    handleSelectThickness = thicknessOption => {
        const thickness = thicknessOption && thicknessOption.value
        if (thickness === this.state.selectedThickness) return
        this.setState({ selectedThickness: thickness })
    }

    handleSelectAddon = addonOption => {
        const addon = addonOption && addonOption.value
        if (addon === this.state.selectedAddon) return

        this.setState({ selectedAddon: addon })
    }

    handleChangeWidth = (value, extKey, flipMax) => {
        value = Math.max(Math.min(value, (flipMax ? this._maxHeight : this._maxWidth)), 0)
        this.setWidth(value, extKey)
    }

    handleChangeMinWidth = (value, extKey) => {
        if (value) {
            value = Math.max(value, this._minWidth)
            this.setWidth(value, extKey)
        }        
    }

    setWidth = (value, extKey) => {
        value = formatValue(value)
        if (extKey) {
            this.state.extensions[extKey].width = value
            const extDimensionsByShape = this.getDimensionsByShape(this.state.extensions[extKey].shape)
            if (extDimensionsByShape.isSquare || extDimensionsByShape.hasDiameter) this.state.extensions[extKey].height = value
            if (this.state.extensions[extKey].refs.widthInput) this.state.extensions[extKey].refs.widthInput.current.value = value
            this.forceUpdate()
        }
        else {
            this.setState({ width: value })
            if (this.isHeightEqualA()) this.setState({ height: value })
            if (this.widthInput.current) this.widthInput.current.value = value
        }
    }


    handleChangeHeight = (value, extKey) => {
        value = value && Math.max(Math.min(value, this._maxHeight), 0)
        this.setHeight(value, extKey)
    }

    handleChangeMinHeight = (value, extKey) => {
        if (value) {
            value = Math.max(value, this._minHeight)
            this.setHeight(value, extKey)
        }
    }

    setHeight = (value, extKey) => {
        value = formatValue(value)
        if (extKey) {
            this.state.extensions[extKey].height = value
            this.forceUpdate()
            if (this.state.extensions[extKey].refs.heightInput) this.state.extensions[extKey].refs.heightInput.current.value = value
        }
        else {
            this.setState({ height: value })
            if (this.heightInput.current) this.heightInput.current.value = value
        }
    }

    handleChangeRadius = (value, extKey) => {
        value = value && Math.max(Math.min(value, this._maxRadius), this._minRadius)
        value = formatValue(value)
        if (extKey) {
            this.state.extensions[extKey].radius = value
            this.forceUpdate()
            if (this.state.extensions[extKey].refs.radiusInput) this.state.extensions[extKey].refs.radiusInput.current.value = value
        }
        else {
            this.setState({ radius: value })
            if (this.radiusInput.current) this.radiusInput.current.value = value
        }
    }

    handleChangeInsertsQuantity = (value) => {
        value = value && Math.max(Math.min(value, MAX_INSERTS), 0)
        this.setState({ insertsQuantity: value })
        if (this.insertsInput.current) this.insertsInput.current.value = value
    }

    handleChangeBevel = bevelOption => {
        const bevel = bevelOption && bevelOption.value
        if (bevel === this.state.selectedBevel) return
        this.setState({ selectedBevel: bevel })
    }

    handleSelectEdge = edgeOption => {
        const edge = edgeOption && edgeOption.value
        if (edge === this.state.selectedEdge) return
        this.setState({ selectedEdge: edge, selectedBevel: !!edge ? this.state.selectedBevel : null})
    }

    handleSelectPackaging = packagingOption => {
        const packaging = packagingOption && packagingOption.value
        if (packaging === this.state.selectedPackaging) return
        this.setState({ selectedPackaging: packaging })
    }

    // disableSendButton = () => {
    //
    //     const hasExtension = this.state.selectedExtensions.length > 0
    //     let disableByExtensions = false
    //     let disableByOptions = false
    //
    //     const requiredOptions = this._modelsOptions && this._modelsOptions.filter(o => o.required === true)
    //     if (requiredOptions && requiredOptions.length > 0) {
    //         const requieredCumplished = requiredOptions.map(op => {
    //             return (op.id in this.state.modelsOptions)
    //         })
    //         disableByOptions = requieredCumplished.find(r => r === false) === undefined ? false : true
    //     }
    //
    //
    //
    //     if (hasExtension) {
    //         const selectedExtensions = this.state.selectedExtensions.map((e, index) => {return {index, value:e}}).filter(e => e.value === true)
    //         const filteredSelectedExtensions = selectedExtensions.map(e => {
    //             if (e.index === 0) {
    //                 return this.state.height !== null && this.state.width !== null
    //             }
    //             else return this.state.extHeight !== null && this.state.extWidth !== null
    //         }).filter(e => e === true)
    //
    //         disableByExtensions = selectedExtensions.length === 0 || filteredSelectedExtensions.length !== selectedExtensions.length // ||selectedExtensions.find(e => e === false)
    //     }
    //
    //     return !this.state.selectedBrand ||
    //             !this.state.selectedColor ||
    //             !this.state.selectedThickness ||
    //             !this.state.selectedFinished ||
    //             (!hasExtension && (!this.state.height || !this.state.width)) ||
    //             disableByExtensions ||
    //             disableByOptions
    //             // ((this.isSoleria() || this.isRodapie()) && !this.state.selectedFormat) ||
    //             // (this.isRodapie() && !this.state.height) ||
    //             // (this.isJusCut() && (!this.state.height || !this.state.width))
    // }

    setActiveForm = (activeForm, elementId) => this.setState({ activeForm }, () => {
        const id = elementId ? elementId : activeForm
        const element = document.getElementById(id)
        element.scrollIntoView({block: "center"})
        // const header = document.getElementById("header")
        // const headerHeight = header.offsetHeight
        // window.scroll({top: headerHeight})
    })

    addExtension = () => {
        const key = uuidv4()
        this.state.extensions[key] = {
            shape: null,
            width: null,
            height: null,
            radius: null,
            samePattern: true,
            quantity: 1,
            refs: {
                widthInput: React.createRef(),
                heightInput: React.createRef(),
                radiusInput: React.createRef(),
                samePatternYes: React.createRef(),
                samePatternNo: React.createRef()
            }
        }
        const extensionFormKey = 'EXTENSION_' + key
        this.setActiveForm(extensionFormKey)
    }

    removeExtension = key => {
        delete this.state.extensions[key]
        const lastExtensionIndex = Object.keys(this.state.extensions).length - 1
        if (lastExtensionIndex >= 0) {
            const lastExtensionKey = Object.keys(this.state.extensions)[lastExtensionIndex]
            const extensionFormKey = 'EXTENSION_' + lastExtensionKey
            this.setActiveForm(extensionFormKey)
        }
        else {
            this.setActiveForm('PACKAGING_AND_LABELING')
        }
    }


    // clearProduct = () => {
    //     this.setState({ selectedProduct: null })
    // }
    //
    // onResize = (event, { size }, conversionFactor) => {
    //     this.setState({ width: parseInt(size.width / conversionFactor, 10), height: parseInt(size.height / conversionFactor, 10) })
    // }

    onFileChange = async (event, type) => {
        const file = event.target.files[0];
        if (file) {
            // if (!ACCEPTED_EXT[type].includes(file.type)) {
            //     event.target.value = null
            //     this.state.selectedFiles[type] = null
            // } else
            {
                const fileSizeInMB = file.size / BYTES_IN_MB
                if (fileSizeInMB > FILE_LIMIT) {
                    this.setState({showExceedFileSizeAlert: true})
                    event.target.value = '';
                } else {
                    this.state.selectedFiles[type] = file;
                }
            }
        } else {
            delete this.state.selectedFiles[type]
            // this.state.selectedFiles[type] = null;
        }
        this.forceUpdate()
    }

    getFinishOptions = () => {
        
        const rawFinish = this.props.secondaryParams && this.state.selectedColor && this.props.secondaryParams.finishes
        
        if (rawFinish) {
            const finishOptions = rawFinish.map(finished => {
                if (finished !== '') {
                    const label = this.props.intl.formatMessage({ id: finished })
                    return {
                        label,
                        value: finished
                    }
                }                    
                else return null
            })
            if (finishOptions.length === 1) this.handleSelectFinish(finishOptions[0])
            return finishOptions
        }
    }

    getThicknessOptions = () => {

        const rawThickness = this.props.secondaryParams && this.state.selectedColor && this.props.secondaryParams.thicknesses.sort()
        
        if (rawThickness) {
            const thicknessOptions = rawThickness.map(thickness => {
                return {
                    label: thickness + ' cm',
                    value: thickness
                }
            })   
            if (thicknessOptions.length === 1) this.handleSelectThickness(thicknessOptions[0])
            return thicknessOptions
        }
    }

    getDimensionsByShape = (shape) => {
        if (shape && this._models && this._models[shape]) {
            const hasDiameter = this._models[shape].width === 'A' && !(!!this._models[shape].height)
            const isSquare = this._models[shape].width === 'A' && this._models[shape].height === 'A'
            const hasRadius = !!this._models[shape].radius
            let dimensionInputsCount = 1
            if (!hasDiameter || !isSquare) ++dimensionInputsCount
            if (hasRadius) ++dimensionInputsCount
            return {
                isSquare,
                hasDiameter,
                hasRadius,
                dimensionInputsCount
            }
        }
    }


    getModelWidth = () => this.state.selectedModel && 
                            this._models && 
                            this._models[this.state.selectedModel] &&
                            this._models[this.state.selectedModel].width

    getModelHeight = () => this.state.selectedModel && 
                            this._models && 
                            this._models[this.state.selectedModel] &&
                            this._models[this.state.selectedModel].height

    getModelHasRadius = () => this.state.selectedModel &&
                            this._models &&
                            this._models[this.state.selectedModel] &&
                            !!this._models[this.state.selectedModel].radius

    getShapeHasRadius = (shape) => this._models &&
                            this._models[shape] &&
                            !!this._models[shape].radius

    getModelOptions = () => this.state.selectedModel && 
                            this._models && 
                            this._models[this.state.selectedModel] &&
                            this._models[this.state.selectedModel].options

    getModelInfo = () => this.state.selectedModel && 
                            this._models && 
                            this._models[this.state.selectedModel] &&
                            this._models[this.state.selectedModel].info

    getGenericInfo = () => this.state.selectedModel && this._modelsInfo
                            
    getModelExtension = () => this.state.selectedModel && 
                            this._models && 
                            this._models[this.state.selectedModel] &&
                            this._models[this.state.selectedModel].ext

    getModelOutlineImages = () => this.state.selectedModel && 
                            this._models && 
                            this._models[this.state.selectedModel] &&
                            this._models[this.state.selectedModel].outline_images

    getModelDetailImages = () => this.state.selectedModel && 
                            this._models && 
                            this._models[this.state.selectedModel] &&
                            this._models[this.state.selectedModel].detail_images

    // getModelDimensions() {
    //     const options = this.getModelOptions()
    //     return options && options.dimensions.map((v, index) => {
    //         let dim = v[0]
    //         if (v[1]) dim += `X${v[1]}`
    //         return {
    //             label: `${this.props.intl.formatMessage({id:"option", defaultMessage:"Option"})} ${index+1}: ${dim} cm`,
    //             value: index
    //         }
    //     })
    // }
    //
    // getModelsRadioOptions() {
    //     return this._modelsOptions && this._modelsOptions.filter(o => o.type === 'radio')
    // }
    //
    // getModelsFileOptions() {
    //     return this._modelsOptions && this._modelsOptions.filter(o => o.type === 'file')
    // }
    //
    // getModelsTextOptions() {
    //     return this._modelsOptions && this._modelsOptions.filter(o => o.type === 'text')
    // }
    //
    // handleSelectModelOption = (modelOption) => {
    //     const optionIndex = modelOption && modelOption.value
    //     if (optionIndex === this.state.selectedModelOption) return
    //     const allOptions = this.getModelOptions().dimensions
    //     const currentOption = allOptions[optionIndex]
    //
    //     let height
    //     if (currentOption[1]) {
    //         height = currentOption[1]
    //     } else {
    //         const modelHeight = Number.parseFloat(this.getModelHeight())
    //         const modelHasHeight = !Number.isNaN(modelHeight)
    //         height = modelHasHeight ? modelHeight : currentOption[0]
    //     }
    //
    //     this.setState({
    //         selectedModelOption: optionIndex,
    //         width: currentOption[0],
    //         height
    //     })
    // }
    //
    // handleSelectModelsOptions = (e, option, innerOption) => {
    //     let value
    //
    //     switch (option.type) {
    //         case "radio":
    //             value = innerOption.value
    //             // if (!value) delete this.state.modelsOptions[option.id]
    //             // else this.state.modelsOptions[option.id] = {comments: value}
    //             break
    //         case "text":
    //             value = e.target.value
    //             // if (!value) delete this.state.modelsOptions[option.id]
    //             // else this.state.modelsOptions[option.id] = {sap_field: option.sap_field, value: value}
    //             break
    //         case "file":
    //             value = e.target.value
    //             // if (!value) delete this.state.modelsOptions[option.id]
    //             // else this.state.modelsOptions[option.id] = {has_file: true}
    //             break
    //     }
    //
    //     if (!value) {
    //         delete this.state.modelsOptions[option.id]
    //     }
    //     else {
    //         const optionsState = {}
    //         if (option.as_comment) {
    //             optionsState.comments = value
    //         } else if (option.sap_field) {
    //             optionsState.sap_field = option.sap_field
    //             optionsState.value = value
    //         } else {
    //             optionsState.has_value = true
    //         }
    //         this.state.modelsOptions[option.id] = optionsState
    //     }
    // }

    // getZModel = () => {
    //     if (this.isCustomFurniture()) {
    //         const selectedModel = this.state.selectedModelOption ? this.state.selectedModelOption : 0
    //         let model = this._models && this._models[this.state.selectedModel].z_models[selectedModel]
    //         if (!model) model = this._models && this._models[this.state.selectedModel].z_models[0]
    //         return model
    //     }
    // }

    isMainPieceDone = () => {
        const passRadius = !this.getModelHasRadius() || !!this.state.radius
        // const passBlueprint = !(!!this.state.insertsQuantity) || !!this.state.selectedFiles[BLUEPRINT_FILE_TYPE]
        const passBlueprint = !!this.state.selectedFiles[BLUEPRINT_FILE_TYPE]
        return !!this.state.selectedModel &&
                !!this.state.selectedColor &&
                !!this.state.selectedFinished &&
                !!this.state.selectedThickness &&
                !!this.state.selectedAddon &&
                !!this.state.width && !!this.state.height &&
                passRadius && passBlueprint
    }

    isExtensionDone = (extKey) => {
        const ext = this.state.extensions[extKey]
        return !!ext && !!ext.shape && ext.width && ext.height && (!this.getShapeHasRadius(ext.shape) || !!ext.radius)
    }

    areExtensionsDone = () => {
        try {
            Object.keys(this.state.extensions).forEach(key => {
                if (!this.isExtensionDone(key)) {
                    throw "undone";
                }
            })
        } catch (e) {
            return false
        }
        return true
    }

    isPackagingDone = () => {
        return !!this.state.selectedPackaging
    }

    isHeightEqualA = () =>  this.getModelHeight() === 'A' || !this.getModelHeight()


    submitCartLine = () => {

        let width = parseFloat(this.state.width)
        let height = parseFloat(this.state.height)
        let radius = parseFloat(this.state.radius)
        height = this.props.metricMeasureFromInput(height, CM_DECIMALS)
        width = this.props.metricMeasureFromInput(width, CM_DECIMALS)
        radius = this.props.metricMeasureFromInput(radius, CM_DECIMALS)

        const extensions = Object.values(this.state.extensions).map(ext => {
            let extWidth = parseFloat(ext.width)
            let extHeight = parseFloat(ext.height)
            let extRadius = parseFloat(ext.radius)
            extWidth = this.props.metricMeasureFromInput(extWidth, CM_DECIMALS)
            extHeight = this.props.metricMeasureFromInput(extHeight, CM_DECIMALS)
            extRadius = this.props.metricMeasureFromInput(extRadius, CM_DECIMALS)

            return {
                quantity: ext.quantity,
                shape: ext.shape,
                shapeType: this.props.materials[this.props.selectedElaboration].models[ext.shape].shape_type,
                width: Math.round((extWidth + Number.EPSILON) * 100) / 100,
                height: Math.round((extHeight + Number.EPSILON) * 100) / 100,
                radius: Math.round((extRadius + Number.EPSILON) * 100) / 100,
                samePattern: ext.samePattern ? 'YES' : 'NO'
            }
        })

        this.props.addCartLineCutToSize(
            this.qtyToAdd, {
                elaboration: this.props.selectedElaboration,
                colorName: this.props.catalog[this.state.selectedColor].name,
                brand: this.state.selectedBrand,
                colorId: this.state.selectedColor,
                finish: this.state.selectedFinished,
                thickness: this.state.selectedThickness,
                addon: this.state.selectedAddon,
                width: Math.round((width + Number.EPSILON) * 100) / 100,
                height: Math.round((height + Number.EPSILON) * 100) / 100,
                radius: Math.round((radius + Number.EPSILON) * 100) / 100,
                files: this.state.selectedFiles,
                shape: this.state.selectedModel,
                edge: this.state.selectedEdge,
                bevel: this.state.selectedBevel,
                inserts: this.state.insertsQuantity,
                shapeType: this.props.materials[this.props.selectedElaboration].models[this.state.selectedModel].shape_type,
                packaging: this.state.selectedPackaging,
                setDefaultPackaging: this.state.setPackingAsDefault,
                extensions,
                thumb: this.props.catalog[this.state.selectedColor].img,
                units: this.props.units
        })

        this.props.setDefaultFurniturePackaging(this.state.selectedPackaging)

    }

    /**********************************************************************************************/



    getMeasureLabel = (dimensionsByShape) => {
        if (dimensionsByShape) {
            if (dimensionsByShape.hasDiameter) return this.props.intl.formatMessage(Messages.DIAMETER)
            else if (dimensionsByShape.isSquare) return this.props.intl.formatMessage(Messages.SIDE)
        }
        return this.props.intl.formatMessage({ id: 'length' })
    }

    render() {
        const { selectedBrand, selectedColor, selectedModel, selectedPackaging, setPackingAsDefault,
                selectedEdge, selectedThickness, currentBrandOptions, selectedBevel, currentColorOptions,
                width, height, radius } = this.state
        const { intl, selectedElaboration, materials, secondaryParams, catalog, units} = this.props

        if (!selectedElaboration) return null

        const modelOptions = selectedElaboration && materials[selectedElaboration] && Object.entries(materials[selectedElaboration].models).map(([key,value]) => {
            return {
                label: this.props.intl.formatMessage(Messages[key]),
                value: key,
                image: process.env.REACT_APP_API_HOST + value.shape
            }
         })

        const packagingOptions = selectedElaboration && materials[selectedElaboration] && Object.entries(materials[selectedElaboration].packaging).map(([key,value]) => {
            return {
                label: this.props.intl.formatMessage(Messages[key]),
                value: key
            }
        })

        const edgeOptions = selectedModel && selectedThickness && Object.entries(materials[selectedElaboration].edges)
            .filter(([key,edge]) => {
                if (edge.avoid && edge.avoid[selectedModel]) return false
                if (!edge.restrictions) return true
                const shapeType = materials[selectedElaboration].models[selectedModel].shape_type
                const allowedThicknessByEdge = edge.restrictions[selectedBrand] && edge.restrictions[selectedBrand][shapeType]
                return !!allowedThicknessByEdge && allowedThicknessByEdge.includes(selectedThickness)
            })
            .map(([key,edge]) => {
                return {
                    label: this.props.intl.formatMessage(Messages[key]),
                    value: key,
                    image: process.env.REACT_APP_API_HOST + edge.image
                }
            })

        const bevelOptions = selectedThickness && selectedEdge && materials[selectedElaboration].edges[selectedEdge].bevel
            && Object.entries(materials[selectedElaboration].bevels)
            .filter(([key,bevel]) => {
                if (!bevel.restrictions) return true
                return bevel.restrictions.includes(selectedThickness)
            })
            .map(([key,bevel]) => {
                return {
                    label: bevel.label,
                    value: key
                }
            })

        const modelOptionsFilteredByEdge = modelOptions && modelOptions.filter((po) => {
            if (!selectedEdge) return true
            const selectedEdgeData = materials[selectedElaboration].edges[selectedEdge]
            if (!selectedEdgeData.restrictions) return true
            const shapeType = materials[selectedElaboration].models[po.value].shape_type
            const allowedThicknessByEdge = selectedEdgeData.restrictions[selectedBrand] && selectedEdgeData.restrictions[selectedBrand][shapeType]
            return !!allowedThicknessByEdge && allowedThicknessByEdge.includes(selectedThickness)
        })

        // const finishesOptions = this.getFinishOptions()
        // const thicknessOptions = this.getThicknessOptions()

        const finishesOptions = getFinishOptions(this.props, this.state)
        const thicknessOptions = getThicknessOptions(this.props, this.state)
        const addonsOptions = getAddonOptions(this.props, this.state)

        if (addonsOptions && addonsOptions.length === 1) this.handleSelectAddon(addonsOptions[0])
        if (thicknessOptions && thicknessOptions.length === 1) this.handleSelectThickness(thicknessOptions[0])
        if (thicknessOptions && finishesOptions.length === 1) this.handleSelectFinish(finishesOptions[0])

        if (catalog && catalog[selectedColor] && /\d+[xX]\d+/.exec(catalog[selectedColor].format)) {
            const maxValues = catalog[selectedColor].format.split(/[xX]/)
            if (maxValues[0]) maxValues[0] -= this._securityMargin
            if (maxValues[1]) maxValues[1] -= this._securityMargin
            this._maxWidth = Math.max(maxValues[0], maxValues[1])
            this._maxHeight = Math.min(maxValues[0], maxValues[1])
        } else {
            this._maxWidth = MAX_WIDTH
            this._maxHeight = MAX_HEIGHT
        }
        this._maxWidth = this.props.measureFromMetric(this._maxWidth, IN_DECIMALS)
        this._maxHeight = this.props.measureFromMetric(this._maxHeight, IN_DECIMALS)

        const modelImage = this.getModelOutlineImages()
        const modelDetail = this.getModelDetailImages()
        // const modelExtensions = this.getModelExtension()
        // const modelDimensionsOptions = this.getModelDimensions()
        // const modelsRadioOptions = this.getModelsRadioOptions()
        // const modelsFileOptions = this.getModelsFileOptions()
        // const modelsTextOptions = this.getModelsTextOptions()


        // const hasMeasureA = this.state.currentMeasures.has('A')
        // const hasMeasureB = this.state.currentMeasures.has('B')
        // // const hasMeasureC = this.state.currentMeasures.has('C')
        // // const hasMeasureD = this.state.currentMeasures.has('D')
        // const hasDiameter = this.state.currentMeasures.has('0')
        // const hasRadius = this.state.currentMeasures.has('R')
        //
        // let measureInputsCount = 1
        // if (hasMeasureB) ++measureInputsCount
        // if (hasRadius) ++measureInputsCount

        const dimensionsByShape = this.getDimensionsByShape(this.state.selectedModel)
        const extensionsLenght = Object.keys(this.state.extensions).length
        let extensionsCounter = 0

        const isMainPieceDone = this.isMainPieceDone()
        const isPackagingDone = this.isPackagingDone()


        return (
            <section className="cut_to_size">
                <div className="cut_to_size__go_back mt-4 mb-3">
                    <LocalizedLink routeId={'ROUTE_CUT_TO_SIZE'} header={true} queryString={''} params={{'outlet':''}}>
                        <GoBack
                            text={intl.formatMessage(Messages.BACK_SELECT_ELABORATED_TYPE)}
                            dataCY={'product_detail__go_back'}
                        />
                    </LocalizedLink>
                </div>

                <div className="container form">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <div style={{ width: '100%' }}>
                                <ElaborationDetails
                                    id={selectedElaboration}
                                    modelImage={modelImage}
                                    hasDetail={modelDetail ? true : false}
                                    showDetail={() => this.setState({ showDetail: true })}
                                    setCurrentModelOption={index => this.setState({ currentModelImageOption: index })}
                                    modelInfo={this.getModelInfo()}
                                    genericInfo={this.getGenericInfo()}
                                    currentModelOption={this.state.currentModelImageOption}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-5">
                            <div
                                className={`mt-2 ${this.state.activeForm == 'MAIN_PIECE' ? 'cut_to_size__filters--active' : 'cut_to_size__filters'}`}
                                style={{
                                    padding: this.state.activeForm == 'MAIN_PIECE' ? '24px 24px 16px 24px' : '8px 24px 8px 24px'
                                }}
                            >
                                <h5 onClick={() => this.setState({ activeForm: 'MAIN_PIECE' })} style={{ cursor: 'pointer', marginInlineStart: '-8px' }}>
                                    <div className={`desp_cirle_mark${isMainPieceDone ? '_success' : ''}`}>{isMainPieceDone ? <i className="fa fa-check" /> : 1}</div>
                                    <Translation id="main_piece" defaultMessage="Main piece" />
                                    &nbsp;&nbsp;
                                    <i className={this.state.activeForm == 'MAIN_PIECE' ? 'fa fa-caret-down' : 'fa fa-caret-right'} aria-hidden="true" />
                                </h5>
                                <div className={`${this.state.activeForm == 'MAIN_PIECE' ? 'd-block' : 'd-none'}`}>
                                    {/*<div className="d-block">*/}
                                    {/**************** MODELO PREDEFINIDO **********************/}
                                    {selectedElaboration && (
                                        <React.Fragment>
                                            <div className="group__input m-0">
                                                <label htmlFor="model-select" className="form-label">
                                                    <Translation id="predefined_model" defaultMessage="Predefined model" />*
                                                </label>
                                                <div className="contain__select--model">
                                                    <Select
                                                        id="model-select"
                                                        placeholder={this.props.intl.formatMessage(Messages.CHOOSE_MODEL)}
                                                        options={modelOptions}
                                                        optionComponent={modelOption}
                                                        valueComponent={modelValue}
                                                        value={selectedModel}
                                                        onChange={selectedOption => this.handleSelectModel(selectedOption)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="field-info">
                                                {this.props.hasPreOrder && (
                                                    <React.Fragment>
                                                        <Translation id="dont-find-shape" defaultMessage="Don't find the shape you are looking for? Ask your quotation" />
                                                        <span style={{ color: '#007bff' }}>
                                                            &nbsp;
                                                            <a
                                                                href="#"
                                                                onClick={e => {
                                                                    e.preventDefault()
                                                                    this.goToPreOrder()
                                                                }}
                                                            >
                                                                <Translation id="here" defaultMessage="here" />
                                                            </a>
                                                        </span>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </React.Fragment>
                                    )}
                                    {/**************** MARCA **********************/}
                                    {selectedElaboration && (
                                        <div className="group__input">
                                            <label htmlFor="brand-select" className="form-label">
                                                <Translation id="brand" defaultMessage="Brand" />*
                                            </label>
                                            <div className="contain__select--brand">
                                                <Select
                                                    id="brand-select"
                                                    placeholder={this.props.intl.formatMessage(Messages.CHOOSE_BRAND)}
                                                    options={currentBrandOptions}
                                                    value={selectedBrand}
                                                    onChange={selectedOption => this.handleSelectBrand(selectedOption)}
                                                    disabled={!currentBrandOptions || currentBrandOptions.length <= 1}
                                                    className={`${!currentBrandOptions || currentBrandOptions.length <= 1 ? ' disabled' : ' '}`}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {/**************** COLORES **********************/}
                                    <div className="group__input">
                                        <label htmlFor="color-select" className="form-label">
                                            <Translation id="color" defaultMessage="Color" />*
                                        </label>
                                        <Select
                                            id="color-select"
                                            placeholder={this.props.intl.formatMessage(Messages.CHOOSE_COLOR)}
                                            options={currentColorOptions}
                                            value={selectedColor}
                                            onChange={selectedOption => this.handleSelectColor(selectedOption)}
                                            optionComponent={colorOption}
                                            valueComponent={colorValue}
                                            disabled={!currentColorOptions || currentColorOptions.length <= 1}
                                            className={`${!currentColorOptions || currentColorOptions.length <= 1 ? ' disabled' : ' '}`}
                                        />
                                    </div>
                                    {/**************** ACABADOS **********************/}
                                    <div className="group__input group__input--finish">
                                        <label htmlFor="finish-select" className="form-label">
                                            <Translation id="finish" defaultMessage="Finish" />*
                                        </label>
                                        <Select
                                            id="finish-select"
                                            placeholder={this.props.intl.formatMessage(Messages.CHOOSE_FINISH)}
                                            options={finishesOptions}
                                            value={this.state.selectedFinished}
                                            onChange={selectedOption => this.handleSelectFinish(selectedOption)}
                                            disabled={!finishesOptions || finishesOptions.length === 0}
                                            className={`capitalize ${!finishesOptions || finishesOptions.length === 0 ? ' disabled' : ''}`}
                                        />
                                    </div>
                                    {/**************** GROSOR **********************/}
                                    <div className="group__input group__input--thickness">
                                        <label htmlFor="thickness-select" className="form-label">
                                            <Translation id="thickness" defaultMessage="Thickness" />*
                                        </label>
                                        <Select
                                            id="thickness-select"
                                            // clearable={false}
                                            placeholder={this.props.intl.formatMessage(Messages.CHOOSE_THICKNESS)}
                                            options={thicknessOptions}
                                            value={this.state.selectedThickness}
                                            onChange={selectedOption => this.handleSelectThickness(selectedOption)}
                                            disabled={!thicknessOptions || thicknessOptions.length <= 1}
                                            className={`${!thicknessOptions || thicknessOptions.length === 0 ? ' disabled' : ''}`}
                                        />
                                    </div>

                                    {/**************** ADDONS **********************/}
                                    <div className="group__input group__input--addon">
                                        <label htmlFor="addon-select" className="form-label">
                                            <Translation id="MAL" defaultMessage="Meshed" />*
                                        </label>
                                        <div data-qas="cts_form_default-addon-dropdown">
                                            <Select
                                                id="addon-select"
                                                placeholder={this.props.intl.formatMessage(Messages.CHOOSE_OPTION)}
                                                options={addonsOptions}
                                                value={this.state.selectedAddon}
                                                onChange={selectedOption => this.handleSelectAddon(selectedOption)}
                                                disabled={!addonsOptions || addonsOptions.length <= 1}
                                                className={`capitalize ${!addonsOptions || addonsOptions.length === 0 ? ' disabled' : ''}`}
                                                // clearable={addonsOptions && addonsOptions.length > 1}
                                            />
                                        </div>
                                    </div>

                                    {/**************** MEDIDAS **********************/}
                                    {dimensionsByShape && (
                                        <div className="row">
                                            <div className={`col-${12 / dimensionsByShape.dimensionInputsCount}`}>
                                                <label htmlFor="width-input" className="measures-label">
                                                    {/*{this.props.intl.formatMessage(Messages.MEASURE_A)} (cm)*/}
                                                    {this.getMeasureLabel(dimensionsByShape)} ({units})*
                                                </label>
                                                <input
                                                    type="number"
                                                    id="width-input"
                                                    placeholder={this.getMeasureLabel(dimensionsByShape)}
                                                    required
                                                    ref={this.widthInput}
                                                    value={width}
                                                    min={this._minWidth}
                                                    onBlur={event => this.handleChangeMinWidth(event.target.value)}
                                                    onChange={event => this.handleChangeWidth(event.target.value, null, this.isHeightEqualA())}
                                                    disabled={!selectedColor}
                                                    className={`form-control ${selectedColor ? ' ' : ' disabled'}`}
                                                />
                                            </div>

                                            {!dimensionsByShape.isSquare && !dimensionsByShape.hasDiameter && (
                                                <div className={`col-${12 / dimensionsByShape.dimensionInputsCount}`}>
                                                    <label htmlFor="height-input" className="measures-label">
                                                        <Translation id="width" defaultMessage="Width" /> ({units})*
                                                    </label>
                                                    <input
                                                        type="number"
                                                        id="height-input"
                                                        placeholder={this.props.intl.formatMessage({ id: 'width' })}
                                                        required
                                                        ref={this.heightInput}
                                                        value={height}
                                                        onBlur={event => this.handleChangeMinHeight(event.target.value)}
                                                        min={this._minHeight}
                                                        onChange={event => this.handleChangeHeight(event.target.value)}
                                                        disabled={!selectedColor}
                                                        className={`form-control ${selectedColor ? ' ' : ' disabled'}`}
                                                    />
                                                </div>
                                            )}

                                            {dimensionsByShape.hasRadius && (
                                                <div className={`col-${12 / dimensionsByShape.dimensionInputsCount}`}>
                                                    <label htmlFor="radius-input" className="measures-label">
                                                        <Translation id="radius" defaultMessage="Radius" /> ({units})*
                                                    </label>
                                                    <input
                                                        type="number"
                                                        id="radius-input"
                                                        placeholder={this.props.intl.formatMessage({ id: 'radius' })}
                                                        required
                                                        ref={this.radiusInput}
                                                        value={radius}
                                                        onChange={event => this.handleChangeRadius(event.target.value)}
                                                        disabled={!selectedColor}
                                                        className={`form-control ${selectedColor ? ' ' : ' disabled'}`}
                                                    />
                                                </div>
                                            )}

                                            <div className="col-12">
                                                <div className="field-info">
                                                    {this.props.intl.formatMessage(Messages.MAX_DIMENSIONS)}:&nbsp;
                                                    {dimensionsByShape.hasDiameter || dimensionsByShape.isSquare ? this._maxHeight : this._maxWidth}&nbsp;
                                                    {!dimensionsByShape.isSquare && !dimensionsByShape.hasDiameter ? `x ${this._maxHeight} ` : ''}
                                                    {/*{dimensionsByShape.hasRadius ? `x ${this._maxRadius}` : ''}&nbsp;*/}
                                                    {units}
                                                    {/* { this.props.intl.formatMessage(Messages.MAX_DIMENSIONS)}: <CalculateMeasure cm={this._maxWidth}/> x <CalculateMeasure cm={this._maxHeight}/> <CalculateMeasure/> */}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/**************** TIPOS DE CANTO  **********************/}
                                    {edgeOptions && (
                                        <div className="group__input">
                                            <label htmlFor="edge-select" className="form-label">
                                                <Translation id="edge" defaultMessage="Edge" />
                                            </label>
                                            <div className="contain__select--edge">
                                                <Select
                                                    id="edge-select"
                                                    placeholder={this.props.intl.formatMessage(Messages.CHOOSE_EDGE)}
                                                    options={edgeOptions}
                                                    optionComponent={modelOption}
                                                    valueComponent={modelValue}
                                                    value={selectedEdge}
                                                    onChange={selectedOption => this.handleSelectEdge(selectedOption)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {/**************** BISEL DIMENSIONES *************************/}
                                    {bevelOptions && (
                                        <div className="group__input">
                                            <label htmlFor="bevel-select" className="form-label">
                                                <Translation id="bevel" defaultMessage="Bevel" />
                                            </label>
                                            <div className="contain__select--bevel">
                                                <Select
                                                    id="bevel-select"
                                                    placeholder={this.props.intl.formatMessage(Messages.CHOOSE_BEVEL)}
                                                    options={bevelOptions}
                                                    value={selectedBevel}
                                                    onChange={selectedOption => this.handleChangeBevel(selectedOption)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {/**************** Nº DE INSERTOS *************************/}
                                    <div className="row">
                                        <div className="col-12">
                                            <label htmlFor="inserts-input" className="form-label">
                                                <Translation id="inserts_qty" defaultMessage="Inserts quantity" />
                                            </label>
                                            <input
                                                type="number"
                                                id="inserts-input"
                                                ref={this.insertsInput}
                                                placeholder={this.props.intl.formatMessage({ id: 'inserts_qty' })}
                                                value={this.state.insertsQuantity}
                                                step={1}
                                                onChange={event => this.handleChangeInsertsQuantity(event.target.value)}
                                                disabled={!selectedColor}
                                                className={`form-control ${selectedColor ? ' ' : ' disabled'}`}
                                                onKeyPress={e => {
                                                    if (!/[0-9]/.test(e.key)) e.preventDefault()
                                                }}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="field-info">
                                                <Translation id="inserts-advise" defaultMessage="The position of the inserts must be correctly indicated on the attached drawing" />
                                            </div>
                                        </div>
                                    </div>

                                    {/**************** ADJUNTAR DOCUMENTO **********************/}
                                    <div className="colgroup__input">
                                        <label htmlFor="attach_blueprint_file" className="form-label">
                                            <Translation id="blueprint" defaultMessage="Blueprint" />*{/*{this.state.insertsQuantity > 0 ? '*' : ''}*/}
                                        </label>
                                        <div className="attach-file">
                                            <input
                                                id="attach_blueprint_file"
                                                type="file"
                                                accept={ACCEPTED_EXT[BLUEPRINT_FILE_TYPE].join()}
                                                onChange={e => this.onFileChange(e, BLUEPRINT_FILE_TYPE)}
                                                // accept="image/jpeg, image/png, application/pdf"
                                            />
                                            {!!this.state.selectedFiles[BLUEPRINT_FILE_TYPE] && (
                                                <i
                                                    className="fal fa-times null_attached_file_button"
                                                    onClick={e => {
                                                        const fileInput = document.getElementById('attach_blueprint_file')
                                                        fileInput.value = null
                                                        this.state.selectedFiles[BLUEPRINT_FILE_TYPE] = null
                                                        this.forceUpdate()
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className="field-info">
                                            <Translation id="exceed_file_limit" defaultMessage={`The file has a limit of {fileLimit} MB`} values={{ fileLimit: FILE_LIMIT }} />
                                        </div>
                                    </div>
                                    {/**************** COMENTARIOS **********************/}
                                    {/*        <div className="group__input">*/}
                                    {/*            <label htmlFor="input-comments" className="form-label">*/}
                                    {/*                <Translation id="comment" defaultMessage="Comments" />*/}
                                    {/*            </label>*/}
                                    {/*            <textarea*/}
                                    {/*                className="form-control"*/}
                                    {/*                id="input-comments"*/}
                                    {/*                rows="3"*/}
                                    {/*                maxLength="2499"*/}
                                    {/*                onChange={e => {*/}
                                    {/*                    e && this.setState({comments:e.target.value})*/}
                                    {/*                }}*/}
                                    {/*            />*/}
                                    {/*        </div> */}
                                    {/**************** CANTIDAD **********************/}
                                    <div className="row">
                                        <div className="col-6 group__input">
                                            <div>
                                                <label htmlFor="quantity-input" className="form-label">
                                                    <Translation id="quantity" defaultMessage="Quantity" />
                                                </label>
                                                <InputNumber
                                                    id="quantity-input"
                                                    className="form-control"
                                                    value={this.qtyToAdd}
                                                    change={qty => {
                                                        this.qtyToAdd = qty
                                                        this.forceUpdate()
                                                    }}
                                                    step={1}
                                                    oldStyle={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <span className="field-info"><strong><Translation id="mandatory_field_meaning" defaultMessage="* Means mandatory field" /></strong></span>
                                    </div>
                                    {/**************** ADD EXTENSION **********************/}
                                    {isMainPieceDone && extensionsLenght === 0 && (
                                        <div className="row">
                                            <div className="col-12 group__input mb-1">
                                                <span style={{ fontWeight: 500, cursor: 'pointer' }} onClick={this.addExtension}>
                                                    <i className="fa fa-plus" style={{ fontWeight: 500 }} />
                                                    &nbsp;&nbsp;
                                                    <Translation id="add-extension" defaultMessage="Add extension" />
                                                </span>
                                                <div className="field-info m-0 px-2">
                                                    <Translation
                                                        id="ext_explanation"
                                                        defaultMessage="Extra piece, with the same characteristics as the main piece, which allows you to change the shape and size of the table"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/******************************** EXTENSIONES **************************************/}
                            {Object.entries(this.state.extensions).map(([key, extension]) => {
                                const extensionKey = 'EXTENSION_' + key
                                const isOpen = this.state.activeForm === extensionKey
                                ++extensionsCounter
                                const isLastExtension = extensionsLenght === extensionsCounter
                                const extDimensionsByShape = this.getDimensionsByShape(extension.shape)
                                const isExtensionDone = this.isExtensionDone(key)

                                return (
                                    <div
                                        id={extensionKey}
                                        key={extensionKey}
                                        className={`mt-2 cut_to_size__filters ${isOpen ? 'cut_to_size__filters--active' : ''}`}
                                        style={{
                                            padding: isOpen ? '24px 24px 16px 24px' : '8px 24px 8px 24px'
                                        }}
                                    >
                                        <h5 onClick={() => this.setActiveForm(extensionKey)} style={{ cursor: 'pointer', marginInlineStart: '-8px' }}>
                                            <div className={`desp_cirle_mark${isExtensionDone ? '_success' : ''}`}>
                                                {isExtensionDone ? <i className="fa fa-check" /> : 1 + extensionsCounter}
                                            </div>
                                            <Translation id="extension" defaultMessage="Extension" />
                                            &nbsp;&nbsp;
                                            <i className={isOpen ? 'fa fa-caret-down' : 'fa fa-caret-right'} aria-hidden="true" />
                                        </h5>
                                        <div className={isOpen ? 'd-block' : 'd-none'}>
                                            {/**************** MODELO PREDEFINIDO **********************/}
                                            <div className="group__input">
                                                <label htmlFor={`${key}_model-select`} className="form-label">
                                                    <Translation id="shape" defaultMessage="Shape" />*
                                                </label>
                                                <div className="contain__select--model">
                                                    <Select
                                                        id={`${key}_model-select`}
                                                        placeholder={this.props.intl.formatMessage(Messages.CHOOSE_SHAPE)}
                                                        options={modelOptionsFilteredByEdge}
                                                        optionComponent={modelOption}
                                                        valueComponent={modelValue}
                                                        value={extension.shape}
                                                        onChange={selectedOption => this.handleSelectModel(selectedOption, key)}
                                                    />
                                                </div>
                                            </div>

                                            {extDimensionsByShape && (
                                                <div className="row">
                                                    <div className={`col-${12 / extDimensionsByShape.dimensionInputsCount}`}>
                                                        <label htmlFor={`${key}_width-input`} className="measures-label">
                                                            {this.getMeasureLabel(extDimensionsByShape)} ({units})*
                                                        </label>
                                                        <input
                                                            type="number"
                                                            id={`${key}_width-input`}
                                                            placeholder={this.getMeasureLabel(extDimensionsByShape)}
                                                            required
                                                            ref={extension.refs.widthInput}
                                                            value={extension.width}
                                                            onChange={event => this.handleChangeWidth(event.target.value, key, extDimensionsByShape.isSquare || extDimensionsByShape.hasDiameter)}
                                                            onBlur={event => this.handleChangeMinWidth(event.target.value, key)}
                                                            min={this._minWidth}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                    {!extDimensionsByShape.isSquare && !extDimensionsByShape.hasDiameter && (
                                                        <div className={`col-${12 / extDimensionsByShape.dimensionInputsCount}`}>
                                                            <label htmlFor={`${key}_height-input`} className="measures-label">
                                                                <Translation id="width" defaultMessage="Width" /> ({units})*
                                                            </label>
                                                            <input
                                                                type="number"
                                                                id={`${key}_height-input`}
                                                                placeholder={this.props.intl.formatMessage({ id: 'width' })}
                                                                required
                                                                ref={extension.refs.heightInput}
                                                                value={extension.height}
                                                                onChange={event => this.handleChangeHeight(event.target.value, key)}
                                                                onBlur={event => this.handleChangeMinHeight(event.target.value, key)}
                                                                min={this._minHeight}
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    )}
                                                    {extDimensionsByShape.hasRadius && (
                                                        <div className={`col-${12 / extDimensionsByShape.dimensionInputsCount}`}>
                                                            <label htmlFor={`${key}_radius-input`} className="measures-label">
                                                                <Translation id="radius" defaultMessage="Radius" /> ({units})*
                                                            </label>
                                                            <input
                                                                type="number"
                                                                id={`${key}_radius-input`}
                                                                placeholder={this.props.intl.formatMessage({ id: 'radius' })}
                                                                required
                                                                ref={extension.refs.radiusInput}
                                                                value={extension.radius}
                                                                onChange={event => this.handleChangeRadius(event.target.value, key)}
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    )}
                                                    <div className="col-12">
                                                        <div className="field-info mb-2">
                                                            {this.props.intl.formatMessage(Messages.MAX_DIMENSIONS)}:&nbsp;
                                                            {extDimensionsByShape.hasDiameter || extDimensionsByShape.isSquare ? this._maxHeight : this._maxWidth}&nbsp;
                                                            {!extDimensionsByShape.isSquare && !extDimensionsByShape.hasDiameter ? `x ${this._maxHeight} ` : ''}
                                                            {/*{extDimensionsByShape.hasRadius ? `x ${this._maxRadius}` : ''}&nbsp;*/}
                                                            {units}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {/**************** MISMO PATRÓN *******************/}
                                            <div className="group__input">
                                                <label htmlFor={`${key}_pattern_radio`} className="form-label">
                                                    <Translation id="use_same_pattern" defaultMessage="Do you want to use same vein or pattern as main piece?" />
                                                </label>
                                                <div id={`${key}_pattern_radio`} className="d-flex">
                                                    <div>
                                                        <input
                                                            id={`${key}_pattern_radio_yes`}
                                                            style={{
                                                                transform: 'scale(1.5)',
                                                                margin: '4px'
                                                            }}
                                                            // checked={this.state.extensions[key].samePattern}
                                                            ref={extension.refs.samePatternYes}
                                                            value="true"
                                                            name="same_pattern_radio"
                                                            type="radio"
                                                            onClick={e => (extension.samePattern = true)}
                                                        />
                                                        <label htmlFor={`${key}_pattern_radio_yes`} className="form-label">
                                                            &nbsp;
                                                            <b>
                                                                <Translation id="yes" defaultMessage="Yes" />
                                                            </b>
                                                        </label>
                                                    </div>
                                                    <div style={{ width: '16px' }} />
                                                    <div>
                                                        <input
                                                            id={`${key}_pattern_radio_no`}
                                                            style={{
                                                                transform: 'scale(1.5)',
                                                                margin: '4px'
                                                            }}
                                                            // checked={!this.state.extensions[key].samePattern}
                                                            ref={extension.refs.samePatternNo}
                                                            name="same_pattern_radio"
                                                            value="false"
                                                            type="radio"
                                                            onClick={e => (extension.samePattern = false)}
                                                        />
                                                        <label htmlFor={`${key}_pattern_radio_no`} className="form-label">
                                                            &nbsp;
                                                            <b>
                                                                <Translation id="no" defaultMessage="No" />
                                                            </b>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            {/**************** CANTIDAD **********************/}
                                            <div className="row">
                                                <div className="col-6 group__input">
                                                    <div>
                                                        <label htmlFor={`${key}_quantity-input`} className="form-label">
                                                            <Translation id="quantity" defaultMessage="Quantity" />
                                                        </label>
                                                        <InputNumber
                                                            id={`${key}_quantity-input`}
                                                            className="form-control"
                                                            value={extension.quantity}
                                                            change={qty => {
                                                                extension.quantity = qty
                                                                this.forceUpdate()
                                                            }}
                                                            step={1}
                                                            oldStyle={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/****************  ADD/DEL EXTENSIONS  **********************/}
                                            <div className="row">
                                                <div className="col-12 group__input" style={{ margin: '0.4rem 0' }}>
                                                    <div style={{ fontyWeight: 500, cursor: 'pointer' }} onClick={() => this.removeExtension(key)}>
                                                        <i className="fa fa-minus" style={{ fontWeight: 500 }} />
                                                        &nbsp;&nbsp;
                                                        <Translation id="del-this-extension" defaultMessage="Delete this extension" />
                                                    </div>
                                                </div>
                                                {isLastExtension && (
                                                    <div className="col-12 group__input mb-1">
                                                        <div style={{ fontWeight: 500, cursor: 'pointer' }} onClick={this.addExtension}>
                                                            <i className="fa fa-plus" style={{ fontWeight: 500 }} />
                                                            &nbsp;&nbsp;
                                                            <Translation id="add-additional-extension" defaultMessage="Add an additional extension" />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {/********************************* PACKAGING ***************************************/}
                            <div
                                id="PACKAGING_AND_LABELING"
                                className={`mt-2 ${this.state.activeForm == 'PACKAGING_AND_LABELING' ? 'cut_to_size__filters--active' : 'cut_to_size__filters'}`}
                                style={{
                                    padding: this.state.activeForm == 'PACKAGING_AND_LABELING' ? '24px 24px 16px 24px' : '8px 24px 8px 24px'
                                }}
                            >
                                <h5 onClick={() => this.setActiveForm('PACKAGING_AND_LABELING')} style={{ cursor: 'pointer', marginInlineStart: '-8px' }}>
                                    <div className={`desp_cirle_mark${isPackagingDone ? '_success' : ''}`}>
                                        {isPackagingDone ? <i className="fa fa-check" /> : 2 + extensionsCounter}
                                    </div>
                                    <Translation id="packaging_labeling" defaultMessage="Packaging and Labeling" />
                                    &nbsp;&nbsp;
                                    <i className={this.state.activeForm == 'PACKAGING_AND_LABELING' ? 'fa fa-caret-down' : 'fa fa-caret-right'} /*aria-hidden="true"*/ />
                                </h5>
                                <div className={`${this.state.activeForm == 'PACKAGING_AND_LABELING' ? 'd-block' : 'd-none'}`}>
                                    <div className="group__input mb-0">
                                        <label htmlFor="brand-select" className="form-label">
                                            <Translation id="packaging" defaultMessage="Packaging" />*
                                        </label>
                                        <div className="contain__select--packaging">
                                            <Select
                                                id="packaging-select"
                                                placeholder={this.props.intl.formatMessage(Messages.CHOOSE_PACKAGING)}
                                                options={packagingOptions}
                                                value={selectedPackaging}
                                                onChange={selectedOption => this.handleSelectPackaging(selectedOption)}
                                                // disabled={!currentBrandOptions || currentBrandOptions.length <= 1}
                                                // className={`${!currentBrandOptions || currentBrandOptions.length <= 1 ? ' disabled' : ' '}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="field-info">
                                        <Translation id="packaging_info_link" defaultMessage="Information about types of packaging" />
                                        <span style={{ color: '#007bff' }}>
                                            &nbsp;
                                            <a
                                                href="#"
                                                onClick={e => {
                                                    e.preventDefault()
                                                    this.props.downloadPackagingTypesPdf()
                                                }}
                                            >
                                                <Translation id="here" defaultMessage="here" />
                                            </a>
                                        </span>
                                    </div>

                                    <div className="group__input d-flex align-items-baseline mt-1">
                                        <input
                                            id="set-default-packaging-cb"
                                            style={{
                                                transform: 'scale(1.5)',
                                                margin: '4px'
                                            }}
                                            value={setPackingAsDefault}
                                            type="checkbox"
                                            onClick={e => this.setState({ setPackingAsDefault: e.target.checked })}
                                        />
                                        &nbsp;&nbsp;
                                        <div style={{ transform: 'translate(0, -2px)' }}>
                                            <label
                                                style={{
                                                    fontSize: '0.8rem',
                                                    fontyWeight: 500,
                                                    margin: 0
                                                }}
                                                htmlFor="set-default-packaging-cb"
                                                className="form-label"
                                            >
                                                <Translation id="set-default-packaging" defaultMessage="Set this packaging type as default" />
                                            </label>
                                            <div style={{ fontSize: '0.65rem', fontyWeight: 300, margin: 0 }}>
                                                <Translation id="set-default-packaging-advise" defaultMessage="You can change this setting at any moment" />
                                            </div>
                                        </div>
                                    </div>

                                    {/**************** ADJUNTAR ETIQUETAS **********************/}
                                    <div className="colgroup__input">
                                        <label htmlFor="attach_labels_file" className="form-label">
                                            <Translation id="packaging_labels" defaultMessage="Packaging labels" />
                                        </label>
                                        <div className="attach-file">
                                            <input
                                                id="attach_labels_file"
                                                type="file"
                                                accept={ACCEPTED_EXT[PACKAGING_LABELS_FILE_TYPE].join()}
                                                onChange={e => this.onFileChange(e, PACKAGING_LABELS_FILE_TYPE)}
                                                // accept="image/jpeg, image/png, application/pdf"
                                            />
                                            {!!this.state.selectedFiles[PACKAGING_LABELS_FILE_TYPE] && (
                                                <i
                                                    className="fal fa-times null_attached_file_button"
                                                    onClick={e => {
                                                        const fileInput = document.getElementById('attach_labels_file')
                                                        fileInput.value = null
                                                        delete this.state.selectedFiles[PACKAGING_LABELS_FILE_TYPE]
                                                        // this.state.selectedFiles[PACKAGING_LABELS_FILE_TYPE] = null
                                                        this.forceUpdate()
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className="field-info">
                                            <Translation
                                                style={{ all: 'revert' }}
                                                id="exceed_file_limit"
                                                defaultMessage={`The file has a limit of {fileLimit} MB`}
                                                values={{ fileLimit: FILE_LIMIT }}
                                            />
                                        </div>
                                    </div>

                                    {/**************** ADJUNTAR DOCUMENTO **********************/}
                                    <div className="colgroup__input">
                                        <label htmlFor="attach_packaging_document_file" className="form-label">
                                            <Translation id="attached_document" defaultMessage="Attached document" />
                                        </label>
                                        <div className="attach-file">
                                            <input
                                                id="attach_packaging_document_file"
                                                type="file"
                                                accept={ACCEPTED_EXT[PACKAGING_DOCUMENT_FILE_TYPE].join()}
                                                onChange={e => this.onFileChange(e, PACKAGING_DOCUMENT_FILE_TYPE)}
                                                // accept="image/jpeg, image/png, application/pdf"
                                            />
                                            {!!this.state.selectedFiles[PACKAGING_DOCUMENT_FILE_TYPE] && (
                                                <i
                                                    className="fal fa-times null_attached_file_button"
                                                    onClick={e => {
                                                        const fileInput = document.getElementById('attach_packaging_document_file')
                                                        fileInput.value = null
                                                        delete this.state.selectedFiles[PACKAGING_DOCUMENT_FILE_TYPE]
                                                        // this.state.selectedFiles[PACKAGING_DOCUMENT_FILE_TYPE] = null
                                                        this.forceUpdate()
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className="field-info">
                                            <Translation
                                                id="attached_document_info"
                                                defaultMessage={'Add here the information you want to include within the packaging. May involve expenses and associated costs'}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="field-info"><strong><Translation id="mandatory_field_meaning" defaultMessage="* Means mandatory field" /></strong></span>
                                    </div>
                                </div>
                            </div>
                            {/********************************** SELECTED ITEMS **************************************/}
                            {selectedModel && (
                                <div className="mt-3 pb-3 cut_to_size__filters">
                                    <div style={{ fontSize: '1rem', fontWeight: 500, marginBottom: '8px' }}>
                                        <Translation id="selected-items" defaultMessage="Selected items" />
                                    </div>
                                    <div className="d-flex mb-1 align-items-start">
                                        <img
                                            src={process.env.REACT_APP_API_HOST + materials[selectedElaboration].models[selectedModel].shape}
                                            width="32px"
                                            style={{ margin: '0 16px' }}
                                        />
                                        <div>
                                            <div style={{ fontSize: '0.9rem', fontWeight: 700 }}>
                                                <Translation id="main_piece" defaultMessage="Main piece" />
                                                &nbsp;({this.qtyToAdd} ud)
                                            </div>
                                            <div style={{ fontSize: '0.8rem' }}>
                                                <strong>
                                                    <div style={{ textTransform: 'capitalize' }}>
                                                        {selectedModel && this.props.intl.formatMessage(Messages[selectedModel])}
                                                        {selectedBrand && ` / ${selectedBrand.toLowerCase()}`}
                                                        {selectedColor && ` / ${catalog[selectedColor].name.toLowerCase()}`}
                                                    </div>
                                                </strong>
                                                {!!this.state.selectedThickness && (
                                                    <div>
                                                        <strong>
                                                            <Translation id="thickness" defaultMessage="Thickness" />:
                                                        </strong>
                                                        &nbsp;{this.state.selectedThickness} cm
                                                    </div>
                                                )}
                                                {!!this.state.selectedFinished && (
                                                    <div>
                                                        <strong>
                                                            <Translation id="finish" defaultMessage="Finish" />:
                                                        </strong>
                                                        &nbsp;{this.props.intl.formatMessage({ id: this.state.selectedFinished })}
                                                    </div>
                                                )}
                                                {!!(this.state.width && this.state.height) && !dimensionsByShape.hasDiameter && (
                                                    <div>
                                                        <strong>
                                                            <Translation id="dimensions" defaultMessage="Dimensions" />:
                                                        </strong>
                                                        &nbsp;{this.state.width}x{this.state.height} {units}
                                                    </div>
                                                )}
                                                {!!this.state.width && dimensionsByShape.hasDiameter && (
                                                    <div>
                                                        <strong>
                                                            <Translation id="diameter" defaultMessage="Diameter" />:
                                                        </strong>
                                                        &nbsp;{/*&#8960;*/}
                                                        {this.state.width} {units}
                                                    </div>
                                                )}
                                                {!!this.state.radius && (
                                                    <div>
                                                        <strong>
                                                            <Translation id="radius" defaultMessage="Radius" />:
                                                        </strong>
                                                        &nbsp;{this.state.radius} {units}
                                                    </div>
                                                )}
                                                {!!this.state.selectedEdge && (
                                                    <div>
                                                        <strong>
                                                            <Translation id="edge" defaultMessage="Edge" />:
                                                        </strong>
                                                        &nbsp;{this.props.intl.formatMessage({ id: this.props.intl.formatMessage(Messages[this.state.selectedEdge]) })}
                                                    </div>
                                                )}
                                                {!!this.state.selectedBevel && (
                                                    <div>
                                                        <strong>
                                                            <Translation id="bevel" defaultMessage="Bevel" />:
                                                        </strong>
                                                        &nbsp;{materials[selectedElaboration].bevels[this.state.selectedBevel].label}
                                                    </div>
                                                )}
                                                {!!this.state.insertsQuantity && (
                                                    <div>
                                                        <strong>
                                                            <Translation id="inserts_qty" defaultMessage="Inserts quantity" />:
                                                        </strong>
                                                        &nbsp;{this.state.insertsQuantity}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/************ EXTENSIONS *******************/}
                                    {Object.values(this.state.extensions).map(ext => {
                                        const extDimensionsByShape = this.getDimensionsByShape(ext.shape)

                                        return (
                                            ext.shape && (
                                                <div className="d-flex mb-1 d-flex mb-1 align-items-start">
                                                    <img
                                                        src={process.env.REACT_APP_API_HOST + materials[selectedElaboration].models[ext.shape].shape}
                                                        width="32px"
                                                        style={{ margin: '0 16px' }}
                                                    />
                                                    <div>
                                                        <div style={{ fontSize: '0.9rem', fontWeight: 700 }}>
                                                            <Translation id="extension" defaultMessage="Extension" />
                                                            &nbsp;({ext.quantity} ud)
                                                        </div>
                                                        <div style={{ fontSize: '0.8rem' }}>
                                                            <strong>
                                                                <div style={{ textTransform: 'capitalize' }}>{ext.shape && this.props.intl.formatMessage(Messages[ext.shape])}</div>
                                                            </strong>
                                                            {!!(ext.width && ext.height) && !extDimensionsByShape.hasDiameter && (
                                                                <div>
                                                                    <strong>
                                                                        <Translation id="dimensions" defaultMessage="Dimensions" />:
                                                                    </strong>
                                                                    &nbsp;{ext.width}x{ext.height} {units}
                                                                </div>
                                                            )}
                                                            {!!ext.width && extDimensionsByShape.hasDiameter && (
                                                                <div>
                                                                    <strong>
                                                                        <Translation id="diameter" defaultMessage="Diameter" />:
                                                                    </strong>
                                                                    &nbsp;{ext.width} {units}
                                                                </div>
                                                            )}
                                                            {!!ext.radius && (
                                                                <div>
                                                                    <strong>
                                                                        <Translation id="radius" defaultMessage="Radius" />:
                                                                    </strong>
                                                                    &nbsp;{ext.radius} {units}
                                                                </div>
                                                            )}
                                                            {!!ext.samePattern && (
                                                                <div>
                                                                    <Translation id="same_pattern" defaultMessage="Same vein or pattern as main piece" />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    })}
                                    {/************ PACKAGING *******************/}
                                    {selectedPackaging && (
                                        <div className="d-flex mb-1">
                                            {/*<img src={process.env.REACT_APP_API_HOST + materials[selectedElaboration].models[ext.shape].shape}*/}
                                            {/*     width="32px"*/}
                                            {/*     style={{margin:"0 16px"}}*/}
                                            {/*/>*/}
                                            <div style={{ width: '64px' }} />
                                            <div>
                                                <div style={{ fontSize: '0.9rem', fontWeight: 700 }}>
                                                    <Translation id="packaging" defaultMessage="Packaging" />
                                                </div>
                                                <div style={{ fontSize: '0.8rem' }}>{this.props.intl.formatMessage(Messages[selectedPackaging])}</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="mt-3 cut_to_size__filters" style={{ borderBottom: 'none' }}>
                                {/*********************** PRECIO **************************/}
                                {/*<div className="group__input">*/}
                                {/*    <div>*/}
                                {/*        <label htmlFor="price-text" className="form-label">*/}
                                {/*            <Translation id="price" defaultMessage="Price" />*/}
                                {/*        </label>*/}
                                {/*        <div id="price-text" className="form-control text-left" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>*/}
                                {/*            {secondaryParams && secondaryParams.unitaryPrice ? (*/}
                                {/*                `${secondaryParams.unitaryPrice * this.qtyToAdd}€`*/}
                                {/*            ) : (*/}
                                {/*                <Translation id="price_not_available" defaultMessage="Price not available" />*/}
                                {/*            )}*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/**************** AÑADIR AL CARRITO **********************/}
                                <div className="mt-1">
                                    <Button
                                        customClass="bt-dark gt"
                                        icon="shopping-cart"
                                        dataCY="product_detail__add"
                                        // disabled={this.disableSendButton()}
                                        disabled={!isMainPieceDone || !this.areExtensionsDone() || !isPackagingDone}
                                        onClick={this.submitCartLine}
                                    >
                                        <Translation id="add" defaultMessage="Add" />
                                    </Button>
                                </div>
                            </div>{' '}
                            {/*** cut_to_size__filters */}
                            {this.state.showDetail && (
                                <Modal size="xl" title={selectedModel} onClose={() => this.setState({ showDetail: false })}>
                                    <img src={process.env.REACT_APP_API_HOST + modelDetail[this.state.currentModelImageOption]} width="100%" />
                                </Modal>
                            )}
                            {this.state.showExceedFileSizeAlert && (
                                <FloatingAlert
                                    warningMessage={
                                        <Translation id="exceed_file_limit" defaultMessage={`The file has a limit of {fileLimit} MB`} values={{ fileLimit: FILE_LIMIT }} />
                                    }
                                    onClose={() => {
                                        this.setState({ showExceedFileSizeAlert: false })
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CutToSizeFurniture)
)
