import React from 'react'
import Translation from '../../global/Translation'

import 'react-datepicker/dist/react-datepicker-cssmodules.css'

import Alerts from '../../alerts/Alerts'
import Button from '../../global/Button'
import confirm from '../../global/confirm'
import moment from 'moment'
import OrderFinishedContainer from '../../order-finished/OrderFinishedContainer'
import Select from 'react-select'
import Modal from '../../global/Modal'
import CheckboxLinkSaleConditions from '../global/CheckboxLinkSaleConditions'
import CheckboxLinkHealthConditions from '../global/CheckboxLinkHealthConditions'
import ProductDisplayName from '../../global/ProductDisplayName'
import ProductImage from '../../catalog/ProductImage'
import DisplayOutletIndicator from '../../catalog/rectangleResult/displays/DisplayOutletIndicator'
import DisplayBrand from '../../catalog/rectangleResult/displays/DisplayBrand'
import CheckboxAustraliaLegalProcessConditions from '../global/CheckboxAustraliaLegalProcessConditions'

class CheckoutFormCustomer extends React.Component {

    componentDidMount() {
        this.props.resetCheckout().then(() => {
            this.props.setEmployeeId(this.props.employeeId)
            this.props.fetchShippingAddresses().then(() => {
                if (this.props.shippingAddresses && this.props.shippingAddresses.length > 0) {
                    let firstShippingAddres = this.props.shippingAddresses.filter(shippingAddress => {
                        if (this.props.shippingMethod === 'ENDI_CONTAINER') return shippingAddress.ecosentinoKey === this.props.endiSelectedShippingAddressId
                        if (this.props.isMallCartForContainer) return shippingAddress.allowedShippingTypes.includes('SINGLE_SLAB')
                        return shippingAddress.allowedShippingTypes.includes(this.props.shippingMethod)
                    })[0]
                    this.props.setCountryOfSend(firstShippingAddres.country)
                    this.props.setSelectedShippingCenterId(firstShippingAddres.centerId)
                    this.props.setSelectedShippingAddressId(firstShippingAddres.shippingAddressId)
                    this.props.setSelectedShippingAddressSalesOrg(firstShippingAddres.salesOrg)
                    this.props.setSelectedShippingAddressEcosentinoKey(firstShippingAddres.ecosentinoKey)

                    this.props.setSelectedShippingAddress(firstShippingAddres)
                    this.props.setHasSelectedCenter(false)
                }
            })
            this.props.isOffer && this.props.setOfferMode()
        })

        this.props.onLoadCheckout()
    }

    canSendCheckout = () => {

        if (!this.props.canSendMinMaxCheckout()) return false
        if (!this.props.getSelectedShippingAddress) return false
        if (this.props.isSending) return false
        if (this.props.totalQty <= 0) return false
        if (!this.props.canSendNeedDestination()) return false
        if (!this.props.canSendCheckoutSaleConditions) return false
        if (!this.props.canSendCheckoutHealthConditions) return false
        if (this.props.transformingSkuToLots) return false
        if (this.props.isEmserUser && !this.props.po) return false
        if (!this.props.canSendCheckoutAustraliaLegalProcessConditions) return false

        return true
    }

    getOptionsCountryOfSend = filteredShippingAddressesForUsers => {
        if (filteredShippingAddressesForUsers)
            return filteredShippingAddressesForUsers.map(shippingAddress => ({
                value: shippingAddress.ecosentinoKey,
                label:
                    shippingAddress.centerName +
                    ' ' +
                    shippingAddress.customerName +
                    ' ' +
                    shippingAddress.address +
                    ' ' +
                    shippingAddress.zipCode +
                    ' ' +
                    shippingAddress.city +
                    ' ' +
                    shippingAddress.country +
                    ' ' +
                    shippingAddress.salesOrg
            }))
        else return []
    }

    prepareCart() {
        return this.props.isOffer ? this.props.transformSkuToLots() : Promise.resolve()
    }

    submitCheckout = () => {
        if(this.canSendCheckout()){
            this.prepareCart().then(() => {
                if (this.props.haveSomeProductsBelowThreshold) {
                    confirm(
                        <div className="confirm-checkout">
                            <div className="confirm-checkout_title">{this.props.intl.formatMessage({ id: 'please_confirm_the_order' })}:</div>
                            <div className="confirm-checkout_text">
                                {this.props.intl.formatMessage({
                                    id:
                                        'there_are_only_a_few_remaining_items_in_stock_in_at_least_one_of_the_chosen_materials_in_the_order_which_may_constrain_the_availability_upon_orden_confirmation'
                                })}
                            </div>
                        </div>
                    ).then(() => this.props.submitCheckout())
                } else {
                    this.props.submitCheckout()
                }
            })
        }
    }

    redirectToCart() {
        this.props.closeNoStockModal()
        this.props.onStep(0)
    }

    render() {
        const { shippingAddresses, current, shippingMethod, goalMax } = this.props

        //allowedShippingTypes for users
        let filteredShippingAddressesForUsers = shippingAddresses
            ? shippingAddresses.filter(shippingAddress => {
                  if (this.props.isMallCartForContainer) return shippingAddress.allowedShippingTypes.includes('SINGLE_SLAB')
                  return shippingAddress.allowedShippingTypes.includes(shippingMethod)
              })
            : []

        const difCurrent = current <= goalMax ? 0 : current - goalMax

        if (this.props.isLoadingShippingAddress) return <Translation id="loading_shipping_addresses" defaultMessage="Loading Shipping Addresses" />
        let destinationForCustomer = [
            { value: 'CLIENTE', label: this.props.intl.formatMessage({ id: 'customer' }) },
            {
                value: 'HUB_CLIENTE',
                label: this.props.intl.formatMessage({ id: 'cosentino_hub' })
            },
            {
                value: 'CENTER',
                label: this.props.intl.formatMessage({ id: 'center' })
            }
        ]
        if (this.props.isMallCartForContainer && this.props.current <= this.props.goalMin) {
            destinationForCustomer = [
                {
                    value: 'HUB_CLIENTE',
                    label: this.props.intl.formatMessage({ id: 'cosentino_hub' })
                }
            ]
        }

        let alertType = this.props.alert
        let alertStatus = this.props.status
        if (this.props.alert === 'NOT_ENOUGH_LOTS' && this.props.isOffer) {
            alertType = 'NOT_ENOUGH_LOTS_OFFER'
            alertStatus = 400
        }

        return (
            <div className='checkout-address-box'>
                <h1 className='shipping_information'>
                    <Translation id='shipping_information' defaultMessage='Shipping information' />
                </h1>
                <hr/>
                <form>
                    <Alerts
                        alert={alertType}
                        values={{ monthItems: this.props.monthItems, waitTillDate: this.props.waitTillDate ? moment(this.props.waitTillDate).format('L') : null }}
                        status={alertStatus}
                    />

                    {this.props.centerSelected == this.props.latinaVitoriaCenterId && !this.props.canSendNaturalStoneContainer() ? (
                        <Alerts alert={this.props.intl.formatMessage({ id: 'Minimum/maximum weight exceeded, please adjust your order' })} />
                    ) : (
                        ''
                    )}
                    {(this.props.centerSelected == this.props.naturalStoneSpainCenterId || this.props.centerSelected == this.props.latinaVitoriaCenterId) &&
                    this.canSendCheckout() ? (
                        <Translation id="natural_stone_may_be_modified_due_to_shipment" defaultMessage="Please bear in mind the order can be modified due to the shipment" />
                    ) : (
                        ''
                    )}

                    <div className='mb-3'>
                        {this.props.hasDestination && (
                            <div className={`form-group select`}>
                                <Select
                                    className={'form-control '}
                                    placeholder={this.props.intl.formatMessage({ id: 'select_shipping_address' }) + '...'}
                                    value={this.props.destination}
                                    onChange={e => {
                                        e && this.props.setDestination(e.value)
                                    }}
                                    options={destinationForCustomer}
                                />
                            </div>
                        )}
                        {(shippingAddresses || this.props.destination === 'CENTER') && (
                            <div className="form-group select">
                                <Select
                                    id="checkout__select_shipping_address-default"
                                    className="form-control"
                                    disabled={this.props.endiSelectedShippingAddressId}
                                    placeholder={`${this.props.intl.formatMessage({ id: 'select_shipping_address' })}...`}
                                    value={!this.props.endiSelectedShippingAddressId ? (this.props.selectedShippingAddress ? this.props.selectedShippingAddress.ecosentinoKey : null) : this.props.endiSelectedShippingAddressId}
                                    onChange={e => {
                                        e && this.props.changeShippingAddress(e.value)
                                    }}
                                    options={!this.props.endiSelectedShippingAddressId ? this.getOptionsCountryOfSend(filteredShippingAddressesForUsers) : this.getOptionsCountryOfSend(filteredShippingAddressesForUsers).filter(e => e.value === this.props.endiSelectedShippingAddressId) }
                                    clearable={false}
                                />
                            </div>
                        )}
                    </div>
                    <hr/>
                    <div className="form-group">
                        <label htmlFor="input-po">
                            <Translation id="insert_po" defaultMessage="Insert your PO#" />
                            {this.props.isEmserUser && <span style={{ color: 'red' }}> *</span>}
                        </label>
                        <input
                            data-cy="checkout__input_po"
                            type="text"
                            className="form-control"
                            id="input-po"
                            onChange={e => {
                                e && this.props.setPo(e.target.value)
                            }}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="input-comments">
                            <Translation id="comment" defaultMessage="Comments" />
                        </label>
                        <textarea
                            data-cy="checkout__textarea_comment"
                            className="form-control"
                            id="input-comments"
                            rows="3"
                            maxLength="2499"
                            onChange={e => {
                                e && this.props.setComments(e.target.value)
                            }}
                        />
                        {this.props.isEuropeOrIberia ? (
                          <Translation
                            id="same_batch"
                            defaultMessage="Please, indicate if you want the slabs to be the same batch. Check availability with your center. Thank you"
                          />
                        ) : null}
                    </div>

                    <div className="form-group">
                        {!this.props.canSendCheckoutSaleConditions && (
                            <span className='alert_empty'>
                                <Translation id="mandatory_field" defaultMessage="This field is required" />
                            </span>
                        )}
                        <CheckboxLinkSaleConditions />
                        {!this.props.canSendCheckoutHealthConditions && (
                            <span className='alert_empty'>
                                <Translation id="mandatory_field" defaultMessage="This field is required" />
                            </span>
                        )}
                        <CheckboxLinkHealthConditions />

                        {!this.props.canSendCheckoutAustraliaLegalProcessConditions && (
                            <span className='alert_empty'>
                                <Translation id="mandatory_field" defaultMessage="This field is required" />
                            </span>
                        )}
                        <CheckboxAustraliaLegalProcessConditions />
                    </div>
                </form>
                <div className="row align-items-baseline">
                    <div className="col-12" style={{ paddingLeft: '35px', paddingBottom: '10px' }}>
                        {this.props.transformingSkuToLots && <Alerts alert={'TRANSFORMING_SKU_TO_LOTS'} status="warning" />}
                    </div>
                </div>

                {(this.props.reservationCode || this.props.reservationCutToSizeCode) && <OrderFinishedContainer />}
                {this.props.showModalNoStock && (
                    <Modal
                        customClass='modal-no-stock'
                        title={<Translation id="no_stock_checkout_title" defaultMessage="Materials without stock in your order" />}
                        onClose={() => this.props.closeNoStockModal()}
                        size="large"
                        footer={
                            <div className='d-flex'>
                                <Button className='mr-3' onClick={() => this.props.closeNoStockModal()} size="large">
                                    <Translation id="remove_all_items" defaultMessage='Remove all items' />
                                </Button>
                                <Button onClick={() => this.redirectToCart()} inverted size="large">
                                    <Translation id="return_the_cart" defaultMessage='Return to the cart' />
                                </Button>
                            </div>
                        }
                    >
                        <div className="row">
                            <div className="col-12">
                                <div className="confirm-checkout_text">
                                    <Translation
                                        id="no_stock_checkout_desc"
                                        defaultMessage="There are some materials in your order without enough stock to continue. Please, review the material list below and make the adjustment in the materials units to continue with your order."
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            {this.props.haveSomeProductsWithoutSapStock.length > 0 && (
                                <div className='d-flex justify-content-between align-items-center line-item modal-no-stock-title'>
                                    <div className='d-flex-2'></div>
                                    <div className='d-flex-1 d-flex justify-content-center'>
                                        <Translation id="requestedQty" />
                                    </div>
                                    <div className='d-flex-1 d-flex justify-content-center'>
                                        <Translation id="available" />
                                    </div>
                                </div>
                            )}
                            {this.props.haveSomeProductsWithoutSapStock.map(line => (
                                <div className='d-flex justify-content-between align-items-center line-item'>
                                    <div className='d-flex d-flex-2'>
                                        <div className='mr-4 position-relative' style={{maxHeight: '115px', minWidth: '160px'}}>
                                            <div className="indicators">
                                                {line.extra && line.extra.outlet && (
                                                    <DisplayOutletIndicator
                                                        outletQuality={line.extra.outlet}
                                                        outletDeviated={this.props.isAdmin || this.props.impersonatedBy ? this.product.outletDeviated : null}
                                                    />
                                                )}
                                            </div>
                                            <ProductImage product={line.product} />
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <DisplayBrand brand={line.product.brand} span></DisplayBrand>
                                            <ProductDisplayName product={line.product} />
                                            <div className='d-flex'>
                                                {!line.cutToSize && line.product.finish && (
                                                    <div className="mini_cart_line_info__line">
                                                        <Translation id={line.product.finish} />
                                                    </div>
                                                )}
                                                {line.cutToSize && line.cutToSize.finish && (
                                                    <div className="mini_cart_line_info__line">
                                                        <div className="subtitle">
                                                            <Translation id={line.cutToSize.finish} />
                                                        </div>
                                                    </div>
                                                )}
                                                {line.product.thickness && !line.cutToSize && (
                                                    <div className="mini_cart_line_info__line mini_cart_line_info__line--dimensions">
                                                    <span>
                                                        <i className="fas fa-sort-amount-down" />
                                                        {line.product.thickness} cm.{' '}
                                                    </span>
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <span className='link' onClick={() => this.props.onDelete(line.lineId)}><i style={{marginRight: '8px'}} className="mt-1 fal fa-trash-alt"></i> Eliminar</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex-1 d-flex justify-content-center'>
                                        {line.qty}
                                    </div>
                                    <div className='d-flex-1 d-flex justify-content-center'>
                                        {line.stockOutletAvailable}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}

export default CheckoutFormCustomer
