import { connect } from 'react-redux'
import ResponsiveMenu from './ResponsiveMenu'
import { getIsLogged, getIsAdmin, getUserName, getName, getUserImage, getImpersonatedBy, getUserType, getEmail, getIsMktoolsAdmin, canViewPreorder, getUserAuthorization, getIsMallAdmin, getIsCampaignsPilotUser } from '../../store/login/reducers'
import { logout } from '../../store/login/actions'
import * as ui from '../../store/ui/actions'
import { getIsAdding } from '../../store/cart/reducers'
import { showMenuSelector, showPersonalDataSelector } from '../../store/ui/reducers'
import { canOffer, canViewCatalog, getIsIkeaCenter, getIsLowesCenter, getIsMkToolsCenter } from '../../store/centers/reducers'
import { getEmployeeId } from '../../store/big-account/reducers'
import {withMsal} from "@azure/msal-react";

const mapStateToProps = state => {
    return {
        username: getUserName(state),
        name: getName(state),
        userimage: getUserImage(state),
        isLogged: getIsLogged(state),
        isAdmin: getIsAdmin(state),
        isAdding: getIsAdding(state),
        showMenu: showMenuSelector(state),
        showPersonalData: showPersonalDataSelector(state),
        impersonatedBy: getImpersonatedBy(state),
        userType: getUserType(state),
        canViewCatalog: canViewCatalog(state),
        email: getEmail(state),
        employeeId: getEmployeeId(state),
        canRouteOfferStatusHistory: canOffer(state),
        canRouteConfiguration: true,
        isMktoolsCenter: getIsMkToolsCenter(state),
        isLowesCenter: getIsLowesCenter(state),
        isIkeaCenter: getIsIkeaCenter(state),
        isMktoolsAdmin: getIsMktoolsAdmin(state),
        hasPreOrder: canViewPreorder(state),
        isUserMallAuthorized: getUserAuthorization(state),
        isMallAdmin: getIsMallAdmin(state),
        isCampaignAdmin: getIsCampaignsPilotUser(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onToggleMenu: active => {
            dispatch(ui.toggleMenu(active))
        },
        onShowUpLogin: () => {
            dispatch(ui.showLoginModal())
        },
        onShowUpCart: () => {
            dispatch(ui.showCartModal())
        },
        onShowUpImpersonate: () => {
            dispatch(ui.showImpersonateModal())
        },
        onLogout: () => {
            dispatch(logout())
        },
        onTogglePersonalData: active => {
            dispatch(ui.showPersonalData(active))
        },
    }
}
const ResponsiveMenuContainer = withMsal(connect(
    mapStateToProps,
    mapDispatchToProps
)(ResponsiveMenu))

export default ResponsiveMenuContainer
