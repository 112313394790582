import React from 'react'

import { connect } from 'react-redux'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'
import { getIsIkeaCenter } from '../../store/centers/reducers'
import SelectShippingAddress from './SelectShippingAddress'

const mapStateToProps = state => {
    return { userPreferences: getProfileConfiguration(state), isIkeaCenter: getIsIkeaCenter(state) }
}
class AssignCenterInfoModal extends React.Component {
    render() {
        return (
            <div className="global__assign_center_info_modal" data-cy="global__assign_center_info_modal">
                <div
                    className="full-overlay"
                    style={{ display: this.props.show ? 'block' : 'none', zIndex: 200, background: 'rgba(77,77,77, 0.8)', top: '40px' }}
                    onClick={!this.props.isIkeaCenter && this.props.onClose}
                >
                    <div className="container">
                        <div className="row h-100">
                            <div className="col-12 h-100 d-flex justify-content-center mt-5">
                                {this.props.showSelectShippingAddress && <SelectShippingAddress />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(
    mapStateToProps,
    null
)(AssignCenterInfoModal)
