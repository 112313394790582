import React from 'react'
import Translation from '../../global/Translation'

import Button from '../../global/Button'
import ProductDisplayName from '../../global/ProductDisplayName'
import AddFavourite from '../../global/AddFavourite'
import DisplayDetail from '../rectangleResult/displays/DisplayDetail'
import DisplayBrand from '../rectangleResult/displays/DisplayBrand'

export default class SampleResult extends React.Component {
    constructor(props) {
        super(props)
        const source = props.result !== undefined ? props.result._source : props.product

        this.product = source
    }
    render() {
        if (this.product.productId === '223277') return null
        return (
            <div className="sample-result" data-cy="catalog__product_sample_result" type={this.product.type}>
                {this.props.cart &&
                    this.props.cart.map(line => {
                        if (line.productId === this.product.productId && line.extra && (line.extra.type === 'custom_sample' || line.extra.type === 'brochures'))
                            return <span className="badge badge-pill badge-secondary"><span>{line.qty}</span></span>
                        else return null
                    })}
                <div className="product-image-container">
                    <div className="product-image" style={{ backgroundImage: 'url("' + this.props.getImageBackground(this.product) + '")' }} />
                    <div className="product-color-name">
                        <ProductDisplayName product={this.product} />
                        {this.product.subtypeId === 'BROCHURES' ? <DisplayDetail value={'Brochure'} /> : <DisplayDetail value={this.product.finish} />}
                        <DisplayBrand brand={this.product.brand} />
                    </div>
                </div>
                <AddFavourite productId={this.product.productId} />

                {this.props.canBuyCatalog && (
                    <div className="product-actions">
                        <div className="bt-row">
                            <Button
                                icon="shopping-cart"
                                size="small"
                                customClass="bt-add-cart"
                                onClick={() => {
                                    if (this.product.subtypeId === 'CUSTOMER SAMPLES') this.props.addCartLine(this.product.productId, 6, { type: 'custom_sample' })
                                    else if (this.product.subtypeId === 'BROCHURES') this.props.addCartLine(this.product.productId, 50, { type: 'brochures' })
                                }}
                            >
                                <Translation id="add_to_cart" defaultMessage="Add to cart" />
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
