import React from 'react'
import { connect } from 'react-redux'
import { addCartLine, addCartLineWithouthSeg } from '../../../store/cart/actions'
import { getShippingMethod, getNeedsSegmentation } from '../../../store/centers/reducers'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import { getIsAdding, getQtyProductsCart } from '../../../store/cart/reducers'
import { discountsByProductId } from '../../../store/products/reducers'
import Translation from '../../global/Translation'
import LocalizedLink from '../../global/LocalizedLink'
import Button from '../../global/Button'
import InputNumber from '../../global/InputNumber'
import ProductImage from '../ProductImage'
import DetailsDefault from './SimpleDetailsDefault'

//Displays
import DisplayTechnology from './displays/DisplayTechnology'
import {setLastSelectedProduct} from "../../../store/selected-objects/actions";


const mapStateToProps = (state, ownProps) => {
    const source = ownProps.result !== undefined ? ownProps.result._source : ownProps.product

    return {
        shippingMethod: getShippingMethod(state),
        outlet: ownProps.match.params.outlet,
        qtyProductCart: getQtyProductsCart(state),
        needsSegmentation: getNeedsSegmentation(state),
        isAdding: getIsAdding(state),
        productDiscounts: discountsByProductId(state, source.productId, ownProps.match.params.outlet)
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        addCartLine: (productId, qty, extra, callbackCenter, dektonGripRequested) => {
            dispatch(addCartLine(productId, qty, extra, callbackCenter, dektonGripRequested))
        },
        addCartLineWithouthSeg: (productId, qty, extra) => {
            dispatch(addCartLineWithouthSeg(productId, qty, extra))
        },
        clickDetail: (product) => {
            dispatch(setLastSelectedProduct(product))
            dispatch({ type: 'PRODUCT_CLICK', product, list:props.list, index:props.indexOfList })
        },
        closeCrossSellinModal: () => {
            dispatch({ type: 'HIDE_CROSSELLLING_MODAL'})
        },
        sendClickMetrics: (articleType) => {
            dispatch({ type: 'CLICK_CROSS_SELLING_LINK', list:props.list, articleType})
        }
    }
}

class SingleRectangleResult extends React.Component {
    constructor(props) {
        super(props)
        const source = props.result !== undefined ? props.result._source : props.product
        this.product = source

        let firstState = {}
        let step = 1
        let qtyAdd = 1
        if (this.product && (this.product.typeId === '01' || this.product.typeId === 'NEWS')) step = 1
        else if (this.product.minQty[this.props.shippingMethod]) step = this.product.minQty[this.props.shippingMethod]

        if (this.getMin()) qtyAdd = this.getMin()
        else if (this.product && (this.product.typeId === '01' || this.product.typeId === 'NEWS')) qtyAdd = 1
        else if (this.product.minQty[this.props.shippingMethod]) qtyAdd = this.product.minQty[this.props.shippingMethod]
        firstState = { ...firstState, qtyToAdd: qtyAdd, step: step, promotions: [] }

        if (this.getLimit() === 0) firstState = { ...firstState, qtyToAdd: 0 }

        this.state = firstState
        //this.generatePromotionTags()
    }
    generatePromotionTags() {

        let allPromotions = []

        let sorting = {
            Z70D: { val: 0 },
            Z71D: { val: 1 },
            Z73D: { val: 2 },
            ZQ00: { val: 3 }
        }

        if (this.props.productDiscounts && Object.keys(this.props.productDiscounts).length > 0) {
            allPromotions = allPromotions.concat(this.props.productDiscounts)
        }
        if (this.props.outlet) {
            allPromotions = this.validateOutletPromotions(allPromotions)
        }
        let promotionsUpdated = allPromotions.sort((a, b) => {
            if (sorting[b.conditionClass] && sorting[a.conditionClass]) {
                // console.log(a.conditionClass)
                // console.log(sorting[a.conditionClass].val)
                if (a.conditionClass == b.conditionClass) {
                    if (a.conditionClass == 'Z71D') {
                        //Discunt higher more favorable.
                        if (a.value > b.value) {
                            return -1
                        }
                        if (a.value < b.value) {
                            return 1
                        }
                    } else {
                        if (a.value > b.value) {
                            return 1
                        }
                        if (a.value < b.value) {
                            return -1
                        }
                    }
                }

                return sorting[a.conditionClass].val - sorting[b.conditionClass].val
            }

            // a must be equal to b
            return 1
        })

        this.setState({ promotions: promotionsUpdated })
    }

    getMin() {
        return this.product.min
    }

    getLimit() {
        if (this.product.discontinued || (this.props.outlet && this.product.outlet)) {
            return this.getLimitDiscontinued()
        }
        return null
    }

    getLimitDiscontinued() {
        let result = 0
        if (this.props.outlet) {
            if (this.product.stock_outlet) {
                result = parseInt(this.product.stock_outlet, 10)
            }
        } else {
            if (this.product.stock) {
                result = parseInt(this.product.stock, 10)
            }
        }
        if (this.props.qtyProductCart[this.product.productId]) {
            result = result - parseInt(this.props.qtyProductCart[this.product.productId], 10)
        }
        return result
    }

    getValueWithMax() {
        return this.state.qtyToAdd <= this.getLimit() ? this.state.qtyToAdd : this.getLimit()
    }

    componentDidMount() {
        this.generatePromotionTags()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.productDiscounts !== this.props.productDiscounts) {
            this.generatePromotionTags()
        }
    }

    validateOutletPromotions = allPromotions => {
        if (allPromotions == undefined || allPromotions == null || Object.keys(allPromotions).length == 0) return []
        //remove other outlet types promos.
        allPromotions = Object.values(allPromotions).filter(promo => {
            if (promo.outlet && promo.outlet == true && promo.outlet_types != this.product.outletQuality) {
                return false
            }
            return true
        })

        return allPromotions
    }

    handleClickLink = (articleType) => {
        this.props.closeCrossSellinModal()
        this.props.sendClickMetrics(articleType)
    }

    render() {
        if (this.getLimit() && this.getValueWithMax() < this.state.qtyToAdd) this.setState({ qtyToAdd: this.getValueWithMax() })

        const isMulti = this.props.count && this.props.count > 1
        const extra = {}

        if (this.props.outlet) extra.outlet = this.product.outletQuality
        if (this.props.list) extra.analyticsTag = this.props.list
        if (this.props.sectionFlag) {
            extra.crossSelling = true
            extra.sectionFlag = this.props.sectionFlag
        }

        return (
            <div className="single-rectangle-result row" type={this.product.type}>
                <div className="wrapper-product-image col-5">
                    <DisplayTechnology technology={this.product.technology} />
                    <LocalizedLink
                        routeId="ROUTE_PRODUCT_DETAIL"
                        // target="_parent"
                        params={{ id: this.product.productId, outlet: this.props.outlet ? 'outlet' : '' }}
                        onClick={() => {
                            const product = this.product
                            if (this.props.list) {
                                product.extra = {...product.extra, analyticsTag:this.props.list}
                            }
                            this.props.clickDetail(product)
                            this.props.closeCrossSellinModal()
                        }}
                    >
                        <ProductImage product={this.product} />
                    </LocalizedLink>
                </div>

                <div className="col-7 pl-0">

                    <DetailsDefault {...this.props} isMulti={isMulti} product={this.product} />

                    {
                        !isMulti && (
                            <div className="product-actions row no-gutters">
                                <div className="input-row col-6">
                                    <div className="input">
                                        <InputNumber
                                            value={this.getLimit() !== null ? this.getValueWithMax() : this.state.qtyToAdd}
                                            change={qty =>
                                                this.setState({
                                                    qtyToAdd: qty
                                                })
                                            }
                                            size="small"
                                            step={this.props.isIkeaCenter && this.product.unit === 'M2' ? 0.01 : this.state.step}
                                            limit={this.getLimit()}
                                            min={this.getMin()}
                                            decimals={this.props.isIkeaCenter && this.product.unit === 'M2' ? 2 : 0}
                                            oldStyle={true}
                                        />
                                    </div>
                                </div>

                                <div className="bt-row col-6 pl-3">
                                    <Button
                                        //icon="shopping-cart"
                                        size="small"
                                        customClass="bt-add-cart"
                                        disabled={this.props.isAdding || (this.getLimit() != null ? this.getLimit() : 1) <= 0 || !this.state.qtyToAdd >= 1}
                                        onClick={() => {
                                            this.props.closeCrossSellinModal()
                                            if (this.props.needsSegmentation) {
                                                this.props.addCartLine(
                                                    this.product.productId,
                                                    this.state.qtyToAdd,
                                                    extra,
                                                    null,
                                                    this.product.finish === 'AND' && ['DEKTON', 'DKTN'].includes(this.product.brand)
                                                )
                                            } else {
                                                this.props.addCartLineWithouthSeg(this.product.productId, this.state.qtyToAdd, { crossSelling:true })
                                            }
                                        }}
                                    >
                                        <Translation id="add" defaultMessage="Add" />
                                    </Button>
                                </div>
                            </div>
                        )
                    }

                    {
                        isMulti && (
                            <div className="product-actions row no-gutters">
                                <div className="bt-row col-12 mt-1">
                                    <Button
                                        //icon="shopping-cart"
                                        size="small"
                                        customClass="bt-add-cart"
                                        disabled={this.props.isAdding || (this.getLimit() != null ? this.getLimit() : 1) <= 0 || !this.state.qtyToAdd >= 1}
                                    >
                                        <LocalizedLink routeId="ROUTE_CATALOG"
                                                       queryString={`?q=${this.product.colorName}&type[0]=${this.product.type}&cross-selling`}
                                                       params={{ outlet: '' }}
                                                       onClick={() => {this.handleClickLink(this.product.type)}}
                                                       gtmLabel="ec_cs_popup_carrito"
                                        >
                                            <FormattedMessage id="see_n_models" defaultMessage="See {modelCount} models" values={{ modelCount: this.props.count }} />
                                        </LocalizedLink>
                                    </Button>
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>
        )
    }
}

export default withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(SingleRectangleResult)
    )
)
