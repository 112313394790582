import { connect } from 'react-redux'
import OrdersSelector from './OrdersSelector'
import {
    getOrders,
    getOrdersLoading,
    getOpenOrders,
    getTransferOrders,
    canEditOrders,
    canDeleteOrders,
    getStatusMap,
    getOrdersCurrentPage,
    getOrdersLastPage
} from '../../store/orders/reducers'
import { injectIntl } from 'react-intl'
import { fetchOrders, fetchOrdersPaginate, rejectOrder } from '../../store/orders/actions'
import { withRouter } from 'react-router-dom'
import { getUserType, canViewPreorder } from '../../store/login/reducers'
import { getIsIkeaCenter, canOffer, canViewNewTracking, getCenters } from '../../store/centers/reducers'
import { getSelectedShippingAddressForIkea, getShippingAddresses } from '../../store/shipping-addresses/reducers'
import { dispatchPushURL } from '../../store/ui/actions'

const mapStateToProps = (state, { match }) => {
    return {
        orders: getOrders(state),
        centers: getCenters(state),
        openOrders: getOpenOrders(state),
        transferOrders: getTransferOrders(state),
        type: match.params.open,
        key: match.params.open,
        isLoading: getOrdersLoading(state),
        userType: getUserType(state),
        canEditOrders: canEditOrders(state),
        canDeleteOrders: canDeleteOrders(state),
        isIkeaCenter: getIsIkeaCenter(state),
        selectedShippingAddressForIkea: getSelectedShippingAddressForIkea(state),
        shippingAddressesByUser: getShippingAddresses(state),
        currentPage: getOrdersCurrentPage(state),
        lastPage: getOrdersLastPage(state),
        statusMap: getStatusMap(state),
        canOffer: canOffer(state),
        canViewNewTracking: canViewNewTracking(state),
        hasPreOrder: canViewPreorder(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchOrders: (type, startDate, endDate, filterTypeDate, status, shippingType, supplierCenter, search) => {
            dispatch(fetchOrders(type, startDate, endDate, filterTypeDate, status, shippingType, supplierCenter, search))
        },
        fetchOrdersPaginate: (type, filterTypeDate, startDate, endDate, status, shippingType, supplierCenter, search, page) => {
            dispatch(fetchOrdersPaginate(type, filterTypeDate, startDate, endDate, status, shippingType, supplierCenter, search, page))
        },
        rejectOrder: (reservationId, orderId) => {
            dispatch(rejectOrder(reservationId, orderId))
        },
        redirect: url => {
            dispatch(dispatchPushURL(url))
        }
    }
}

const OrdersContainer = withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(OrdersSelector)
    )
)

export default OrdersContainer
