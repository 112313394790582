import React from 'react'
import Translation from '../global/Translation'
import DisplayThickness from '../catalog/rectangleResult/displays/DisplayThickness'

import { connect } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import { clearOrderDetails, fetchOrderDetails, fetchPackingList, getFileByUrl, rejectOrder } from '../../store/orders/actions'
import {
    getError,
    getInvoices,
    getOpenDocDetails,
    getOrder,
    getOrderDetailsHead,
    getOrderDetailsLoading,
    getOrderStatusMap,
    getPackingList,
    getStatusMap
} from '../../store/orders/reducers'
import {getToken, isEmserUser} from '../../store/login/reducers'
import { getLanguage } from '../../store/ui/reducers'
import Spinner from '../global/Spinner'
import Modal from '../global/Modal'
import { getShippingAddresses } from '../../store/shipping-addresses/reducers'
import moment from 'moment'
import PackingList from './PackingList'
import OrderHeader from './OrderHeader'
import confirm from '../global/confirm'
import ProductDisplayName from '../global/ProductDisplayName'
import OrderFullMobile from './OrderFullMobile'
import ProductImage from '../catalog/ProductImage'
import ReactTable from 'react-table'
import Error from '../alerts/Error'
import DisplayOutletIndicator from '../catalog/rectangleResult/displays/DisplayOutletIndicator'
import { dispatchPushURL } from '../../store/ui/actions'
import OrderDetailsTableV2 from './OrderDetailsTableV2'
import ProfileContainer from '../profile/ProfileContainer'

defineMessages({
    delivery_status_NOT_TREATED: {
        id: 'delivery_status_NOT_TREATED',
        description: 'delivery_status_NOT_TREATED',
        defaultMessage: 'Pending'
    },
    delivery_status_TREATED_PARTIALLY: {
        id: 'delivery_status_TREATED_PARTIALLY',
        description: 'delivery_status_TREATED_PARTIALLY',
        defaultMessage: 'In progress'
    },
    delivery_status_TREATED_COMPLETELLY: {
        id: 'delivery_status_TREATED_COMPLETELLY',
        description: 'delivery_status_TREATED_COMPLETELLY',
        defaultMessage: 'Finalized'
    }
})

const mapStateToProps = (state, { match }) => {
    return {
        packingList: getPackingList(state),
        order: getOrder(state),
        orderDetailsHead: getOrderDetailsHead(state),
        openDocDetails: getOpenDocDetails(state),
        isLoading: getOrderDetailsLoading(state),
        token: getToken(state),
        lang: getLanguage(state),
        error: getError(state),
        reservationId: match.params.open,
        shippingAddresses: getShippingAddresses(state),
        statusMap: getStatusMap(state),
        orderStatusMap: getOrderStatusMap(state),
        invoices: getInvoices(state),
        isEmserUser : isEmserUser(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchOrderDetails: reservationId => {
            dispatch(fetchOrderDetails(reservationId))
        },
        fetchPackingList: reservationId => {
            dispatch(fetchPackingList(reservationId))
        },
        clearOrderDetails: reservationId => {
            dispatch(clearOrderDetails(reservationId))
        },
        rejectOrder: (reservationId, orderId) => {
            dispatch(rejectOrder(reservationId, orderId))
        },
        getFileByUrl: url => dispatch(getFileByUrl(url)),
        redirect: url => {
            dispatch(dispatchPushURL(url))
        }
    }
}

const ProgressDeliveryDate = props => {
    let total = moment(props.finishDate).valueOf() - moment(props.initDate).valueOf()
    let progressValueOf = moment().valueOf() - moment(props.initDate).valueOf()
    let percentage = (progressValueOf * 100) / total
    if (total < 0) percentage = 100

    return (
        <div className="row">
            <div className="col-12">
                <div className="row">
                    <div className="col-12">{props.intl.formatMessage({ id: props.statusText })}</div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="progress">
                            <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                style={{ width: percentage + '%' }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

class OrderDetailsV2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showDetails: false,
            showHelp: false,
            isLoading: false,
            showPackingList: false,
            itemsView: 'per-delivery',
            showInvoicesModal: false,
            width: window.innerWidth
        }

        this.getOrderInvoiceDownloadUrl = this.getOrderInvoiceDownloadUrl.bind(this)
        this.getOrderImagesDownloadUrl = this.getOrderImagesDownloadUrl.bind(this)
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange)
    }

    componentDidMount() {
        this.props.fetchOrderDetails(this.props.reservationId)
        this.props.fetchPackingList(this.props.reservationId)
    }
    componentWillUnmount() {
        this.props.clearOrderDetails(this.props.reservationId)
        window.removeEventListener('resize', this.handleWindowSizeChange)
    }

    componentDidUpdate(prevProps) {
        const order = this.props.order

        if (order) {
            if (order.deliveries.length === 0 && this.state.itemsView !== 'all') {
                this.setState({ itemsView: 'all' })
            }
        }
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth })
    }

    getOrderInvoiceDownloadUrl(invoiceNum) {
        return process.env.REACT_APP_API_HOST + '/users/order-invoice/' + invoiceNum
        // return process.env.REACT_APP_API_HOST + '/users/order-invoice/' + invoiceNum + '/?authToken=' + this.props.token
    }

    getOrderImagesDownloadUrl() {
        return process.env.REACT_APP_API_HOST + '/users/order-images?reservationId=' + this.props.reservationId + '&lang=' + this.props.lang
        // return process.env.REACT_APP_API_HOST + '/users/order-images?reservationId=' + this.props.reservationId + '&lang=' + this.props.lang + '&authToken=' + this.props.token
    }

    setItemsView = type => {
        this.setState({ itemsView: type })
    }

    writeContent = printWindow => {
        printWindow.document.write('<html><head>')
        printWindow.document.write(document.getElementsByTagName('head')[0].innerHTML)

        printWindow.document.write('</head><body class="p-4 my-4">')
        printWindow.document.write(this.orders_group.innerHTML)
        printWindow.document.write('</body></html>')

        return Promise.resolve(printWindow)
    }

    downloadPdf = () => {
        let printWindow = window.open('', 'PRINT', 'height=400,width=600')
        setTimeout(() => {
            this.writeContent(printWindow).then(printWindow => {
                setTimeout(() => {
                    printWindow.focus()
                    printWindow.print()
                    printWindow.close()
                }, 2000)
            })
        }, 100)

        return false
    }

    confirmRejectOrder = (reservationId, orderId, callback, intl) => {
        return confirm(intl.formatMessage({ id: 'CONFIRM_REJECT_ORDER' })).then(
            confirm => {
                return callback(reservationId, orderId)
            },
            cancel => {
                return Promise.reject()
            }
        )
    }

    rejectOrder = (reservationId, orderId) => {
        this.confirmRejectOrder(reservationId, orderId, this.props.rejectOrder, this.props.intl)
    }

    showInvoicesModal = () => {
        this.setState({ showInvoicesModal: true })
    }

    closeInvoicesModal = () => {
        this.setState({ showInvoicesModal: false })
    }

    render() {
        const { order, error } = this.props
        if (!order && error) {
            return <Error alert={this.props.intl.formatMessage({ id: error.data.err })} />
        }
        const { width } = this.state
        const isMobile = width <= 768
        let orderShippingAddress =
            this.props.shippingAddresses && this.props.order
                ? this.props.shippingAddresses.filter(shippingAddress => {
                      return shippingAddress.shippingAddressId === this.props.order.shippingAddressId
                  })[0]
                : null

        let shippingDates = []
        this.props.openDocDetails &&
            this.props.openDocDetails.forEach(line => {
                if (!shippingDates.includes(line.shipDate)) {
                    shippingDates.push(line.shipDate)
                }
            })

        return (
            <div className={`container mt-4 order_details ${this.props.isLoading && 'container--loading'}`}>
                <div className="box-profile row">
                    <div className="box-profile-menu col-12 col-md-4 col-lg-3">
                        <ProfileContainer />
                    </div>

                    <div className="box-profile-form col-12 col-xl-9 v2">
                        {this.props.order && (
                            <OrderHeader
                                dataOrder={this.props.order}
                                showDetails={this.state.showDetails}
                                toggleShowDetails={() => this.setState({ showDetails: !this.state.showDetails })}
                                showActionButtons={this.props.statusMap[this.props.order.statusText] ? this.props.statusMap[this.props.order.statusText].editable : false}
                                backgroundTransparent={true}
                                isOrderDetails={true}
                                ordersGroup={this.orders_group}
                            />
                        )}

                        <div id="orders_group" className="mt-3" ref={element => (this.orders_group = element)}>
                           

                            {order && (
                                <div className="order-history-detail-items">
                                    <div className="view-selector" style={{ backgroundColor: 'white', padding: '1rem', marginLeft: '-15px', marginRight: '-15px' }}>
                                        <span className={this.state.itemsView == 'all' ? 'active' : null} onClick={() => this.setItemsView('all')}>
                                            <Translation id="full_order_view" defaultMessage="View full order" />
                                        </span>
                                        {order.deliveries.length > 0 && (
                                            <span className={this.state.itemsView == 'per-delivery' ? 'active' : null} onClick={() => this.setItemsView('per-delivery')}>
                                                <Translation id="delivery_view" defaultMessage="View by deliveries" />
                                            </span>
                                        )}
                                    </div>
                                    {this.state.itemsView == 'per-delivery' && (
                                        <div className="per-delivery-view time-line-2 mt-3">
                                            {order.deliveries.map((delivery, i) => {
                                                let deliveryStatus = order.timelines.find(timeline => timeline.delivery_id == delivery.delivery_id).delivery_status
                                                return (
                                                    <div
                                                        className=""
                                                        key={delivery.delivery_id}
                                                        data={delivery.delivery_id}
                                                        style={{ backgroundColor: 'white', padding: '1em', marginLeft: '-15px', marginRight: '-15px' }}
                                                    >
                                                        <div className="delivery-head row">
                                                            <div className="col-7 col-md-2 number">
                                                                <b>
                                                                    <Translation id="delivery_number" defaultMessage="Nº delivery" />: {i + 1}
                                                                </b>
                                                            </div>
                                                            <div className="col-7 col-md-3">
                                                                <b>
                                                                    <Translation id="delivery_id" defaultMessage="Delivery id" />:
                                                                </b>{' '}
                                                                #{delivery.delivery_id}
                                                            </div>
                                                            <div className="col-12 col-md-4">
                                                                {deliveryStatus === 'TREATED_COMPLETELLY' ? (
                                                                    <div>
                                                                        <b>
                                                                            <Translation id="delivered_date" defaultMessage="Delivered date" />:{' '}
                                                                        </b>
                                                                        {moment(delivery.delivered_date || delivery.billing_date).format('ll')}
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <b>
                                                                            <Translation id="estimated_delivery_date" defaultMessage="Estimated delivery date" />:{' '}
                                                                        </b>
                                                                        {moment(delivery.expected_delivery_date).format('ll')}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-12 col-md-3 text-right">
                                                                <b>
                                                                    <Translation id="delivery_status" defaultMessage="Delivery status" />:
                                                                </b>{' '}
                                                                {this.props.intl.formatMessage({ id: 'delivery_status_' + deliveryStatus })}{' '}
                                                                <span className={`delivery-status-dot ${deliveryStatus === 'TREATED_COMPLETELLY' ? 'blue' : 'grey'}`}></span>
                                                            </div>
                                                        </div>
                                                        <div className="delivery-lines time-line">
                                                            {order.orderLinesByDelivery[delivery.delivery_id] &&
                                                                order.orderLinesByDelivery[delivery.delivery_id].map((orderLine, i) => {
                                                                    if (orderLine.product) {
                                                                        if (isMobile) {
                                                                            return (
                                                                                <div className="row card-lines time-line">
                                                                                    <div>
                                                                                        <ProductImage product={orderLine.product} />
                                                                                    </div>
                                                                                    <div>
                                                                                        {orderLine.outlet_quality && (
                                                                                            <DisplayOutletIndicator outletQuality={orderLine.outlet_quality} />
                                                                                        )}
                                                                                        <ProductDisplayName product={orderLine.product} />
                                                                                        <p>
                                                                                            <b>
                                                                                                <Translation id="brand" defaultMessage="Brand" />:{' '}
                                                                                            </b>{' '}
                                                                                            {orderLine.product.brand}
                                                                                        </p>
                                                                                        <p>
                                                                                            <b>
                                                                                                <Translation id="thickness" defaultMessage="Thickness" />:{' '}
                                                                                            </b>
                                                                                            <DisplayThickness thickness={orderLine.product.thickness} string />
                                                                                        </p>
                                                                                        <p>
                                                                                            <b>
                                                                                                <Translation id="finish" defaultMessage="Finish" />:{' '}
                                                                                            </b>

                                                                                            {orderLine.product.finish &&
                                                                                                this.props.intl.formatMessage({ id: orderLine.product.finish })}
                                                                                        </p>
                                                                                        <p>
                                                                                            <b>
                                                                                                <Translation id="material_id" defaultMessage="Material Id" />:{' '}
                                                                                            </b>
                                                                                            {orderLine.product.productId}
                                                                                        </p>
                                                                                        {orderLine.lot_id && (
                                                                                            <p>
                                                                                                <b>
                                                                                                    <Translation id="lot_id" defaultMessage="Lot ID" />:{' '}
                                                                                                </b>
                                                                                                {orderLine.lot_id}
                                                                                            </p>
                                                                                        )}
                                                                                        <p>
                                                                                            <b>
                                                                                                <Translation id="format" defaultMessage="Format" />:{' '}
                                                                                            </b>
                                                                                            {orderLine.product.format}
                                                                                        </p>
                                                                                        {orderLine.delivery_qty && (
                                                                                            <p>
                                                                                                <b>
                                                                                                    <Translation id="delivery_units" defaultMessage="Units included in shipment" />:{' '}
                                                                                                </b>
                                                                                                {parseInt(orderLine.delivery_qty, 10)}/{parseInt(orderLine.quantity, 10)}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <div
                                                                                    className="d-flex order-details"
                                                                                    style={{ borderBottom: '1px solid #EBEBEB', marginBottom: '1rem', padding: '1em' }}
                                                                                >
                                                                                    <div style={{ minWidth: '100px', marginRight: '3rem' }}>
                                                                                        <ProductImage product={orderLine.product} />
                                                                                    </div>
                                                                                    <div className="d-flex flex-column" style={{ marginRight: '3rem' }}>
                                                                                        {orderLine.outlet_quality && (
                                                                                            <DisplayOutletIndicator outletQuality={orderLine.outlet_quality} />
                                                                                        )}
                                                                                        <b>
                                                                                            <Translation id="material" defaultMessage="Material" />:
                                                                                        </b>
                                                                                        <ProductDisplayName product={orderLine.product} />
                                                                                        <b>
                                                                                            <Translation id="brand" defaultMessage="Brand" />:
                                                                                        </b>
                                                                                        {orderLine.product.brand}
                                                                                    </div>
                                                                                    <div className="d-flex flex-column" style={{ marginRight: '3rem' }}>
                                                                                        <b>
                                                                                            <Translation id="thickness" defaultMessage="Thickness" />:
                                                                                        </b>
                                                                                        <DisplayThickness thickness={orderLine.product.thickness} string />
                                                                                        <b>
                                                                                            <Translation id="finish" defaultMessage="Finish" />:
                                                                                        </b>
                                                                                        {orderLine.product.finish && (
                                                                                            <React.Fragment>
                                                                                                <b>
                                                                                                    <Translation id="finish" defaultMessage="Finish" />
                                                                                                </b>
                                                                                                {this.props.intl.formatMessage({ id: orderLine.product.finish })}
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="d-flex flex-column" style={{ marginRight: '3rem' }}>
                                                                                        <b>
                                                                                            <Translation id="material_id" defaultMessage="Material Id" />:
                                                                                        </b>
                                                                                        {orderLine.product.productId}
                                                                                    </div>
                                                                                    <div className="d-flex flex-column box-price" style={{ marginLeft: 'auto' }}>
                                                                                        {orderLine.lot_id && (
                                                                                            <React.Fragment>
                                                                                                <b>
                                                                                                    <Translation id="lot_id" defaultMessage="Lot ID" />:
                                                                                                </b>
                                                                                                {orderLine.lot_id}
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                        <b>
                                                                                            <Translation id="format" defaultMessage="Format" />:
                                                                                        </b>
                                                                                        {orderLine.product.format}
                                                                                        {orderLine.delivery_qty && (
                                                                                            <React.Fragment>
                                                                                                <b>
                                                                                                    <Translation id="delivery_units" defaultMessage="Units included in shipment" />:
                                                                                                </b>
                                                                                                {parseInt(orderLine.delivery_qty, 10)}/{parseInt(orderLine.quantity, 10)}
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    } else {
                                                                        return (
                                                                            <div className="delivery-line row" key={i}>
                                                                                <div className="col-12 col-md-2">
                                                                                    <ProductImage product={null}></ProductImage>
                                                                                </div>
                                                                                <div className="col-12 col-md-5"></div>
                                                                                <div className="col-12 col-md-2">
                                                                                    <p>
                                                                                        <Translation id="material_id" defaultMessage="Material Id" />:{' '}
                                                                                        <b>{orderLine.material_id}</b>
                                                                                    </p>
                                                                                    {orderLine.lot_id && (
                                                                                        <p>
                                                                                            <Translation id="lot_id" defaultMessage="Lot ID" />: <b>{orderLine.lot_id}</b>
                                                                                        </p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="col-12 col-md-2">
                                                                                    <p>
                                                                                        <Translation id="delivery_units" defaultMessage="Units included in shipment" />:{' '}
                                                                                        <b>
                                                                                            {orderLine.delivery_qty && (
                                                                                                <span>
                                                                                                    {parseInt(orderLine.delivery_qty, 10)}/{parseInt(orderLine.quantity, 10)}
                                                                                                </span>
                                                                                            )}
                                                                                        </b>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                    {this.state.itemsView == 'all' &&
                                        (!isMobile ? (
                                            <OrderDetailsTableV2 orderLines={order.orderLines} editMode={false} isEmserUser={this.props.isEmserUser} lang={this.props.lang} />
                                        ) : (
                                            <OrderFullMobile orderLines={order.orderLines} lang={this.props.lang} />
                                        ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {this.state.showInvoicesModal && (
                    <Modal title={<Translation id="invoices" defaultMessage="Invoices" />} onClose={this.closeInvoicesModal}>
                        <ReactTable
                            data={this.props.invoices}
                            columns={[
                                {
                                    Header: <Translation id="invoice_num" defaultMessage="Invoice Num." />,
                                    Cell: ({ original }) => {
                                        return (
                                            <span
                                                onClick={e => {
                                                    e.preventDefault()

                                                    this.props.getFileByUrl(this.getOrderInvoiceDownloadUrl(original.invoiceNum))
                                                }}
                                                style={{ color: 'blue', cursor: 'pointer' }}
                                            >
                                                {original.invoiceNum}
                                            </span>
                                        )
                                    }
                                },
                                { Header: <Translation id="invoice_date" defaultMessage="Invoice Date" />, accessor: 'invoiceDate' }
                            ]}
                            defaultPageSize={4}
                            pageSizeOptions={[8, 16, 32, 64, 128]}
                            previousText={<Translation id="previous" defaultMessage="Previous" />}
                            nextText={<Translation id="next" defaultMessage="Next" />}
                            noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                            pageText={<Translation id="page" defaultMessage="Page" />}
                            ofText={<Translation id="of" defaultMessage="Of" />}
                        />
                    </Modal>
                )}

                {this.state.showPackingList && this.props.orderDetailsHead && (
                    <PackingList
                        reservationId={this.props.reservationId}
                        orderId={this.props.orderDetailsHead.orderId}
                        packingList={this.props.packingList}
                        onClose={() => this.setState({ showPackingList: false })}
                        canEditOrders={false}
                    />
                )}
                <Spinner isLoading={this.props.isLoading} />
            </div>
        )
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OrderDetailsV2)
)
