import React from 'react'
import Button from '../../global/Button'
import DisplayPricePhase2 from '../../catalog/rectangleResult/displays/DisplayPricePhase2'
import DisplayOutletIndicator from '../../catalog/rectangleResult/displays/DisplayOutletIndicator';
import cameraOff from '../../../assets/img/icons/camera-off.svg'
import ProductDisplayName from '../../global/ProductDisplayName';
import Translation from '../../global/Translation';
import RectangleResultContainer from '../../catalog/rectangleResult/RectangleResultContainer';
import ProductContext, {MallProductContext} from "../../catalog/rectangleResult/contexts/ProductContext";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {
    getCommonParams,
    getPriceListAtSelectedCenter,
    getUserPriceList, getUserType
} from "../../../store/login/reducers";
import {getSelectedCenter} from "../../../store/centers/reducers";
import {dispatchPushURL} from "../../../store/ui/actions";
import {setLastSelectedProduct} from "../../../store/selected-objects/actions";

const SliderItem = ({
    product,
    setProductSelectedToAdd,
    onZoomClick,
    sapLandingId,
    ...props
}) => {

    const showValidImage = () => {
        if (product.detailImage && product.detailImage !== 'null' && product.detailImage !== undefined && product.detailImage !== 'undefined' ) return product.detailImage
        if (product.imgLarge && (product.imgLarge !== 'null' || product.imgLarge != 'null')) return product.imgLarge
        return cameraOff
    }

    const placeHolderClassName = () => {
        if((!product.detailImage || product.detailImage === 'null') || (!product.imgLarge || product.imgLarge === 'null' || product.imgLarge == 'null')) return '--placeholder'
        return ''
    }

    return (
        <div className="slide-card">
            <div className="container p-0">
                <div className="slide-card--content">
                    <div className={"col-lg-7 col-md-6 col-12 slide-card--image" + placeHolderClassName()}
                         style={{ backgroundImage: `url(${showValidImage()})` }}
                    >
                        {product && product.outlet && product.realStock > 0 &&
                            <DisplayOutletIndicator outletQuality={product.outletQuality} />
                        }
                        {product.realStock === 0 &&
                            <span className="slide-card__tag"><Translation id="sold_out" defaultMessage="Sold out" /></span>
                        }
                        {(product.detailImage && product.detailImage !== 'null') || (product.imgLarge && (product.imgLarge !== 'null' || product.imgLarge != 'null')) ?
                            <Button
                                className="slide-card__zoom"
                                icon="expand"
                                size="medium"
                                inverted
                                onClick={onZoomClick}
                            />
                        : null}
                    </div>

                    <div className="col-lg-5 col-md-6 col-12 mall-rectangle-container">
                        <ProductContext.Provider value={MallProductContext}>
                            <RectangleResultContainer
                                product={product}
                                key={product.productId}
                                list={'MALL'}
                                sapLandingId={sapLandingId}
                            />
                        </ProductContext.Provider>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        landing: state.landing.landingBySlug,
        userType: getUserType(state),
        commonParamsAnalytics: getCommonParams(state),
        priceList: getPriceListAtSelectedCenter(state),
        userPriceList: getUserPriceList(state),
        selectedCenter: getSelectedCenter(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        addProductInFetchedProducts: (product, centerId, locale) => {
            console.log('FETCHED PRODUCT FROM MALL SLIDER ITEM:' , product.productId, centerId)
            dispatch({ type: 'FETCH_PRODUCT_SUCCESS', product, centerId, locale })
        },
        setSelectedProduct: product => dispatch(setLastSelectedProduct(product))
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SliderItem)
)

