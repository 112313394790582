import GoBack from '../global/GoBack'
import cosentinoMessage from '../../assets/img/icons/cosentino-message.svg'
import { TimelinePreOrder } from './PreOrdersCts';
import {
    cancelPreorder,
    downloadPreorderFile,
    markMessagesAsReaded
} from '../../store/cut-to-size/actions';
import React from 'react'
import Translation from '../global/Translation'
import Button from '../global/Button'
import Spinner from '../global/Spinner'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import {connect} from "react-redux";
import {BudgetMessages, PreorderMessages} from "../cut-to-size/CutToSizeMaps";
import {v4 as uuidv4} from "uuid";
import {ACCEPTED_EXT} from "../cut-to-size/CutToSizeFormPreOrder";
import confirm from "../global/extendedConfirm";
import LineSegmentation from "../cart-line/LineSegmentation";

const FILE_LIMIT = 4 //MB
const BYTES_IN_MB = 1024 * 1024
const FILE_TYPES_MAP = {
    BLUEPRINT_FILE_TYPE : 'BX01',
    LABELS_FILE_TYPE : 'BX10',
    EMAIL_FILE_TYPE : 'BX03'
}
const INVERSE_FILE_TYPES_MAP = {
    BX01 : 'BLUEPRINT_FILE_TYPE',
    BX04 : 'BLUEPRINT_FILE_TYPE',
    BX07 : 'BLUEPRINT_FILE_TYPE',
    BX10 : 'LABELS_FILE_TYPE',
    BX03 : 'EMAIL_FILE_TYPE'
}


const BLUEPRINT_ICON = <i className="far fa-pencil-ruler"/>
const LABELS_ICON = <i className="far fa-tags"/>
const EMAIL_ICON = <i className="far fa-envelope"/>
const FILE_ICON = <i className="far fa-file"/>

const PreorderSummary = ({preOrder, intl}) => {
    return (
        <div className='d-flex flex-column preorder-summary-details'>
            <div style={{ width: 'max-content' }}>
                <span><Translation id="preorder_id" defaultMessage="Pre-Order ID" />
                &nbsp;#:&nbsp;</span>
                <strong>{preOrder.reservation_id ? preOrder.reservation_id : <Translation id="pending" defaultMessage="Pending" />}</strong>
            </div>
            <div>
                <span><Translation id="order_id" defaultMessage="Order ID" />
                &nbsp;#:&nbsp;</span>
                <strong>{preOrder.order_id ? preOrder.order_id : <Translation id="pending" defaultMessage="Pending" />}</strong>
            </div>
            <div>
                <span><Translation id="purchase-order" defaultMessage="Your reference" />
                &nbsp;#:&nbsp;</span>
                <strong>{preOrder.purchase_order ? preOrder.purchase_order : '----'}</strong>
            </div>
            <br />
            <div>
                <span><Translation id="project_type" defaultMessage="Project type" />
                :&nbsp;</span><strong>{intl.formatMessage(BudgetMessages[preOrder.project])}</strong>
            </div>
            <div>
                <span><Translation id="date" defaultMessage="Date" />
                :&nbsp;</span><strong>{moment(preOrder.created_at).format('L')}</strong>
            </div>
            <div>
                <span><Translation id="last_update" defaultMessage="Last update" />
                :&nbsp;</span><strong>{moment(preOrder.update_at).format('L')}</strong>
            </div>
            <br />
            <LineSegmentation line={{ ...preOrder.segmentation, segmentation: preOrder.segmentation }} showAsList={true} />
        </div>
    )
}

class PreOrdersCtsDetails extends React.Component {

    today = moment().format('L')

    fileOptions = [
        {
            label:this.props.intl.formatMessage(BudgetMessages.BLUEPRINT_FILE_TYPE),
            value:'BLUEPRINT_FILE_TYPE'
        },
        {
            label:this.props.intl.formatMessage(BudgetMessages.LABELS_FILE_TYPE),
            value:'LABELS_FILE_TYPE'
        },
        {
            label:this.props.intl.formatMessage(BudgetMessages.EMAIL_FILE_TYPE),
            value:'EMAIL_FILE_TYPE'
        }
    ]

    state = {
        showFileType : false,
        selectedFiles: {},
        attachedFiles : []
    }

    fileInputDialog = null;

    componentDidMount() {
        if (!this.props.preOrder) this.props.fetchPreOrdersCts(this.init)
        else this.init()
        window.document.getElementById('section-profile').addEventListener('click', () => this.setState({showFileType : false}), false)
        this.fileInputDialog = window.document.createElement('input')
        this.fileInputDialog.type = 'file'
        this.fileInputDialog.onchange = this.onFileChange

    }

    componentWillUnmount() {
        window.document.getElementById('section-profile').removeEventListener('click', () => this.setState({showFileType : false}), false)
        this.fileInputDialog.remove()
    }

    onFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            console.log('FILE:' , file)
            // if (!ACCEPTED_EXT[this.fileInputDialog.docType].includes(file.type)) {
            //     event.target.value = null
            // } else
            {
                const fileSizeInMB = file.size / BYTES_IN_MB
                if (fileSizeInMB > FILE_LIMIT) {
                    this.setState({showExceedFileSizeAlert: true})
                    event.target.value = '';
                } else {
                    const fileId = uuidv4()
                    this.state.selectedFiles[fileId] = {
                        fileType: this.fileInputDialog.docType,
                        file
                    }
                }
            }
        }

        // this.forceUpdate()
    }

    init = () => {
        this.goToLastMessage()
        this.props.markMessagesAsReaded(this.props.preOrder.id)
    }

    goToLastMessage = () => {
        const el = window.document.getElementById('communications-panel')
        if (el) el.scrollTop = el.scrollHeight
    }

    delFile = key => {
        this.fileInputDialog.value = null
        delete this.state.selectedFiles[key]
        this.forceUpdate()
    }

    getFileTypeIntl = (fileType) => {
        const labels = PreorderMessages[fileType] ? PreorderMessages[fileType] : PreorderMessages.GENERIC
        return this.props.intl.formatMessage(labels)
    }

    getFileTypeIcon = (fileType) => {
        switch (fileType) {
            case 'BLUEPRINT_FILE_TYPE': return BLUEPRINT_ICON
            case 'LABELS_FILE_TYPE': return LABELS_ICON
            case 'EMAIL_FILE_TYPE': return EMAIL_ICON
            default: return FILE_ICON
        }
    }

    getCommunicationDate = date => {
        const fDate = moment(date).format('L')
        if (fDate === this.today) return this.props.intl.formatMessage({id:'today'})
        else return fDate
    }

    sendApproveMessage = (message, deny=false) => {
        const formData = new FormData()
        formData.append('preorder_id', message.pre_order_id)
        formData.append('message_id', message.id)
        formData.append('action', deny ? 'CUSTOMR_KO' : 'CUSTOMR_OK')

        this.props.sendApproveMessage(formData, () => {
            this.setState({selectedFiles: {}}, this.goToLastMessage)
        })
    }

    sendMessage = () => {
        const messageInput = window.document.getElementById('send_preorder_message_input')
        const selectedFiles = Object.values(this.state.selectedFiles)

        if (!messageInput.value && selectedFiles.length === 0) return

        const formData = new FormData()
        formData.append('preorder_id', this.props.preOrder.id)
        formData.append('message', messageInput.value)
        formData.append('action', 'CUS_COMMEN')

        const filePositionByType = {}
        const fileKeys = []

        Object.values(this.props.preOrder.files).forEach(f => {
            if (!filePositionByType[f.file_type] || f.file_position > filePositionByType[f.file_type]) {
                filePositionByType[f.file_type] = f.file_position
            }
        })

        selectedFiles.forEach(f => {
            const fileType = FILE_TYPES_MAP[f.fileType]
            let intPosition = parseInt(filePositionByType[fileType])
            let position = isNaN(intPosition) ? 0 : (intPosition + 1)
            const key = `cts_preorder_file_${fileType}_${position}`
            fileKeys.push(key)
            formData.append(key, f.file)
            filePositionByType[fileType] = position
        })

        formData.append('file_keys', fileKeys)

        this.props.sendMessage(formData, () => {
            messageInput.value = null
            this.fileInputDialog.value = null
            this.setState({selectedFiles: {}}, this.goToLastMessage)
        })
    }

    confirmRejectOrder = (preorderId) => {
        return confirm(
            this.props.intl.formatMessage({ id: 'CONFIRM_REJECT_ORDER' }),
            {
                advise:<React.Fragment>
                    <i className="fal fa-exclamation-triangle"></i>&nbsp;
                    {this.props.intl.formatMessage({ id: 'CONFIRM_REJECT_PREORDER_ADVISE' })}
                </React.Fragment>,
                textCancel:this.props.intl.formatMessage({ id: 'CONFIRM_REJECT_PREORDER_CANCEL_BUTTON' }),
                textOk:this.props.intl.formatMessage({ id: 'CONFIRM_REJECT_PREORDER_OK_BUTTON' })
            }
        )
            .then(
                confirm => {
                    this.props.cancelPreorder(preorderId)
                        .then(() => this.forceUpdate())
                    // .catch(error => alert(error))
                },
                cancel => {
                    return Promise.reject()
                }
            )
    }

    confirmApproveOrder = (message) => {
        return confirm(
            this.props.intl.formatMessage({ id: 'CONFIRM_APPROVE_PREORDER' }),
            {
                advise:
                    <div className="d-flex align-items-center">
                        <i className="fal fa-exclamation-triangle"></i>&nbsp;&nbsp;
                        <span>
                            <div>{this.props.intl.formatMessage({ id: 'CONFIRM_APPROVE_PREORDER_ADVISE_TOP' })}</div>
                            <div>{this.props.intl.formatMessage({ id: 'CONFIRM_APPROVE_PREORDER_ADVISE_BOTTOM' })}</div>
                        </span>
                    </div>,
                textCancel:this.props.intl.formatMessage({ id: 'CONFIRM_APPROVE_PREORDER_CANCEL_BUTTON' }),
                textOk:this.props.intl.formatMessage({ id: 'CONFIRM_APPROVE_PREORDER_OK_BUTTON' })
            }
        )
            .then(
                confirm => {
                    this.sendApproveMessage(message)
                },
                cancel => {}
            )
    }

    confirmDenyOrder = (message) => {
        return confirm(
            this.props.intl.formatMessage({ id: 'CONFIRM_DENY_PREORDER' }),
            {
                textCancel:this.props.intl.formatMessage({ id: 'no' }),
                textOk:this.props.intl.formatMessage({ id: 'yes' })
            }
        )
            .then(
                confirm => {
                    this.sendApproveMessage(message, true)
                },
                cancel => {}
            )
    }

    render() {
        if (!this.props.preOrder) {
            return <Spinner isLoading={true}></Spinner>
        }

        const status = this.props.preOrder.status
        const unreadedMessages = this.props.preOrder.communications.reduce((ac, c) => {return ac + (c.is_read == 0 ? 1:0)}, 0)
        const canSendMessages = status === 'TECHNICAL_REVIEW' || status === 'CSTMR_APPROVAL'

        let provCommunicationDate

        return (
            <React.Fragment>
                <div className="container preorder-details-view">
                    <div className="row">
                        <div className="col-12 px-0 pb-5 header-goback">
                            <GoBack />
                        </div>
                        <div className="col-12 p-0">
                            <div className="col-12 col-lg-8 p-0">
                                <div className="preoder-details-timeline">
                                    <TimelinePreOrder preOrder={this.props.preOrder} intl={this.props.intl} checkMark />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 preorder-chat-left">
                            <div className="preorder-details-chat-panel">
                                <div>
                                    <div className="preorder-details-header">
                                        <div className="preorder-details-title">
                                            <h5>
                                                <Translation id="preorder-details" defaultMessage="Pre-order Details"/>
                                            </h5>
                                        </div>
                                        <div className="preorder-details-delete">
                                            {
                                                this.props.preOrder.isCancelable && (
                                                    <Button fal icon="times" onClick={()=> this.confirmRejectOrder(this.props.preOrder.id)}>
                                                        <Translation id="cancel_order" defaultMessage="Cancel order" />
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="preorder-details-description">
                                        <div>
                                            <h3><Translation id="description" defaultMessage="Description"/></h3>
                                        </div>
                                        <div>
                                            <span>{this.props.preOrder.comment}</span>
                                        </div>
                                    </div>
                                    <div className="preoder-details-messages">
                                        <div>
                                            <h3><Translation id="messages" defaultMessage="Messages"/></h3>
                                        </div>
                                        <div>
                                            {unreadedMessages > 0 && <span>({unreadedMessages})</span> }
                                        </div>
                                    </div>
                                </div>
                                <div id="communications-panel" className="preorder-communications-panel">
                                    {
                                        this.props.preOrder.communications.map(message => {
                                            const fromCosentino = message.origin === 'COSENTINO'
                                            const className = fromCosentino ? "message message--received cosentino-message" : "message message--sent"
                                            const currentDate = this.getCommunicationDate(message.created_at)
                                            const sameDate = provCommunicationDate === currentDate
                                            provCommunicationDate = currentDate
                                            return (
                                                <React.Fragment>
                                                    {
                                                        !sameDate && (
                                                            <div className="row w-100 align-items-center same-date-container">
                                                                <div className="preorder-left-line" />
                                                                <div className="col-6 col-md-4 col-lg-2 preorder-current-date">{currentDate}</div>
                                                                <div className="preorder-right-line" />
                                                            </div>
                                                        )
                                                    }
                                                    <div className={className}>
                                                        <div className="preorder-communications-fromcosentino">
                                                            {fromCosentino &&
                                                                <div className="fromcosentino-header">
                                                                    <div>
                                                                        <img src={cosentinoMessage} alt="ecosentino-avatar"/>
                                                                    </div>
                                                                    <div className="cosentino-name">
                                                                        {message.user_name ? message.user_name : 'Cosentino'}
                                                                    </div>
                                                                </div>
                                                            }
                                                            <span className="cosentino-message">{['approve_preorder','deny_preorder'].includes(message.message) ? this.props.intl.formatMessage({id:message.message}): message.message}</span>
                                                            <div className="preorder-communications-files">
                                                                {this.props.preOrder.files
                                                                        .filter(f => f.communication_id === message.id)
                                                                        .map(f => {
                                                                            return (
                                                                                <div className="communications-files-container">
                                                                                    <i className="fal fa-file">
                                                                                        <div className="communications-files-type">
                                                                                            {this.getFileTypeIcon(INVERSE_FILE_TYPES_MAP[f.file_type])}
                                                                                        </div>
                                                                                    </i>
                                                                                    <div className="file-name-date">                                                                                
                                                                                        <span>{f.file_name}</span>
                                                                                        <div>{moment(f.created_at).format('L')}</div>
                                                                                    </div>
                                                                                    <div className="download-btn-files" onClick={() => this.props.downloadFile(f)}>
                                                                                        <i className="far fa-arrow-to-bottom" />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        (message.is_read == 0 && message.action === 'ASK_APPROV') && (
                                                            <div className="messages-suggestions-buttons py-3">
                                                                <Button className="bt-preorder" onClick={() => this.confirmApproveOrder(message)}>
                                                                    <Translation id="approve_preorder" defaultMessage="Yes, I approve the plan and quotation"/>
                                                                </Button>
                                                                <Button className="bt-preorder" onClick={() => this.confirmDenyOrder(message)}>
                                                                    <Translation id="deny_preorder" defaultMessage="No, I need to make changes"/>
                                                                </Button>
                                                            </div>
                                                        )
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </div>
                                {/******************************** SEND INPUT ****************************************/}
                                <div className="preorder-communications-send">
                                    <div className="preorder-send-input">
                                        <div className="send-input-container" style={{border: `1px solid ${canSendMessages ? '#BABABA' : '#DADADA'}`}}>
                                            <div>
                                                <i className="far fa-paperclip px-2"
                                                    style={{cursor: canSendMessages ? 'pointer' : 'default', color: canSendMessages ? '#6A6A6A' : '#BABABA'}}
                                                    onClick={(e) => {
                                                        if (canSendMessages) {
                                                            this.setState({showFileType: true})
                                                        }
                                                        e.stopPropagation()
                                                    }}
                                                />
                                                {
                                                    <div style={{
                                                        display: this.state.showFileType ? 'block' : 'none',
                                                        position: 'absolute',
                                                        backgroundColor: 'white',
                                                        boxShadow: 'rgba(0,0,0,0.2) 0 5px 10px',
                                                        padding: '8px 16px',
                                                        zIndex: 999
                                                    }}
                                                    >
                                                        {this.fileOptions.map(fo => {
                                                            return (
                                                                <div onClick={() => {
                                                                    const docType = fo.value
                                                                    this.fileInputDialog.accept = ACCEPTED_EXT[docType].join()
                                                                    this.fileInputDialog.docType = docType
                                                                    this.fileInputDialog.click()
                                                                    this.setState({showFileType: false})
                                                                }}
                                                                    className="select-file-type d-flex"
                                                                >
                                                                    <div
                                                                        style={{width: '2em', textAlign: 'center'}}>
                                                                        {this.getFileTypeIcon(fo.value)}
                                                                    </div>
                                                                    <div>
                                                                        {fo.label}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>                                                
                                                }
                                            </div>
                                            <div style={{height: '100%', borderLeft: `1px solid ${canSendMessages ? '#BABABA' : '#DADADA'}`}}/>
                                            <input id="send_preorder_message_input"
                                                type="text"
                                                disabled={!canSendMessages}
                                                maxLength="500"
                                                className="send-message-input px-2"/>
                                        </div>
                                        <div style={{
                                            cursor: canSendMessages ? 'pointer' : 'default',
                                            fontWeight: '700',
                                            color: canSendMessages ? '#6A6A6A' : '#BABABA',
                                            margin: '0 8px'
                                        }}
                                            onClick={() => {if (canSendMessages) this.sendMessage()}}
                                        >
                                            <Translation id="send" defaultMessage="Send"/>
                                        </div>
                                </div>

                                <div className="d-flex mt-2"
                                     style={{
                                         columnGap:'4px',
                                         rowGap: '4px',
                                         flexWrap: 'wrap'
                                    }}
                                >
                                    {
                                        Object.entries(this.state.selectedFiles).map(([key, file]) =>
                                            <div style={{
                                                fontSize: '12px',
                                                display: 'flex',
                                                columnGap: '4px',
                                                alignItems: 'center',
                                                padding: '2px 4px',
                                                border: '1px solid #BABABA',
                                                borderRadius: '8px',
                                                backgroundColor: '#DADADA'
                                            }}>
                                                {this.getFileTypeIcon(file.fileType)}
                                                {file.file.name}
                                                <i className="fas fa-times-circle ml-2" onClick={() => this.delFile(key)} style={{cursor:'pointer'}}/>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 preorder-sidebar-right">
                            <div className="preorder-summary-card">
                                <div className="preorder-summary-title">
                                    <h5><Translation id="pre_order_summary" defaultMessage="Pre-order summary" /></h5>
                                </div>
                                <div className="preorder-summary-container">
                                    <PreorderSummary {...this.props}></PreorderSummary>
                                </div>
                            </div>
                            <div className="preorder-docs-attachments">
                                <div className="preorder-attach-title">
                                    <h5><Translation id="attached-files" defaultMessage="Attached files"/></h5>
                                </div>                               
                                <div className="preorder-attach-files">
                                    <div className="preorder-attach-container">
                                        {
                                            this.props.preOrder.files.map((file) => {
                                                return (
                                                    <React.Fragment>
                                                        <div className="preorder-attach-content">
                                                            <i className="fal fa-file">
                                                                <div className="attach-icon">
                                                                    {this.getFileTypeIcon(INVERSE_FILE_TYPES_MAP[file.file_type])}
                                                                </div>
                                                            </i>
                                                            <div className="attach-file-name-container">
                                                                <div className="file-name">
                                                                    <div className="file-name-content">
                                                                        <strong>{file.file_name}</strong>
                                                                        <div><Translation id="type" defaultMessage="Type"/>
                                                                            : {this.getFileTypeIntl(file.file_type)}
                                                                        </div>
                                                                        <div>{moment(file.updated_at).format('L')}</div>
                                                                    </div>
                                                                </div>
                                                                <i className="fal fa-download" onClick={() => this.props.downloadFile(file)} />
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Spinner isLoading={this.props.isLoading}></Spinner>
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        downloadFile: (file) => dispatch(downloadPreorderFile(file)),
        markMessagesAsReaded: (preorderId) => dispatch(markMessagesAsReaded(preorderId)),
        cancelPreorder: (preorderId) => dispatch(cancelPreorder(preorderId))
    }
}

export default injectIntl(connect(
    null,
    mapDispatchToProps
)(PreOrdersCtsDetails))
