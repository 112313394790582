import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import ShippingMethodsContainer from '../shipping-method-selector/ShippingMethodsContainer'
import Alerts from '../alerts/Alerts'
import LocalizedLink from '../global/LocalizedLink'
import IncentivesMktools from "./IncentivesMktools";
import IncentivesWeights from "./IncentivesWeights";

const Incentives = ({
    current,
    goalMin,
    goalMax,
    unit,
    shippingMethod,
    intl,
    isLowesCenter,
    isMktoolCenter,
    total,
    maxBudget,
    mktoolsBudgetWasted,
    coin,
    isIkeaCenter,
    center,
    isCartMallOnly,
    salesOrg,
}) => {
    const message = shippingMethod === 'SINGLE_SLAB' ? 'for_full_truck' : 'min_to_checkout'
    const difCurrent = current <= goalMax ? 0 : current - goalMax
    const difCurrentForBudget = maxBudget * 0.15 // total <= maxBudget + 20000 ? 20000 : total - maxBudget

    const errorMessage = difCurrent !== 0 ? 'MAX_WEIGHT_EXCEEDED' : null
    const haveWeights = ['FULL_TRUCK', 'DIRECT_TRUCK', 'CONTAINER', 'SINGLE_SLAB', 'ENDI_CONTAINER', 'ENDI_TRUCK']

    if (shippingMethod === 'CONTAINER' && center == 7500) {
        return null
    }
    return (
        <div className="incentive-container">
            <div className="container">
                <div className={shippingMethod === 'CONTAINER' ? 'incentive mini row full-container' : 'incentive mini row'}>
                    <div style={{display: 'flex'}}>
                        {haveWeights.includes(shippingMethod) && !isIkeaCenter && !(isCartMallOnly && ['2011', '2032'].includes(salesOrg)) ? (
                            <div className="incentive-container__checkout" style={{display: "flex", flexDirection: "column"}}>
                                <div className={'incentive-info-text'}>
                                    {errorMessage ? (
                                        <Alerts
                                            alert={errorMessage}
                                            values={{
                                                shippingMethod: intl.formatMessage({ id: shippingMethod }).toLowerCase()
                                            }}
                                            status={404}
                                        />
                                    ) : current >= goalMin ? (
                                        <span className="current">
                                            <Translation id="you_currently_have" defaultMessage="You currently have" />{' '}
                                            <b>
                                                {current} {unit.toLowerCase()}
                                            </b>{' '}
                                            <Translation id="in_your_cart" defaultMessage="in your cart" />
                                        </span>
                                    ) : (
                                        <div>
                                            {' '}
                                            <span className="current">
                                                <Translation id="you_currently_have" defaultMessage="You currently have" />{' '}
                                                <b>
                                                    {current} {unit.toLowerCase()}
                                                </b>{' '}
                                                <Translation id="in_your_cart" defaultMessage="in your cart" />
                                            </span>{' '}
                                            .{' '}
                                            <span>
                                                <Translation id="you_must_reach" defaultMessage="You must reach" />{' '}
                                                    <b>
                                                        {goalMin} {unit.toLowerCase()}
                                                    </b>{' '}
                                                <Translation id={errorMessage ? errorMessage : message} />
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div style={{flex: 1}}/>
                                {!isMktoolCenter &&
                                    <React.Fragment>
                                        <IncentivesWeights isMobile={true} shippingMethod={shippingMethod} goalMax={goalMax} goalMin={goalMin} unit={unit} haveWeights={haveWeights} isLowesCenter={isLowesCenter} isIkeaCenter={isIkeaCenter} isMktoolCenter={isMktoolCenter} difCurrent={difCurrent} current={current}/>
                                        <div className={'incentive-buttons'} style={{display: "flex", flexWrap: "wrap", gap: "1em"}}>
                                            <div className='d-flex'>
                                                <LocalizedLink style={{ border: '1px solid black', padding: '0.5rem 1rem', cursor: 'pointer' }} routeId="ROUTE_CATALOG" queryString="?type[0]=TABLA" params={{ outlet: '' }} gtmLabel="ec_funnel_compra_carrito_añadir">
                                                    <Translation id={'add_more_items'} defaultMessage={'Add more items'} />
                                                </LocalizedLink>
                                            </div>
                                            {!isLowesCenter && (
                                                <div className="incentive-select-shipping"><ShippingMethodsContainer v2={true}/></div>
                                            )}
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        ) : null}

                        {!isMktoolCenter &&
                            <IncentivesWeights shippingMethod={shippingMethod}
                                               goalMax={goalMax}
                                               goalMin={goalMin}
                                               unit={unit}
                                               haveWeights={haveWeights}
                                               isLowesCenter={isLowesCenter}
                                               isIkeaCenter={isIkeaCenter}
                                               isMktoolCenter={isMktoolCenter}
                                               difCurrent={difCurrent} current={current}
                            />
                        }

                        {isMktoolCenter && (
                            <IncentivesMktools mktoolsBudgetWasted={mktoolsBudgetWasted}
                                               maxBudget={maxBudget}
                                               difCurrentForBudget={difCurrentForBudget}
                                               total={total} coin={coin}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default injectIntl(Incentives)
