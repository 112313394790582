import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import Button from '../global/Button'
import Translation from '../global/Translation'


class ElaborationType extends Component {

    render () {
        const {title, icon} = this.props

        return (
            <div className={'cut_to_size_elaboration_type v2'}>

                <img src={icon} alt={title} className={"elaboration-type-icon"}/>

                <h5 className="cts-title questa-bold mt-2 mt-md-3">
                    { title }
                </h5>

                <Button size="small" customClass='bt-cts-v2' dataQas={'cut_to_size_custom_products-' + title.split(' ').join('-').toLowerCase()}>
                    <Translation id="customize" defaultMessage="Customize" />
                </Button>
            </div>
        )
    }
}

export default injectIntl(ElaborationType)