import React from 'react'
import Translation from '../global/Translation'

import Select from 'react-select'

import Button from '../global/Button'
import groupBy from 'json-groupby'
import { injectIntl } from 'react-intl'
import * as analytics from '../../analyticsEvents'
import axios from 'axios'
import FileDownload from 'js-file-download'
import FormatCSVModal from '../global/FormatCSVModal'
import Modal from '../global/Modal'

class LotsFilter extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            emailPromptOpen: false,
            showCSVFormat: false,
            selectedOption: ''
        }

        this.promptEmail = this.promptEmail.bind(this)
        this.downloadPdf = this.downloadPdf.bind(this)
    }

    promptEmail() {
        this.setState({ emailPromptOpen: true })
    }

    closeFormatCSVModal(format) {
        this.setState({ showCSVFormat: false })

        if (!format) {
            return
        }

        const { intl } = this.props
        const fields = ['quarryBlock', 'shade', 'bundleId', 'lotId', 'length', 'width', 'quality', 'qty', 'location', 'qualityOutlet']
        analytics.downloadLots(format, this.props.commonParamsAnalytics)
        const header = fields.map(field => {
            return intl.formatMessage({ id: `${field}` })
        })

        const FileDownload = require('js-file-download')
        axios
            .get(
                process.env.REACT_APP_API_HOST +
                    `/catalog/${this.props.center}/lots/${this.props.productId}/csv/?format=${format}&lang=${this.props.language}${
                        this.props.outlet ? '&outlet=1' : ''
                    }&fields=${encodeURIComponent(fields.join(','))}&header=${encodeURIComponent(header.join(','))}`,
                { headers: { Authorization: `Bearer ${this.props.token}` }, 'Content-Type': 'text/plain', responseType: 'blob' }
            )
            .then(response => {
                FileDownload(response.data, `lots.${format}`)
            })
    }

    downloadPdf() {
        if (!this.inputEmail.value) {
            return
        }

        this.setState({ emailPromptOpen: false })
        analytics.downloadLots('PDF', this.props.commonParamsAnalytics)

        const email = this.inputEmail.value

        const { intl } = this.props
        const fields = ['image', 'quarryBlock', 'shade', 'bundleId', 'lotId', 'length', 'width', 'quality', 'qty', 'location', 'qualityOutlet']
        const header = fields.map(field => {
            return intl.formatMessage({ id: `${field}` })
        })

        const url = `${process.env.REACT_APP_API_HOST}/catalog/${this.props.center}/lots/${this.props.productId}/pdf/?lang=${this.props.language}${
            this.props.outlet ? '&outlet=1' : ''
        }&fields=${encodeURIComponent(fields.join(','))}&header=${encodeURIComponent(header.join(','))}&to=${encodeURIComponent(email)}`
        // }&fields=${encodeURIComponent(fields.join(','))}&header=${encodeURIComponent(header.join(','))}&to=${encodeURIComponent(email)}&authToken=${this.props.token}`

        fetch(url, {
            headers: {Authorization: `Bearer ${this.props.token}`}
        })
    }

    quarryBlockHandleChange = selectedOption => {
        this.props.handleFilterChange(selectedOption, 'quarryBlock')
        this.setState({ selectedOption })
    }

    qualityHandleChange = selectedOption => {
        this.props.handleFilterChange(selectedOption, 'quality')
        this.setState({ selectedOption })
    }

    shadeHandleChange = selectedOption => {
        this.props.handleFilterChange(selectedOption, 'shade')
        this.setState({ selectedOption })
    }

    locationHandleChange = selectedOption => {
        this.props.handleFilterChange(selectedOption, 'location')
        this.setState({ selectedOption })
    }

    render() {
        const { onlyLots, isAdmin, search, searchValue, flush, intl } = this.props
        const groupByQuarryBlock = onlyLots !== undefined ? groupBy(onlyLots, ['quarryBlock'], ['lotId']) : null
        const groupByShade = onlyLots !== undefined ? groupBy(onlyLots, ['shade'], ['lotId']) : null
        const groupByQuality = onlyLots !== undefined ? groupBy(onlyLots, ['quality'], ['lotId']) : null
        const groupByLocation = onlyLots !== undefined ? groupBy(onlyLots, ['location'], ['lotId']) : null

        const { selectedOption } = this.state
  	    const selectedValue = selectedOption && selectedOption.value

        const searchBar = <input type="text" placeholder={intl.formatMessage({ id: 'search' })} value={searchValue} onChange={search()} />

        return (
            <div>
                <div className="filters">
                    <div className="dropdown-filters">
                        <div className="left-side">
                            <div className="filter">
                                <Select
                                    placeholder={this.props.intl.formatMessage({ id: 'quarryBlock' })}
                                    options={
                                        groupByQuarryBlock &&
                                        Object.keys(groupByQuarryBlock).map(option => ({
                                            label: option + ' (' + groupByQuarryBlock[option].lotId.length + ')',
                                            value: option
                                        }))
                                    }
                                    value={selectedValue}
                                    onChange={this.quarryBlockHandleChange}
                                />
                            </div>

                            {!(this.props.original.brand === 'SILESTONE' || this.props.original.brand === 'DEKTON' || this.props.original.brand === 'XERON') && (
                                <div className="filter">
                                    <Select
                                        placeholder={this.props.intl.formatMessage({ id: 'quality' })}
                                        options={
                                            groupByQuality &&
                                            Object.keys(groupByQuality).map(option => ({
                                                label: option + ' (' + groupByQuality[option].lotId.length + ')',
                                                value: option
                                            }))
                                        }
                                        value={selectedValue}
                                        onChange={this.qualityHandleChange}
                                    />
                                </div>
                            )}

                            {(isAdmin || this.props.impersonatedBy) && (
                                <div className="filter">
                                    <Select
                                        placeholder={this.props.intl.formatMessage({ id: 'shade' })}
                                        options={
                                            groupByShade &&
                                            Object.keys(groupByShade).map(option => ({
                                                label: option + ' (' + groupByShade[option].lotId.length + ')',
                                                value: option
                                            }))
                                        }
                                        value={selectedValue}
                                        onChange={this.shadeHandleChange}
                                    />
                                </div>
                            )}

                            {(isAdmin || this.props.impersonatedBy) && (
                                <div className="filter">
                                    <Select
                                        placeholder={this.props.intl.formatMessage({ id: 'location' })}
                                        options={
                                            groupByLocation &&
                                            Object.keys(groupByLocation).map(option => ({
                                                label: option + ' (' + groupByLocation[option].lotId.length + ')',
                                                value: option
                                            }))
                                        }
                                        value={selectedValue}
                                        onChange={this.locationHandleChange}
                                    />
                                </div>
                            )}

                            <div className="search-bar-filters-responsive">
                                <div className="lots-search-bar-responsive">{searchBar}</div>
                            </div>
                        </div>

                        <div className="filter-clear-btn">
                            <button onClick={this.props.clearFilters}>
                                <Translation id="clear_all" defaultMessage="Reset filters" />
                            </button>
                            {/*RELOAD*/}
                            {/*<Button className={"ml-3"} inline size="small" onClick={flush}>*/}
                            {/*    <i className="fa fa-sync" />*/}
                            {/*</Button>*/}
                        </div>
                    </div>

                    <div className="search-bar-filters">
                        <div className="lots-search-bar-desktop">{searchBar}</div>

                        <div className="dropdown dropdown-btn">
                            <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="lots-filter-btn-download">
                                <Translation id="download" defaultMessage="Download" />
                                <i class="fas fa-caret-down"></i>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <button onClick={() => this.setState({ showCSVFormat: true })}>
                                    <Translation id="download_excel" defaultMessage="Download Excel" />
                                </button>
                                <button onClick={this.promptEmail}>
                                    <Translation id="download_pdf" defaultMessage="Download PDF" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showCSVFormat && <FormatCSVModal onClose={format => this.closeFormatCSVModal(format)} token={() => this.props.token} />}
                {this.state.emailPromptOpen && (
                    <Modal
                        title={<Translation id="emailPromptModalTitle" defaultMessage="We are preparing your listing" />}
                        onClose={() => this.setState({ emailPromptOpen: false })}
                        footer={
                            <Button onClick={this.downloadPdf} inverted size="medium">
                                <Translation id="send" defaultMessage="Send" />
                            </Button>
                        }
                    >
                        <p>
                            <Translation
                                id="emailPromptModalBody"
                                defaultMessage="This process may take several minutes. Please, give us your email so we can send it once it is ready."
                            />
                        </p>
                        <div className="form-group">
                            <b>
                                <small>
                                    <Translation id="email" defaultMessage="Email" />
                                </small>
                            </b>
                            <input ref={ref => (this.inputEmail = ref)} type="email" className="form-control" aria-describedby="emailHelp" placeholder="Email" />
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}

export default injectIntl(LotsFilter)
