import React from 'react'
import { connect } from 'react-redux'
import {
    getError, getInvoices,
    getOpenDocDetails,
    getOrder, getOrderDetailsHead,
    getOrderDetailsLoading,
    getOrderStatusMap,
    getPackingList,
    getStatusMap
} from '../../store/orders/reducers'
import { injectIntl } from 'react-intl'
import {
    clearOrderDetails,
    fetchOrderDetails,
    fetchPackingList, getFileByUrl, rejectOrder,
} from '../../store/orders/actions'
import { withRouter } from 'react-router-dom'
import { dispatchPushURL } from '../../store/ui/actions'
import { getToken, isEmserUser } from '../../store/login/reducers'
import { getLanguage } from '../../store/ui/reducers'
import { getShippingAddresses } from '../../store/shipping-addresses/reducers'
import OrderDetailsV2 from "./OrderDetailsV2";

const mapStateToProps = (state, { match }) => {
    return {
        packingList: getPackingList(state),
        order: getOrder(state),
        orderDetailsHead: getOrderDetailsHead(state),
        openDocDetails: getOpenDocDetails(state),
        isLoading: getOrderDetailsLoading(state),
        token: getToken(state),
        lang: getLanguage(state),
        error: getError(state),
        reservationId: match.params.open,
        shippingAddresses: getShippingAddresses(state),
        statusMap: getStatusMap(state),
        orderStatusMap: getOrderStatusMap(state),
        invoices: getInvoices(state),
        isEmserUser : isEmserUser(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchOrderDetails: reservationId => {
            dispatch(fetchOrderDetails(reservationId))
        },
        fetchPackingList: reservationId => {
            dispatch(fetchPackingList(reservationId))
        },
        clearOrderDetails: reservationId => {
            dispatch(clearOrderDetails(reservationId))
        },
        rejectOrder: (reservationId, orderId) => {
            dispatch(rejectOrder(reservationId, orderId))
        },
        getFileByUrl: url => dispatch(getFileByUrl(url)),
        redirect: url => {
            dispatch(dispatchPushURL(url))
        }
    }
}

const OrderDetailsContainer = withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(OrderDetailsV2)
    )
)

export default OrderDetailsContainer
