import React from 'react'
import Translation from '../global/Translation'
import LocalizedLink from '../global/LocalizedLink'
import iconLogoutV2 from '../../assets/img/icons/logoutv2.svg'
import MenuContainer from './MenuContainer'

export default class ResponsiveMenu extends React.Component {
    render() {
        
        const userProfile2 = (
            <div className="d-flex flex-column menu-configuration my-account-header-responsive">
                <div className="d-flex justify-content-between align-items-center" style={{ padding: '0.5rem 1rem', fontWeight: '300', borderBottom: '1px solid #D8D8D8' }}>
                    <div>
                        <div className="mr-3">
                            <i className="fal fa-user fa-lg" />
                        </div>
                        <div>
                            <span>
                                {this.props.name} {this.props.employeeId && <strong>{this.props.employeeId}</strong>}
                                <br></br>
                                <span className="txt-username">{this.props.userType === 'SHOP' || this.props.userType === 'LOWES' ? this.props.email : this.props.username}</span>
                            </span>
                        </div>
                    </div>
                    <div className="close-button-userprofile">
                        <span
                            onClick={() => {
                                if (!this.props.showMenu) this.props.onToggleMenu(this.props.showMenu)
                                if (this.props.showMenu && this.props.showPersonalData) {
                                    this.props.onToggleMenu(this.props.showMenu)
                                }
                                this.props.onTogglePersonalData(this.props.showPersonalData)
                            }}
                        >
                            <i class="fas fa-times"></i>
                        </span>
                    </div>
                </div>
                <br></br>

                {this.props.canRouteConfiguration && (
                    <LocalizedLink
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('configuration') !== -1 ? 'active' : ''
                        } linkMenu`}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'configuration', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                    >
                        {this.props.isLowesCenter ? (
                            <Translation id="store_profile" defaultMessage="Store Profile" />
                        ) : (
                            <Translation id="configuration" defaultMessage="Configuration" />
                        )}
                        <span className="arrow-right">
                            <i className="fal fa-chevron-right"></i>
                        </span>
                    </LocalizedLink>
                )}

                {this.props.userType === 'SHOP' && (
                    <React.Fragment>
                        <LocalizedLink
                            routeId="ROUTE_RESERVATIONS"
                            params={{ unassigned: 'unassigned' }}
                            className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} linkMenu`}
                        >
                            <li className="nav-item">
                                <Translation id="unassigned_reservations" defaultMessage="Unassigned reservations" />{' '}
                                {this.props.countReservations ? (
                                    <span className="badge badge-pill badge-secondary">
                                        <span>{this.props.countReservations}</span>
                                    </span>
                                ) : null}
                            </li>
                            <span className="arrow-right">
                                <i className="fal fa-chevron-right"></i>
                            </span>
                        </LocalizedLink>
                        <LocalizedLink
                            routeId="ROUTE_RESERVATIONS"
                            params={{ unassigned: '' }}
                            className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} linkMenu`}
                        >
                            <li className="nav-item">
                                <Translation id="reservations_history" defaultMessage="Reservations history" />
                            </li>
                            <span className="arrow-right">
                                <i className="fal fa-chevron-right"></i>
                            </span>
                        </LocalizedLink>
                    </React.Fragment>
                )}
                {(this.props.userType === 'CUSTOMER' || this.props.isLowesCenter) && (
                    <LocalizedLink
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('orders') !== -1 && window.location.pathname.indexOf('recurring-orders') === -1 && window.location.pathname.indexOf('pre-orders-cts') === -1 ? 'active' : ''
                        } linkMenu`}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'orders', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                    >
                        <Translation id="orders_history" defaultMessage="Orders history" />
                        <span className="arrow-right">
                            <i className="fal fa-chevron-right"></i>
                        </span>
                    </LocalizedLink>
                )}

                {this.props.userType === 'CUSTOMER' && this.props.hasPreOrder && (
                    <LocalizedLink
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('pre-orders-cts') !== -1 ? 'active' : ''
                        } linkMenu`}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'pre-orders-cts', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                    >
                        <Translation id="pre_orders_cts" defaultMessage="Pre Orders CTS" />
                        <span className="arrow-right">
                            <i className="fal fa-chevron-right"></i>
                        </span>
                    </LocalizedLink>
                )}

                {/*(this.props.userType === 'CUSTOMER' || this.props.isLowesCenter) && this.props.userType === 'CUSTOMER' && this.props.isIkeaCenter && (
                <LocalizedLink
                    className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${window.location.pathname.indexOf('orders') !== -1 && this.props.match.params.open === 'open' ? 'active' : ''} linkMenu`}
                    routeId="ROUTE_PROFILE"
                    params={{ filter: 'orders', open: 'open' }}
                    onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}

                >
                    <Translation id="open_orders" defaultMessage="Open orders" />
                    <span className='arrow-right'><i className="fal fa-chevron-right"></i></span>
                </LocalizedLink>
            )*/}
                {this.props.canRouteOfferStatusHistory && (
                    <LocalizedLink
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('offers') !== -1 ? 'active' : ''
                        } linkMenu`}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'offers', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                    >
                        <Translation id="quotes" defaultMessage="Quotes" />
                        <span className="arrow-right">
                            <i className="fal fa-chevron-right"></i>
                        </span>
                    </LocalizedLink>
                )}
                {this.props.isMktoolsCenter && this.props.userType === 'ADMIN' && (
                    <LocalizedLink
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('transers') !== -1 ? 'active' : ''
                        } linkMenu`}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'transfers', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                    >
                        <Translation id="transfer_orders" defaultMessage="Transfer orders" />
                        <span className="arrow-right">
                            <i className="fal fa-chevron-right"></i>
                        </span>
                    </LocalizedLink>
                )}

                {this.props.userType === 'CUSTOMER' && !this.props.isMktoolsCenter && !this.props.isIkeaCenter && !this.props.isLowesCenter && (
                    <LocalizedLink
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('recurring-orders') !== -1 ? 'active' : ''
                        } linkMenu`}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'recurring-orders', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                        gtmLabel="ec_volver_a_comprar"
                    >
                        <Translation id="recurring_orders_access" defaultMessage="Recurring orders access" />
                        <span className="arrow-right">
                            <i className="fal fa-chevron-right"></i>
                        </span>
                    </LocalizedLink>
                )}

                {this.props.userType === 'ADMIN' && this.props.isMktoolsCenter && this.props.isMktoolsAdmin && (
                    <React.Fragment>
                        <LocalizedLink
                            className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                                window.location.pathname.indexOf('mkt-admin-products') !== -1 ? 'active' : ''
                            } linkMenu`}
                            routeId={'ROUTE_MKT_ADMIN_PRODUCTS'}
                            params={{ filter: 'mkt-admin-products', open: '' }}
                            onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                        >
                            <Translation id="mkt_admin_products" defaultMessage="Mktools admin" />
                            <span className="arrow-right">
                                <i className="fal fa-chevron-right"></i>
                            </span>
                        </LocalizedLink>
                        <LocalizedLink
                            className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                                window.location.pathname.indexOf('mkt-admin-colors') !== -1 ? 'active' : ''
                            } linkMenu`}
                            routeId={'ROUTE_MKT_ADMIN_COLORS'}
                            params={{ filter: 'mkt-admin-colors', open: '' }}
                            onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                        >
                            <Translation id="mkt_admin_colors" defaultMessage="Admin colors" />
                            <span className="arrow-right">
                                <i className="fal fa-chevron-right"></i>
                            </span>
                        </LocalizedLink>
                    </React.Fragment>
                )}

                <LocalizedLink
                    className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                        window.location.pathname.indexOf('segmentation') !== -1 ? 'active' : ''
                    } linkMenu`}
                    routeId="ROUTE_PROFILE"
                    params={{ filter: 'segmentation', open: '' }}
                    onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                >
                    <Translation id={'segmentation'} defaultMessage="Segmentation" />
                    <span className="arrow-right">
                        <i className="fal fa-chevron-right"></i>
                    </span>
                </LocalizedLink>
                <LocalizedLink
                    className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                        window.location.pathname.indexOf('products-favourite') !== -1 ? 'active' : ''
                    } linkMenu`}
                    routeId="ROUTE_PROFILE"
                    params={{ filter: 'products-favourite', open: '' }}
                    onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                >
                    <Translation id="products_favourite" defaultMessage="Favourite Products" />
                    <span className="arrow-right">
                        <i className="fal fa-chevron-right"></i>
                    </span>
                </LocalizedLink>

                {this.props.isUserMallAuthorized && this.props.isMallAdmin && (
                    <LocalizedLink
                        routeId="ROUTE_LANDING_MANAGER"
                        queryString=""
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('landing-manager') !== -1 ? 'active' : ''
                        } linkMenu`}
                        params={{ filter: 'landing-manager', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                    >
                        <Translation id="lndmngr" defaultMessage="Landing Manager" />
                        <span className="arrow-right">
                            <i className="fal fa-chevron-right"></i>
                        </span>
                    </LocalizedLink>
                )}

                {this.props.isCampaignAdmin && (
                    <LocalizedLink
                        routeId={'ROUTE_PROMOTIONS_LIST'}
                        className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} ${
                            window.location.pathname.indexOf('promotions-list') !== -1 ? 'active' : ''
                        } linkMenu`}
                        params={{ filter: 'promotions-list', open: '' }}
                        onClick={() => this.props.onTogglePersonalData(this.props.showPersonalData)}
                    >
                        <Translation id="admin_campaigns" defaultMessage="Admin campaigns" />
                        <span className="arrow-right">
                            <i className="fal fa-chevron-right"></i>
                        </span>
                    </LocalizedLink>
                )}

                <span
                    className={`${this.props.withButtons ? 'configuration-btn btn btn-secondary' : ''} linkMenu linkLogout`}
                    onClick={() => {
                        if (this.props.msalContext.accounts && this.props.msalContext.accounts[0]) {
                            this.props.msalContext.instance.logoutRedirect()
                        }
                        this.props.onLogout()
                    }}
                >
                    <div className="d-flex">
                        <div className="icon" style={{ marginRight: '8px' }}>
                            <img alt="" src={iconLogoutV2} aria-hidden="true" />
                        </div>
                        <Translation id="logout" defaultMessage="Logout" />
                    </div>
                </span>
            </div>
        )
        return (
            <nav id="mobile-main-menu" className="d-xl-none navbar navbar-expand-xl navbar-light">
                <div className="navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">                            
                        {this.props.showPersonalData && userProfile2}
                        {
                            !this.props.showPersonalData &&
                            < MenuContainer comp = {true} responsive={true} />
                        }
                        
                    </ul>
                </div>
            </nav>
        )
    }
}
