import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { showLanguageSelector, getAvailableLands, getLanguage } from '../../store/ui/reducers'
import * as ui from '../../store/ui/actions'
import logo from '../../assets/img/e.cosentino.svg'
import Cookies from 'js-cookie'
import { push } from 'react-router-redux'
import chevronDown from '../../assets/img/icons/chevron-down.svg'

const mapStateToProps = (state, { match }) => {
    return {
        showLanguageSelector: showLanguageSelector(state),
        markets: getAvailableLands(state),
        localeSelected: getLanguage(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closeLanguageModal())
        },
        onShowSelector: seg => {
            dispatch(ui.showLanguageModal())
        },
        push: url => {
            dispatch(push(url))
        },
        setLocale: locale => {
            dispatch(ui.setLanguage(locale))
        }
    }
}

class LanguageSelector extends React.Component {

    changeLocale = locale => {
        const {push, setLocale} = this.props
        Cookies.set('defaultLanguage', locale)

        let url = locale === 'en-US' ? '' : ('/' + locale)
        if (locale === 'en-US') url += '/'
        setLocale(locale)
        push(url)
        this.props.onClose()
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.location !== nextProps.localeSelected) {
            return true
        }
    }

    render() {
        const code = this.props.intl.messages.landCode
        console.log('CODE', this.props.intl)

        const selectedMarket = this.props.markets.find(option => this.props.localeSelected === option.localecode)
        return (
            <div>
                <div onClick={this.props.onShowSelector} className="selected-language" key={this.props.key}>
                    <span className="d-lg-inline">
                        <React.Fragment>
                            <strong style={{ textTransform: "uppercase" }}>{selectedMarket.localecode.split("-")[0]} ({selectedMarket.code})</strong>
                            <img alt="" src={chevronDown} />
                        </React.Fragment>
                        
                    </span>
                </div>
                <div className={'market-selector text-center ' + (this.props.showLanguageSelector ? 'open' : '')}>
                    <div className="container-fluid bg-gray-lighter">
                        <div className="row">
                            <div className="col-sm-8 col-md-6 col-lg-4 col-centered">
                                <header className="logo-cosentino-lan text-uppercase">
                                    <img src={logo} alt=""/>
                                    <br/>
                                    <Translation id="select_your_language" defaultMessage="Select your language"/>
                                </header>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid bg-gray-light text-center p-2 mb-1">
                        <div className="row">
                            <div className="col-sm-10 col-centered market-selector-info">
                                <span className={`flag-icon flag-icon-${code} mx-1`}/>
                                <b>
                                    <Translation id="localeName" defaultMessage="English (United States)"/>
                                </b>
                            </div>
                        </div>
                    </div>

                    <div className="container country-list">
                        <div className="row">
                            <div className="col-md-11 col-lg-10 col-centered">
                                <ul className="continentes">
                                    <li className="selected">
                                        <ul className="row">
                                            {this.props.markets.map(land => {
                                                return (
                                                    <li className="col-sm-3" key={land.localecode}>
                                                        <a
                                                            href="javascript:void(0)"
                                                            onClick={() => this.changeLocale(land.localecode)}
                                                            className="market-link__pais"
                                                        >
                                                            <span className={`flag-icon flag-icon-${land.code} mx-1`}/>
                                                            {land.name}
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <button onClick={this.props.onClose} className="btn btn-cerrar"
                            data-role="market-selector-switcher">
                        Cerrar
                    </button>
                </div>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LanguageSelector)
)
