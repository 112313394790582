import { getUserLandings } from './../../store/landing/reducers';
import { connect } from 'react-redux'

import {
    getShippingMethod,
    canOnlyViewOutlet,
    getCenterName,
    hasPromoActivated,
    getSelectedCenter,
    getIsIkeaCenter,
    getPromoBanners,
    getIsVirtualVisitEnabled,
    hasPromotionLandingActivated,
    getWarehoseCenterId,
    getShowroomCenterId,
    canViewMktools,
    getHQCenterId,
    getLowesCenterId,
    isEuropeOrIberia,
    isUsaOrCanada, isAustralianCenter
} from '../../store/centers/reducers'
import { changeCenter } from '../../store/centers/actions'
import { getIsLogged, getSalesOrg, getUserType, getIsLogging, getVirtualVisitURL, canViewRockMasterPromotion, canViewEarthicPromotion, canViewHybriqPromotion, canViewSilestonePromotionXM, getClientType, getIsMallPilotUser, getTypeClient,getTestAB } from '../../store/login/reducers'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'
import { getLanguage, getMaintenanceScheduled, getMaintenanceScheduledText } from '../../store/ui/reducers'
import { dispatchPushURL, fetchMaintenance } from '../../store/ui/actions'
import { getBannerDescription, getBannerTitle, getPromotionHomeBanner, getPromotionLateralBanner, getPromotionLink } from '../../store/promotions/reducers'
import moment from 'moment/moment'
import HomeV2 from "./HomeV2";

const mapStateToProps = (state, { match }) => {
    return {
        shippingMethod: getShippingMethod(state),
        isLogged: getIsLogged(state),
        canOnlyViewOutlet: canOnlyViewOutlet(state),
        userPreferences: getProfileConfiguration(state),
        center: getCenterName(state),
        canViewMktools: canViewMktools(state),
        salesOrg: getSalesOrg(state),
        userType: getUserType(state),
        typeClient: getTypeClient(state),
        testAB: getTestAB(state),
        hasPromoActivated: hasPromoActivated(state),
        hasPromotionLandingActivated: hasPromotionLandingActivated(state),
        selectedCenter: getSelectedCenter(state),
        isIkeaCenter: getIsIkeaCenter(state),
        isLogging: getIsLogging(state),
        locale: getLanguage(state),
        homeBanner: getPromotionHomeBanner(state),
        promotionLateralBanner: getPromotionLateralBanner(state),
        promotionLink: getPromotionLink(state),
        promoBanners: getPromoBanners(state),
        virtualVisitEnabled: getIsVirtualVisitEnabled(state),
        URLVirtualVisit: getVirtualVisitURL(state),
        maintenanceScheduled: getMaintenanceScheduled(state),
        maintenanceScheduledText: getMaintenanceScheduledText(state),
        promotionBannerTitle: getBannerTitle(state),
        promotionBannerDescription: getBannerDescription(state),
        warehoseCenterId: getWarehoseCenterId(),
        showroomCenterId: getShowroomCenterId(),
        HQCenterId: getHQCenterId(),
        LowesCenterId: getLowesCenterId(),
        userLandings: getUserLandings(state),
        isMallPilotUser: getIsMallPilotUser(state),
        showBannerPromo: new moment() < moment('2022-11-18 18:00') && isEuropeOrIberia(state),
        isUsaOrCanada: isUsaOrCanada(state),
        canViewRockMasterPromotion: canViewRockMasterPromotion(state),
        isWarehouse: getSelectedCenter(state) == getWarehoseCenterId(),
        canViewEarthicPromotion: canViewEarthicPromotion(state),
        canViewHybriqPromotion: canViewHybriqPromotion(state),
        isAustralianCenter: isAustralianCenter(state),
        canViewSilestonePromotionXM: canViewSilestonePromotionXM(state),
        // showBannerPromo: false
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeCenter: centerId => {
            dispatch(changeCenter(centerId))
        },
        goLowes: (userType, intl) => {
            userType === 'ADMIN'
                ? dispatch(dispatchPushURL(intl.formatMessage({ id: 'ROUTE_CATALOG_LOWES_ADMIN' }).replace(':filter', 'home')))
                : dispatch(dispatchPushURL(intl.formatMessage({ id: 'ROUTE_CATALOG_LOWES' }).replace(':filter', 'home')))
        },
        goIkea: intl => {
            dispatch(dispatchPushURL(intl.formatMessage({ id: 'ROUTE_CATALOG' }).replace(':outlet?', '')))
        },
        fetchMaintenance: () => {
            dispatch(fetchMaintenance())
        }        
    }
}

const HomeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeV2)

export default HomeContainer
