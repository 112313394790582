import React from 'react'
import backgroundImage from '../../../assets/img/banner-dekton/dekton_894x470.png'
import dekton from '../../../assets/img/banner-dekton/dekton.png'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getLanguage } from '../../../store/ui/reducers'
import Translation from '../../global/Translation'

const mapStateToProps = (state, { match }) => {
    return {
        language: getLanguage(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}
class BannerDekton extends React.Component {
    render() {


        return (
            <React.Fragment>
                {
                    !this.props.squared &&
                    <div className="banner_dekton">
                        <div className='logo-dekton'>
                            <img src={dekton} alt="logo-dekton" />
                        </div>

                        <div className='dekton-texts'>
                            <h1><Translation id="dekton_title" defaultMessage="New White Colours formulations" /></h1>
                            <h2><Translation id="dekton_desc" defaultMessage="We have improved the Dekton white colours to have better performance in the processing." /></h2>
                            <div>
                                <a target={"_blank"} href='https://cosentino.app.box.com/s/ff2j2a3bru3rqtc3112y2zks8oum7x1p?utm_campaign=New%20features_CosentinoWe&utm_medium=email&utm_source=diggest&utm_content=Ads'><Translation id="see_colors" defaultMessage="See Colours" /><i className="fal fa-chevron-right" /> </a>
                                <a target={"_blank"} href='https://cosentino.app.box.com/s/u0slbxoymyu16ykgic4i4eraxk1lrnhn?utm_campaign=New%20features_CosentinoWe&utm_medium=email&utm_source=diggest&utm_content=Ads'><Translation id="watch_video" defaultMessage="Watch Video" /><i className="fal fa-chevron-right" /> </a>
                            </div>
                        </div>
                        <div className='dekton-img' style={{ backgroundImage: `url(${backgroundImage})` }}>
                            <span><Translation id="new_formulation" defaultMessage="New Formulation" /></span>
                        </div>
                    </div>

                }
                {
                    this.props.squared &&
                    <div className="banner_dekton-responsive" style={this.props.style ? this.props.style : {}}>
                        <div className='logo-dekton-responsive'>
                            <img src={dekton} alt="logo-dekton" />
                            <span><Translation id="new_formulation" defaultMessage="New Formulation" /></span>
                        </div>
                        <div className='container-dekton-responsive' style={{
                            background: `rgba(255,255,255,0.3) url(${backgroundImage})`
                        }}>
                        </div>
                        <div className='x' style={this.props.style && this.props.style.width ? { width: this.props.style.width } : {}}>
                            <h1><Translation id="dekton_title" defaultMessage="New White Colours formulations" /></h1>
                            <h2><Translation id="dekton_desc" defaultMessage="We have improved the Dekton white colours to have better performance in the processing." /></h2>
                            <div>
                                <a target={"_blank"} href='https://cosentino.app.box.com/s/ff2j2a3bru3rqtc3112y2zks8oum7x1p?utm_campaign=New%20features_CosentinoWe&utm_medium=email&utm_source=diggest&utm_content=Ads'><Translation id="see_colors" defaultMessage="See Colours" /><i className="fal fa-chevron-right" /> </a>
                                <a target={"_blank"} href='https://cosentino.app.box.com/s/u0slbxoymyu16ykgic4i4eraxk1lrnhn?utm_campaign=New%20features_CosentinoWe&utm_medium=email&utm_source=diggest&utm_content=Ads'><Translation id="watch_video" defaultMessage="Watch Video" /><i className="fal fa-chevron-right" /> </a>
                            </div>
                        </div>
                    </div>
                }


            </React.Fragment>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(BannerDekton)
)
