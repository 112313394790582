import {productLogic as catalogProductLogic} from "../catalog/rectangleResult/contexts/logic/ProductLogic";
import {mallProductLogic} from "../catalog/rectangleResult/contexts/logic/MallProductLogic";
import {BaseProductLogic} from "../catalog/rectangleResult/contexts/logic/BaseProductLogic";
import {useEffect, useState} from "react";
import * as analytics from "../../analyticsEvents";
import {getMallProduct} from "../landing-templates/utils";

export const productCardLogic = (props) => {

    const [product, setProduct] = useState(props.lastSelectedProduct ? props.lastSelectedProduct : props.product)

    const productLogicFunction = product.fromMall ? mallProductLogic : catalogProductLogic
    const productLogic = productLogicFunction({...props, product}, BaseProductLogic)

    const initialExtras = !product.haveOptions ? {
        ...productLogic.getExtras(),
        ...product.extra,
        type: productLogic.getType(),
        comingFromCampaign: product.fromMall ? true : null,
        outlet: props.outlet || (product.fromMall && product.outletQuality)
            ? product.outletQuality
            : null
    } : { type: productLogic.getType(), brand: 'No Brand', colorName: 'No Color' }

    const [selectedBrandMktoolAttr, setSelectedBrandMktoolAttr] = useState(product.haveOptions ? { label: 'No Brand', value: 'No Brand' } : null)
    const [selectedColorMktoolAttr, setSelectedColorMktoolAttr] = useState(product.haveOptions ? { label: 'No Color', value: 'No Color' } : null)
    const [extras, setExtras] = useState(initialExtras)

    useEffect(() => {
        if (product.fromMall && props.mallProducts.length > 0) {
            const _product = getMallProduct(product, props.mallProducts, props.mallProductsLots)
            setProduct(_product)
            props.setLastSelectedProduct(_product)
        }
    }, [props.mallProducts])

    useEffect(() => {
        if (props.product) {
            let _product
            let variant
            let _extra = extras

            if (props.lastSelectedProduct && props.lastSelectedProduct.productId == props.productId) {
                _product = {...props.product, ...props.lastSelectedProduct}
                _extra = {..._extra, ...props.lastSelectedProduct.extra }
            } else {
                _product = props.product
                if (props.lastSelectedProduct) {
                    variant =`variant_${props.lastSelectedProduct.productId}`
                    _extra = {..._extra, variant }
                }
            }

            setExtras(_extra)
            _product.extra = _extra
            setProduct(_product)

            if (props.isLogged) {
                props.onLoadProductDetail(_product)
                props.postVisited(props.productId, _product.outlet)
            }

            props.setLastSelectedProduct(_product)
        }
    }, [props.product])
    //
    useEffect(() => {
        if (!props.lastSelectedProduct && props.landing && product.fromMall && props.mallProducts.length === 0) {
            props.fetchProductsLandingsByCenters(props.landing.landingId, [product.centerId], [product], props.landing.portfolio_mode)
        }
    }, [props.landing])

    useEffect(() => {
        if (!props.product) {
            props.fetchProduct(props.productId)
        } else if (props.product.haveOptions) {
            props.fetchColorsMktoolsAttr()
            props.fetchSimilarProduct(props.productId, product.colorId)
        }

        props.fetchCrossSelling(props.productId).then(() => {
            if (props.crossSelling) analytics.sendCatalogImpressions(props.crosselling, 'RELATED_PRODUCTS')
        })
        props.fetchAlternatives(props.productId)

    }, [props.productId, props.product && props.product.productId])

    useEffect(() => {
        if (props.isLogged) {
            props.fetchProductsFavourite()
        }
        props.setInProductDetailScreen(true)
        return () => props.setInProductDetailScreen(false)
    }, [])


    productLogic.getExtras = () => extras

    const canAddCartMktoolColorAttr = () => {
        return product.haveOptions ? !!selectedColorMktoolAttr : true
    }
    const canAddCartQtyOk = () => {
        return !((productLogic.getLimit() != null ? productLogic.getLimit() : 1) <= 0 || !productLogic.state.qtyToAdd >= 1)
    }
    const canAddMall = () => {
        //REVIEW::
        if (product.outletQuality && product.stock_outlet > 0) {
            return true
        }
        if (!product.outletQuality && product.stock > 0) {
            return true
        }
        return false
    }
    const canAddCart = () => {
        if (product.fromMall) {
            canAddCartQtyOk() && canAddCartMktoolColorAttr() && canAddMall()
        }
        return canAddCartQtyOk() && canAddCartMktoolColorAttr()
    }
    const getOptionsColors = () => {
        let options = []
        if (!selectedBrandMktoolAttr || selectedBrandMktoolAttr.value === 'No Brand') {
            if (Object.values(props.colorsMktoolsAttr).length > 0)
                options = ['No Color'].concat(Object.values(props.colorsMktoolsAttr).reduce((valorAnterior, valorActual) => valorAnterior.concat(valorActual)))
        } else if (selectedBrandMktoolAttr) {
            options = ['No Color'].concat(props.colorsMktoolsAttr[selectedBrandMktoolAttr.value])
        }
        return options.map(color => ({
            label: color,
            value: color
        }))
    }

    const changeBrandOption = selectedOption => {
        // console.log('changeBrandOption', selectedOption)
        setSelectedBrandMktoolAttr(selectedOption)
        setExtras({
            ...extras,
            brand: selectedOption ? selectedOption.value : null
        })

        if (!selectedOption || selectedOption.value !== 'No Brand') {
            setSelectedColorMktoolAttr({ label: 'No Color', value: 'No Color' })
            setExtras({
                ...extras,
                colorName: 'No Color',
                brand: selectedOption ? selectedOption.value : null
            })
        }
    }

    const changeColorOption = selectedOption => {
        // console.log('changeColorOption', selectedOption)
        setSelectedColorMktoolAttr(selectedOption)
        setExtras({
            ...extras,
            colorName: selectedOption ? selectedOption.value : null
        })
    }

    const state = {
        ...productLogic.state,
        selectedBrandMktoolAttr, setSelectedBrandMktoolAttr,
        selectedColorMktoolAttr, setSelectedColorMktoolAttr,
        extras, setExtras
    }

    const addToCart = () => productLogic.addToCart(extras)

    return {...productLogic,
        state,
        canAddCart,
        getOptionsColors,
        changeBrandOption,
        changeColorOption,
        addToCart,
        product
    }
}
