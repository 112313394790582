import React from 'react'
import Translation from '../../global/Translation'
import ProductDisplayName from '../../global/ProductDisplayName'
import DisplayDetail from '../../catalog/rectangleResult/displays/DisplayDetail'
import DisplayBrand from '../../catalog/rectangleResult/displays/DisplayBrand'
import DisplayOutletIndicator from '../../catalog/rectangleResult/displays/DisplayOutletIndicator'

export default class StockResult extends React.Component {
    constructor(props) {
        super(props)
        this.state = {showModalStock: false}
        const source = props.result !== undefined ? props.result._source : props.product
        this.product = source
    }

    render() {

        return (
            <div className="list-result" data-cy="catalog__product_sample_result" type={this.product.type}>
                <div className="product-image-container">
                    <div className="product-image" style={{ backgroundImage: 'url("' + this.props.getImageBackground(this.product) + '")' }} />
                </div>
                    {this.product.outlet && (
                        <DisplayOutletIndicator
                            outletQuality={this.product.outletQuality}
                            outletDeviated={this.props.isAdmin || this.props.impersonatedBy ? this.product.outletDeviated : null}
                        />
                    )}
                <div style={{display:'flex',flexDirection:'column',maxWidth:'180px'}}>
                    <ProductDisplayName product={this.product} />
                    <DisplayBrand brand={this.product.brand} />
                </div>
                <DisplayDetail class="center_name" value={this.props.centers.find(center => center.value == this.product.centerId).label} />
                <div>
                    <Translation defaultMessage="Stock" id="stock"></Translation>:{' '}
                    {this.product.stock}
                </div>
                <div>
                    <Translation defaultMessage="Stock outlet" id="stock_outlet"></Translation>:{' '}          
                    {this.product.stock_outlet}
                </div>
            </div>
        )
    }
}
