import React from 'react'

import { Resizable } from 'react-resizable'

class LazyImage extends React.Component {
    state = {
        currentImage: this.props.preview,
        loading: true,
        loadingMask: true,
        extraClass: this.props.extraClass,
        type: this.props.type,
        cutWidth: null,
        cutHeight: null,
        isResizing: false,
        masked: this.props.masked
    }

    componentDidMount() {
        this.fetchImage(this.props.image)
        this.props.masked && this.fetchImageMask(this.props.masked)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.image !== this.props.image) {
            this.setState({ currentImage: nextProps.preview, loading: true }, () => {
                this.fetchImage(nextProps.image)
            })
        }
    }

    componentWillUnmount() {
        if (this.loadingImage) {
            this.loadingImage.onload = null
        }
    }

    fetchImage = src => {
        const image = new Image()
        image.onload = () => this.setState({ currentImage: this.loadingImage.src, loading: false })
        image.src = src
        this.loadingImage = image
    }
    fetchImageMask = src => {
        const imageMask = new Image()
        imageMask.onload = () => this.setState({ currentImageMask: this.loadingImageMask.src, loadingMask: false })
        imageMask.src = src
        this.loadingImageMask = imageMask
    }

    style = loading => {
        const { currentImage, type } = this.state
        if (type === 'div-linear-not-100') {
            return {
                background: `linear-gradient(2deg, rgba(0, 0, 0, 0.58) 44.64%, rgba(43, 43, 43, 0.38) 65.64%, rgba(255, 255, 255, 0) 79.32%), center/150%100%url(${currentImage})`,
                transition: '0.5s filter linear',
                filter: `${loading ? 'blur(5px)' : ''}`
            }
        } else if (type === 'div-recurring') {
            return {
                backgroundImage: `url(${currentImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '80%',
                backgroundPositionY: 'bottom',
                transition: '0.5s filter linear',
                backgroundColor: '#F5F5FB',
                filter: `${loading ? 'blur(5px)' : ''}`
            }
        } else if (type === 'div-linear') {
            return {
                background: `linear-gradient(2deg, rgba(0, 0, 0, 0.58) 44.64%, rgba(43, 43, 43, 0.38) 65.64%, rgba(255, 255, 255, 0) 79.32%), center/100%100%url(${currentImage})`,
                transition: '0.5s filter linear',
                filter: `${loading ? 'blur(5px)' : ''}`
            }
        } else if (type === 'div') {
            return {
                backgroundImage: `url(${currentImage})`,

                transition: '0.5s filter linear',
                filter: `${loading ? 'blur(5px)' : ''}`
            }
        } else if (type === 'cut') {
            return {
                backgroundImage: `url(${currentImage})`,

                transition: '0.5s filter linear',
                filter: `${loading ? 'blur(5px)' : ''}`
            }
        } else {
            return {
                transition: '0.5s filter linear',
                filter: `${loading ? 'blur(5px)' : ''}`,
                width: `${this.props.width}px`,
                height: `${this.props.height}px`
            }
        }
    }

    render() {
        const { currentImage, currentImageMask, loading, loadingMask, extraClass, type } = this.state

        const { alt, format, onResize, disabled } = this.props
        if (type === 'otherMask') {
            return (
                <div className={'fit-image' + (loading || loadingMask ? ' loading' : '')}>
                    <div style={{ position: 'relative' }}>
                        <img
                            style={{
                                width: '30%',
                                height: '30%',
                                position: 'absolute',
                                bottom: '0px',
                                WebkitMaskSize: 'cover',
                                WebkitMaskPosition: 'center',
                                WebkitMaskRepeat: 'no-repeat',
                                transition: '0.5s filter linear',
                                filter: `${loading || loadingMask ? 'blur(3px)' : ''}`,
                                zIndex: 1
                            }}
                            src={currentImageMask}
                            alt={alt}
                            className={extraClass}
                        />
                        <img style={this.style(loading || loadingMask)} src={currentImage} alt={alt} className={extraClass} />
                    </div>
                </div>
            )
        } else if (type === 'div-linear-not-100') {
            return (
                <div className="blur-overflow">
                    <div style={this.style(loading)} alt={alt} className={extraClass}>
                        {this.props.children}
                    </div>
                </div>
            )
        } else if (type === 'div-linear') {
            return (
                <div className="blur-overflow">
                    <div style={this.style(loading)} alt={alt} className={extraClass}>
                        {this.props.children}
                    </div>
                </div>
            )
        } else if (type === 'div') {
            return (
                <div className="blur-overflow">
                    <div style={this.style(loading)} alt={alt} className={extraClass}>
                        {this.props.children}
                    </div>
                </div>
            )
        } else if (type === 'div-recurring') {
            return (
                <div className="blur-overflow div-banner-recurring">
                    <div style={this.style(loading)} alt={alt} className={extraClass}>
                        {this.props.children}
                    </div>
                </div>
            )
        } else if (type === 'cut') {
            const realWidth = this.divImage !== undefined && this.divImage.clientWidth < 500 && this.divImage.clientWidth > 1 ? this.divImage.clientWidth : 500
            const heightCalculated = (format.height / format.width) * realWidth
            const conversionFactor = realWidth / format.width
            return (
                <div className="cut_to_size__image">
                    <div
                        style={{
                            backgroundImage: `url(${currentImage})`,
                            transition: '0.5s filter linear',
                            filter: `${loading ? 'blur(5px)' : 'brightness(0.4)'}`,
                            backgroundSize: `${loading ? 'cover' : ''}`,
                            width: realWidth + 'px',
                            height: heightCalculated + 'px',
                            position: 'absolute',
                            zIndex: '-1'
                        }}
                        alt={alt}
                        className={extraClass + ' cut_to_size__image--dark'}
                    >
                        {this.props.children}
                    </div>
                    <Resizable
                        minConstraints={[0, 0]}
                        maxConstraints={[realWidth, heightCalculated]}
                        width={conversionFactor * this.props.width}
                        height={conversionFactor * this.props.height}
                        onResize={(event, otracosa) => {
                            if (!disabled) onResize(event, otracosa, conversionFactor)
                        }}
                        onResizeStart={() => this.setState({ isResizing: true })}
                        onResizeStop={() => this.setState({ isResizing: false })}
                    >
                        <div
                            style={{
                                backgroundImage: `url(${currentImage})`,
                                transition: this.state.isResizing ? '0s' : '0.5s',
                                filter: `${loading ? 'blur(5px)' : ''}`,
                                backgroundSize: `${loading ? 'cover' : ''}`,

                                width: `${conversionFactor * this.props.width}px`,
                                height: `${conversionFactor * this.props.height}px`,
                                // border: '1px dotted white',
                                position: 'absolute'
                            }}
                            alt={alt}
                            className={extraClass + ` cut_to_size__image--cut ${disabled ? ' cut_to_size__image--disabled' : ''}`}
                        >
                            {this.props.children}
                        </div>
                    </Resizable>
                    <div
                        style={{
                            height: heightCalculated + 'px',
                            visibility: 'hidden',
                            zIndex: '-1'
                        }}
                        ref={div => {
                            this.divImage = div
                        }}
                        className={extraClass + ' cut_to_size__image--canvas'}
                    >
                        {this.props.children}
                    </div>
                </div>
            )
        } else {
            return (
                <div className={'fit-image' + (loading ? ' loading' : '')}>
                    <img style={this.style(loading)} src={currentImage} alt={alt} className={extraClass} />
                </div>
            )
        }
    }
}
export default LazyImage
