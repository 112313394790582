import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import Spinner from '../global/Spinner'
import Alerts from '../alerts/Alerts'
import ChannelV4 from './ChannelV4'
import SalesForceIdV3 from './SalesForceIdV3'
import ProjectIdV3 from './ProjectIdV3'
import UseV3 from './UseV3'
import TypologyV3 from './TypologyV3'


class SegmentationForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            excuse: null,
            name: this.props.name
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.segmentatingProductId !== this.props.segmentatingProductId) {
            this.setState({ product: this.props.getProductById(this.props.segmentatingProductId) })
        }
    }

    componentDidMount() {
        this.ref.value = this.props.name
    }

    scrollDown = (e) => {
        var element = document.getElementById("seg-content")
        element.scrollTop = element.scrollHeight
    }

    changeName = (e) => {
        const newName = e.target && e.target.value
        if (newName != this.state.name) {
            this.setState({name: newName})
            this.props.setNameInput(newName)
        }
    }

    render() {

        return (
            <div>
                {(this.props.isSaving || this.props.isLoading) && <Spinner isLoading={this.props.isSaving || this.props.isLoading} />}
                <div className="container-fluid">                   
                    {this.state.excuse && <Alerts alert={this.state.excuse} status="warning" />}
                    <div id="seg-content" className="row" style={{ overflow: 'auto', padding: ".5em" }}>
                        <p>
                            <Translation id="segmentation_prompt_body" defaultMessage="Please choose a unique name for this configuration" />
                        </p>
                        <div className="form-group">
                            <b>
                                <small>
                                    <Translation id="name" defaultMessage="Name" />
                                </small>
                            </b>
                            <input
                                ref={ref => this.ref = ref}
                                type="text"
                                className="form-control"
                                data-cy="modal_segmentation__input_name_save_segmentation"
                                placeholder={this.props.intl.formatMessage({
                                    id: 'name'
                                })}
                                onChange={this.changeName}
                            />
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <h5>
                                        <Translation id="choose_segmentation" defaultMessage="Choose segmentation"></Translation>
                                    </h5>
                                    <ChannelV4 intl={this.props.intl} inProfile={true} />
                                </div>
                            </div>
                            <div className="row">
                                {this.props.channel != null
                                    && this.props.channel !== 'B0'
                                    && this.props.channel !== 'HE'
                                    && (
                                    <div className="col" onClick={this.scrollDown}>
                                        <SalesForceIdV3 intl={this.props.intl} inProfile={true} />
                                    </div>
                                )}
                                {(this.props.channel === 'C0'
                                    || this.props.channel === 'BB'
                                    || this.props.channel === 'BD'
                                    || this.props.channel === 'B0'
                                    || this.props.channel === 'HE'
                                    || this.props.channel === 'DA'
                                    || this.props.channel === 'HO'
                                    || this.props.channel === 'nexus_retail'
                                    || this.props.channel === 'nexus_non_retail'
                                ) && (
                                    <div className="col" onClick={this.scrollDown}>
                                        <ProjectIdV3 intl={this.props.intl} inProfile={true} />
                                    </div>
                                )}
                            </div>
                            {this.props.viewFullSegmentationV3 && (
                                <div className="row">
                                    <div className="col" onClick={this.scrollDown}>
                                        <TypologyV3 intl={this.props.intl} product={this.state.product} />
                                    </div>
                                    <div className="col" onClick={this.scrollDown}>
                                        <UseV3 intl={this.props.intl} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default injectIntl(SegmentationForm)
