import React from 'react'
import { withRouter } from 'react-router-dom'
import ProfileNavBar from './ProfileNavBar'

const Profile = ({ username, name, isIkeaCenter, isLowesCenter, employeeId, userType, isMkToolsCenter, canOffer }) => {
    const canRouteConfiguration = () => true
    const canRouteOrders = () => userType === 'CUSTOMER' || isLowesCenter
    const canRouteEditOrder = () => !isLowesCenter
    const canRouteTransfers = () => isMkToolsCenter && userType === 'ADMIN'
    const canRouteDirectSlabAccess = () => !isIkeaCenter && !isLowesCenter
    const canRouteProductsFavourite = () => !isLowesCenter
    const canRouteRecurringOrders = () => !isLowesCenter
    const canRouteSegmentation = () => !isLowesCenter
    const canRouteOfferStatusHistory = () => canOffer
    return (
        <div className="profile-menu">
            <div className="user-box">
                <ProfileNavBar
                    userType={userType}
                    isMkToolsCenter={isMkToolsCenter}
                    isIkeaCenter={isIkeaCenter}
                    isLowesCenter={isLowesCenter}
                    canRouteConfiguration={canRouteConfiguration()}
                    canRouteOrders={canRouteOrders()}
                    canRouteEditOrder={canRouteEditOrder()}
                    canRouteTransfers={canRouteTransfers()}
                    canRouteDirectSlabAccess={canRouteDirectSlabAccess()}
                    canRouteProductsFavourite={canRouteProductsFavourite()}
                    canRouteRecurringOrders={canRouteRecurringOrders()}
                    canRouteSegmentation={canRouteSegmentation()}
                    canRouteOfferStatusHistory={canRouteOfferStatusHistory()}
                    name={name}
                    employeeId={employeeId}
                    username={username}
                />
            </div>
        </div>
    )
}

export default withRouter(Profile)
