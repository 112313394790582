import { connect } from 'react-redux'
import LandingPromotions from './LandingPromotions'
import { withRouter } from 'react-router-dom'
import { getFetchedProducts } from '../../store/products/reducers'
import { fetchProduct, fetchUserPriceListByUser } from '../../store/products/actions'
import { getDataLandingTemplateId, getPromotionDataProduct, getPromotionDescription, getPromotionTitle,getError,getPromotionLandingBanner, getHeadLandingBannerData, getBannerTitle, getBannerDescription } from '../../store/promotions/reducers'
import { getCenterName } from '../../store/centers/reducers'
import banner from '../../assets/img/banners-promo-july/banner-catalog-es.png'
import { fetchPromotionData } from '../../store/promotions/actions'
import { getIsAdmin, getIsLogged } from '../../store/login/reducers'
import { gerPriceListByUserId, loginRequired } from '../../store/login/actions'
import { usersSelector } from '../../store/impersonate/reducers'

const mapStateToProps = (state, { match }) => {
    return {
        promotionId: match.params.promotionId,
        promotion: '',
        promotionProducts: [],
        landingId: getDataLandingTemplateId(state),
        // products: getFetchedProducts(state),
        products: getPromotionDataProduct(state),
        promotionTitle: getPromotionTitle(state),
        //TODO: Cambiar el srcBanner por el selector
        srcBanner: getPromotionLandingBanner(state),
        // srcBanner: getSrcBanner(state),
        promotionDescription: getPromotionDescription(state),
        centerName: getCenterName(state),
        isAdmin: getIsAdmin(state),
        users: usersSelector(state),
        error: getError(state),
        headLandingBannerData: getHeadLandingBannerData(state),
        promotionBannerTitle: getBannerTitle(state),
        promotionBannerDescription: getBannerDescription(state),
        isLogged: getIsLogged(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchPromotionData: promotionId => {
            dispatch(fetchPromotionData(promotionId))
        },
        fetchUserPriceListByUser: userId => {
            dispatch(fetchUserPriceListByUser(userId))
        },
        gerPriceListByUserId: userId => {
            dispatch(gerPriceListByUserId(userId))
        },
        removePriceList: () => {
            dispatch({ type: 'REMOVE_PRICE_LIST' })
            dispatch({ type: 'FETCH_PRODUCTS_DISCOUNTS_SUCCESS', discounts: [] })
        },
        loginRequired: () => {
            dispatch(loginRequired())
        },
    }
}

const LandingPromotionsContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LandingPromotions)
)
export default LandingPromotionsContainer
