import { getSalesOrg } from './reducers';
import { getWhatsappOptinPreviousPreferences } from '../profile-configuration/reducers';
import * as api from '../api'
import {
    getImpersonatedBy,
    getMandatoryChangePasswordOldPassword,
    getMandatoryChangePasswordUsername,
    getMulticartEnabled,
    getToken,
    getUserType,
} from './reducers'
import { getLanguage } from '../ui/reducers'
import { getIsLogged } from './reducers'
import {
    getCenters,
    getLowesCenterId,
    getIsIkeaCenter,
    getIsLowesCenter,
    getSelectedCenter,
    getIsMkToolsCenter
} from '../centers/reducers'
import {
    fetchSpecialProductNames,
    fetchUserPriceList,
    fetchProductsDiscounts,
    fetchProductsDiscountsByToken,
    fetchSlidersProducts
} from '../products/actions'
import { fetchProfileConfiguration, fetchPromotionConfiguration,fetchWhatsappConfiguration } from '../profile-configuration/actions'
import { getPreferredCenter, getProfileConfiguration } from '../profile-configuration/reducers'
import {changeCenter, changeFirstCenterFromSalesOrg} from '../centers/actions'
import { fetchReservations } from '../reservations/actions'
import { dispatchPushURL, setLanguage } from '../ui/actions'
import Cookies from 'js-cookie'
import { fetchShippingAddresses } from '../shipping-addresses/actions'
import { fetchUserLandings } from '../landing/actions'
import { canViewPricesPhase3 } from '../products/reducers'

export const SIMPLE_LOGIN_REQUEST = 'SIMPLE_LOGIN_REQUEST'
export const SIMPLE_LOGIN_SUCCESS = 'SIMPLE_LOGIN_SUCCESS'

export const SIMPLE_IMPERSONATE_REQUEST = 'SIMPLE_IMPERSONATE_REQUEST'
export const SIMPLE_IMPERSONATE_SUCCESS = 'SIMPLE_IMPERSONATE_SUCCESS'
export const SIMPLE_IMPERSONATE_FAILURE = 'SIMPLE_IMPERSONATE_FAILURE'

const simpleLogin = (username, password, ssoData) => (dispatch, getState) => {
    dispatch({ type: SIMPLE_LOGIN_REQUEST })
    // console.log(ssoData)
    if (ssoData) {
        if (ssoData.type === 'SSO') {
            return api.loginSSO(ssoData.sapId, ssoData.email, getLanguage(getState())).then(response => {
                dispatch({ type: SIMPLE_LOGIN_SUCCESS, token: response.data.token })
                dispatch({ type: 'SET_SAP_ID', sapId: username , simpleAccess: false})
            })
        } else if (ssoData.type === 'AD') {
            // console.log('attempting to login ad')
            return api.loginAD(ssoData.username, getLanguage(getState()), ssoData.azureToken).then(response => {
                dispatch({ type: SIMPLE_LOGIN_SUCCESS, token: response.data.token })
                dispatch({ type: 'SET_SAP_ID', sapId: response.sapId })
            })
        } else if (ssoData.type === 'JT') {
            return api.loginJT(ssoData.sapId, ssoData.email, getLanguage(getState())).then(response => {
                dispatch({ type: SIMPLE_LOGIN_SUCCESS, token: response.data.token })
                dispatch({ type: 'SET_SAP_ID', sapId: username , simpleAccess: false})
                dispatch(fetchLowesStoresData())
            })
        } else if (ssoData === 'SHOP') {
            return api.loginShops(username, password, getLanguage(getState())).then(response => {
                dispatch({ type: SIMPLE_LOGIN_SUCCESS, token: response.data.token })
                dispatch({ type: 'SET_SAP_ID', sapId: username , simpleAccess: false})
            })
        }
    } else {
        return api.login(username, password, getLanguage(getState())).then(response => {
            dispatch({ type: SIMPLE_LOGIN_SUCCESS, token: response.data.token })
            dispatch({ type: 'SET_SAP_ID', sapId: null , simpleAccess: true})
        })
    }
}

const fetchLowesStoresData = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_LOWES_STORE_DATA_REQUEST' })
    return api
        .fetchInfoUserLowes(getToken(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_LOWES_STORE_DATA_SUCCESS', lowesStoreData: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_LOWES_STORE_DATA_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}

const simpleImpersonate = (userId, locale, stay = false) => (dispatch, getState) => {
    dispatch({ type: SIMPLE_IMPERSONATE_REQUEST })
    return api.impersonate(userId, getToken(getState()), getLanguage(getState())).then(response => {
        dispatch({ type: SIMPLE_IMPERSONATE_SUCCESS, token: response.data.token })
        dispatch({ type: 'SIMPLE_IMPERSONATE_RESUCCESS' })
        
        if (!stay) {
            dispatch(dispatchPushURL())
        }
    })
}

export const gerPriceListByUserId = userId => (dispatch, getState) => {
    dispatch({ type: 'GET_PRICE_LIST_REQUEST' })
    return api.impersonate(userId, getToken(getState()), getLanguage(getState())).then(response => {
        dispatch({ type: 'GET_PRICE_LIST_SUCCESS', token: response.data.token })
        if (canViewPricesPhase3(getState())) {
            dispatch(fetchProductsDiscountsByToken(response.data.token))
        }
    })
}

const initalizeUser = () => (dispatch, getState) => {
    return dispatch(fetchProfileConfiguration()).then(() => {
        dispatch(fetchPromotionConfiguration()).then(() => {})
        const preferredCenter = getPreferredCenter(getState())
        const centers = getCenters(getState())
        if (centers.length > 0) {
            const selectedCenter = preferredCenter ? parseInt(preferredCenter, 10) : centers[0].centerId
            dispatch(changeCenter(selectedCenter))
            dispatch(fetchShippingAddresses())
            dispatch(fetchUserPriceList())
            dispatch(fetchUserLandings())

            return dispatch(fetchSpecialProductNames())
        } else return Promise.reject({ response: { data: { err: 'USER_WITHOUT_CENTERS' } } })
    })
}

export const impersonate = (userId, stay = false) => (dispatch, getState) => {
    const locale = getLanguage(getState()) === 'en-US' ? '' : getLanguage(getState())
    dispatch({ type: 'IMPERSONATE_PROCESS_INIT' })
    return dispatch(simpleImpersonate(userId, locale, stay))
        .then(success => {
            return dispatch(initalizeUser()).then(() => {
                dispatch({ type: 'RESET_CUT_TO_SIZE' })
                dispatch({ type: 'IMPERSONATE_PROCESS_FINISH' })
                // dispatch(dispatchPushURL())
                if (getCenters(getState()).length > 0) dispatch({ type: 'REMEMBER_CENTER' })
                //Disabled because its unmantained and affects performance
                // dispatch(fetchChangelogByProject())
                //Disabled because its unmantained and affects performance
                // dispatch(fetchChangelogByProject(true))
                //Call to get user discounts.
                if (canViewPricesPhase3(getState())) {
                    dispatch(fetchProductsDiscounts())
                }
                //if user bigaccount
                if (getMulticartEnabled(getState())) {
                    dispatch({ type: 'TOGGLE_SHOW_BIG_ACCOUNT_MODAL' })
                }

                dispatch(fetchSlidersProducts())
                if (getUserType(getState()) === 'ADMIN') {
                    //Disabled because its unmantained and affects performance
                    // dispatch(fetchChangelogByProject())
                    dispatch(getIsCampaignAdmin())
                    api.mallCheckLogin(userId, getState().login.token).then(response => {
                        dispatch({ type: 'CHECK_LOGIN_SUCCESS', userAuthorized: response.data })
                    })
                }
            })
        })
        .catch(error => {
            dispatch({ type: 'IMPERSONATE_PROCESS_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}
export const loginRequired = (callback = null, callbackCenter = null, callbackSalesOrg = null) => (dispatch, getState) => {
    dispatch({
        type: 'LOGIN_REQUIRED',
        callbackCenter: callbackCenter,
        callbackSalesOrg: callbackSalesOrg,
        // callback: callback ? callback : () => { },
        callback: () => {},
        payload: { statusText: 'LOGIN_REQUIRED' }
    })
}
export const login = (username, password, callback = null, callbackCenter = null, intl, ssoData = null, callbackSalesOrg = null) => (dispatch, getState) => {
    dispatch({ type: 'LOGIN_PROCESS_INIT' })
    
    return dispatch(simpleLogin(username, password, ssoData))
        .then(() => {
            return dispatch(initalizeUser()).then(() => {
                let lang = getLanguage(getState())

                dispatch({ type: 'LOGIN_PROCESS_FINISH' })
                if (username) {
                    const token = getState().login.token
                    dispatch(fetchUserLandings())
                    api.mallCheckLogin(username, token).then(response => {
                        dispatch({ type: 'CHECK_LOGIN_SUCCESS', userAuthorized: response.data })
                    })
                }
                if (callback === null && getCenters(getState()).length > 0 && typeof username === 'string' && username.toUpperCase() !== 'MKTOOLS') dispatch({ type: 'REMEMBER_CENTER' })
                if (getUserType(getState()) === 'SHOP') dispatch(fetchReservations('unassigned'))
                //Call to get user discounts.
                if (canViewPricesPhase3(getState())) {
                    dispatch(fetchProductsDiscounts())
                }
                if (callback != null) {
                    if(callbackSalesOrg){
                        dispatch(changeFirstCenterFromSalesOrg(callbackSalesOrg))
                    }
                    const isBetweenCenters = getCenters(getState()).find(center => parseInt(center.centerId, 10) === parseInt(callbackCenter, 10))
                    if (callbackCenter && isBetweenCenters) {
                        dispatch(changeCenter(parseInt(callbackCenter, 10)))
                    }
                    dispatch(callback)
                }

                if (getSelectedCenter(getState()) === getLowesCenterId() && getUserType(getState()) === 'ADMIN')
                    dispatch(dispatchPushURL(intl.formatMessage({ id: 'ROUTE_CATALOG_LOWES_ADMIN' }).replace(':filter', 'home')))
                else if (getSelectedCenter(getState()) === getLowesCenterId()) dispatch(dispatchPushURL(intl.formatMessage({ id: 'ROUTE_CATALOG_LOWES' }).replace(':filter', 'home')))
                else if (getIsIkeaCenter(getState())) {
                    dispatch(dispatchPushURL(intl.formatMessage({ id: 'ROUTE_CATALOG' }).replace(':outlet?', '')))
                    dispatch({ type: 'REMEMBER_CENTER' })
                }

                if (getUserType(getState()) === 'ADMIN') {
                    //Disabled because its unmantained and affects performance
                    // dispatch(fetchChangelogByProject())
                    dispatch(getIsCampaignAdmin())
                }

                if (callback === null && getProfileConfiguration(getState()).locale) {
                    lang = getProfileConfiguration(getState()).locale
                    Cookies.set('defaultLanguage', lang)
                    dispatch(setLanguage(lang))
                    dispatch(dispatchPushURL())
                }
                //if user bigaccount
                if (getMulticartEnabled(getState())) {
                    dispatch({ type: 'TOGGLE_SHOW_BIG_ACCOUNT_MODAL' })
                }


                if (getUserType(getState()) === 'CUSTOMER') {

                    dispatch({ type: 'SHOW_SILESTONEXM_MODAL' })

                    // Dispatch only for user type customer
                    dispatch(fetchHasWalkthrough())
                    //whatsapp optin only in Holland, Germany, and Austria
                    if (['2000', '2001', '2014'].includes(getSalesOrg(getState()))) {
                        dispatch(fetchWhatsappConfiguration()).then(() => {
                            // console.log('toy akaaa', getWhatsappOptinPreviousPreferences(getState()))
                            if (
                                !getWhatsappOptinPreviousPreferences(getState()) &&
                                getUserType(getState()) === 'CUSTOMER' &&
                                !getIsIkeaCenter(getState()) &&
                                !getIsLowesCenter(getState()) &&
                                !getImpersonatedBy(getState())
                            ) {
                                dispatch({ type: 'SHOW_WHATSAPP_MODAL', showWhatsappModal: true })
                            }
                        })
                    }

                    if (lang === 'es') {
                        dispatch({ type: 'HOTJAR_FABRICATORS_SURVEY_ES' });
                        try {
                            if (window.hj) {
                                window.hj('trigger', 'fabricators_survey_es');
                            }
                        } catch (error) {
                            console.error('Error triggering Hotjar for ES survey:', error);
                        }
                    } else {
                        dispatch({ type: 'HOTJAR_FABRICATORS_SURVEY_EN' });
                        try {
                            if (window.hj) {
                                window.hj('trigger', 'fabricators_survey_en');
                            }
                        } catch (error) {
                            console.error('Error triggering Hotjar for EN survey:', error);
                        }
                    }
                }

                if(getIsMkToolsCenter(getState()) || getSelectedCenter(getState()) === getLowesCenterId() || getIsIkeaCenter(getState())) dispatch({ type: 'HIDE_ALL_BANNER_CATALOG' })
                // dispatch(dispatchPushURL())               
            })
        })
        .catch(error => {
            // console.log('ERROR_LOGIN', error)
            if (error.message === 'Network Error') dispatch({ type: 'LOGIN_PROCESS_FAILURE', statusText: error.message })
            else if (error.response && error.response.status === 503 && error.response.data.err === 'MAINTENANCE_MODE_ON') {
                dispatch({ type: 'MAINTENANCE_MODE_ON' })
                dispatch({ type: 'RESET_UI' })
            } else if (error.response && error.response.data && error.response.data.err === 'PASSWORD_DOESNT_MEET_REQUIREMENTS') {
                dispatch({
                    type: 'MANDATORY_CHANGE_PASSWORD',
                    alert: 'MANDATORY_PASSWORD_CHANGE',
                    username: username,
                    oldPassword: password
                })
            } else dispatch({ type: 'LOGIN_PROCESS_FAILURE', statusText:error.response && error.response.data && error.response.data.err ? error.response.data.err : 'NETWORK_ERROR' })
        })
}

export const hasFeedback = (banner) => (dispatch, getState) => {
    if(!getIsLogged(getState())) return
    return api
        .hasFeedback(banner, getToken(getState()))
        .then(response => {
            dispatch({type: 'HAS_FEEDBACK', response: response.data.show})
        })
        .catch(error => {
            dispatch({
                type: 'SEND_FEDDBACK_FAILURE',
                statusText: error.response.data.err,
                status: error.response.status
            })
        })
}

export const logout = (stay = false) => (dispatch, getState) => {
    dispatch({
        type: 'LOGOUT'
    })
    dispatch({ type: 'EMPTY_CENTERS' })
    dispatch({ type: 'EMPTY_CART' })
    dispatch({ type: 'EMPTY_RESERVATIONS' })
    localStorage.clear()
    if (!stay) {
        dispatch(dispatchPushURL())
    }
    return Promise.resolve()
}
export const recoverPassword = (username, email) => (dispatch, getState) => {
    dispatch({ type: 'RECOVER_PASSWORD_REQUEST', username, email })

    return api
        .recoverPassword(username, email)
        .then(response => {
            dispatch({ type: 'RECOVER_PASSWORD_SUCCESS', statusText: response.data, status: response.status })
        })
        .catch(error => {
            dispatch({
                type: 'RECOVER_PASSWORD_FAILURE',
                statusText: error.response.data.err,
                status: error.response.status
            })
        })
}
export const createAccount = (username, email) => (dispatch, getState) => {
    dispatch({ type: 'CREATE_ACCOUNT_REQUEST', username, email })

    return api
        .createAccount(username, email)
        .then(response => {
            dispatch({ type: 'CREATE_ACCOUNT_SUCCESS', alert: response.data, status: response.status })
        })
        .catch(error => {
            dispatch({
                type: 'CREATE_ACCOUNT_FAILURE',
                statusText: error.response.data.err,
                status: error.response.status
            })
        })
}
export const addEmailCenter = (user, email) => (dispatch, getState) => {
    dispatch({ type: 'ADD_EMAIL_CENTER_REQUEST', user, email })

    return api
        .addEmailCenter(user, email, getToken(getState()))
        .then(response => {
            dispatch({ type: 'ADD_EMAIL_CENTER_SUCCESS', alert: response.data, status: response.status })
        })
        .catch(error => {
            dispatch({
                type: 'ADD_EMAIL_CENTER_FAILURE',
                statusText: error.response.data.err,
                status: error.response.status
            })
        })
}
export const mandatoryChangePasswordRequest = (newPassword, newPasswordRepeat) => (dispatch, getState) => {
    if (newPassword !== newPasswordRepeat) {
        dispatch({ type: 'MANDATORY_CHANGE_PASSWORD_NOT_MATCH', alert: 'PASSWORDS_NOT_MATCH' })
        return
    }

    dispatch({
        type: 'MANDATORY_CHANGE_PASSWORD_REQUEST'
    })
    return api.mandatoryChangePassword(getMandatoryChangePasswordUsername(getState()), getMandatoryChangePasswordOldPassword(getState()), newPassword).then(
        response => {
            dispatch({
                type: 'MANDATORY_CHANGE_PASSWORD_SUCCESS',
                alert: 'PASS_CHANGED_LOGIN_AGAIN'
            })
        },
        error => {
            dispatch({
                type: 'MANDATORY_CHANGE_PASSWORD_FAILURE',
                alert: error.response.data.err,
                status: error.response.status
            })
        }
    )
}

export const getIsCampaignAdmin = () => (dispatch, getState) => {
    dispatch({ type: 'GET_IS_CAMPAIGN_ADMIN_REQUEST' })
    return api
        .getIsCampaignAdmin(getToken(getState()))
        .then(response => {
            dispatch({ type: 'GET_IS_CAMPAIGN_ADMIN_SUCCESS', isCampaignAdmin: response.data })
        })
        .catch(error => {
            dispatch({ type: 'GET_IS_CAMPAIGN_ADMIN_FAILURE' })
        })
}

/**
 * Action to fetch / get the user walkthrough data
 */
export const fetchHasWalkthrough = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_HAS_WALKTHROUGH_REQUEST' })

    const token = getToken(getState())

    return api.fetchHasWalkthrough(token).then(response => {
        dispatch({ 
            type: 'FETCH_HAS_WALKTHROUGH_SUCCESS',
            hasWalkthrough: response.data
        })
    },
    error => {
            dispatch({ 
                type: 'FETCH_HAS_WALKTHROUGH_FAILURE',
                status: error.response.status 
            })
        }
    )
}