import { canViewDiscountStep } from './../../store/promotions/reducers';
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Spinner from '../global/Spinner'
import Translation from '../global/Translation'
import ReactTable from 'react-table'
import { getIsLoading, getPromotions } from '../../store/promotions/reducers'
import {
    clonePromotion,
    disablePromotion,
    enablePromotion,
    fetchPromotionsList,
    fetchPromotionToEdit,
    sendPreviewPromotionEmail
} from '../../store/promotions/actions'
import moment from 'moment'
import { getLanguage } from '../../store/ui/reducers'
import { defineMessages, injectIntl } from 'react-intl'
import confirm from '../global/confirm'
import Toggle from 'react-toggle'
import ReactTooltip from 'react-tooltip'
import Modal from '../global/Modal'
import Select from 'react-select'
import { usersSelector } from '../../store/impersonate/reducers'
import { fetchUserPriceListByUser } from '../../store/products/actions'
import { gerPriceListByUserId } from '../../store/login/actions'
import { dispatchPushURL } from '../../store/ui/actions'
import {getIsCampaignsPricingUser} from "../../store/login/reducers"
import DiscountStateLabel from "./discounts/DiscountStateLabel"
import ApplyDiscountsModal from "./discounts/ApplyDiscountsModal"

defineMessages({
    the_campaign_is_incomplete: {
        id: 'the_campaign_is_incomplete',
        description: 'The campaign is incomplete',
        defaultMessage: 'The campaign is incomplete'
    },
    enter_the_email_to_send_the_preview: {
        id: 'enter_the_email_to_send_the_preview',
        description: 'enter_the_email_to_send_the_preview',
        defaultMessage: 'Enter the email to send the preview'
    }
})
const confirmEnablePromotion = (center, onChangeShippingCenter, intl) => {
    return confirm(intl.formatMessage({ id: 'ACTIVATE_PROMOTION_MAILING_CONFIRM' })).then(
        () => {
            return onChangeShippingCenter(center)
        },
        () => {
            return Promise.reject()
        }
    )
}
const mapStateToProps = state => {
    return {
        data: getPromotions(state),
        locale: getLanguage(state),
        isLoading: getIsLoading(state),
        users: usersSelector(state),
        isPricing: getIsCampaignsPricingUser(state),
        canViewDiscountStep:canViewDiscountStep(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchPromotionsList: (page = 1, itemsPerPage = 8, filters = null) => dispatch(fetchPromotionsList(page, itemsPerPage, filters)),
        fetchPromotion: (promotionId, intl) => dispatch(fetchPromotionToEdit(promotionId, intl)),
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        initCreateNewPromotion: () => Promise.resolve(dispatch({ type: 'INIT_CREATE_NEW_PROMOTION' })),
        sendPreviewPromotionEmail: (promotionId, email, userSelected) => {
            dispatch(sendPreviewPromotionEmail(promotionId, email, userSelected))
        },
        enablePromotion: promotionId => dispatch(enablePromotion(promotionId)),
        disablePromotion: promotionId => dispatch(disablePromotion(promotionId)),
        fetchUserPriceListByUser: userId => {
            dispatch(fetchUserPriceListByUser(userId))
        },
        gerPriceListByUserId: userId => {
            dispatch(gerPriceListByUserId(userId))
        },
        sendClonePromotion: (promotionId) => dispatch(clonePromotion(promotionId)),
        showAlertFailure: () => dispatch({ type: 'FLOATING_ALERTS_ADD_FAILURE_MESSAGE'})
        // updateCampaignDiscountStatus: (campaignId, status) => dispatch(sendCampaignDiscountStatus(campaignId, status))
    }
}

class PromotionsList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: null,
            inputEmailValue: null,
            showModalPreview: false,
            showApplyDiscountsModal: false,
            selectedCampaign: null,
            selectedRow: null
        }
    }

    sendPreviewPromotionEmail() {
        this.props.sendPreviewPromotionEmail(this.state.selected, this.state.inputEmailValue, this.state.userSelected)
        this.setState({ showModalPreview: false })
    }


    getActionCell = original => {
        if (!this.props.isPricing) {
            return (
                <div className="d-flex justify-content-center">
                    {/* {original.isReady ? 'is ready': 'no esta ready'} */}
                    {original.isReady ? (
                        <Toggle
                            className="react-toggle-small"
                            checked={original.status === 'ACTIVE'}
                            disabled={!original.isReady}
                            onChange={() => {
                                if (original.status === 'INACTIVE' || original.status === 'DRAFT')
                                    confirmEnablePromotion(original.id, this.props.enablePromotion, this.props.intl).then(() => this.props.fetchPromotionsList())
                                else if (original.status === 'ACTIVE') this.props.disablePromotion(original.id).then(() => this.props.fetchPromotionsList())
                            }}
                        />
                    ) : (
                        <span>
                        <Translation id="draft" defaultMessage="Draft" />{' '}
                            <i
                                className="fal fa-info-circle"
                                style={{ margin: '5px', flex: '0 0 auto' }}
                                data-tip={this.props.intl.formatMessage({
                                    id: 'the_campaign_is_incomplete',
                                    defaultMessage: 'The campaign is incomplete'
                                })}
                            />
                        <ReactTooltip effect="solid" place="top" />
                    </span>
                    )}
                </div>
            )
        } else {
            if (original.discount_request_status === 'REQUESTED') {
                return (
                    <div style={{color:"#007bff", textDecoration:"underline", cursor:"pointer", paddingInlineStart:"4px"}}
                         onClick={() => this.setState({ showApplyDiscountsModal: true, selectedCampaign: original })}
                    >
                        <Translation id="pending_approval" defaultMessage="Pending approval"/>
                    </div>
                )
            } else {
                let messageId
                let message

                if (original.isReady) {
                    if (original.status === 'ACTIVE') {
                        messageId = 'active'
                        message = 'Active'
                    } else {
                        messageId = 'inactive'
                        message = 'Inactive'
                    }
                } else {
                    messageId = 'draft'
                    message = 'Draft'
                }

                return (
                    <div style={{paddingInlineStart:"4px"}}>
                        {
                            this.props.intl.formatMessage({id:messageId})
                        }
                        {/*<Translation id={messageId} defaultMessage={message}/>*/}
                    </div>
                )
            }
        }
    }

    render() {
        const filterable = false

        const trProps = (state, rowInfo) => {
            // if (this.props.isPricing) return {}

            let cursor = rowInfo ? 'pointer' : 'default'

            return {
                onClick: () => {
                    if (this.state.selected === rowInfo.original.id) {
                        this.setState({ selected: null, selectedRow: null })
                    } else {
                        this.setState({ selected: rowInfo.original.id, selectedRow: rowInfo })
                    }
                },
                style: {
                    cursor: cursor,
                    backgroundColor: rowInfo && rowInfo.original.id === this.state.selected ? 'rgba(100, 100, 100, 0.1)' : 'transparent'
                }
            }
        }

        const columns = [
            {
                id: 'name',
                Header: <Translation id="name" defaultMessage="Name" />,
                filterMethod: (filter, row) => {
                    return row._original.name.toLowerCase().includes(filter.value.toLowerCase())
                },
                accessor: 'name'
            },
            {
                id: 'commercial_name_data',
                Header: <Translation id="commercial_name" defaultMessage="Commercial name" />,
                Cell: ({ original }) => {
                    return (
                        <div>
                            {JSON.parse(original.commercial_name_data.text) &&
                            JSON.parse(original.commercial_name_data.text).default_text &&
                            JSON.parse(original.commercial_name_data.text).default_text.replace(/(<([^>]+)>)/gi, '')}
                        </div>
                    )
                },
                filterMethod: (filter, row) => {
                    return (
                        JSON.parse(row._original.commercial_name_data.text) &&
                        JSON.parse(row._original.commercial_name_data.text)
                            .default_text.toLowerCase()
                            .includes(filter.value.toLowerCase())
                    )
                }
            },
            {
                id: 'start_date',
                Header: <Translation id="start_date" defaultMessage="Start Date" />,
                Cell: ({ original }) => {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            {moment(original.start_date)
                                .locale(this.props.locale)
                                .format('L')}
                        </div>
                    )
                },
                filterMethod: (filter, row) => {
                    return moment(row._original.start_date)
                        .locale(this.props.locale)
                        .format('L')
                        .toLowerCase()
                        .includes(filter.value.toLowerCase())
                },
                width: 120
            },
            {
                id: 'end_date',
                Header: <Translation id="end_date" defaultMessage="End Date" />,
                Cell: ({ original }) => {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            {moment(original.end_date)
                                .locale(this.props.locale)
                                .format('L')}
                        </div>
                    )
                },
                filterMethod: (filter, row) => {
                    return moment(row._original.start_date)
                        .locale(this.props.locale)
                        .format('L')
                        .toLowerCase()
                        .includes(filter.value.toLowerCase())
                },
                width: 120
            },
            {
                id: 'market',
                Header: <Translation id="market" defaultMessage="Market" />,
                filterMethod: (filter, row) => {
                    return row._original.market.toLowerCase().includes(filter.value.toLowerCase())
                },
                accessor: 'market'
            },
            {
                id: 'countries',
                Header: <Translation id="countries" defaultMessage="Countries" />,
                filterMethod: (filter, row) => {
                    return row._original.countries.toLowerCase().includes(filter.value.toLowerCase())
                },
                Cell: ({ original }) => {
                    const countries = original.countries.split(',')
                    return countries.map((countyCode, i) => {
                        if (!countyCode) return null
                        else
                            return (
                                <span>
                                    {this.props.intl.formatMessage({ id: 'country_' + countyCode, defaultMessage: countyCode })}
                                    {countries.length - 1 > i && ', '}
                                </span>
                            )
                    })
                }
            },
            {
                id: 'centers',
                Header: <Translation id="centers" defaultMessage="Centers" />,
                filterMethod: (filter, row) => {
                    return row._original.centers.toLowerCase().includes(filter.value.toLowerCase())
                },
                Cell: ({ original }) => {
                    return (
                        <span data-tip data-for={'promotion' + original.id}>
                            {original.centersData.map(center => {
                                if (center) {
                                    return center.id + ' ' + center.name + ', '
                                }
                                return null
                            })}
                            <ReactTooltip id={'promotion' + original.id} bg-coloreffect="solid" place="bottom">
                                <div>
                                    {original.centersData.map(center => {
                                        if (center) {
                                            return <div>{center.id + ' ' + center.name}</div>
                                        }
                                        return null
                                    })}
                                </div>
                            </ReactTooltip>
                        </span>
                    )
                }
            },
            {
                id: 'created_by',
                Header: <Translation id="created_by" defaultMessage="Created by" />,
                filterMethod: (filter, row) => {
                    return row._original.created_by.toLowerCase().includes(filter.value.toLowerCase())
                },
                accessor: 'created_by'
            },
            {
                id: 'discount_request_state',
                Header: <Translation id="discount_request_state" defaultMessage="Discount request state" />,
                filterMethod: (filter, row) => {
                    return row._original.discount_request_status.toLowerCase().includes(filter.value.toLowerCase())
                },
                accessor: 'discount_request_status',
                Cell: ({ original }) => {
                    return (
                        <DiscountStateLabel campaign={original}/>
                    )
                },
                show: this.props.canViewDiscountStep
            },
            {
                id: 'status',
                Header: <Translation id="status" defaultMessage="Status" />,
                Cell: ({ original }) => {return this.getActionCell(original)}
            }
        ]

        // const selectedRow = this.state.selectedRow && this.state.selectedRow.original
        // const disablePricingButton = !selectedRow || selectedRow.discount_request_status === 'NONE'
        // const disableApproved = !selectedRow || selectedRow.discount_request_status === 'APPROVED'
        // const disableDenied = !selectedRow || selectedRow.discount_request_status === 'DENIED'

        return (
            <div className="container">
                <section className="promotions-list">
                    <h2 className="my-4">
                        <Translation id="campaign_list" defaultMessage="Campaign List" />
                    </h2>

                        <div className="container-group-promo-btns">
                            { !this.props.isPricing && (
                                <React.Fragment>
                                    <div
                                        className="btn btn-outline-secondary"
                                        onClick={() => {
                                            this.props.initCreateNewPromotion().then(() => this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_ADMIN_PROMOTIONS' })))
                                        }}
                                    >
                                        <Translation id="create" defaultMessage="Create" />
                                    </div>
                                    <div
                                        className={`btn btn-outline-secondary ${!this.state.selected ? 'disabled' : ''}`}
                                        onClick={() => {
                                            if (this.state.selected) {
                                                this.props
                                                    .fetchPromotion(this.state.selected, this.props.intl)
                                                    .then(() => this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_ADMIN_PROMOTIONS' })))
                                            }
                                        }}
                                    >
                                        <Translation id="view_edit" defaultMessage="View/Edit" />
                                    </div>
                                </React.Fragment>
                            )}
                            <div
                                className={`btn btn-outline-secondary ${!this.state.selected ? 'disabled' : ''} ${this.state.showModalPreview ? 'active' : ''}`}
                                onClick={() => this.state.selected && this.setState({ showModalPreview: true })}
                            >
                                <span>
                                    <Translation id="preview" defaultMessage="Preview" />
                                </span>
                            </div>
                            {!this.props.isPricing && (
                                <div className={`btn btn-outline-secondary ${!this.state.selected ? 'disabled' : ''}`} onClick={() => {
                                        if (this.state.selected) {
                                            this.props.sendClonePromotion(this.state.selected).then(() => this.props.fetchPromotionsList())
                                        } else {
                                            this.props.showAlertFailure()
                                        }
                                    }}
                                >
                                    <Translation id="clone" defaultMessage="Clone" />
                                </div>
                            )}
                        </div>

                    {/*{ this.props.isPricing && (*/}
                    {/*    <div className="d-flex justify-content-start">*/}
                    {/*        <div*/}
                    {/*            className={`btn btn-outline-secondary mr-2 ${disablePricingButton || disableApproved ? 'disabled' : ''}`}*/}
                    {/*            onClick={() => {*/}
                    {/*                this.props.updateCampaignDiscountStatus(this.state.selected, 'APPROVED')*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <Translation id="approve" defaultMessage="Approve" />*/}
                    {/*        </div>*/}
                    {/*        <div*/}
                    {/*            className={`btn btn-outline-secondary mr-2 ${disablePricingButton || disableDenied ? 'disabled' : ''}`}*/}
                    {/*            onClick={() => {*/}
                    {/*                this.props.updateCampaignDiscountStatus(this.state.selected, 'DENIED')*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <Translation id="deny" defaultMessage="Deny" />*/}
                    {/*        </div>*/}
                    {/*        <div*/}
                    {/*            className={`btn btn-outline-secondary mr-2 ${disablePricingButton ? 'disabled' : ''}`}*/}
                    {/*            onClick={() => this.setState({ showApplyDiscountsModal: true, selectedCampaign: this.state.selectedRow.original })}*/}
                    {/*        >*/}
                    {/*            <Translation id="check_discounts" defaultMessage="Check discounts" />*/}
                    {/*        </div>*/}
                    {/*        <div*/}
                    {/*            className={`btn btn-outline-secondary mr-2 ${!this.state.selected ? 'disabled' : ''} ${this.state.showModalPreview ? 'active' : ''}`}*/}
                    {/*            onClick={() => this.state.selected && this.setState({ showModalPreview: true })}*/}
                    {/*        >*/}
                    {/*            <span>*/}
                    {/*                <Translation id="preview" defaultMessage="Preview" />*/}
                    {/*            </span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    <div className="row my-5">
                        {filterable && (
                            <div className="col-12">
                                <h5>
                                    <Translation id="filter_your_campaigns_using_the_following_fields" defaultMessage="Filter your campaigns using the following fields" />
                                </h5>
                            </div>
                        )}
                        <div className="col-12">
                            <ReactTable
                                filterable={filterable}
                                manual
                                data={this.props.data.data}
                                pages={this.props.data.last_page}
                                loading={this.props.isLoading}
                                loadingText={<Spinner isLoading={this.props.isLoading} />}
                                columns={columns}
                                getTrProps={trProps}
                                subRowsKey="lots"
                                defaultPageSize={8}
                                pageSizeOptions={[8, 16, 32, 64, 128]}
                                previousText={<Translation id="previous" defaultMessage="Previous" />}
                                nextText={<Translation id="next" defaultMessage="Next" />}
                                noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                                pageText={<Translation id="page" defaultMessage="Page" />}
                                ofText={<Translation id="of" defaultMessage="Of" />}
                                className="light promotions-list"
                                onFetchData={state => {
                                    this.props.fetchPromotionsList(state.page + 1, state.pageSize, state.filtered)
                                }}
                            />
                        </div>
                    </div>

                </section>

                {this.state.showModalPreview && (
                    <Modal title={<Translation id="preview" defaultMessage="Preview" />} onClose={() => this.setState({ showModalPreview: false })} size="large">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <label>{this.props.intl.formatMessage({ id: 'promotion_select_user' })}</label>
                                    <Select
                                        placeholder={this.props.intl.formatMessage({ id: 'promotion_select_user' })}
                                        options={
                                            this.props.users &&
                                            this.props.users.map(item => {
                                                return { label: item.name, value: item.login }
                                            })
                                        }
                                        value={this.state.userSelected}
                                        onChange={e => {
                                            this.setState({ userSelected: e && e.value })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <label>
                                        <Translation id="send_email_preview" defaultMessage="Preview newsletter:" />
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'enter_the_email_to_send_the_preview',
                                            defaultMessage: 'Enter the email to send the preview'
                                        })}
                                        value={this.state.inputEmailValue}
                                        onChange={e => this.setState({ inputEmailValue: e.target.value })}
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                    />
                                    <br />

                                    <br />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button" onClick={() => this.sendPreviewPromotionEmail()}>
                                            <Translation id="send_email" defaultMessage="Send email" />
                                        </button>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label>
                                        <Translation id="preview_landing" defaultMessage="Preview landing:" />
                                    </label>
                                    <br />
                                    <div
                                        style={{ height: '38px' }}
                                        className={`btn btn-outline-secondary mr-2 ${!this.state.selected ? 'disabled' : ''}`}
                                        onClick={() => {
                                            if (this.state.selected) {
                                                this.props
                                                    .fetchPromotion(this.state.selected, this.props.intl)
                                                    .then(() =>
                                                        this.props.redirect(
                                                            this.props.intl.formatMessage({ id: 'ROUTE_PROMOTION_LANDING' }).replace(':promotionId?', this.state.selected)
                                                        )
                                                    )
                                            }
                                        }}
                                    >
                                        <Translation id="landing" defaultMessage="Landing" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}

                {this.state.showApplyDiscountsModal && (
                    <ApplyDiscountsModal campaign={this.state.selectedCampaign}
                                         onClose={() => this.setState({ showApplyDiscountsModal: false, selectedCampaign: null })}
                    />
                )}
            </div>
        )
    }
}

const PromotionsListContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(PromotionsList))
)
export default PromotionsListContainer
