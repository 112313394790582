import { connect } from 'react-redux'

import { getCommonParams, getIsLogged, getPriceListAtSelectedCenter, getUserPriceList, getUserType } from '../../store/login/reducers'
import { fetchLandingBySlug, fetchPreviewLandingBySlug, fetchLandinById, fetchProductsLandingsByCenters } from '../../store/landing/actions'
import { addMallCartLine } from '../../store/cart/actions'

import { loginRequired } from '../../store/login/actions'

import LandingTemplates from './LandingTemplates'
import { getFilters } from './utils'
import { getSalesOrg, getSelectedCenter } from '../../store/centers/reducers'
import { getIsLoadingLanding } from '../../store/landing/reducers'
import { showToastNotification } from '../../store/toast-notifications/actions'
import { getLines } from '../../store/cart/reducers'
import { dispatchPushURL } from '../../store/ui/actions'
import {setLastSelectedProduct} from "../../store/selected-objects/actions";

const mapStateToProps = (state, { match }) => {
    return {
        lines: getLines(state),
        isLogged: getIsLogged(state),
        userLandings: state.landing.userLandings,
        landing: state.landing.landingBySlug,
        productsLanding: state.landing.productsLanding,
        productsLots: state.landing.productsLots,
        slug: match.params.slug,
        currentIso: match.params.iso,
        previewMode: match.params.preview === 'preview',
        filters: getFilters((state.landing.landingBySlug && state.landing.landingBySlug.landing && state.landing.landingBySlug.landing.products) || []),
        commonParamsAnalytics: getCommonParams(state),
        priceList: getPriceListAtSelectedCenter(state),
        userPriceList: getUserPriceList(state),
        selectedCenter: getSelectedCenter(state),
        userType: getUserType(state),
        isLoadingLanding: getIsLoadingLanding(state),
        isUsaOrCa: ['2011', '2032'].includes(getSalesOrg(state)),
        isLoadingProductsAndLots: state.landing.isLoadingProductsAndLots
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToastNotification: (text = '', type = 'none', duration = 10000) => dispatch(showToastNotification(text, type, duration)),
        fetchLandingbyId: (id, iso) => dispatch(fetchLandinById(id, iso)),
        fetchLandingBySlug: (slug) => dispatch(fetchLandingBySlug(slug)),
        fetchPreviewLandingBySlug: (slug, langID) => dispatch(fetchPreviewLandingBySlug(slug, langID)),
        loginRequired: (callback) => {
            dispatch(loginRequired(callback))
        },
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        addCartLine: (productId, qty, extra, callbackCenter, dektonGripRequested, productSupplierCenter) => {
            dispatch(addMallCartLine(productId, qty, extra, callbackCenter, dektonGripRequested, productSupplierCenter))
        },
        fetchProductsLandingsByCenters: (landingId, centers, products, portfolioMode) => {
            return dispatch(fetchProductsLandingsByCenters(landingId, centers, products, portfolioMode))
        },
        addProductInFetchedProducts: (product, centerId, locale) => {
            dispatch({ type: 'FETCH_PRODUCT_SUCCESS', product, centerId, locale })
        },
        setSelectedProductId: productId => dispatch({ type: 'SET_SELECTED_MALL_PRODUCT', productId }),
        setSelectedProduct: product => dispatch(setLastSelectedProduct(product))
    }
}

const LandingTemplatesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingTemplates)

export default LandingTemplatesContainer
