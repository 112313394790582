import React from 'react'
import rectangle from '../../../assets/img/banners-hybriq/hybriq_1140x200.png'
import squared from '../../../assets/img/banners-hybriq/hybriq_210x210.png'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getLanguage } from '../../../store/ui/reducers'

const mapStateToProps = (state) => {
    return {
        language: getLanguage(state)
    }
}

class BannerHybriq extends React.Component {
    render() {
        const img = this.props.squared ? squared : rectangle
        const links = {
            it: 'https://www.cosentino.com/it-it/silestone/hybriq-technology/',
            ja: 'https://www.cosentino.com/ja-jp/silestone/hybriq-technology/',
            de: 'https://www.cosentino.com/de/silestone/hybriq-technology/',
            es: 'https://www.cosentino.com/es/silestone/hybriq-technology/',
            he: 'https://www.cosentino.com/he-il/silestone/hybriq-technology/',
            en: 'https://www.cosentino.com/en-gb/silestone/hybriq-technology/',
            fr: 'https://www.cosentino.com/fr-fr/silestone/hybriq-technology/',
            sv: 'https://www.cosentino.com/sv-se/silestone/hybriq-technology/',
            tr: 'https://www.cosentino.com/tr-tr/silestone/hybriq-technology/',
            no: 'https://www.cosentino.com/no-no/silestone/hybriq-technology/',
            da: 'https://www.cosentino.com/da-dk/silestone/hybriq-technology/',
            nl: 'https://www.cosentino.com/nl-nl/silestone/hybriq-technology/',
            fi: 'https://www.cosentino.com/fi-fi/silestone/hybriq-technology/',
            pl: 'https://www.cosentino.com/pl-pl/silestone/hybriq-technology/',
            pt: 'https://www.cosentino.com/pt-pt/silestone/hybriq-technology/',
            'en-US': 'https://www.cosentino.com/usa/silestone/hybriq-technology/',
            'fr-CA': 'https://www.cosentino.com/fr-ca/silestone/hybriq-technology/',
            'en-CA': 'https://www.cosentino.com/en-ca/silestone/hybriq-technology/'
        }
        return (
            <div className="banner_hybriq" style={this.props.styles}>
                <a href={links[this.props.language]} target="_blank" rel="noopener noreferrer">
                    <img style={{ maxWidth: '100%' }} src={img} alt="banner-hybriq" />
                </a>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(BannerHybriq)
)
