import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import Button from '../global/Button'
import confirm from '../global/confirm'
import DatePicker from 'react-datepicker'
import LocalizedLink from '../global/LocalizedLink'
import Modal from '../global/Modal'
import moment from 'moment'
import OrderStatus from './OrderStatus'
import ReactTable from 'react-table'
import Spinner from '../global/Spinner'
import IkeaOrderStatus from './IkeaOrderStatus'
import PackingList from './PackingList'

const confirmRejectOrder = (reservationId, orderId, onRejectOrder, intl) => {
    return confirm(intl.formatMessage({ id: 'CONFIRM_REJECT_ORDER' }), {intl: intl}).then(
        confirm => {
            return onRejectOrder(reservationId, orderId)
        },
        cancel => {
            return Promise.reject()
        }
    )
}

class OldOrders extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedOrder: null,
            selectedOrderId: null,
            showOrderDetails: false,
            startDate: moment().add(this.props.isIkeaCenter ? -15 : -90, 'days'),
            endDate: moment(),
            installationData: null,
            oldInstallationData: null
        }
    }

    componentDidMount() {
        if (
            (this.props.type === 'transfer' && this.props.transferOrders.length === 0) ||
            (this.props.type === 'open' && this.props.openOrders.length === 0) ||
            (!this.props.type && this.props.orders.length === 0)
        )
            this.props.fetchOrders('ikea', this.state.startDate, this.state.endDate, null, null, null, this.props.selectedShippingAddressForIkea)
    }
    haveInstallationData = original => {
        return original.notas
    }
    showOrderInstallationData = original => {
        let installationDataSplited = original.notas.split(' // ')
        if (installationDataSplited.length >= 6) {
            let name = installationDataSplited[0].replace('Name: ', '')
            let address = installationDataSplited[1].replace('Address: ', '')
            let town = installationDataSplited[2].replace('Town: ', '')
            let province = installationDataSplited[3].replace('Pronvince: ', '')
            let zip = installationDataSplited[4].replace('Zip Code: ', '')
            let phone = installationDataSplited[5].replace('Phone: ', '')
            let comments = installationDataSplited[6].replace('Comments: ', '')

            this.setState({ installationData: { name, address, town, province, zip, phone, comments } })
        } else if (original.notas) {
            this.setState({ oldInstallationData: original.notas })
        }
    }
    showOrderDetails = (reservationId, orderId) => {
        this.setState({ selectedOrder: reservationId, selectedOrderId: orderId, showOrderDetails: true })
    }
    rejectOrder = (reservationId, orderId) => {
        confirmRejectOrder(reservationId, orderId, this.props.rejectOrder, this.props.intl)
    }
    closeOrderDetails = () => {
        this.setState({ selectedOrder: null, selectedOrderId: null, showOrderDetails: false })
    }
    search = e => {
        this.setState({ search: e.target.value })
    }
    changeStartDate = date => {
        this.setState({ startDate: date })
    }

    changeEndDate = date => {
        this.setState({ endDate: date }, () => this.props.fetchOrders('ikea', this.state.startDate, this.state.endDate))
    }
    canDoActions = original => {
        if (original.statusAtRescab === 'RR') return true
        //the logic to edit before oldorders
        return !['06', '07', '08', '09'].includes(original.status)
        //not working on production
        //return this.props.statusMap[original.statusText] ? this.props.statusMap[original.statusText].editable : false
    }
    render() {
        const { intl } = this.props
        let orders = this.props.type ? this.props.openOrders : this.props.orders
        if (!orders) return null
        let ordersFiltered = null

        if (this.props.isIkeaCenter)
            orders = orders.filter(row => {
                return row.shippingAddressId.includes(this.props.selectedShippingAddressForIkea)
            })

        if (this.state.search) {
            ordersFiltered = orders
            ordersFiltered = ordersFiltered.filter(row => {
                return (
                    row.reservationId.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    row.orderId.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    row.createdDate.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    (row.po && row.po.toLowerCase().includes(this.state.search.toLowerCase()))
                )
            })
        }
        const dataOrders = ordersFiltered !== null ? ordersFiltered : orders
        const columns = [
            {
                Header: <Translation id="order_details" defaultMessage="Order details" />,
                id: 'assign',
                Cell: ({ original }) => {
                    return (
                        <React.Fragment>
                            <Button inline size="small" icon="hand-point-up" onClick={() => this.showOrderDetails(original.reservationId, original.orderId)}>
                                <Translation id="packing_list" defaultMessage="packing_list" />
                            </Button>

                            {this.props.isIkeaCenter && this.haveInstallationData(original) && (
                                <React.Fragment>
                                    <div style={{ marginTop: '5px' }} />
                                    <Button inline size="small" icon="clipboard-list" far onClick={() => this.showOrderInstallationData(original)}>
                                        <Translation id="installation_data" defaultMessage="Installation Data" />
                                    </Button>
                                </React.Fragment>
                            )}
                            {original.rejected ? null : (
                                <React.Fragment>
                                    {' '}
                                    {this.props.canEditOrders && this.canDoActions(original) && (
                                        <LocalizedLink
                                            routeId="ROUTE_PROFILE"
                                            params={{ filter: 'edit-order', open: original.orderId }}
                                            onClick={e => {
                                                if (!original.orderId) e.preventDefault()
                                            }}
                                        >
                                            <Button inline size="small" icon="edit" disabled={!original.orderId}>
                                                <Translation id="edit_order" defaultMessage="Edit" />
                                            </Button>
                                        </LocalizedLink>
                                    )}{' '}
                                    {this.props.canDeleteOrders && this.canDoActions(original) && (
                                        <Button
                                            inline
                                            size="small"
                                            icon="trash"
                                            onClick={() => this.rejectOrder(original.reservationId, original.orderId)}
                                            disabled={!original.orderId || !original.reservationId}
                                        >
                                            <Translation id="delete" defaultMessage="delete" />
                                        </Button>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )
                },
                className: 'text-center',
                headerClassName: 'text-center',
                minWidth: 280
            },
            { Header: <Translation id="reservation_id" defaultMessage="Reservation ID" />, accessor: 'reservationId' },
            { Header: <Translation id="order_id" defaultMessage="Order ID" />, accessor: 'orderId' },
            {
                Header: <Translation id="customer_id" defaultMessage="Customer id" />,
                accessor: 'customerId',
                show: this.props.userType !== 'CUSTOMER'
            },
            { Header: <Translation id="created_date" defaultMessage="Created Date" />, accessor: 'createdDate' },
            { Header: <Translation id="customer_order" defaultMessage="Customer Order" />, accessor: 'po' },
            {
                Header: <Translation id="status" defaultMessage="Status" />,
                id: 'status',
                Cell: ({ original }) => {
                    return this.props.isIkeaCenter ? (
                        <IkeaOrderStatus original={original} rejected={original.rejected} />
                    ) : (
                        <OrderStatus original={original} rejected={original.rejected} />
                    )
                },
                className: 'text-center',
                headerClassName: 'text-center',
                minWidth: 250
            },
            { Header: <Translation id="quote" defaultMessage="Quote" />, accessor: 'offer', show: this.props.canOffer },
            { Header: <Translation id="container" defaultMessage="Container" />, accessor: 'container' },
            {
                Header: <Translation id="eta" defaultMessage="ETA" />,
                Cell: ({ original }) => {
                    return <span>{original.eta !== '0000-00-00' && original.eta}</span>
                },
                accessor: 'eta',
                minWidth: 100
            }
        ]
        const columnsOfIkea = [
            { Header: <Translation id="id_employee" defaultMessage="Id employee" />, accessor: 'employeeId' },
            { Header: <Translation id="addressee" defaultMessage="Addressee" />, accessor: 'customerName' }
        ]
        if (this.props.isIkeaCenter) columns.splice(7, 1, ...columnsOfIkea)
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="toolbar">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 order_header">
                                        <div className="group_title">
                                            <h4>
                                                {!this.props.type && <Translation id="orders_history" defaultMessage="Orders history" />}
                                                {this.props.type === 'open' && <Translation id="open_orders" defaultMessage="Open orders" />}
                                                {this.props.type === 'transfer' && <Translation id="transfer_orders" defaultMessage="Transfer orders" />}
                                            </h4>
                                        </div>
                                        <div className="group_date_picker">
                                            {this.props.type !== 'transfer' && (
                                                <React.Fragment>
                                                    <div className="group_date_picker__item">
                                                        <div className="name">
                                                            <Translation id="from" defaultMessage="From" /> <i className="fal fa-chevron-down" />
                                                        </div>
                                                        <DatePicker
                                                            selected={this.state.startDate}
                                                            selectsStart={true}
                                                            startDate={this.state.startDate}
                                                            endDate={this.state.endDate}
                                                            maxDate={moment()}
                                                            onChange={selectedOption => this.changeStartDate(selectedOption, 'startDate')}
                                                            onFocus={() => this.setState({ endDate: null })}
                                                            autoComplete="off"
                                                            onKeyDown={e => e.preventDefault()}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            className="form-control form-control-sm date"
                                                        />
                                                    </div>
                                                    <div className="group_date_picker__item">
                                                        <div className="name">
                                                            <Translation id="to" defaultMessage="To" /> <i className="fal fa-chevron-down" />
                                                        </div>
                                                        <DatePicker
                                                            selected={this.state.endDate}
                                                            selectsEnd
                                                            startDate={this.state.startDate}
                                                            endDate={this.state.endDate}
                                                            minDate={this.state.startDate}
                                                            maxDate={
                                                                //PARA IKEA 3 MESES MAXIMOPORQUE UTILIZA LA OPEN DOC
                                                                this.props.isIkeaCenter
                                                                    ? moment() < moment(this.state.startDate).add(3, 'month')
                                                                        ? moment()
                                                                        : moment(this.state.startDate).add(3, 'month')
                                                                    : //RESTO DE CASOS 12 MESES
                                                                    moment() < moment(this.state.startDate).add(12, 'month')
                                                                    ? moment()
                                                                    : moment(this.state.startDate).add(12, 'month')
                                                            }
                                                            onChange={selectedOption => this.changeEndDate(selectedOption, 'endDate')}
                                                            autoComplete="off"
                                                            onKeyDown={e => e.preventDefault()}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            className="form-control form-control-sm date"
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <div className="location search-bar search-bar--thin disabled">
                                            <input
                                                type="text"
                                                data-qa="query"
                                                className="search-box"
                                                placeholder={intl.formatMessage({
                                                    id: 'search'
                                                })}
                                                value={this.state.search}
                                                onChange={e => this.setState({ search: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ReactTable
                            data={dataOrders}
                            loading={this.props.isLoading}
                            loadingText={<Spinner isLoading={this.props.isLoading} />}
                            className="-highlight"
                            columns={columns}
                            defaultSorted={[{ id: 'reservationId', desc: true }]}
                            defaultPageSize={8}
                            pageSizeOptions={[8, 16, 32, 64, 128]}
                            previousText={<Translation id="previous" defaultMessage="Previous" />}
                            nextText={<Translation id="next" defaultMessage="Next" />}
                            noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                            pageText={<Translation id="page" defaultMessage="Page" />}
                            ofText={<Translation id="of" defaultMessage="Of" />}
                        />
                        {this.state.showOrderDetails && (
                            <PackingList
                                reservationId={this.state.selectedOrder}
                                order={this.state.selectedOrder}
                                orderId={this.state.selectedOrderId}
                                onClose={() => this.closeOrderDetails()}
                                canEditOrders={this.props.canEditOrders}
                            />
                        )}
                        {(this.state.installationData || this.state.oldInstallationData) && (
                            <Modal
                                title={<Translation id="installation_data" defaultMessage="Installation Data" />}
                                onClose={() => this.setState({ installationData: null })}
                                size="large"
                                footer={
                                    <div className="modal-footer__buttons">
                                        <Button onClick={() => this.setState({ installationData: null, oldInstallationData: null })} inverted size="medium">
                                            <Translation id="ok" defaultMessage="Ok" />
                                        </Button>
                                    </div>
                                }
                            >
                                {this.state.installationData && (
                                    <React.Fragment>
                                        <Translation id="name" defaultMessage="Name" />: <span>{this.state.installationData.name}</span> <br />
                                        <Translation id="address" defaultMessage="Address" />: <span>{this.state.installationData.address}</span> <br />
                                        <Translation id="town" defaultMessage="Town" />: <span>{this.state.installationData.town}</span> <br />
                                        <Translation id="province" defaultMessage="Province" />: <span>{this.state.installationData.province}</span> <br />
                                        <Translation id="zip" defaultMessage="Zip Code" />: <span>{this.state.installationData.zip}</span> <br />
                                        <Translation id="phone" defaultMessage="Phone" />: <span>{this.state.installationData.phone}</span> <br />
                                        <Translation id="comments" defaultMessage="Comments" />: <span>{this.state.installationData.comments}</span> <br />
                                    </React.Fragment>
                                )}
                                {this.state.oldInstallationData && <span>{this.state.oldInstallationData}</span>}
                            </Modal>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(OldOrders)
