import React from 'react'

import { connect } from 'react-redux'
import { withMsal } from '@azure/msal-react'

import { addLocaleData } from 'react-intl'
import { IntlProvider } from 'react-intl'
import { Route, Switch } from 'react-router-dom'
import enMessages from './locales/enMessages.json'
import enLocale from 'react-intl/locale-data/en'
import esMessages from './locales/esMessages.json'
import esLocale from 'react-intl/locale-data/es'
import deMessages from './locales/deMessages.json'
import deLocale from 'react-intl/locale-data/de'
import daMessages from './locales/daMessages.json'
import daLocale from 'react-intl/locale-data/da'
import enUsMessages from './locales/en-usMessages.json'
import enUsLocale from 'react-intl/locale-data/en'
import fiMessages from './locales/fiMessages.json'
import fiLocale from 'react-intl/locale-data/fi'
import enCaMessages from './locales/en-caMessages.json'
import frCaMessages from './locales/fr-caMessages.json'
import frMessages from './locales/frMessages.json'
import frLocale from 'react-intl/locale-data/fr'
import itMessages from './locales/itMessages.json'
import itLocale from 'react-intl/locale-data/it'
import jaMessages from './locales/jaMessages.json'
import jaLocale from 'react-intl/locale-data/ja'
import nlMessages from './locales/nlMessages.json'
import nlLocale from 'react-intl/locale-data/nl'
import noMessages from './locales/noMessages.json'
import noLocale from 'react-intl/locale-data/no'
import plMessages from './locales/plMessages.json'
import plLocale from 'react-intl/locale-data/pl'
import ptMessages from './locales/ptMessages.json'
import ptLocale from 'react-intl/locale-data/pt'
import svMessages from './locales/svMessages.json'
import svLocale from 'react-intl/locale-data/sv'
import trMessages from './locales/trMessages.json'
import trLocale from 'react-intl/locale-data/tr'
import heMessages from './locales/heMessages.json'
import heLocale from 'react-intl/locale-data/he'

import moment from 'moment'
import Routes from '../routes'
import { askLocation } from '../store/ui/actions'
import { withRouter } from 'react-router-dom'
import { getLanguage, getLanguageDir } from '../store/ui/reducers.js'
import Spinner from '../components/global/Spinner.js'
import { getIsLogged } from '../store/login/reducers.js'
import { fetchProfileConfiguration, fetchPromotionConfiguration } from '../store/profile-configuration/actions.js'
import { hotjar } from 'react-hotjar'
import { getCallbackCenter, getLoginCallback } from '../store/login/reducers'
import { messages } from './messages'

// Kendo internationalization
import { IntlProvider as KendoIntlProvider, load, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl'
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json'
import currencyData from 'cldr-core/supplemental/currencyData.json'
import weekData from 'cldr-core/supplemental/weekData.json'
import enCaGregorian from 'cldr-dates-full/main/en/ca-gregorian.json'
import enDateFields from 'cldr-dates-full/main/en/dateFields.json'
import deCaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json'
import deDateFields from 'cldr-dates-full/main/de/dateFields.json'
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json'
import esDateFields from 'cldr-dates-full/main/es/dateFields.json'
import enCACaGregorian from 'cldr-dates-full/main/en-CA/ca-gregorian.json'
import enCADateFields from 'cldr-dates-full/main/en-CA/dateFields.json'
import enUSCaGregorian from 'cldr-dates-full/main/en-US-POSIX/ca-gregorian.json'
import enUSDateFields from 'cldr-dates-full/main/en-US-POSIX/dateFields.json'
import daCaGregorian from 'cldr-dates-full/main/da/ca-gregorian.json'
import daDateFields from 'cldr-dates-full/main/da/dateFields.json'
import fiCaGregorian from 'cldr-dates-full/main/fi/ca-gregorian.json'
import fiDateFields from 'cldr-dates-full/main/fi/dateFields.json'
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json'
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json'
import frCACaGregorian from 'cldr-dates-full/main/fr-CA/ca-gregorian.json'
import frCADateFields from 'cldr-dates-full/main/fr-CA/dateFields.json'
import heCaGregorian from 'cldr-dates-full/main/he/ca-gregorian.json'
import heDateFields from 'cldr-dates-full/main/he/dateFields.json'
import itCaGregorian from 'cldr-dates-full/main/it/ca-gregorian.json'
import itDateFields from 'cldr-dates-full/main/it/dateFields.json'
import jaCaGregorian from 'cldr-dates-full/main/ja/ca-gregorian.json'
import jaDateFields from 'cldr-dates-full/main/ja/dateFields.json'
import nlCaGregorian from 'cldr-dates-full/main/nl/ca-gregorian.json'
import nlDateFields from 'cldr-dates-full/main/nl/dateFields.json'
import noCaGregorian from 'cldr-dates-full/main/nn/ca-gregorian.json'
import noDateFields from 'cldr-dates-full/main/nn/dateFields.json'
import plCaGregorian from 'cldr-dates-full/main/pl/ca-gregorian.json'
import plDateFields from 'cldr-dates-full/main/pl/dateFields.json'
import ptCaGregorian from 'cldr-dates-full/main/pt/ca-gregorian.json'
import ptDateFields from 'cldr-dates-full/main/pt/dateFields.json'
import svCaGregorian from 'cldr-dates-full/main/sv/ca-gregorian.json'
import svDateFields from 'cldr-dates-full/main/sv/dateFields.json'
import trCaGregorian from 'cldr-dates-full/main/tr/ca-gregorian.json'
import trDateFields from 'cldr-dates-full/main/tr/dateFields.json'

//Moment js locale
import 'moment/locale/es'
import 'moment/locale/it'
import 'moment/locale/he'
import 'moment/locale/tr'
import 'moment/locale/fi'
import 'moment/locale/fr-ca'
import 'moment/locale/ja'
import 'moment/locale/nb'
import 'moment/locale/pl'
import 'moment/locale/en-ca'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/da'
import 'moment/locale/pt'
import 'moment/locale/es'
import 'moment/locale/sv'
import 'moment/locale/nl'

addLocaleData([
    ...enLocale,
    ...esLocale,
    ...deLocale,
    ...fiLocale,
    ...frLocale,
    ...heLocale,
    ...itLocale,
    ...jaLocale,
    ...nlLocale,
    ...noLocale,
    ...plLocale,
    ...ptLocale,
    ...svLocale,
    ...trLocale,
    ...enUsLocale,
    ...daLocale
])

const Error404 = () => <h1>404</h1>
const mapStateToProps = (state,{match}) => {
    return {
        locale: getLanguage(state),
        dir: getLanguageDir(state),
        isLogged: getIsLogged(state),
        callback: getLoginCallback(state),
        callbackCenter: getCallbackCenter(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        askLocation: (locale, route) => {
            return dispatch(askLocation(locale, route))
        },
        fetchProfileConfiguration: () => {
            return dispatch(fetchProfileConfiguration())
        },
        fetchPromotionConfiguration: () => {
            return dispatch(fetchPromotionConfiguration())
        },
        callMountApp: () => dispatch({type:'MOUNT_APP'})
    }
}

const parseLangToCldr = lang => {
    if (lang === 'no') {
        return 'nn'
    } else if (lang === 'en-ca') {
        return 'en-CA'
    } else if (lang === 'fr-ca') {
        return 'fr-CA'
    }

    return lang
}

loadMessages(daMessages, 'da')
loadMessages(deMessages, 'de')
loadMessages(enCaMessages, 'en-CA')
loadMessages(enUsMessages, 'en-us')
loadMessages(enMessages, 'en')
loadMessages(esMessages, 'es')
loadMessages(fiMessages, 'fi')
loadMessages(frCaMessages, 'fr-CA')
loadMessages(frMessages, 'fr')
loadMessages(heMessages, 'he')
loadMessages(itMessages, 'it')
loadMessages(jaMessages, 'ja')
loadMessages(nlMessages, 'nl')
loadMessages(noMessages, 'nn')
loadMessages(plMessages, 'pl')
loadMessages(ptMessages, 'pt')
loadMessages(svMessages, 'sv')
loadMessages(trMessages, 'tr')

class MyIntlProvider extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            messagesLoaded: false
        }

        this.props.callMountApp();




        this.html = window.document.documentElement

        if (this.props.isLogged) {
            //TODO: i hate this fucking line
            // this.props.fetchProfileConfiguration().then(() => {
                this.props.askLocation(this.props.match.params.lang, this.props.location.pathname)
            // })
            this.props.fetchPromotionConfiguration()
        } else {
            this.props.askLocation(this.props.match.params.lang, this.props.location.pathname)
        }
        //1329517
        hotjar.initialize(1069993, 6)

        load(
            likelySubtags,
            currencyData,
            weekData,
            enCaGregorian,
            enDateFields,
            deCaGregorian,
            deDateFields,
            esCaGregorian,
            esDateFields,
            enCACaGregorian,
            enCADateFields,
            enUSCaGregorian,
            enUSDateFields,
            daCaGregorian,
            daDateFields,
            fiCaGregorian,
            fiDateFields,
            frCaGregorian,
            frDateFields,
            frCACaGregorian,
            frCADateFields,
            heCaGregorian,
            heDateFields,
            itCaGregorian,
            itDateFields,
            jaCaGregorian,
            jaDateFields,
            nlCaGregorian,
            nlDateFields,
            noCaGregorian,
            noDateFields,
            plCaGregorian,
            plDateFields,
            ptCaGregorian,
            ptDateFields,
            svCaGregorian,
            svDateFields,
            trCaGregorian,
            trDateFields
        )
    }



    render() {
        if (this.props.locale === null) return <Spinner isLoading={true} />
        if (this.props.locale === 'no') moment.locale('nb')
        else moment.locale(this.props.locale)
        //set language at html tag
        this.html.lang = this.props.locale
        this.html.dir = this.props.dir ? this.props.dir : 'ltr'
        return (
            <div>
                <LocalizationProvider language={parseLangToCldr(this.props.locale)}>
                    <KendoIntlProvider locale={parseLangToCldr(this.props.locale)}>
                        <IntlProvider locale={this.props.locale} messages={messages[this.props.locale]} key={this.props.locale}>
                            <Switch>
                                <Route path={'/'} component={Routes} />
                            </Switch>
                        </IntlProvider>
                    </KendoIntlProvider>
                </LocalizationProvider>
            </div>
        )
    }
}

MyIntlProvider = withMsal(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(MyIntlProvider)
    )
)

const IntlRoute = () => {
    return (
        <div>
            <Switch>
                <Route path="/:lang(es|en|de|da|fi|he|en-CA|fr-CA|fr|it|ja|nl|no|pt|pl|sv|tr)?" component={MyIntlProvider} />
                <Route component={Error404} />
            </Switch>
        </div>
    )
}

export default IntlRoute
