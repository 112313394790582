import { getToken } from '../../store/login/reducers';
import { getFileByUrl } from '../../store/orders/actions';
import { getOrderDetailsHead, getInvoices } from '../../store/orders/reducers';
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Translation from '../global/Translation'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import Timeline from './Timeline'
import Button from '../global/Button'
import confirm from '../global/confirm'
import PackingList from './PackingList'
import { canDeleteOrders, canEditOrders, getOrderStatusMap, getStatusMap } from '../../store/orders/reducers'
import { rejectOrder } from '../../store/orders/actions'
import { getIsIkeaCenter } from '../../store/centers/reducers'
import { getShippingAddresses } from '../../store/shipping-addresses/reducers'
import { dispatchPushURL } from '../../store/ui/actions'
import GoBack from '../global/GoBack'
import Modal from '../global/Modal'
import iconFile from '../../assets/img/icons/file.svg'
import iconPrint from '../../assets/img/icons/print.svg'
import ReactTable from 'react-table'

const mapStateToProps = (state, { match }) => {
    return {
        canEditOrders: canEditOrders(state),
        canDeleteOrders: canDeleteOrders(state),
        shippingAddressesByUser: getShippingAddresses(state),
        statusMap: getStatusMap(state),
        orderStatusMap: getOrderStatusMap(state),
        isIkeaCenter: getIsIkeaCenter(state),
        orderDetailsHead: getOrderDetailsHead(state),
        invoices: getInvoices(state),
        reservationId: match.params.open,
        token:getToken(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        rejectOrder: (reservationId, orderId) => {
            return dispatch(rejectOrder(reservationId, orderId))
        },
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        getFileByUrl: url => dispatch(getFileByUrl(url))
    }
}

const DisplayShippingAddress = props => {
    let { shippingAddress, shippingAddressId, shippingAddressesByUser } = props
    if (!shippingAddress) {
        shippingAddress =
            shippingAddressesByUser && shippingAddressId
                ? shippingAddressesByUser.filter(shippingAddress => {
                      return parseInt(shippingAddress.shippingAddressId) === parseInt(shippingAddressId)
                  })[0]
                : null
    }

    return shippingAddress ? (
        <div>
            {shippingAddress.customerName}
            <br />
            {shippingAddress.address + '' + shippingAddress.zipCode}
            <br />
            {shippingAddress.city + ' ' + shippingAddress.country}
        </div>
    ) : null
}

class OrderHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDelivery: 0 // siempre seleccionamos la 0 porque se ordenan en el metodo getOrderStatusAndDelivery
        }
        this.getOrderInvoiceDownloadUrl = this.getOrderInvoiceDownloadUrl.bind(this)
        this.getOrderImagesDownloadUrl = this.getOrderImagesDownloadUrl.bind(this)
    }
    getOrderInvoiceDownloadUrl(invoiceNum) {
        // return process.env.REACT_APP_API_HOST + '/users/order-invoice/' + invoiceNum + '/?authToken=' + this.props.token
        return process.env.REACT_APP_API_HOST + '/users/order-invoice/' + invoiceNum
    }

    getOrderImagesDownloadUrl() {
        return process.env.REACT_APP_API_HOST + '/users/order-images?reservationId=' + this.props.reservationId + '&lang=' + this.props.lang
        // return process.env.REACT_APP_API_HOST + '/users/order-images?reservationId=' + this.props.reservationId + '&lang=' + this.props.lang + '&authToken=' + this.props.token
    }

    confirmRejectOrder = (reservationId, orderId, intl) => {
        return confirm(intl.formatMessage({ id: 'CONFIRM_REJECT_ORDER' }), { intl: intl }).then(
            confirm => {
                //TODO: actualizar este registro (recargando, mirar a ver opciones)
                this.props.rejectOrder(reservationId, orderId).then(() => {
                    this.props.fetchOrders()
                })
            },
            cancel => {
                return Promise.reject()
            }
        )
    }

    rejectOrder = (reservationId, orderId) => {
        this.confirmRejectOrder(reservationId, orderId, this.props.intl)
    }

    writeContent = printWindow => {
        printWindow.document.write('<html><head>')
        printWindow.document.write(document.getElementsByTagName('head')[0].innerHTML)

        printWindow.document.write('</head><body class="p-4 my-4">')
        printWindow.document.write(this.props.ordersGroup.innerHTML)
        printWindow.document.write('</body></html>')

        return Promise.resolve(printWindow)
    }

    downloadPdf = () => {
        let printWindow = window.open('', 'PRINT', 'height=400,width=600')
        setTimeout(() => {
            this.writeContent(printWindow).then(printWindow => {
                setTimeout(() => {
                    printWindow.focus()
                    printWindow.print()
                    printWindow.close()
                }, 2000)
            })
        }, 100)

        return false
    }
    showInvoicesModal = () => {
        this.setState({ showInvoicesModal: true })
    }

    closeInvoicesModal = () => {
        this.setState({ showInvoicesModal: false })
    }
    render() {
        const { dataOrder, orderStatusMap } = this.props

        let activeTimeLine = dataOrder.timelines && dataOrder.timelines.length > 0 ? dataOrder.timelines[this.state.selectedDelivery].timeline : null

        return (
            <div id={dataOrder.reservationId}>
                <div style={{ backgroundColor: 'white', padding: '1rem', marginRight: '-15px', marginLeft: '-15px' }}>
                    <div className="row" style={{ marginLeft: '0px' }}>
                        <div className="col-6">
                            <GoBack text={this.props.intl.formatMessage({ id: 'back' })} newView={true} />
                        </div>
                        <div className="col-6" style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="col-12 bt-group-detail">
                                <Button inline size="small" className="mr-2" onClick={() => this.setState({ showPackingList: true })}>
                                    <img alt="" className="icon" src={iconFile} />
                                    <Translation id="packing_list" defaultMessage="Packing list" />
                                </Button>

                                {this.props.invoices && this.props.invoices.length > 0 && (
                                    <Button inline size="small" onClick={this.showInvoicesModal}>
                                        <img alt="" className="icon" src={iconFile} />
                                        <Translation id="view_invoice" defaultMessage="View invoice" />
                                    </Button>
                                )}

                                {dataOrder && dataOrder.orderLines && (
                                    <Button inline size="small" onClick={this.downloadPdf}>
                                        <img alt="" className="icon" src={iconPrint} />
                                        <Translation id="print" defaultMessage="Print" />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <br></br>
                    {dataOrder.orderId && (
                        <React.Fragment>
                            <b className="font-weight-bold">
                                <Translation id="reservation_#" defaultMessage="Reservation #" /> : <span className="font-weight-bold">{dataOrder.reservationId}</span>
                            </b>
                            <br></br>
                            <b className="font-weight-bold">
                                <Translation id="order_#" defaultMessage="Order #" /> : <span className="font-weight-bold">{dataOrder.orderId}</span>
                            </b>
                        </React.Fragment>
                    )}
                    <div className="order-details-header">
                        {dataOrder.ecosentino_is_editable && dataOrder.reservationStatus !== 'PENDING_CANCELLATION' && dataOrder.reservationStatus !== 'PENDING_CHANGES' && (
                            <React.Fragment>
                                <div>
                                    <button className="bt bt-not-style" style={{ paddingLeft: 0 }} onClick={() => this.rejectOrder(dataOrder.reservationId, dataOrder.orderId)}>
                                        <i className="fal fa-times" /> <Translation id="cancel_order" defaultMessage="Cancel order" />
                                    </button>                 
                                </div>
                                <div>
                                    <button
                                        className="bt bt-not-style"
                                        onClick={() => {
                                            this.props.redirect(
                                                this.props.intl
                                                    .formatMessage({ id: 'ROUTE_PROFILE' })
                                                    .replace(':filter', 'edit-order')
                                                    .replace(':open', dataOrder.orderId)
                                            )
                                        }}
                                    >
                                        <i className="fal fa-pencil" /> <Translation id="edit_order" defaultMessage="Edit order" />
                                    </button>
                                </div>
                            </React.Fragment>
                        )}

                        <div className="d-flex justify-content-end" style={{ whiteSpace: 'nowrap' }}>
                            <button className="bt bt-not-style" onClick={this.props.toggleShowDetails}>
                                {this.props.showDetails ? (
                                    <span>
                                        <span>
                                            <Translation id="hide_details" defaultMessage="Hide details" />
                                        </span>
                                        <i style={{ marginLeft: '8px' }} className="fal fa-chevron-up" />
                                    </span>
                                ) : (
                                    <span>
                                        <span>
                                            <Translation id="show_details" defaultMessage="Show details" />
                                        </span>
                                        <i style={{ marginLeft: '8px' }} className="fal fa-chevron-down" />
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                    <div className={`box-details-order p-1 card-body collapse ${this.props.showDetails ? 'd-flex' : ''}`}>
                        <div>
                            <span className="font-weight-bold">
                                <Translation id="shipping_to" defaultMessage="Shipping To" />:{' '}
                            </span>
                            <div className="value pt-1">
                                <DisplayShippingAddress
                                    shippingAddress={dataOrder.shippingAddress}
                                    shippingAddressId={dataOrder.shippingAddressId}
                                    shippingAddressesByUser={this.props.shippingAddressesByUser}
                                />
                            </div>
                            {dataOrder.po && (
                              <React.Fragment>
                                  <br></br>
                                  <b style={{fontWeight: 500}}>
                                      PO: <span className="font-weight-normal">{dataOrder.po}</span>
                                  </b>
                              </React.Fragment>
                            )}
                            {dataOrder.comments && (
                              <React.Fragment>
                                  <br></br>
                                  <b style={{fontWeight: 500}}>
                                      <Translation id="comment" defaultMessage="Comments" />: <span className="font-weight-normal">{dataOrder.comments}</span>
                                  </b>
                              </React.Fragment>
                            )}
                        </div>
                        <div className="d-flex flex-column font-weight-bold">
                            <div className="d-flex">
                                <span style={{ marginRight: '8px' }}>
                                    <Translation id="reservation_#" defaultMessage="Reservation #" />
                                </span>
                                <span>{dataOrder.reservationId}</span>
                            </div>
                            <div className="d-flex">
                                <span style={{ marginRight: '8px' }}>
                                    <Translation id="order_date" defaultMessage="Order date" />
                                </span>
                                <span>
                                    {dataOrder.orderCreationDate
                                        ? moment(dataOrder.orderCreationDate).format('L')
                                        : dataOrder.createdDate
                                        ? moment(dataOrder.createdDate).format('L')
                                        : '--'}
                                </span>
                            </div>
                            <div className="d-flex">
                                <span style={{ marginRight: '8px' }}>
                                    <Translation id="order_type" defaultMessage="Order Type" />
                                </span>
                                <span>
                                    <Translation id={dataOrder.shippingType} />
                                </span>
                            </div>
                            <div className="d-flex">
                                <span style={{ marginRight: '8px' }}>
                                    <Translation id="supplier_center" defaultMessage="Supplier center" />
                                </span>
                                <span>{dataOrder.centerId && `${dataOrder.centerName} (${dataOrder.centerId})`}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {activeTimeLine && (
                    <div className="mt-3" style={{ backgroundColor: 'white', padding: '1rem', margin: '0', marginLeft: '-15px', marginRight: '-15px' }}>
                        <Timeline
                            detailsView={false /*this.props.isOrderDetails*/}
                            // delivery={statusAndDelivery.delivery}
                            delivery={activeTimeLine}
                            rejected={dataOrder.rejected}
                            orderStatusMap={orderStatusMap}
                            withDates
                        />
                    </div>
                )}
                {this.state.showInvoicesModal && (
                    <Modal title={<Translation id="invoices" defaultMessage="Invoices" />} onClose={this.closeInvoicesModal}>
                        <ReactTable
                            data={this.props.invoices}
                            columns={[
                                {
                                    Header: <Translation id="invoice_num" defaultMessage="Invoice Num." />,
                                    Cell: ({ original }) => {
                                        return (
                                            <span
                                                onClick={e => {
                                                    e.preventDefault()

                                                    this.props.getFileByUrl(this.getOrderInvoiceDownloadUrl(original.invoiceNum))
                                                }}
                                                style={{ color: 'blue', cursor: 'pointer' }}
                                            >
                                                {original.invoiceNum}
                                            </span>
                                        )
                                    }
                                },
                                { Header: <Translation id="invoice_date" defaultMessage="Invoice Date" />, accessor: 'invoiceDate' }
                            ]}
                            defaultPageSize={4}
                            pageSizeOptions={[8, 16, 32, 64, 128]}
                            previousText={<Translation id="previous" defaultMessage="Previous" />}
                            nextText={<Translation id="next" defaultMessage="Next" />}
                            noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                            pageText={<Translation id="page" defaultMessage="Page" />}
                            ofText={<Translation id="of" defaultMessage="Of" />}
                        />
                    </Modal>
                )}

                {this.state.showPackingList && this.props.orderDetailsHead && (
                    <PackingList
                        reservationId={this.props.reservationId}
                        orderId={this.props.orderDetailsHead.orderId}
                        packingList={this.props.packingList}
                        onClose={() => this.setState({ showPackingList: false })}
                        canEditOrders={false}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(OrderHeader)
    )
)
