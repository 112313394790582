import React from 'react'
import { connect } from 'react-redux'
import { closeSilestoneXMModal } from '../../../store/ui/actions'
import { getLanguage } from '../../../store/ui/reducers'
import { getShowSilestoneXMModal } from '../../../store/ui/reducers'

//Danes
import imgda from '../../../assets/img/banner-silestone-xm/DA/modal-silestonexm.jpg'
import imgdaSquared from '../../../assets/img/banner-silestone-xm/DA/squared.jpg'
//Aleman
import imgde from '../../../assets/img/banner-silestone-xm/DE/modal-silestonexm.jpg'
import imgdeSquared from '../../../assets/img/banner-silestone-xm/DE/squared.jpg'
//Ingles
import imgen from '../../../assets/img/banner-silestone-xm/EN/modal-silestonexm.jpg'
import imgenSquared from '../../../assets/img/banner-silestone-xm/EN/squared.jpg'
//Español
import imges from '../../../assets/img/banner-silestone-xm/ES/modal-silestonexm.jpg'
import imgesSquared from '../../../assets/img/banner-silestone-xm/ES/squared.jpg'
//Suomi
import imgfi from '../../../assets/img/banner-silestone-xm/FI/modal-silestonexm.jpg'
import imgfiSquared from '../../../assets/img/banner-silestone-xm/FI/squared.jpg'
//Frances
import imgfr from '../../../assets/img/banner-silestone-xm/FR/modal-silestonexm.jpg'
import imgfrSquared from '../../../assets/img/banner-silestone-xm/FR/squared.jpg'
//Hungria (Se usa el ingles)
import imghu from '../../../assets/img/banner-silestone-xm/EN/modal-silestonexm.jpg'
import imghuSquared from '../../../assets/img/banner-silestone-xm/EN/squared.jpg'
//Italiano
import imgit from '../../../assets/img/banner-silestone-xm/IT/modal-silestonexm.jpg'
import imgitSquared from '../../../assets/img/banner-silestone-xm/IT/squared.jpg'
//Japones
import imgja from '../../../assets/img/banner-silestone-xm/JP/modal-silestonexm.jpg'
import imgjaSquared from '../../../assets/img/banner-silestone-xm/JP/squared.jpg'
//Neerlandes
import imgnl from '../../../assets/img/banner-silestone-xm/NL/modal-silestonexm.jpg'
import imgnlSquared from '../../../assets/img/banner-silestone-xm/NL/squared.jpg'
//Noruego
import imgno from '../../../assets/img/banner-silestone-xm/NO/modal-silestonexm.jpg'
import imgnoSquared from '../../../assets/img/banner-silestone-xm/NO/squared.jpg'
//Polaco
import imgpl from '../../../assets/img/banner-silestone-xm/PL/modal-silestonexm.jpg'
import imgplSquared from '../../../assets/img/banner-silestone-xm/PL/squared.jpg'
//Portugues
import imgpt from '../../../assets/img/banner-silestone-xm/PT/modal-silestonexm.jpg'
import imgptSquared from '../../../assets/img/banner-silestone-xm/PT/squared.jpg'
//Suecia
import imgsv from '../../../assets/img/banner-silestone-xm/SE/modal-silestonexm.jpg'
import imgsvSquared from '../../../assets/img/banner-silestone-xm/SE/squared.jpg'
//Turquia
import imgtr from '../../../assets/img/banner-silestone-xm/TR/modal-silestonexm.jpg'
import imgtrSquared from '../../../assets/img/banner-silestone-xm/TR/squared.jpg'
//Hebreo
import imghe from '../../../assets/img/banner-silestone-xm/EN/modal-silestonexm.jpg'
import imgheSquared from '../../../assets/img/banner-silestone-xm/EN/squared.jpg'

class SilestoneXMModal extends React.Component {
    render() {
        const { showModal, onCloseModal, language } = this.props
        const responsiveWidth = window.innerWidth <= 768

        if (!showModal) {
            return null
        }

        const images = {
            da: responsiveWidth ? imgdaSquared : imgda,
            de: responsiveWidth ? imgdeSquared : imgde,
            en: responsiveWidth ? imgenSquared : imgen,
            es: responsiveWidth ? imgesSquared : imges,
            fi: responsiveWidth ? imgfiSquared : imgfi,
            fr: responsiveWidth ? imgfrSquared : imgfr,
            hu: responsiveWidth ? imghuSquared : imghu,
            it: responsiveWidth ? imgitSquared : imgit,
            ja: responsiveWidth ? imgjaSquared : imgja,
            nl: responsiveWidth ? imgnlSquared : imgnl,
            no: responsiveWidth ? imgnoSquared : imgno,
            pl: responsiveWidth ? imgplSquared : imgpl,
            pt: responsiveWidth ? imgptSquared : imgpt,
            sv: responsiveWidth ? imgsvSquared : imgsv,
            tr: responsiveWidth ? imgtrSquared : imgtr,
            he: responsiveWidth ? imgheSquared : imghe,
            'en-US': responsiveWidth ? imgenSquared : imgen,
            'fr-CA': responsiveWidth ? imgfrSquared : imgfr,
            'en-CA': responsiveWidth ? imgenSquared : imgen
        }

        const links = {
            da: 'https://www.cosentino.com/da-dk/silestone-xm/',
            de: 'https://www.cosentino.com/de/silestone-xm/',
            en: 'https://www.cosentino.com/silestone-xm/',
            es: 'https://www.cosentino.com/es/silestone-xm/',
            fi: 'https://www.cosentino.com/fi-fi/silestone-xm/',
            fr: 'https://www.cosentino.com/fr-fr/silestone-xm/',
            hu: 'https://www.cosentino.com/silestone-xm/',
            it: 'https://www.cosentino.com/it-it/silestone-xm/',
            ja: 'https://www.cosentino.com/ja-jp/silestone-xm/',
            nl: 'https://www.cosentino.com/nl-nl/silestone-xm/',
            no: 'https://www.cosentino.com/no-no/silestone-xm/',
            pl: 'https://www.cosentino.com/pl-pl/silestone-xm/',
            pt: 'https://www.cosentino.com/pt-pt/silestone-xm/',
            sv: 'https://www.cosentino.com/sv-se/silestone-xm/',
            tr: 'https://www.cosentino.com/tr-tr/silestone-xm/',
            he: 'https://www.cosentino.com/silestone-xm/',
            'en-US': 'https://www.cosentino.com/silestone-xm/',
            'fr-CA': 'https://www.cosentino.com/fr-ca/silestone-xm/',
            'en-CA': 'https://www.cosentino.com/en-ca/silestone-xm/'
        }

        return (
            <div>
                <div className="full-overlay" style={{ display: 'block' }} />
                <div className="modal-promotion-container">
                    <div className="container-fluid" style={{ position: 'relative', height: '100%' }}>
                        <div
                            onClick={onCloseModal}
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '28px',
                                color: 'white',
                                cursor: 'pointer',
                                zIndex: '10',
                                padding: '6px'
                            }}
                        >
                            <i className="fal fa-times" style={{ fontSize: '24px' }} />
                        </div>
                        <a href={links[language]} target="_blank" rel="noopener noreferrer">
                            <img
                                src={images[language]}
                                alt="Silestone XM Promotion"
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    display: 'block',
                                    maxHeight: 'calc(100vh - 60px)',
                                }}
                            />
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModal: getShowSilestoneXMModal(state),
        language: getLanguage(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onCloseModal: () => {
            dispatch(closeSilestoneXMModal())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SilestoneXMModal)
