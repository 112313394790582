import React from 'react'

import { connect } from 'react-redux'
import { goBack } from 'react-router-redux'
import { getLanguageDir } from '../../store/ui/reducers'

const mapStateToProps = (state, ownProps) => {
    return {
        languageDir: getLanguageDir(state),
        newView: ownProps.newView
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    const action = ownProps.goBack ? ownProps.goBack : goBack()
    return {
        goBack: () => {
            dispatch(action)
        }
    }
}
const GoBack = ({ goBack, text = 'back', datacy = 'go_back', languageDir,href, newView }) => {
    let arrow = 'fal fa-chevron-'
    let style = {fontSize: '24px'}
    let buttonStyle = {}

    if (newView) {
        arrow = 'fal fa-chevron-'
        buttonStyle = { marginBottom: "1em", marginLeft: "-15px", marginTop: "1em" }
    }
    if (href) {
        return (
            <a className="go-back" href={href} data-cy={datacy} style={buttonStyle}>
                <i style={style} className={arrow + (languageDir === 'rtl' ? 'right' : 'left')} />
                <span className="label">
                    {text}
                </span>
            </a>
        )
    }

    return (
        <div className="go-back" onClick={goBack} data-cy={datacy} style={buttonStyle}>
            <i style={style} className={arrow + (languageDir === 'rtl' ? 'right' : 'left')} />
            <span className="label label-v2">
                {text}
            </span>
        </div>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GoBack)
