import React from 'react'
import ProgressBar from "../global/ProgressBar";
import Translation from "../global/Translation";
import {injectIntl} from "react-intl";
import iconTabla from '../../assets/img/icons/tabla.svg'
import iconMedioCamion from '../../assets/img/icons/medio_camion.svg'
import iconCamionCompleto from '../../assets/img/icons/camion_completo.svg'
import iconTriangle from '../../assets/img/icons/triangle.svg'
import ShippingMethodsContainer from "../shipping-method-selector/ShippingMethodsContainer";

const IncentivesWeights = ({ shippingMethod, goalMax, goalMin, unit, haveWeights, isIkeaCenter, isMktoolCenter, difCurrent, current, isMobile }) => {

  return (
    <div className={'incentives-weights ' + (isMobile ? 'incentives-weights-mobile' : 'incentives-weights-desktop')}>

      <div className='d-flex justify-content-between align-items-center' style={{position: "relative"}}>
        {

          shippingMethod.toLowerCase().includes("container")
            ?
            <React.Fragment>
              <div></div>
              <div className='d-flex flex-column align-items-center justify-self-end'>
                <i className="fal fa-ship"></i>
                <span><Translation
                  id="ENDI_CONTAINER"
                  defaultMessage="Container"
                />*</span>

                <span>{`${goalMax} ${unit.toLowerCase()}`}</span>
                <img src={iconTriangle} alt='' />
              </div>
            </React.Fragment>

            :
            <React.Fragment>
              <div className={'d-flex justify-content-start'} style={{flex: '1 1 0px'}}>
                <div className='d-flex flex-column align-items-center'>
                  <img src={iconTabla} alt='' />
                  <Translation id={'SINGLE_SLAB'} defaultMessage={'Single slab'} />
                  <img src={iconTriangle} alt='' />
                </div>
              </div>
              <div className={'d-flex justify-content-center'} style={{flex: '1 1 0px'}}>
                <div className='d-flex flex-column align-items-center'>
                  <img src={iconMedioCamion} alt='' />
                  <span><Translation id={'HALF_TRUCK'} defaultMessage={'Half truck'} />*</span>
                  <span>{`${goalMin} ${unit.toLowerCase()}`}</span>
                  <img src={iconTriangle} alt='' />
                </div>
              </div>
              <div className={'d-flex justify-content-end'} style={{flex: '1 1 0px'}}>
                <div className='d-flex flex-column align-items-center'>
                  <img src={iconCamionCompleto} alt='' />
                  <span><Translation
                    id="FULL_TRUCK"
                    defaultMessage="Full Truck"
                  />*</span>

                  <span>{`${goalMax} ${unit.toLowerCase()}`}</span>
                  <img src={iconTriangle} alt='' />
                </div>
              </div>

            </React.Fragment>
        }

      </div>

      {haveWeights.includes(shippingMethod) && !isIkeaCenter && !isMktoolCenter ? (
        <div style={{height: '40px'}}>
          <ProgressBar
            config={[
              '0 kg.',
              '#9BC2C7',
              `${goalMin} ${unit.toLowerCase()}`,
              '#9BC2C7',
              `${goalMax} ${unit.toLowerCase()}`,
              '#FF5F6D',
              `${goalMax + difCurrent}${unit.toLowerCase()}`
            ]}
            difCurrent={difCurrent}
            value={current}
            showWeigthLabel={`${current} ${unit.toLowerCase()}`}
          />
        </div>
      ) : null}



    </div>
  )
}

export default injectIntl(IncentivesWeights)
