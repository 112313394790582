import React from 'react'

import { connect } from 'react-redux'
import { getProfileConfigurationEmails } from '../../store/profile-configuration/reducers'
import Translation from '../global/Translation'
import {clone} from "lodash";

const mapStateToProps = (state, ownProps) => {      
    return {
        emails: ownProps.emails ? ownProps.emails : getProfileConfigurationEmails(state),
    }
}

class ProfileConfigurationEmails extends React.Component {
    constructor(props) {
        super(props)            
        this.email = []
        this.state={
            emails: []
        }
    }

    componentDidMount() {
        const emails = clone(this.props.emails)
        this.setState({emails})
    }
   
    addEmail = input => {
        if (input.validity.valid && input.value !== '') {
            let emails = clone(this.state.emails)
            emails = emails.concat(input.value)
            this.setState({emails})
            this.props.change(emails)

            input.value = ''
        }
    }

    removeEmail = idRemove => {
        const emails = clone(this.state.emails)
        emails.splice(idRemove, 1)
        this.setState({emails})
        this.props.change(emails)
    }

    render() {
        return (
            <div>
                <div className="input-group input-group-sm">
                    <input
                        id={this.props.idForInput ? `input-${this.props.idForInput}` : null}
                        type="email"
                        ref={node => {
                            this.inputEmail = node
                        }}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                e.preventDefault()
                                this.addEmail(this.inputEmail)
                            }
                        }}
                        className='form-control customDropdown'
                        placeholder="Email"
                        style={{ height: '35px' }}
                    />
                    <span className="input-group-btn input-group-append">
                        <button
                            className="btn btn-outline-secondary btn-add"
                            type="button"
                            onClick={() => {
                                this.addEmail(this.inputEmail)
                            }}
                        >
                            <i className="fa fa-plus" />
                            <span style={{ marginLeft: '6px' }}><Translation id="add" defaultMessage="Add" /></span>
                        </button>
                    </span>
                </div>
                {this.state.emails.map((email, i) => {
                    return (
                        <span className="badge badge-secondary badge-emails" key={email}>
                            {email}{' '}
                            {this.state.emails.length > 0 ? (
                                <i
                                    className='fa fa-times-circle'
                                    onClick={() => {
                                        this.removeEmail(i)
                                    }}
                                />
                            ) : null}
                        </span>
                    )
                })}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    null
)(ProfileConfigurationEmails)
