import React from 'react'
import imgit from '../../../assets/img/banners-rockmaster/IT/wide.jpg'
import imgja from '../../../assets/img/banners-rockmaster/EN/wide.jpg'
import imgde from '../../../assets/img/banners-rockmaster/DE/wide.jpg'
import imges from '../../../assets/img/banners-rockmaster/ES/wide.jpg'
import imghe from '../../../assets/img/banners-rockmaster/EN/wide.jpg'
import imgen from '../../../assets/img/banners-rockmaster/EN/wide.jpg'
import imgfr from '../../../assets/img/banners-rockmaster/FR/wide.jpg'
import imgtr from '../../../assets/img/banners-rockmaster/TR/wide.jpg'
import imgno from '../../../assets/img/banners-rockmaster/NO/wide.jpg'
import imgnl from '../../../assets/img/banners-rockmaster/EN/wide.jpg'
import imgfi from '../../../assets/img/banners-rockmaster/FI/wide.jpg'
import imgpt from '../../../assets/img/banners-rockmaster/PT/wide.jpg'
import imgitSquared from '../../../assets/img/banners-rockmaster/IT/squared.jpg'
import imgjaSquared from '../../../assets/img/banners-rockmaster/EN/squared.jpg'
import imgdeSquared from '../../../assets/img/banners-rockmaster/DE/squared.jpg'
import imgesSquared from '../../../assets/img/banners-rockmaster/ES/squared.jpg'
import imgheSquared from '../../../assets/img/banners-rockmaster/EN/squared.jpg'
import imgenSquared from '../../../assets/img/banners-rockmaster/EN/squared.jpg'
import imgfrSquared from '../../../assets/img/banners-rockmaster/FR/squared.jpg'
import imgtrSquared from '../../../assets/img/banners-rockmaster/TR/squared.jpg'
import imgnoSquared from '../../../assets/img/banners-rockmaster/NO/squared.jpg'
import imgnlSquared from '../../../assets/img/banners-rockmaster/EN/squared.jpg'
import imgfiSquared from '../../../assets/img/banners-rockmaster/FI/squared.jpg'
import imgptSquared from '../../../assets/img/banners-rockmaster/PT/squared.jpg'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getLanguage } from '../../../store/ui/reducers'

const mapStateToProps = state => {
    return {
        language: getLanguage(state)
    }
}

class BannerRockMaster extends React.Component {
    render() {
        const responsiveWidth = window.innerWidth <= 768

        const images = {
            it: responsiveWidth ? imgitSquared : imgit,
            ja: responsiveWidth ? imgjaSquared : imgja,
            de: responsiveWidth ? imgdeSquared : imgde,
            es: responsiveWidth ? imgesSquared : imges,
            he: responsiveWidth ? imgheSquared : imghe,
            en: responsiveWidth ? imgenSquared : imgen,
            fr: responsiveWidth ? imgfrSquared : imgfr,
            sv: responsiveWidth ? imgenSquared : imgen,
            tr: responsiveWidth ? imgtrSquared : imgtr,
            no: responsiveWidth ? imgnoSquared : imgno,
            da: responsiveWidth ? imgenSquared : imgen,
            nl: responsiveWidth ? imgnlSquared : imgnl,
            fi: responsiveWidth ? imgfiSquared : imgfi,
            pl: responsiveWidth ? imgenSquared : imgen,
            pt: responsiveWidth ? imgptSquared : imgpt,
            'en-US': responsiveWidth ? imgenSquared : imgen,
            'fr-CA': responsiveWidth ? imgfrSquared : imgfr,
            'en-CA': responsiveWidth ? imgenSquared : imgen
        }
        const links = {
            it: 'https://www.cosentino.com/it-it/rock-masters/',
            ja: 'https://www.cosentino.com/ja-jp/rock-masters/',
            de: 'https://www.cosentino.com/de/rock-masters/',
            es: 'https://www.cosentino.com/es/rock-masters/',
            he: 'https://www.cosentino.com/rock-masters/',
            en: 'https://www.cosentino.com/en-gb/rock-masters/',
            fr: 'https://www.cosentino.com/fr-fr/rock-masters/',
            sv: 'https://www.cosentino.com/sv-se/rock-masters/',
            tr: 'https://www.cosentino.com/tr-tr/rock-masters/',
            no: 'https://www.cosentino.com/no-no/rock-masters/',
            da: 'https://www.cosentino.com/rock-masters/',
            nl: 'https://www.cosentino.com/nl-nl/rock-masters/',
            fi: 'https://www.cosentino.com/fi-fi/rock-masters/',
            pl: 'https://www.cosentino.com/rock-masters/',
            pt: 'https://www.cosentino.com/pt-pt/rock-masters/',
            'en-US': 'https://www.cosentino.com/usa/rock-masters/',
            'fr-CA': 'https://www.cosentino.com/fr-ca/rock-masters/',
            'en-CA': 'https://www.cosentino.com/en-ca/rock-masters/'
        }
        return (
            <div className="mt-4">
                <a href={links[this.props.language]} target="_blank" rel="noopener noreferrer">
                    <img style={{ maxWidth: '100%' }} src={images[this.props.language]} alt="Banner Rock Master" />
                </a>
            </div>
        )
    }
}

export default injectIntl(connect(mapStateToProps, null)(BannerRockMaster))
