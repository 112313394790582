import React  from 'react'
import { FormattedHTMLMessage, injectIntl } from 'react-intl'
import Translation from '../global/Translation'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import Stepper from "react-stepper-horizontal";
import CartSummaryContainer from './CartSummaryContainer'
import Spinner from '../global/Spinner'
import iconCheckmark from '../../assets/img/icons/checkmark.svg'
import { defineMessages } from 'react-intl'
import Outlet_V2 from '../../assets/img/banners-v2/Outlet_V2.png'
import Button from '../global/Button'
import LocalizedLink from '../global/LocalizedLink'
import GroupInputVoucherV2 from './global/GroupInputVoucherV2'
import CheckoutFormCustomerContainer from './customer/CheckoutFormCustomerContainer'
import { getNextWorkingDay } from '../../helpers'
import SlaDateSelectV2 from './SlaDateSelectV2'
import CheckoutFormMktoolsContainer from './mktools/CheckoutFormMktoolsContainer'
import CheckoutFormIkeaContainer from './ikea/CheckoutFormIkeaContainer'
import CheckoutFormLowesContainer from './lowes/CheckoutFormLowesContainer'
import DiscoverProductsContainer from "../products/DiscoverProductsContainer";
import CartBoughtToguetherContainer from "../products/CartBoughtToguetherContainer";
import CartOtherProductsContainer from "../products/CartOtherProductsContainer";
import confirm from "../global/confirm";
import getNumberFormat from "../getNumberFormat";
import SmallBannerRocket from '../banners/banner-rocket/SmallBannerRocket';
import Alerts from "../alerts/Alerts";
import WeightsContainer from "../weights/WeightsContainer";
import WarningIcon from "../../assets/img/icons/warning-triangle.svg"
import BannerSilestone from '../banners/banner-silestone/BannerSilestone'
import Slider from 'react-slick'

const MESSAGES = defineMessages({
    my_default_addresses: {
        id: 'my_default_addresses',
        description: 'my_default_addresses',
        defaultMessage: 'My default addresses'
    },
    new_address: {
        id: 'new_address',
        description: 'new_address',
        defaultMessage: 'New address'
    },
    select: {
        id: 'select',
        description: 'select',
        defaultMessage: 'Select...'
    },
    destination_name_placeholder: {
        id: 'destination_name_placeholder',
        description: 'destination_name_placeholder',
        defaultMessage: 'Destination name'
    },
    street_and_number_placeholder: {
        id: 'street_and_number_placeholder',
        description: 'street_and_number_placeholder',
        defaultMessage: 'Street and number'
    },
    pc_placeholder: {
        id: 'pc_placeholder',
        description: 'pc_placeholder',
        defaultMessage: 'PC'
    },
    city_placeholder: {
        id: 'city_placeholder',
        description: 'city_placeholder',
        defaultMessage: 'City'
    },
    order_id: {
        id: 'order_id',
        description: 'order_id',
        defaultMessage: 'New address'
    },
    comments_placeholder: {
        id: 'comments_placeholder',
        description: 'comments_placeholder',
        defaultMessage: 'Comments'
    },
    alert_empty: {
        id: 'alert_empty',
        description: 'alert_empty',
        defaultMessage: 'This field is required'
    },
    products_for_you: {
        id: 'products_for_you',
        description: 'products_for_you',
        defaultMessage: 'Products for you'
    },
    bought_together: {
        id: 'bought_together',
        description: 'bought_together',
        defaultMessage: 'Bought together'
    },
    other_products: {
        id: 'other_products',
        description: 'other_products',
        defaultMessage: 'Other products'
    },
})

class CheckoutV2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            steps: [],
            coupon: null,
            couponText: '',
            selectDateOptions: [
                {
                    label: <Translation id="no_keep_the_delivery_dates_for_each_item" defaultMessage="No, keep the delivery dates for each item" />,
                    value: 1
                },
                {
                    label: <Translation id="yes_choose_the_delivery_date_for_all_items" defaultMessage="Yes, choose the delivery date for all items" />,
                    value: 2
                }
            ],
            selectDate: {
                label: <Translation id="no_keep_the_delivery_dates_for_each_item" defaultMessage="No, keep the delivery dates for each item" />,
                value: 1
            },
            dateText: '',
            selectAddressOptions: [
                {
                    label: 'TEST 1',
                    value: 1
                },
                {
                    label: 'TEST 2',
                    value: 2
                }
            ],
            selectAddress: null,
            selectBoolean: true,
            destination_name: null,
            street: null,
            pc: null,
            city: null,
            order_id: null,
            comments: null,
            pressConfirm: false,
            lines: null,
            form: null,
            canSendCheckout: false,
            submitCheckout: () => {},
            el: this,
            props: props,
            validate: [],
            showAll: false,
            showAllHQ: false,
            showAllCenter: false,
            isNextButtonDisabled: false,
        }
        this.childCustomer = React.createRef()
        this.childMktools = React.createRef()
        this.childIkea = React.createRef()
        this.childLowes = React.createRef()
        this.cartTypes = []
        this.cartSubtypes = []
        this.props.lines.map(line =>{
            this.cartTypes.push(line.product.type)
            this.cartSubtypes.push(line.product.subtypeId)}
        )
    }

    getCheckoutType() {
        if (this.props.isIkeaCenter) return 'ikea'
        else if (this.props.isMktoolsCenter) return 'mktools'
        else if (this.props.isLowesCenter) return 'lowes'
        else if (this.props.userType === 'SHOP') return 'shop'
        else if (this.props.isEndiCenter) return 'endi'
        else return 'default'
    }

    componentDidMount() {
        //This callback fn update a global state that is used in sla-dates endpoint
        this.props.setIsCheckoutPage(true)
        this.props.setStep(0)
        this.setState({
            steps: [
                {
                    title: <Translation id="your_cart" defaultMessage="Your cart" />,
                    onClick: () => this.props.setStep(0)
                },
                {
                    title: <Translation id="shipping_address" defaultMessage="Shipping address" />,
                    onClick: () => this.props.setStep(1)
                },
                {
                    title: <Translation id="confirmation" defaultMessage="Confirmation" />,
                    onClick: () => this.props.setStep(2)
                }
            ]
        })
        switch (this.getCheckoutType()) {
            case 'mktools':
                this.setState({
                    form: <CheckoutFormMktoolsContainer ref={ref => (this.childMktools = ref)} intl={this.props.intl} />,
                    submitCheckout: this.submitCheckoutFormMktools
                })
                break
            case 'ikea':
                this.setState({
                    form: <CheckoutFormIkeaContainer ref={ref => (this.childIkea = ref)} intl={this.props.intl} />,
                    submitCheckout: this.submitCheckoutFormIkea
                })
                break
            case 'lowes':
                this.setState({
                    form: <CheckoutFormLowesContainer ref={ref => (this.childLowes = ref)} intl={this.props.intl} />,
                    submitCheckout: this.submitCheckoutFormLowes
                })
                break
            default:
                this.setState({
                    form: <CheckoutFormCustomerContainer onStep={this.props.setStep} ref={ref => (this.childCustomer = ref)} intl={this.props.intl} />,
                    submitCheckout: this.submitCheckoutFormCustomer
                })


            // case 'shop':
            //     return <CheckoutFormShopContainer />
            // case 'endi':
            //     return <CheckoutFormEndiContainer />
        }

        this.updateNextButtonState()
    }

    componentWillUnmount(){
        //This callback fn update a global state that is used in sla-dates endpoint
        this.props.setIsCheckoutPage(false)
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentStep !== prevProps.currentStep) {
            this.props.history.replace(this.props.extendedPath(this.getPath()))
        }

        if (this.childCustomer && this.childCustomer.canSendCheckout && this.state.canSendCheckout !== this.childCustomer.canSendCheckout()) {
            this.setState({canSendCheckout : this.childCustomer.canSendCheckout()})
        }

        if (
            prevProps.totalQty !== this.props.totalQty ||
            prevProps.canSendMinMaxCheckout !== this.props.canSendMinMaxCheckout ||
            prevProps.canViewOutletAndNaturalStoneFromHQInOtherCenters !== this.props.canViewOutletAndNaturalStoneFromHQInOtherCenters ||
            prevProps.totalThickness !== this.props.totalThickness
        ) {
            this.updateNextButtonState()
        }
    }

    updateNextButtonState = () => {
        const { totalQty, canSendMinMaxCheckout, canViewOutletAndNaturalStoneFromHQInOtherCenters, totalThickness, setCheckoutStepOneButtonNextDisabled } = this.props
        const isNextButtonDisabled =
            totalQty === 0 ||
            !canSendMinMaxCheckout() ||
            (canViewOutletAndNaturalStoneFromHQInOtherCenters && totalThickness > 0 && totalThickness < 12);
        
        this.setState({ isNextButtonDisabled }, () => {
            setCheckoutStepOneButtonNextDisabled(this.state.isNextButtonDisabled)
        })
    }

    submitCheckoutFormCustomer = () => {
        this.childCustomer.submitCheckout()
    }

    submitCheckoutFormLowes = () => {
        this.childLowes.submitCheckout()
    }

    submitCheckoutFormIkea = () => {
        this.childIkea.confirmSubmitCheckout()
    }

    submitCheckoutFormMktools = () => {
        this.childMktools.submitCheckout()
    }

    validate(elements) {
        for (let i = 0; i < elements.length; i++) {
            let input = document.getElementById(elements[i])
            if (input) {
                if (!this.state[elements[i]]) {
                    let span = document.createElement('span')
                    if (!input.parentNode.querySelector('.alert_empty')) {
                        span.textContent = this.props.intl.formatMessage({ id: 'alert_empty' })
                        span.classList.add('alert_empty')
                        input.parentNode.insertBefore(span, input.nextSibling)
                        input.classList.add('alert_empty')
                    }
                } else {
                    input.classList.remove('alert_empty')
                    if (input.parentNode.querySelector('.alert_empty')) {
                        input.parentNode.querySelector('.alert_empty').remove()
                    }
                }
            }
        }
    }

    next() {
        const { steps } = this.state
        this.props.setStep(steps.length === this.props.currentStep ? this.props.currentStep : this.props.currentStep + 1)
    }

    getPath() {
        if (this.props.currentStep == 1) return 'address'
        else if (this.props.currentStep == 2) return 'thankyou'
        return ''
    }

    redirectToCatalog() {
        this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_CATALOG' }).replace(':outlet?', ''))
    }

    showAll() {
        this.setState({showAll: !this.state.showAll})
    }

    showAllHQ() {
        this.setState({showAllHQ: !this.state.showAllHQ})
    }

    showAllCenter() {
        this.setState({showAllCenter: !this.state.showAllCenter})
    }

    confirmEmptyCart = () => {
        confirm(this.props.intl.formatMessage({ id: 'CONFIRM' }), { isV2: true }).then(
            confirm => {
                this.props.emptyCart()
            },
            cancel => {}
        )
    }

    isSubmitDisabled = () => {
        //ikea
        if(this.props.isIkeaCenter || this.props.isLowesCenter) return false
        //lowes y mktools
        if (this.props.isMktoolsCenter) return !this.props.hasSaleConditionsCheck
        //base case
        // return (!this.props.canSendCheckoutSaleConditions || !this.props.canSendCheckoutHealthConditions)
        return !this.state.canSendCheckout
    }

    render() {
        let { steps, coupon, selectBoolean, destination_name, street, pc, city, order_id, comments, selectAddressOptions, selectAddress, pressConfirm } = this.state
        let { total, lang, calculatingLinesPrice, totalQty, coin, crossSelling, shippingMethod, crossSellingCheckout, totalMktools, coinMktools, centerSelected, hasSingleSlabAvailableInSelectedCenter } = this.props
        let numberFormat = getNumberFormat(lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 })

        /**
         * If the customer has the ENV3 (Single Slab) type of shipment available in its centers, it will be able to choose the delivery dates by position. 
         * Otherwise, it will not be possible to modify the date by position.
         * @returns {boolean}
         */
        const isDisabledPreferedDeliveryDatePicker = () => {
            if (hasSingleSlabAvailableInSelectedCenter) {
                return false
            }

            return true
        }

        const quickGuides = this.props.quickGuides.filter(guide => {
            return (
                (this.cartTypes.includes(guide.type) || this.cartSubtypes.includes(guide.subtype)) &&
                ((this.props.locale == 'es' && guide.language == 'es') || guide.language == 'en')
            )
        })
        if(coin === 'JPY') {
            numberFormat = getNumberFormat(lang, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
        }

        const thicknessFormat = getNumberFormat(lang);
        let totalThickness = 0;

        const cosentinoGlobalLinesNumber = this.props.lines.filter((line) => {
            if(line.extra && line.extra.isCosentinoGlobal){
                totalThickness += line.product && line.product.thickness && ['SENSA', 'SCALEA', 'PREXURY'].includes(line.brand) ? parseFloat(line.product.thickness) * line.qty : 0
            }
            return line.extra && line.extra.isCosentinoGlobal
        }).length

        const centerLinesNumber = this.props.lines.filter((line) => {
            return !line.extra || !line.extra.isCosentinoGlobal
        }).length

        const thicknessErrorElement = (thickness, totalThickness = '12 cm') => {
            return (
                <React.Fragment>
                    <div className="alert-danger__main-text">
                        <FormattedHTMLMessage
                            id='checkout_total_thickness_HQ'
                            defaultMessage="Currently, you have a <b>total thickness in Natural Stone* slabs from Cosentino Global of {varThickness}</b>. You must add slabs until the TOTAL width of all the Natural Stone* slabs you purchase from HQ is at least: {varTotalThickness}. "
                            values={{varThickness: thickness, varTotalThickness: totalThickness}}
                        />
                    </div>
                    <div className="alert-danger__sub-text">
                        <FormattedHTMLMessage
                            id='checkout_total_thickness_HQ_example'
                            defaultMessage="Example: 4 slabs of 3 cm = 12 cm"
                        />
                    </div>
                </React.Fragment>)
        }

        const submit = (
            <React.Fragment>
                <Button
                    className="bt bt-inverted next w-100"
                    disabled={this.isSubmitDisabled()}
                    inverted
                    datacy="checkout__send_checkout"
                    onClick={() => this.state.submitCheckout()}
                    dataQas="checkout_steptwo-checkout-button-desktop"
                    gtmLabel="ec_funnel_compra_de_siguiente"
                >
                    <Translation id="checkout" defaultMessage="Checkout" />
                </Button>
                <Button
                    className="bt bt-inverted next-floating w-100"
                    disabled={this.isSubmitDisabled()}
                    inverted
                    datacy="checkout__send_checkout"
                    onClick={() => this.state.submitCheckout()}
                    dataQas="checkout_steptwo-checkout-button-responsive"
                    gtmLabel="ec_funnel_compra_de_siguiente"
                >
                    <Translation id="checkout" defaultMessage="Checkout" />
                </Button>
            </React.Fragment>
        )

        const next =
            this.state.isNextButtonDisabled ? (
                <React.Fragment>
                    <Button className="bt bt-inverted next w-100" inverted disabled dataQas="checkout_stepone-next-button-responsive" gtmLabel="ec_funnel_compra_carrito_siguiente">
                        <Translation id="next" defaultMessage="Next" />
                    </Button>
                    <Button className="bt bt-inverted next-floating w-100" inverted disabled dataQas="checkout_stepone-next-button-desktop" gtmLabel="ec_funnel_compra_carrito_siguiente">
                        <Translation id="next" defaultMessage="Next" />
                    </Button>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Button inverted className="bt bt-inverted next" onClick={() => this.next()} dataQas="checkout_stepone-next-button-desktop" gtmLabel="ec_funnel_compra_carrito_siguiente">
                        <Translation id="next" defaultMessage="Next" />
                    </Button>
                    <Button inverted className="bt bt-inverted next-floating" onClick={() => this.next()} dataQas="checkout_stepone-next-button-responsive" gtmLabel="ec_funnel_compra_carrito_siguiente">
                        <Translation id="next" defaultMessage="Next" />
                    </Button>
                </React.Fragment>
            )

        const sliderSettings = {
            arrows: false,
            swipeToSlide: true,
            dots: false,
            speed: 300,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            variableWidth: false,
        }

        const checkoutCartTotal = (
            <div className="checkout-cart-total">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h1 className="resume">
                            <Translation id="resume" defaultMessage="Resume" />
                        </h1>
                    </div>
                    <div>
                        <span className="total_qty">
                            <Translation id="articles" defaultMessage="Articles" /> ({totalQty})
                        </span>
                    </div>
                </div>
                <hr className={'checkout-hr'} />
                <div className="d-flex justify-content-between align-items-center">
                    <div className="total">
                        <p>
                            <Translation id="total" defaultMessage="Total" />
                        </p>
                    </div>
                    <div className="total-price">
                        {this.props.isMktoolsCenter ? (
                            <React.Fragment>
                                {numberFormat.format(totalMktools)} {coinMktools}
                            </React.Fragment>
                        ) : calculatingLinesPrice ? (
                            <Spinner isLoading={true} relative small></Spinner>
                        ) : !total || (total && total == 0) ? (
                            <Translation id="not_available" defaultMessage="Not available" />
                        ) : (
                            total && (
                                <React.Fragment>
                                    {numberFormat.format(total)} {coin}
                                </React.Fragment>
                            )
                        )}
                    </div>
                </div>
                <hr />

                {this.getCheckoutType() !== 'lowes' && <GroupInputVoucherV2 />}

                {(this.props.canViewRocketPromotion || this.props.canViewSilestonePromotion) && (
                    <Slider ref={s => (this.slider = s)} {...sliderSettings}>
                        {this.props.canViewRocketPromotion && <SmallBannerRocket market={this.props.canViewRocketPromotion} />}
                        {this.props.canViewSilestonePromotion && <BannerSilestone isCheckoutView={true} />}
                    </Slider>
                )}

                {next}
            </div>
        )

        const checkoutCartBox = (
            <div className="checkout-cart-box">
                {checkoutCartTotal}
                {!this.props.isValidVoucher && this.getCheckoutType() !== 'ikea' && this.getCheckoutType() !== 'lowes' && this.getCheckoutType() !== 'mktools' && (
                    <SlaDateSelectV2
                        selected={this.props.deliveryDate}
                        onChange={this.props.setDeliveryDate}
                        minDate={this.props.centerSelected == 1100 ? getNextWorkingDay(this.props.minDeliveryDate) : getNextWorkingDay(1)}
                        isDisabledPreferedDeliveryDatePicker={isDisabledPreferedDeliveryDatePicker()}
                    />
                )}
            </div>
        )

        const aProducts = []
        const aQuantities = []

        this.props.lines.forEach(l => {
            aProducts.push(l.productId)
            aQuantities.push(l.qty)
        })

        const products = aProducts.join(',')
        const quantities = aQuantities.join(',')

        return this.props.currentStep === 2 || (this.props.lines && this.props.lines.length > 0) ? (
            <div style={{ backgroundColor: '#F9F9F9' }}>
                <div className="stepper-checkout">
                    <Stepper
                        steps={steps}
                        activeStep={this.props.currentStep}
                        activeColor="#232323"
                        activeTitleColor="#232323"
                        completeColor="#232323"
                        completeTitleColor="#232323"
                        defaultColor="#F9F9F9"
                        defaultTitleColor="#9B9B9B"
                        defaultBorderColor="#D8D8D8"
                        defaultBorderStyle="solid"
                        defaultBorderWidth="1px"
                        defaultBarColor="#D8D8D8"
                        completeBarColor="#232323"
                        circleFontColor="#D8D8D8"
                        lineMarginOffset={0}
                    />
                </div>

                {this.props.currentStep === 0 && (
                    <div className={'container'} style={{ paddingLeft: '0', paddingRight: '0' }}>
                        <div className="checkout-cart-details">
                            <div>
                                {this.props.canViewOutletAndNaturalStoneFromHQInOtherCenters ? (
                                    <React.Fragment>
                                        <div className="checkout-cart checkout-cart__weights" style={{marginBottom: '8px'}}>
                                            <div className="checkout-cart-details__cart-content">
                                                <div className="checkout-cart-details__total-qty">
                                                    <div>
                                                        <h1 className="your_cart">
                                                            <Translation id="your_cart" defaultMessage="Your cart" />
                                                        </h1>{' '}
                                                    </div>
                                                    <div>
                                                <span className="total_qty">
                                                    {totalQty} <Translation id="articles" defaultMessage="Articles" />
                                                </span>
                                                    </div>
                                                </div>
                                                <div className="checkout-cart-details__empty-cart">
                                            <span onClick={() => this.confirmEmptyCart()}>
                                                <i className="mt-1 fal fa-trash-alt" style={{ marginRight: '8px' }}></i>
                                                <Translation id={'empty_cart'} defaultMessage={'Empty cart'} />
                                            </span>
                                                </div>
                                            </div>
                                            {this.getCheckoutType() !== 'ikea' && this.getCheckoutType() !== 'lowes' && (
                                                <div className="banner">
                                                    <WeightsContainer />
                                                </div>
                                            )}
                                        </div>
                                        {cosentinoGlobalLinesNumber > 0 && (
                                            <div className="checkout-cart checkout-cart__hq" style={{marginBottom: '8px'}}>
                                                <div className="checkout-cart-details__cart-content separator-line-bottom d-block">
                                                    <div className="checkout-cart-details__total-qty">
                                                        <div style={{marginInlineEnd: '8px'}}>
                                                            <h1 className="center_items_title">
                                                                <Translation id='cosentino_hq_articles' defaultMessage='Articles from Cosentino HQ' />
                                                            </h1>
                                                        </div>
                                                        <div>
                                                <span className="center_items_total_qty">
                                                <div>
                                                    {cosentinoGlobalLinesNumber}
                                                </div>
                                                <div>
                                                     <Translation id="articles" defaultMessage="Articles" />
                                                </div>
                                                </span>
                                                        </div>
                                                    </div>
                                                    <div className="info-text__container">
                                                        <img src={WarningIcon} alt="Warning" />
                                                        <p className="info-text__orange">
                                                            <Translation id="info_items_from_HQ" defaultMessage="These products are in our Cosentino Global warehouse. Delivery times may be longer than the delivery times of your usual center" />
                                                        </p>
                                                    </div>
                                                    {totalThickness > 0 && totalThickness < 12 && (
                                                        <div>
                                                            <Alerts element={thicknessErrorElement(thicknessFormat.format(totalThickness) + ' cm')} status={300} />
                                                        </div>
                                                    )}
                                                </div>
                                                <div>
                                                    <CartSummaryContainer
                                                        isCosentinoGlobal={true} type="checkout_v2"
                                                        showAll={this.state.showAllHQ}
                                                        howManyShouldShow={centerLinesNumber > 0 ? 3 : 5}
                                                        isDisabledPreferedDeliveryDatePicker={isDisabledPreferedDeliveryDatePicker()}
                                                    />
                                                </div>
                                                <div>
                                                    {cosentinoGlobalLinesNumber > (centerLinesNumber > 0 ? 3 : 5) && (
                                                        <React.Fragment>
                                                            {!this.state.showAllHQ ? (
                                                                <span
                                                                    id="ver_todos_los_articulos"
                                                                    onClick={() => this.showAllHQ()}
                                                                    style={{ cursor: 'pointer', fontWeight: '600', fontSize: '14px' }}
                                                                >
                                                                <Translation id="view_all_articles" defaultMessage="View all articles" />
                                                            </span>
                                                            ) : (
                                                                <span
                                                                    id="ver_menos"
                                                                    onClick={() => this.showAllHQ()}
                                                                    style={{ cursor: 'pointer', fontWeight: '600', fontSize: '14px' }}
                                                                >
                                                                <Translation id="view_less" defaultMessage="View less" />
                                                            </span>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {centerLinesNumber > 0 && (
                                            <div className="checkout-cart checkout-cart__center">
                                                <div className="checkout-cart-details__cart-content separator-line-bottom d-block">
                                                    <div className="checkout-cart-details__total-qty">
                                                        <div style={{marginInlineEnd: '8px'}}>
                                                            <h1 className="center_items_title">
                                                                <Translation id='your_centers_articles' defaultMessage='Articles from your centers' />
                                                            </h1>
                                                        </div>
                                                        <div>
                                                <span className="center_items_total_qty">
                                                <div>
                                                    {centerLinesNumber}
                                                </div>
                                                <div>
                                                    <Translation id="articles" defaultMessage="Articles" />
                                                </div>
                                                </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <CartSummaryContainer
                                                        isCosentinoGlobal={false}
                                                        type="checkout_v2"
                                                        showAll={this.state.showAllCenter} howManyShouldShow={cosentinoGlobalLinesNumber > 0 ? 3 : 5}
                                                        isDisabledPreferedDeliveryDatePicker={isDisabledPreferedDeliveryDatePicker()}
                                                    />
                                                </div>
                                                <div>
                                                    {centerLinesNumber > (cosentinoGlobalLinesNumber > 0 ? 3 : 5) && (
                                                        <React.Fragment>
                                                            {!this.state.showAllCenter ? (
                                                                <span
                                                                    id="ver_todos_los_articulos"
                                                                    onClick={() => this.showAllCenter()}
                                                                    style={{ cursor: 'pointer', fontWeight: '600', fontSize: '14px' }}
                                                                >
                                                                <Translation id="view_all_articles" defaultMessage="View all articles" />
                                                            </span>
                                                            ) : (
                                                                <span
                                                                    id="ver_menos"
                                                                    onClick={() => this.showAllCenter()}
                                                                    style={{ cursor: 'pointer', fontWeight: '600', fontSize: '14px' }}
                                                                >
                                                                <Translation id="view_less" defaultMessage="View less" />
                                                            </span>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div className="checkout-cart">
                                            {this.getCheckoutType() !== 'ikea' && this.getCheckoutType() !== 'lowes' && (
                                                <div className="banner">
                                                    <WeightsContainer />
                                                </div>
                                            )}
                                            <div className="checkout-cart-details__cart-content">
                                                <div className="checkout-cart-details__total-qty">
                                                    <div>
                                                        <h1 className="your_cart">
                                                            <Translation id="your_cart" defaultMessage="Your cart" />
                                                        </h1>{' '}
                                                    </div>
                                                    <div>
                                                <span className="total_qty">
                                                    {totalQty} <Translation id="articles" defaultMessage="Articles" />
                                                </span>
                                                    </div>
                                                </div>
                                                <div className="checkout-cart-details__empty-cart">
                                            <span onClick={() => this.confirmEmptyCart()}>
                                                <i className="mt-1 fal fa-trash-alt" style={{ marginRight: '8px' }}></i>
                                                <Translation id={'empty_cart'} defaultMessage={'Empty cart'} />
                                            </span>
                                                </div>
                                            </div>
                                            <div>
                                                <CartSummaryContainer type="checkout_v2" showAll={this.state.showAll} isDisabledPreferedDeliveryDatePicker={isDisabledPreferedDeliveryDatePicker()}/>
                                            </div>
                                            <div>
                                                {this.props.lines && this.props.lines.length > 5 && (
                                                    <React.Fragment>
                                                        {!this.state.showAll ? (
                                                            <span
                                                                id="ver_todos_los_articulos"
                                                                onClick={() => this.showAll()}
                                                                style={{ cursor: 'pointer', fontWeight: '600', fontSize: '14px' }}
                                                            >
                                                                <Translation id="view_all_articles" defaultMessage="View all articles" />
                                                            </span>
                                                        ) : (
                                                            <span
                                                                id="ver_menos"
                                                                onClick={() => this.showAll()}
                                                                style={{ cursor: 'pointer', fontWeight: '600', fontSize: '14px' }}
                                                            >
                                                                <Translation id="view_less" defaultMessage="View less" />
                                                            </span>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                                <div className="checkout-cart-box-mobile">
                                    {checkoutCartBox}
                                </div>
                                {
                                    this.getCheckoutType() !== 'ikea' &&
                                    this.getCheckoutType() !== 'lowes' &&
                                    this.getCheckoutType() !== 'mktools' &&
                                    this.props.lines &&
                                    this.props.lines.length > 0 && (
                                        <div className={'cross-selling-checkout'}>
                                            <CartBoughtToguetherContainer productIds={products} quantities={quantities} sectionFlag="CJ" />
                                            <CartOtherProductsContainer productIds={products} quantities={quantities} sectionFlag="CO" />
                                        </div>
                                    )}
                            </div>
                            {/* Desktop screens */}
                            <div className="checkout-cart-box-desktop">{checkoutCartBox}</div>
                        </div>
                    </div>
                )}
                {this.props.currentStep === 1 && (
                    <div className={'container p-0'}>
                        <span style={{ cursor: 'pointer', fontSize: '14px', fontWeight: '500', paddingLeft: '24px' }} onClick={() => this.props.setStep(0)} gtm-label="ec_funnel_compra_de_volver_carrito">
                            <i style={{ marginRight: '8px' }} className="fal fa-chevron-left" />
                            <Translation id="back" defaultMessage="Back to cart" />
                        </span>
                        <div className="checkout-address">
                            {this.state.form}
                            <div className="checkout-address-cart">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h1 className="resume">
                                            <Translation id="resume" defaultMessage="Resume" />
                                        </h1>
                                    </div>
                                    <div>
                                        <span className="total_qty">
                                            <Translation id="articles" defaultMessage="Articles" /> ({totalQty})
                                        </span>
                                    </div>
                                </div>
                                <hr className={'checkout-hr'} />
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="total">
                                        <p>
                                            <Translation id="total" defaultMessage="Total" />
                                        </p>
                                    </div>
                                    <div className="total-price">
                                        {this.props.isMktoolsCenter ? (
                                            <React.Fragment>
                                                {numberFormat.format(totalMktools)} {coinMktools}
                                            </React.Fragment>
                                        ) : calculatingLinesPrice ? (
                                            <Spinner isLoading={true} relative small></Spinner>
                                        ) : !total || (total && total == 0) ? (
                                            <Translation id="not_available" defaultMessage="Not available" />
                                        ) : (
                                            total && (
                                                <React.Fragment>
                                                    {numberFormat.format(total)} {coin}
                                                </React.Fragment>
                                            )
                                        )}
                                    </div>
                                </div>
                                <hr className={'my-3'} />
                                <div className="confirm__order__btn">
                                    {submit}
                                </div>

                                <div>
                                    <CartSummaryContainer type="checkout_v2_summary" showAll={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.props.currentStep === 2 && (
                    <div className={'container'}>
                        <div className="checkout-confirmation">
                            <div className="checkout-confirmation-box">
                                <div className="d-flex flex-column">
                                    <div className="title mb-4">
                                        <div>
                                            <img alt="Finished successfully" className="icon" src={iconCheckmark} />
                                        </div>
                                        <Translation id="thank_you_you_have_placed_your_order" defaultMessage="Thank you, you have placed your order" />
                                    </div>
                                    <div className="subtitle mb-2">
                                        <Translation id="confirmation_will_be_sent_to_your_email" defaultMessage="Confirmation will be sent to your email" />
                                    </div>
                                    {this.props.reservationCode && (
                                        <div className="reservation-msg-box">
                                            <div className="subtitle">
                                                <Translation id="reservation_id" defaultMessage="Reservation ID" />
                                                <span className="subtitle-bold">:&nbsp;{this.props.reservationCode}</span>
                                            </div>
                                            <div className="divider-vertical"></div>
                                            <div>
                                                {!this.props.isMktoolsCenter && (
                                                    this.props.canViewNewOrderHistory ? (
                                                        <LocalizedLink routeId={'ROUTE_PROFILE'} params={{ filter: 'new-orders', open: '' }} gtmLabel="ec_funnel_compra_cp_revisar">
                                                            <button className="review-my-order-btn">
                                                                <Translation id="review_my_order" defaultMessage="Review my order" />
                                                            </button>
                                                        </LocalizedLink>
                                                    ) : (
                                                        <LocalizedLink routeId={'ROUTE_PROFILE'} params={{ filter: 'orders', open: '' }} gtmLabel="ec_funnel_compra_cp_revisar">
                                                            <button className="review-my-order-btn">
                                                                <Translation id="review_my_order" defaultMessage="Review my order" />
                                                            </button>
                                                        </LocalizedLink>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    {this.props.reservationCutToSizeCode && (
                                        <div className="cts-reservation-msg-box">
                                            <div className="subtitle mb-2">
                                                <Translation
                                                    id="order_finished_header_cut_to_size"
                                                    defaultMessage="Your order for cut to size has been finished successfully"
                                                />
                                            </div>
                                            <div className="cts-reservation-msg-content">
                                                <div>
                                                    <span className="subtitle"><Translation id="reservation_id" defaultMessage="Reservation ID" /></span>
                                                    <span className="subtitle-bold">:&nbsp;{this.props.reservationCutToSizeCode}</span>
                                                </div>
                                                <div className="divider-vertical"></div>
                                                <div>
                                                    <LocalizedLink routeId={'ROUTE_PROFILE'} params={{ filter: 'pre-orders-cts', open: '' }}>
                                                        <button className="cts-review-my-order-btn">
                                                            <Translation id="review_my_order" defaultMessage="Review my order" />
                                                        </button>
                                                    </LocalizedLink>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="subtitle mb-4">
                                        <span className="bold">
                                            <Translation id="shipping_address" defaultMessage="Shipping address" />
                                            {': '}
                                        </span>
                                        {selectBoolean ? (
                                            this.props.hasDestination ? (
                                                this.props.destination
                                            ) : !this.props.selectedShippingAddress ? (
                                                ''
                                            ) : (
                                                this.props.selectedShippingAddress.centerName +
                                                ' ' +
                                                this.props.selectedShippingAddress.customerName +
                                                ' ' +
                                                this.props.selectedShippingAddress.address +
                                                ' ' +
                                                this.props.selectedShippingAddress.zipCode +
                                                ' ' +
                                                this.props.selectedShippingAddress.city +
                                                ' ' +
                                                this.props.selectedShippingAddress.country +
                                                ' ' +
                                                this.props.selectedShippingAddress.salesOrg
                                            )
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                    <div className="keep-buying-btn">
                                        <div>
                                            <LocalizedLink routeId={'ROUTE_HOME'}>
                                                <button gtm-label="ec_funnel_compra_cp_continuar">
                                                    <Translation id="keep_buying" defaultMessage="Keep buying" />
                                                </button>
                                            </LocalizedLink>
                                        </div>
                                    </div>
                                </div>
                                {!this.props.isIkeaCenter && (
                                    <LocalizedLink routeId="ROUTE_CATALOG" queryString="?type[0]=TABLA" params={{ outlet: 'outlet' }} className="image-slide-item">
                                        <div className="image-outlet" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${Outlet_V2}')` }}>
                                            <div className="slide-title">
                                                <div className="subtitle" style={{ color: 'white' }}>
                                                    <Translation id="cosentino_outlet" defaultMessage="Cosentino Outlet" />
                                                </div>
                                                <h2 className="title" style={{ color: 'white' }}>
                                                    <Translation id="OUTLET_V2_TXT" defaultMessage="Cosentino Outlet" />
                                                </h2>
                                            </div>
                                            <div className="slide-bt">
                                                <Button inline gentle size="medium" style={{ color: 'white', borderColor: 'white' }} gtmLabel="ec_funnel_compra_cp_promocion">
                                                    <Translation id="view_promotion" default Message="Buy here" />
                                                </Button>
                                            </div>
                                        </div>
                                    </LocalizedLink>
                                )}
                            </div>

                            {quickGuides.length > 0 && (
                                <div>
                                    <div
                                        className="product-quick-guides p-3 mb-3 mt-3"
                                        style={{ background: 'white', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', textAlign: 'left', fontWeight: '400' }}
                                    >
                                        <Translation
                                            id="technical_documentation_related_to_the_products_you_have_purchased"
                                            defaultMessage="Technical documentation related with the products you have purchased"
                                        ></Translation>

                                        {quickGuides.map(guide => {
                                            return (
                                                <div className="mt-3" style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <a href={guide.link} className="brand-new-blue" target="_blank" gtm-label="ec_producto_doc_tecnica">
                                                        <div style={{ display: 'inline-flex', marginRight: '8px' }}>
                                                            <i class="fas fa-external-link-alt"></i>
                                                        </div>
                                                        {guide.title}
                                                    </a>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}

                            <DiscoverProductsContainer lines={this.props.lines} sectionFlag="OD" />
                        </div>
                    </div>
                )}
            </div>
        ) : (
            <div className={'container'}>
                <div className={'checkout-empty-cart-container'}>
                    <h2 className={'cart-is-empty-text'}>
                        {this.props.isLoading ? <Spinner isLoading={true} relative></Spinner> : <Translation id={'your_cart_is_empty'} defaultMessage={'Your cart is empty'} />}
                    </h2>
                    <Button className={'bt-inverted cart-is-empty-bt'} onClick={() => this.props.setStep(0)}>
                        <LocalizedLink routeId="ROUTE_HOME">
                            <Translation id="keep_buying" default Message="Keep buying" />
                        </LocalizedLink>
                    </Button>
                </div>
                <DiscoverProductsContainer lines={this.props.lines} sectionFlag="OD" />
            </div>
        )
    }
}

export default injectIntl(CheckoutV2)
