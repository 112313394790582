import React from 'react'
import DetailsIkea from '../DetailsIkea'
import DetailsMktools from '../DetailsMktools'
import DetailsDefaultV2 from "../DetailsDefaultV2";

export const ProductDetails = (props) => {

    const getTypeRectangleResult = () => {
        let type = 'default'
        if (props.isMkToolsCenter) type = 'mktool'
        else if (props.isIkeaCenter) type = 'ikea'
        return type
    }

    const getDetails = () => {
        switch (getTypeRectangleResult()) {
            case 'mktool':
                return <DetailsMktools {...props} product={props.product} />
            case 'ikea':
                return <DetailsIkea {...props} product={props.product} />
            default:
                return <DetailsDefaultV2
                    {...props}
                    product={props.product}
                    mallPromotionSection={props.mallPromotionSection}
                    showImg={props.showImg}
                />
        }
    }

    return getDetails()
}
