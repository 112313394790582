import InfiniteScroll from 'react-infinite-scroll-component';
import React from 'react'
import Translation from '../global/Translation'
import LocalizedLink from '../global/LocalizedLink'
import Button from '../global/Button'
import {defineMessages, injectIntl} from 'react-intl'
import moment from 'moment'
import Timeline from './Timeline'
import PreOrdersCtsFilters from "./PreOrdersCtsFilters";
import {BudgetMessages} from "../cut-to-size/CutToSizeMaps";
import confirm from "../global/extendedConfirm";
import Spinner from "../global/Spinner";
import ProfileContainer from "../profile/ProfileContainer"

defineMessages({
    REQUESTED: {
        id: 'REQUESTED',
        description: 'REQUESTED',
        defaultMessage: 'Order request created'
    },
    TECHNICAL_REVIEW: {
        id: 'TECHNICAL_REVIEW',
        description: 'TECHNICAL_REVIEW',
        defaultMessage: 'Technical review'
    },
    CSTMR_APPROVAL: {
        id: 'CSTMR_APPROVAL',
        description: 'CSTMR_APPROVAL',
        defaultMessage: 'Customer approval'
    },
    ORDER_CREATED: {
        id: 'ORDER_CREATED',
        description: 'ORDER_CREATED',
        defaultMessage: 'Order created'
    },
    CANCELLED: {
        id: 'CANCELLED',
        description: 'CANCELLED',
        defaultMessage: 'Order request cancelled'
    },
    CSTMR_CANCEL: {
        id: 'CSTMR_CANCEL',
        description: 'CSTMR_CANCEL',
        defaultMessage: 'Order cancelled by customer'
    }
})

export const TimelinePreOrder = (props) => {
    const orderStatusMap = {
        REQUESTED: { textToShow: props.intl.formatMessage({id:"REQUESTED"}), editable: false, pos:0 },
        TECHNICAL_REVIEW: { textToShow: props.intl.formatMessage({id:"TECHNICAL_REVIEW"}), editable: false, pos:1 },
        CSTMR_APPROVAL: { textToShow: props.intl.formatMessage({id:"CSTMR_APPROVAL"}), editable: false, pos:2 },
        ORDER_CREATED: { textToShow: props.intl.formatMessage({id:"ORDER_CREATED"}), editable: false, pos:3 },
        CANCELLED: { textToShow: props.intl.formatMessage({id:"CANCELLED"}), editable: false, pos:4 },
        CSTMR_CANCEL: { textToShow: props.intl.formatMessage({id:"CSTMR_CANCEL"}), editable: false, pos:5 }
    }

    const getDeliveries = () => {
        const deliveries = []

        const lastRecord = props.preOrder.records.slice(-1)[0];
        const records = {}
        props.preOrder.records.forEach(record => records[record.status] = record)

        if (!lastRecord) return []

        if (lastRecord.status === 'CANCELLED' || lastRecord.status === 'CSTMR_CANCEL') {
            Object.entries(orderStatusMap).forEach(([k, v]) => {
                const record = records[k]
                if (record) {
                    deliveries.push({
                        active: getStepStatus(k),
                        alert: false,
                        date: record.created_at,
                        statusText: k,
                        current: props.preOrder.status === k,
                        danger: k === 'CANCELLED' || k === 'CSTMR_CANCEL'
                    })
                }
            })
        } else {
            Object.entries(orderStatusMap).forEach(([k, v]) => {
                if (k !== 'CANCELLED' && k !== 'CSTMR_CANCEL') {
                    const date = records[k] && records[k].created_at
                    deliveries.push({
                        active: getStepStatus(k), alert: false, date: date, statusText: k, current: props.preOrder.status === k
                    })
                }
            })
        }

        return deliveries
    }

    const getStepStatus = (stepName) => {
        const stepStatusPos = orderStatusMap[stepName].pos
        const preOrderStatusPos = orderStatusMap[props.preOrder.status].pos

        return stepStatusPos <= preOrderStatusPos
    }

    return <Timeline
        orderStatusMap={orderStatusMap}
        delivery={getDeliveries()}
        withDates
        {...props}
    />
}

class PreOrdersCts extends React.Component {
    state = {
        width: window.innerWidth
    }

    componentDidMount() {
        this.props.clearPreOrderCts()
        this.props.fetchPreOrdersCts()
        this.props.fetchPreOrdersProjectAvailables()
    }

    fetchPreOrdersPaginate = () => {
        this.props.fetchPreOrdersCtsPaginate(
            this.state.startDate,
            this.state.endDate,
            this.state.filterProject,
            this.state.filterTypeOrder,
            this.state.search,
            this.props.currentPage + 1
        )
    }

    confirmRejectOrder = (preorderId) => {
        return confirm(
            this.props.intl.formatMessage({ id: 'CONFIRM_REJECT_ORDER' }),
            {
                advise:<React.Fragment>
                    <i className="fal fa-exclamation-triangle"></i>&nbsp;
                    {this.props.intl.formatMessage({ id: 'CONFIRM_REJECT_PREORDER_ADVISE' })}
                </React.Fragment>,
                textCancel:this.props.intl.formatMessage({ id: 'CONFIRM_REJECT_PREORDER_CANCEL_BUTTON' }),
                textOk:this.props.intl.formatMessage({ id: 'CONFIRM_REJECT_PREORDER_OK_BUTTON' })
            }
        )
        .then(
            confirm => {
                this.props.cancelPreorder(preorderId)
                    .then(() => this.forceUpdate())
                    // .catch(error => alert(error))
            },
            cancel => {
                return Promise.reject()
            }
        )
    }

    render() {
        const isMobile = this.state.width <= 768
        const loadMoreButton = (
            <Button
                onClick={() => this.fetchPreOrdersPaginate()}
                size="medium"
                disabled={this.props.currentPage >= this.props.lastPage || this.props.isLoading}
            >
                {this.props.currentPage >= this.props.lastPage ? (
                    <Translation id="no_more_result" defaultMessage="No more results" />
                ) : (
                    <Translation id="load_more" defaultMessage="Load more" />
                )}
            </Button>
        )
        const unreadedMails = (
            <div
                style={{
                    backgroundColor: '#FF6805',
                    borderRadius: '50%',
                    position: 'absolute',
                    width: '28px',
                    height: '28px',
                    top: '-14px',
                    right: '-12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                className="preorder-unread-msg-icon"
            >
            <i style={{ color: 'white', fontSize: '16px' }} className="fal fa-bell" />
        </div>
        )

        return (
            <React.Fragment>
                <div className="box-profile row">
                    <div className="box-profile-menu col-12 col-md-4 col-lg-3">
                        <ProfileContainer />
                    </div>
                    <div className="col-12 col-xl-9">
                        <div className="profile-form">
                            <div className="col-12">
                                <PreOrdersCtsFilters
                                    currentPage={this.props.currentPage}
                                    fetchPreOrdersCtsPaginate={this.props.fetchPreOrdersCtsPaginate}
                                    projectList={this.props.projectList}
                                    orderStatusMap={this.orderStatusMap}
                                    isNewVersion={this.props.isNewVersion}
                                />
                            </div>
                        </div>

                        <InfiniteScroll
                            dataLength={this.props.preOrdersCts.length}
                            next={() => this.props.currentPage < this.props.lastPage && this.fetchPreOrdersPaginate()}
                            hasMore={true}
                            loader={' '}
                            endMessage={' '}
                        >

                        {this.props.preOrdersCts &&
                            this.props.preOrdersCts.length > 0 &&
                            this.props.preOrdersCts.map(preOrder => {
                                const hasUnreadedMails = !!preOrder.communications.find(p => p.is_read == 0)
                                    return (
                                        <section key={'preorder-key-' + preOrder.id} className="preorder__history">
                                            <div class="preorder__history--header">
                                                <div className="preorder__history--header--status">
                                                    <h4>
                                                        <Translation id="status" defaultMessage="Status" />:&nbsp;{this.props.intl.formatMessage({ id: preOrder.status })}
                                                    </h4>
                                                </div>
                                                <div className="preorder__history--header-id">
                                                    <div>
                                                        <Translation id="preorder_id" defaultMessage="Pre-Order ID" />
                                                        :&nbsp;
                                                        <span>
                                                            {preOrder.reservation_id ? preOrder.reservation_id : <Translation id="pending" defaultMessage="Pending" />}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <Translation id="order_id" defaultMessage="Order ID" />
                                                        :&nbsp;
                                                        <span>
                                                            {preOrder.order_id ? preOrder.order_id : <Translation id="pending" defaultMessage="Pending" />}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <Translation id="date" defaultMessage="Date" />
                                                        :&nbsp;{moment(preOrder.created_at).format('L')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="preorder__history--content row">
                                                <div className="col-12 col-md-5 col-lg-3 p-0 preorder__history--content--summary">
                                                    <div>
                                                        <h5>
                                                            <Translation id="pre_order_summary" defaultMessage="Pre-order summary" />
                                                        </h5>
                                                    </div>
                                                    <div>
                                                        <span className="summary__project--title"><Translation id="project_type" defaultMessage="Project type" /></span>
                                                        <span className="summary__project--name">:&nbsp;{this.props.intl.formatMessage(BudgetMessages[preOrder.project])}</span>
                                                    </div>
                                                    <div>
                                                        <span className="summary__project--title"><Translation id="purchase-order" defaultMessage="Your reference" /></span>
                                                        <span className="summary__project--name">:&nbsp;{preOrder.purchase_order}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-7 col-lg-6 preorder__history--content-timeline">
                                                    <div><TimelinePreOrder preOrder={preOrder} intl={this.props.intl} condensed checkMark={!isMobile} /></div>
                                                    <div>
                                                        <span className="timeline--lastupdate"><Translation id="last_update" defaultMessage="Last update" />
                                                        :&nbsp;{moment(preOrder.created_at).format('L')}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-3 preorder__history--content-description">
                                                    {preOrder.status === 'ORDER_CREATED' && (
                                                        <div>
                                                            <h5>{this.props.intl.formatMessage({ id: 'ORDER_CREATED' })}</h5>
                                                            <span><Translation id="ORDER_CREATED_LONG" defaultMessage="This pre-Order is closed. Now you have a new order on your order history."/></span>
                                                            <div>
                                                                <LocalizedLink routeId="ROUTE_PROFILE" params={{ filter: 'view-order', open: preOrder.reservation_id }} className="preorder__history--vieworder">
                                                                    <Translation id="click_here" defaultMessage="Click here" />
                                                                </LocalizedLink>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {preOrder.status === 'TECHNICAL_REVIEW' && (
                                                        <div>
                                                            <h5>{this.props.intl.formatMessage({ id: 'TECHNICAL_REVIEW' })}</h5>
                                                            <span><Translation id="TECHNICAL_REVIEW_LONG" defaultMessage="This pre-Order is under review."/></span>
                                                        </div>
                                                    )}
                                                    {preOrder.status === 'CSTMR_APPROVAL' && (
                                                        <div>
                                                            <h5>{this.props.intl.formatMessage({ id: 'CSTMR_APPROVAL' })}</h5>
                                                            <span><Translation id="CSTMR_APPROVAL_LONG" defaultMessage="This pre-Order has beem approved by the customer."/></span>
                                                        </div>
                                                    )}
                                                    {preOrder.status === 'REQUESTED' && (
                                                        <div>
                                                            <h5>{this.props.intl.formatMessage({ id: 'REQUESTED' })}</h5>
                                                            <span><Translation id="REQUESTED_LONG" defaultMessage="This pre-Order is pending confirmation."/></span>
                                                        </div>
                                                    )}
                                                    {preOrder.status === 'CANCELLED' && (
                                                        <div>
                                                            <h5>{this.props.intl.formatMessage({ id: 'CANCELLED' })}</h5>
                                                            <span><Translation id="CANCELLED_LONG" defaultMessage="This pre-Order has been cancelled."/></span>
                                                        </div>
                                                    )}
                                                    {preOrder.status === 'CSTMR_CANCEL' && (
                                                        <div>
                                                            <h5>{this.props.intl.formatMessage({ id: 'CSTMR_CANCEL' })}</h5>
                                                            <span><Translation id="CANCELLED_BY_CUSTOMER" defaultMessage="Order request cancelled by customer."/></span>
                                                        </div>
                                                    )}

                                                    <div className="preorder__history--btn-container">
                                                        <LocalizedLink routeId="ROUTE_PROFILE" params={{ filter: 'pre-orders-cts-details', open: preOrder.id }}>
                                                            <Button className="bt-dark no-bo preorder__history--viewdetails">
                                                                <Translation id="preorder-details" defaultMessage="Pre-order Details" />
                                                                <i class="fas fa-chevron-right"></i>
                                                            </Button>
                                                        </LocalizedLink>
                                                        {hasUnreadedMails && (unreadedMails)}
                                                    </div>
                                                    {
                                                    preOrder.isCancelable && (
                                                        <div className="preorder__history--cancel" onClick={()=> this.confirmRejectOrder(preOrder.id)}>
                                                            <i className="fal fa-times"/>&nbsp;&nbsp;
                                                            <Translation id="cancel_order" defaultMessage="Cancel order" />
                                                        </div>
                                                    )
                                                }
                                                </div>
                                            </div>
                                        </section>
                                    )
                                }
                            )
                        }
                        </InfiniteScroll>
                        <div className="row">
                            <div className="col-12 mb-4 pt-2" style={{ display: 'flex', justifyContent: 'center' }}>
                                {loadMoreButton}
                            </div>
                        </div>
                    </div>
                    <Spinner isLoading={this.props.isLoading}></Spinner>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(PreOrdersCts)
