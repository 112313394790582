import { getEmailUsedToLogin } from './../../store/login/reducers';
import React from 'react'

import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import LoginModalContainer from '../login-modal/LoginModalContainer'
import BundleContentContainer from '../bundle/BundleContentContainer'
import SegmentationModal from '../segmentation/SegmentationModal'

import { getIsLogged, showPricesDisclaimer } from '../../store/login/reducers'
import { canBuy, getSelectedCenter, getShippingMethod } from '../../store/centers/reducers'
import AppObsolete from '../global/AppObsolete'
import { getCurrentUrl, getIsObsolete, getShowThankYouSubscribeModal } from '../../store/ui/reducers'
import FloatingAlertContainer from '../alerts/FloatingAlertContainer'
import FloatingAlert from '../alerts/FloatingAlert'
import { canViewPrices } from '../../store/products/reducers'
import ModalFormattedMessage from '../global/ModalFormattedMessage'
import BigAccountModal from '../login-modal/BigAccountModal'
import IkeaModalEmployeeId from '../global/IkeaModalEmployeeId'
import DektonGripModal from '../catalog/modals/DektonGripModal'
import { closePricesDisclaimer } from '../../store/ui/actions'
import AskForEmailModal from '../login-modal/AskForEmailModal'
import IncompatibleTypesExceptionModal from '../global/IncompatibleTypesExceptionModal'
import EmailPromotionsModal from '../catalog/modals/EmailPromotionsModal'
import WhatsappOptin from '../catalog/WhatsappOptin'
import ThankYouSubscribeModal from '../global/ThankYouSubscribeModal'
import AdvertisementModal from '../advertisements/AdvertisementModal'
import PedidosRecurrentesModal from '../catalog/modals/PedidosRecurrentesModal'
import SilestoneXMModal from '../banners/banner-silestone-xm/SilestoneXMModal';

const mapStateToProps = (state, { match }) => {
    return {
        isLogged: getIsLogged(state),
        canBuy: canBuy(state),
        shippingType: getShippingMethod(state),
        isObsolete: getIsObsolete(state),
        canViewPrices: canViewPrices(state),
        selectedCenter: getSelectedCenter(state),
        showPricesDisclaimer: showPricesDisclaimer(state),
        currentUrl: getCurrentUrl(state).pathname,
        showThankYouSubscribeModal: getShowThankYouSubscribeModal(state),
        email:getEmailUsedToLogin(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        closePricesDisclaimer: () => {
            dispatch(closePricesDisclaimer())
        },
        closeThankYouSubscribeModal: () => {
            dispatch({ type: 'CLOSE_THANK_YOU_SUBSCRIBE_MODAL' })
        }
    }
}

class GlobalModals extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {showTipModal:true} 
    }

    isCheckoutFlow() {
        let routeCatalog = this.props.intl.formatMessage({ id: 'ROUTE_CATALOG' }).replace('/:outlet?', '')
        let routeCheckout = this.props.intl.formatMessage({ id: 'ROUTE_CHECKOUT' })
        let routeProducts = this.props.intl.formatMessage({ id: 'ROUTE_PRODUCT_DETAIL' }).replace('/:id/:outlet?', '')

        if (
            this.props.currentUrl &&
            (this.props.currentUrl.includes(routeCatalog) || this.props.currentUrl.includes(routeCheckout) || this.props.currentUrl.includes(routeProducts))
        ) {
            return true
        }
        return false
    }

    render() {
        const { isLogged, canBuy, shippingType, isObsolete, canViewPrices, selectedCenter, closePricesDisclaimer, showPricesDisclaimer, isCheckoutFlow,email } = this.props
        let brazilCenters = [7500, 7501, 7502, 7503, 7504, 7505, 7506, 7507]

        return (
            <div>
                <FloatingAlertContainer /*visible={true}*/ />
                <LoginModalContainer />
                {canViewPrices && showPricesDisclaimer && (
                    <FloatingAlert
                        warningMessage={brazilCenters.includes(selectedCenter) ? 'PRICES_EXPERIMENTAL_NOT_MENTION_TAXES' : 'PRICES_EXPERIMENTAL'}
                        className="catalog-position"
                        pricing
                        onClose={() => closePricesDisclaimer()}
                        hidden={!this.isCheckoutFlow()}
                    />
                )}
                {isLogged && email && <WhatsappOptin/>}
                {isLogged && canBuy && shippingType && <DektonGripModal />}
                {isLogged && canBuy && shippingType && <SegmentationModal />}
                <EmailPromotionsModal />
                <PedidosRecurrentesModal />
                <BundleContentContainer />
                {isObsolete && <AppObsolete />}
                <ModalFormattedMessage />
                <IncompatibleTypesExceptionModal />
                <IkeaModalEmployeeId />
                <BigAccountModal />
                <AskForEmailModal />
                {this.props.showThankYouSubscribeModal && (
                    <ThankYouSubscribeModal show={this.props.showThankYouSubscribeModal} onClose={() => this.props.closeThankYouSubscribeModal()} />
                )}
                <AdvertisementModal />
                {isLogged && <SilestoneXMModal/>}
            </div>
        )
    }
}

export default injectIntl(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(GlobalModals)
    )
)
