import React from 'react'
import ProgressBar from "../global/ProgressBar";
import Translation from "../global/Translation";
import {injectIntl} from "react-intl";

const IncentivesMktools = ({ mktoolsBudgetWasted, maxBudget, difCurrentForBudget, total, coin, difCurrent}) => {

  return (
    <React.Fragment>
      <div className="mktools-bar">
        <ProgressBar
          config={['0', 'lightgray', `${mktoolsBudgetWasted}`, 'green', `${maxBudget}`, 'red', `${maxBudget + difCurrentForBudget}`]}
          //showLabels={true}
          value={total}
          coin={coin}
          difCurrent={difCurrent}
          showMktoolsLabels={[0, maxBudget, mktoolsBudgetWasted]}
        />
      </div>
      <div className="mktools-info">
        <Translation
          id="gray_Budget_consumed_green_budget_to_consume_red_out_of_budget"
          defaultMessage="(Grey) Consumed budget / (Green) Remain budget / (Red) Out of budget"
        />
      </div>
    </React.Fragment>
  )
}

export default injectIntl(IncentivesMktools)
