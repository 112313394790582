import React from 'react'
import Translation from '../global/Translation'

import {FormattedMessage, injectIntl} from 'react-intl'

import Button from '../global/Button'
import Modal from '../global/Modal'
import LineSegmentationV2 from '../cart-line/LineSegmentationV2'
import SegmentationForm from "./SegmentationForm";

class SegmentationConfiguration extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            saveSegmentationPromptOpen: false,
            active: null,
            excuse: null,
            expandSaved: false,
            toErase: null,
            editName: null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.segmentatingProductId !== this.props.segmentatingProductId) {
            this.setState({ product: this.props.getProductById(this.props.segmentatingProductId) })
        }
    }

    clearSegmentation = () => {
        this.setState({ active: null })
        this.props.clearSegmentation()
    }

    editSegmentation = name => {
        this.props.loadPreferredSegmentation(name)
        this.setState({ active: name, editName: name, saveSegmentationPromptOpen: true})
    }

    saveSegmentation = name => {
        if (name) this.props.saveSegmentationPreferred(name)
        if (this.state.active && this.state.active !== name) this.props.deletePreferredSegmentation(this.state.active)
        this.setState({ saveSegmentationPromptOpen: false, active: null, editName: null })
    }

    componentDidMount() {
        this.props.fetchSegmentation()
    }

    getBusinessSegment = businessSegment => {
        const productBS = businessSegment === null ? 'NOT' : businessSegment
        return <FormattedMessage id={productBS} />
    }

    getSalesforceAccount = (channelId, salesforceId) => {
        const channel = this.props.channelOptionsV3 && this.props.channelOptionsV3[channelId]
        const salesforce = channel ? channel.salesforceRelations[salesforceId] : undefined
        return salesforce && salesforce.name
    }

    isFormFullFilled = () => {
        let salesForceIsValid = this.props.sfOptions && Object.keys(this.props.sfOptions).length > 0 ? this.props.salesforceId !== null : true
        if (this.props.channel === 'B0') {
            salesForceIsValid = true
        }
        const needsProjectId = (
            (
                (this.props.channel === 'BD' || this.props.channel === 'BB')
                && this.props.salesforceId
                && this.props.sfOptions[this.props.salesforceId]
                && Object.keys(this.props.sfOptions[this.props.salesforceId].projectOptions).length > 0
            ) || (
                this.props.channel === 'B0' && Object.keys(this.props.projectOptions).length > 0
            ) || (
                this.props.channel === 'C0' && Object.keys(this.props.projectOptions).length > 0 && !this.props.isOptionalBuilderProjectCenter
            )
        )

        // const needsUse = this.props.isAdmin || !this.props.use
        // if (needsUse) return false

        const formIsFullfilled = this.props.channel === 'A0' || (
            this.props.channel && salesForceIsValid && (needsProjectId ? this.props.projectId : true)
        )

        return formIsFullfilled
    }

    scrollDown = (e) => {
        var element = document.getElementById("seg-content")
        element.scrollTop = element.scrollHeight
    }

    render() {
        return (
            <div>
                <div className="container-fluid">

                    {this.props.savedSegmentations && this.props.savedSegmentations.length > 0 && (
                        <div className="col-12">
                            <div className="form-group pb-2" style={{ marginBottom: 0 }}>
                                <div className="saved-segmentations">
                                    {this.props.savedSegmentations.map(option => (
                                        <div
                                            key={option.name}
                                            className='row no-gutters ml-2'
                                            style={{ cursor: 'auto'}}
                                        >
                                            <div className="col-9" style={{display: "flex", flexDirection: "column", cursor: 'auto'}}>
                                                <strong style={{fontWeight: "bold"}}>{option.name}</strong>
                                                <small>
                                                    <LineSegmentationV2 line={{ ...option, segmentation: option }} showAsList hideSpans showAllNexus={true}/>
                                                </small>
                                            </div>
                                            <div className="bt-delete col-3 text-right" style={{ cursor: 'auto'}}>
                                                <i
                                                    className="far fa-pen bt-delete cy-bt-ok text-hovered segmentation-icon2"
                                                    data-cy="modal_segmentation__bt_segmentation_check"
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        this.editSegmentation(option.name)
                                                    }}
                                                    style={{ cursor: 'pointer', "&before:": {fontSize: "20px"} }}

                                                />
                                                <i
                                                    className="far fa-trash-alt bt-delete cy-bt-del text-hovered segmentation-icon2"
                                                    data-cy="modal_segmentation__bt_segmentation_delete"
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        // this.deleteSegmentation(option.name)
                                                        this.setState({deleteSegmentationPromptOpen: true, toErase: option})
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    <hr />
                    <footer>
                        <div>
                            <Button inverted
                                style={{width:'fit-content', paddingInline:'24px'}}
                                icon="plus" fal
                                onClick={() => {
                                    this.setState({ saveSegmentationPromptOpen: true, active: null })
                                    this.clearSegmentation()
                                }}
                            >
                                &nbsp;
                                <Translation id="create_new_segmentation" defaultMessage="Create new segmentation"/>
                            </Button>
                        </div>
                    </footer>
                </div>

                {this.state.saveSegmentationPromptOpen && (
                    <Modal
                        title={
                            this.state.active ?
                            <Translation id="edit_segmentation" defaultMessage="Edit segmentation" />
                            :
                            <Translation id="create_new_segmentation" defaultMessage="Create new segmentation"/>
                        }
                        onClose={() =>
                            this.setState({
                                saveSegmentationPromptOpen: false,
                                toErase: null,
                                editName: null
                            })
                        }
                        footer={
                            <div style={{display:'flex', columnGap:'16px', width:'100%'}}>
                                <Button style={{width:'50%', fontWeight:500}} onClick={() => this.setState({ saveSegmentationPromptOpen: false, active: null, editName: null })}>
                                    <Translation id="cancel" defaultMessage="Cancel" />
                                </Button>
                                <Button style={{width:'50%', fontWeight:500}} className="w-50" onClick={() => this.saveSegmentation(this.state.editName)}
                                        inverted
                                        disabled={!this.state.editName || !this.isFormFullFilled()}>
                                    {
                                        this.state.active ?
                                        <Translation id="save_changes" defaultMessage="Save changes" />
                                        :
                                        <Translation id="create_segmentation" defaultMessage="Create segmentation" />
                                    }
                                </Button>

                            </div>

                        }
                    >
                        <SegmentationForm {...this.props}
                                          name={this.state.editName}
                                          setNameInput={name => {
                                              this.setState({editName: name})
                                          }}
                        />
                    </Modal>
                )}

                {this.state.deleteSegmentationPromptOpen && (
                    <Modal
                        title={
                            <Translation id="sure_delete_segmentation"
                                         defaultMessage="Are you sure you want to delete this segmentation?" />
                        }
                        onClose={() =>
                            this.setState({
                                deleteSegmentationPromptOpen: false
                            })
                        }
                        footer={
                            <div style={{display:'flex', columnGap:'16px', width:'100%'}}>
                                <Button style={{width:'50%', fontWeight:500}} onClick={
                                    () => this.setState({deleteSegmentationPromptOpen: false, toErase: null})
                                }>
                                    <Translation id="cancel" defaultMessage="Cancel" />
                                </Button>
                                <Button style={{width:'50%', fontWeight:500}}
                                        className="w-50"
                                        onClick={() => {
                                            this.props.deletePreferredSegmentation(this.state.toErase.name)
                                            this.setState({deleteSegmentationPromptOpen: false, toErase: null})
                                        }}
                                        inverted
                                >
                                    <Translation id="clear_segmentation" defaultMessage="Clear segmentation" />
                                </Button>
                            </div>
                        }
                    >
                        <div style={{
                            color:'#232323',
                            fontWeight: 400,
                            lineHeight: '1.6em'
                        }}>
                            <Translation id="name" defaultMessage="Name"/>:&nbsp;
                            <strong>{this.state.toErase.name}</strong>
                            <div>
                                <Translation id="business_segment" defaultMessage="Business Segment"/>:&nbsp;
                                <strong>{this.getBusinessSegment(this.state.toErase.businessSegment)}</strong>
                            </div>
                            {
                                this.state.toErase.channelName &&
                                <div>
                                    <Translation id="channel" defaultMessage="Channel"/>:&nbsp;
                                    <strong>{this.state.toErase.channelName}</strong>
                                </div>
                            }
                            {
                                this.getSalesforceAccount(this.state.toErase.channel, this.state.toErase.salesforceId) &&
                                <div>
                                    <Translation id="account" defaultMessage="Account"/>:&nbsp;
                                    <strong>{this.getSalesforceAccount(this.state.toErase.channel, this.state.toErase.salesforceId)}</strong>
                                </div>
                            }
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}
export default injectIntl(SegmentationConfiguration)
