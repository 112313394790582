import React, { useContext } from 'react'
import RectangleResultV2UI from './RectangleResultV2UI'
import ProductContext from './contexts/ProductContext'

const RectangleResult = props => {
    const context = useContext(ProductContext)
    const logic = context.logicComponent(props)
    const Indicators = context.indicatorsComponent
    const Details = context.detailsComponent

    const productProps = {
        ...logic,
        indicators: <Indicators {...logic} />,
        details: <Details {...logic} />
    }

    return <RectangleResultV2UI {...productProps} />
}

export default RectangleResult
